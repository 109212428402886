"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _lifehikesInitData = require("@/lifehikes-init-data");
var _log = require("@/utils/log");
var _cookie = require("@/utils/cookie");
var _resource = require("@/api/resource");
var _publicFile = require("@/api/publicFile");
var _moment = _interopRequireDefault(require("moment"));
var _ga = require("@/utils/ga");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ResourceDetail',
  components: {
    Tinymce: _Tinymce.default
  },
  props: {
    displayType: {
      type: String,
      default: 'create'
    }
  },
  data: function data() {
    return {
      validImageExtension: _lifehikesInitData.validImageExtension,
      fullscreenloading: false,
      typeList: _lifehikesInitData.resourceType,
      subtypeList: ['event-resource', 'learn-grow-content', 'bubble-coach-content'],
      icons: ['doc', 'doc-tips', 'video', 'trail-guide'],
      iconNames: {
        'doc': 'Document',
        'doc-tips': 'Document tips',
        'video': 'Video',
        'trail-guide': 'Trail Guide'
      },
      mimeList: _lifehikesInitData.resourceMimeType,
      createButtonDisable: false,
      showThumbnailUploadErrMsg: false,
      file: null,
      thumbnailFile: null,
      tagInputValue: '',
      /* for tags component user */
      tagInputVisible: false,
      /* for tags component user */
      resourceForm: {
        id: '',
        type: '',
        category: 'event-resource',
        free: false,
        title: '',
        description: '',
        thumbnailUrl: '',
        icon: 'doc',
        filename: '',
        mimeType: '',
        shareable: true,
        isPublished: true,
        tags: []
      }
    };
  },
  computed: {
    isRichText: function isRichText() {
      return this.resourceForm.type === 'text';
    },
    contentShortLength: function contentShortLength() {
      return this.postForm.content_short.length;
    },
    isRoot: function isRoot() {
      var user = (0, _cookie.getCookie)('ROLES');
      return user.indexOf('root') >= 0;
    }
  },
  created: function created() {
    if (this.displayType === 'edit') {
      this.getResource();
    } else {
      // this.fullscreenloading = true
      /* binding data */
      // this.fullscreenloading = false
    }
  },
  methods: {
    doAddSave: function doAddSave() {
      var _this = this;
      this.fullscreenloading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');

      /* remove the mime if no file is attach */
      if (this.resourceForm.filename === '') {
        delete this.resourceForm.filename;
        delete this.resourceForm.mimeType;
      }
      var payload = this.resourceForm;
      payload.status = this.resourceForm.isPublished ? 'published' : 'archived';
      (0, _resource.createResource)(token, payload).then(function (response) {
        var data = response.data;
        var resourceId = data.id;
        (0, _log.showDebugLog)('Resource-createResource-API-response', data);
        var url = typeof data.upload === 'undefined' ? '' : data.upload.url;
        _this.resourceForm.id = typeof data.id === 'undefined' ? '' : data.id;
        _this.resourceForm.free = typeof data.free === 'undefined' ? false : data.free;
        _this.resourceForm.type = typeof data.type === 'undefined' ? '' : data.type;
        _this.resourceForm.title = typeof data.title === 'undefined' ? '' : data.title;
        _this.resourceForm.description = typeof data.description === 'undefined' ? '' : data.description;
        _this.resourceForm.thumbnailUrl = typeof data.thumbnailUrl === 'undefined' ? '' : data.thumbnailUrl;
        _this.resourceForm.filename = typeof data.filename === 'undefined' ? '' : data.filename;
        _this.resourceForm.mimeType = typeof data.mimeType === 'undefined' ? '' : data.mimeType;
        _this.resourceForm.tags = typeof data.tags === 'undefined' ? '' : data.tags;
        _this.resourceForm.shareable = typeof data.shareable === 'undefined' ? false : data.shareable;
        _this.resourceForm.isPublished = data.status === 'published';
        _this.resourceForm.category = typeof data.category === 'undefined' ? false : data.category;
        _this.resourceForm.icon = typeof data.category === 'undefined' ? false : data.icon;
        if (url && _this.file) {
          _this.uploadS3File(url, resourceId, token);
        } else {
          _this.createButtonDisable = true;
          _this.fullscreenloading = false;
          var msg = _this.$i18n.t('otrResource.successMsg');
          (0, _log.displayPopupMessage)(msg, 'success');
          (0, _ga.writeGaEvent)(_this, 'Resource', "".concat(_lifehikesInitData.userEvent.resource.create), oEmail, "Resource ID: ".concat(_this.resourceForm.id));
          // this.$message({ type: 'success', showClose: true, message: 'Resource created successfully' })
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('Resource-createResource-API-error', error);
        _this.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    doSave: function doSave() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');
      var id = typeof this.$route.params.id === 'undefined' ? this.resourceForm.id : this.$route.params.id;
      this.fullscreenloading = true;

      /* remove the mime if no file is attach */
      if (this.resourceForm.filename === '') {
        delete this.resourceForm.filename;
        delete this.resourceForm.mimeType;
      }
      var payload = this.resourceForm;
      payload.status = this.resourceForm.isPublished ? 'published' : 'archived';
      // console.log('Update form data', this.resourceForm.tags)

      (0, _resource.updateResourceById)(token, id, payload).then(function (response) {
        // console.log('Update-Resource-API', response.data)

        // this.fullscreenloading = false
        var data = response.data;
        var resourceId = data.id;
        var url = typeof data.upload === 'undefined' ? '' : data.upload.url;
        _this2.resourceForm.id = typeof data.id === 'undefined' ? '' : data.id;
        _this2.resourceForm.free = typeof data.free === 'undefined' ? false : data.free;
        _this2.resourceForm.type = typeof data.type === 'undefined' ? '' : data.type;
        _this2.resourceForm.title = typeof data.title === 'undefined' ? '' : data.title;
        _this2.resourceForm.description = typeof data.description === 'undefined' ? '' : data.description;
        _this2.resourceForm.thumbnailUrl = typeof data.thumbnailUrl === 'undefined' ? '' : data.thumbnailUrl;
        _this2.resourceForm.filename = typeof data.filename === 'undefined' ? '' : data.filename;
        _this2.resourceForm.mimeType = typeof data.mimeType === 'undefined' ? '' : data.mimeType;
        _this2.resourceForm.tags = typeof data.tags === 'undefined' ? '' : data.tags;
        _this2.resourceForm.shareable = typeof data.shareable === 'undefined' ? false : data.shareable;
        _this2.resourceForm.isPublished = data.status === 'published';
        _this2.resourceForm.category = typeof data.category === 'undefined' ? '' : data.category;
        _this2.resourceForm.icon = typeof data.icon === 'undefined' ? '' : data.icon;
        if (url && _this2.file) {
          _this2.uploadS3File(url, resourceId, token);
        } else {
          _this2.createButtonDisable = true;
          _this2.fullscreenloading = false;
          var msg = _this2.$i18n.t('otrResource.updateOKMsg');
          (0, _log.displayPopupMessage)(msg, 'success');
          (0, _ga.writeGaEvent)(_this2, 'Resource', "".concat(_lifehikesInitData.userEvent.resource.edit), oEmail, "Resource ID: ".concat(_this2.resourceForm.id));
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('Resource-GetResource-API-Error', error);
        _this2.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    doBack: function doBack() {
      this.$router.push('/resources/index');
    },
    uploadS3File: function uploadS3File(url, resourceId, token) {
      var _this3 = this;
      // upload file to S3
      (0, _resource.uploadToS3)(this.file, url).then(function (response) {
        // console.log('S3 URL', url)
        // console.log('Resource-uploadToS3-API', response.data)

        // Sync the data afer upload to S3
        (0, _resource.syncResource)(token, resourceId).then(function (response) {
          _this3.createButtonDisable = true;
          _this3.fullscreenloading = false;
          var msg = _this3.$i18n.t('otrResource.successMsg');
          (0, _log.displayPopupMessage)(msg, 'success');
        }).catch(function (error) {
          (0, _log.showDebugLog)('Resource-uploadToS3Sync-API-API-error', error);
          _this3.fullscreenloading = false;
          (0, _log.displayPopupMessage)(error, 'error');
        });
      }).catch(function (error) {
        (0, _log.showDebugLog)('Resource-uploadTS3File-API-API-error', error);
        // console.log('Resource-uploadTS3File-API-API-error-original', error)
        _this3.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    doThumbnailUpload: function doThumbnailUpload() {
      var _this4 = this;
      (0, _log.showDebugLog)('Debug-uploadIconFile', this.thumbnailFile);
      this.fullscreenloading = true;
      var thumbnailIconForm = new FormData();
      thumbnailIconForm.append('file', this.thumbnailFile);
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _publicFile.uploadFile)(token, thumbnailIconForm).then(function (response) {
        _this4.fullscreenloading = false;
        _this4.showThumbnailUploadErrMsg = false;
        _this4.resourceForm.thumbnailUrl = response.data.location;
      }).catch(function (error) {
        (0, _log.showDebugLog)('upload-resourceThumbnail-icon-api-error', error);
        _this4.fullscreenloading = false;
        _this4.showThumbnailUploadErrMsg = true;
      });
    },
    chooseFile: function chooseFile(e) {
      (0, _log.showDebugLog)('Debug-uploadTrainingImg', e.target.files[0]);
      this.file = e.target.files[0];
      this.resourceForm.filename = typeof this.file.name === 'undefined' ? '' : this.file.name;
      this.resourceForm.mimeType = typeof this.file.type === 'undefined' ? '' : this.file.type;
      (0, _log.showDebugLog)('choose file', this.file);
    },
    chooseThumbnailFile: function chooseThumbnailFile(e) {
      if (e.target.files.length > 0) {
        (0, _log.showDebugLog)('Debug-uploadTrainingImg', e.target.files[0]);
        if (e.target.files[0].size <= 1048576) {
          this.thumbnailFile = e.target.files[0];
        } else {
          (0, _log.displayPopupMessage)(this.$i18n.t('otrTraining.imageLimit'), 'error');
          this.$refs.thumbfile.value = '';
        }
      }
    },
    getResource: function getResource() {
      var _this5 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var id = typeof this.$route.params.id === 'undefined' ? this.resourceForm.id : this.$route.params.id;
      this.fullscreenloading = true;
      (0, _resource.getResourceById)(token, id).then(function (response) {
        _this5.fullscreenloading = false;
        (0, _log.showDebugLog)('getResourceId-API', response.data);
        var data = response.data;
        _this5.resourceForm.id = typeof data.id === 'undefined' ? '' : data.id;
        _this5.resourceForm.free = typeof data.free === 'undefined' ? false : data.free;
        _this5.resourceForm.type = typeof data.type === 'undefined' ? '' : data.type;
        _this5.resourceForm.title = typeof data.title === 'undefined' ? '' : data.title;
        _this5.resourceForm.description = typeof data.description === 'undefined' ? '' : data.description;
        _this5.resourceForm.thumbnailUrl = typeof data.thumbnailUrl === 'undefined' ? '' : data.thumbnailUrl;
        _this5.resourceForm.filename = typeof data.filename === 'undefined' ? '' : data.filename;
        _this5.resourceForm.tags = typeof data.tags === 'undefined' ? '' : data.tags;
        _this5.resourceForm.mimeType = typeof data.mimeType === 'undefined' ? '' : data.mimeType;
        _this5.resourceForm.shareable = typeof data.shareable === 'undefined' ? false : data.shareable;
        _this5.resourceForm.isPublished = data.status === 'published';
        _this5.resourceForm.category = typeof data.category === 'undefined' ? '' : data.category;
        _this5.resourceForm.icon = typeof data.icon === 'undefined' ? '' : data.icon;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Resource-GetResource-API-Error', error);
        _this5.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    /* for tag component use */handleClose: function handleClose(tag) {
      this.resourceForm.tags.splice(this.resourceForm.tags.indexOf(tag), 1);
    },
    handleTagInputConfirm: function handleTagInputConfirm() {
      var inputValue = this.tagInputValue;
      if (inputValue && this.resourceForm.tags.indexOf(inputValue) < 0) {
        this.resourceForm.tags.push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    },
    showTagInput: function showTagInput() {
      var _this6 = this;
      this.tagInputVisible = true;
      this.$nextTick(function (_) {
        _this6.$refs.saveTagInput.$refs.input.focus();
      });
    }
  }
};