var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "otr-line-spacing__30",
          attrs: { gutter: 20, width: "100%" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("search-box", {
                attrs: {
                  placeholder:
                    "Keyword Search: Title/ Internal Title/ Description/ SF Num",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search(1)
                  },
                },
                model: {
                  value: _vm.query.q,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "q", $$v)
                  },
                  expression: "query.q",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search(1)
                    },
                  },
                },
                [_vm._v("Search")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/hike-advanced-report/create" } } },
                [
                  _c("el-button", { attrs: { type: "success" } }, [
                    _vm._v("Create"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.reportList,
                    fit: "",
                    "highlight-current-row": "",
                    height: _vm.height,
                  },
                  on: { "sort-change": _vm.sort },
                },
                [
                  _vm._v(">\n\n        "),
                  _c("el-table-column", {
                    attrs: { label: "ID", fixed: "", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isExipred(scope.row.end.utc)
                              ? _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "mini" } },
                                  [_vm._v("Expired")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(scope.row.id))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Title",
                      fixed: "",
                      width: "250",
                      sortable: "custom",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              { staticClass: "otr-word-break" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/hike-advanced-report/edit/" +
                                        scope.row.id,
                                    },
                                  },
                                  [_c("u", [_vm._v(_vm._s(scope.row.title))])]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Internal Title",
                      width: "250",
                      sortable: "custom",
                      prop: "internal.title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getInternalTitle")(scope.row.internal)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        width: "100",
                        sortable: "custom",
                        prop: "internal.opportunityNumber",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "otr-word-break" }, [
                                _vm._v(
                                  _vm._s(
                                    typeof scope.row.internal === "undefined"
                                      ? ""
                                      : scope.row.internal.opportunityNumber
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v("\n            Salesforce"),
                        _c("br"),
                        _vm._v("\n            Opportunity"),
                        _c("br"),
                        _vm._v("\n            Number\n          "),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        width: "150",
                        sortable: "custom",
                        prop: "start",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.start.utc,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.start.local,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [_vm._v("UTC Start Time /")]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { color: "blue" } }, [
                          _vm._v("Local Start Time"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "End Time",
                        width: "150",
                        sortable: "custom",
                        prop: "end",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.end.utc,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.end.local,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [_vm._v("UTC End Time /")]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { color: "blue" } }, [
                          _vm._v("Local End Time"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Updated At",
                      width: "120",
                      sortable: "custom",
                      prop: "updatedAt",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.updatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Actions", width: "400" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "User Preview",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "10px 10px" },
                                    attrs: { type: "primary" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.getUserPreviewUrl(
                                            scope.row.id
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-share",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Delete Report",
                                  placement: "top",
                                },
                              },
                              [
                                _vm.checkPermission([
                                  "root",
                                  "otr-digital-producer",
                                ])
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteHikeAdvReport(
                                              scope.row.id,
                                              scope.row.title
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete-solid",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.query.limit,
                      "pager-count": 5,
                      total: _vm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }