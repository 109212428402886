var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullscreenloading,
          expression: "fullscreenloading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
        },
        [
          _c("el-tab-pane", [
            _c(
              "span",
              { attrs: { slot: "label" }, slot: "label" },
              [
                _c("svg-icon", { attrs: { "icon-class": "hike" } }),
                _vm._v("Loop Info"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.displayType === "create"
                  ? _c("loop-geninfo", {
                      attrs: { "parent-loop": _vm.loopForm },
                      on: { updateParentGenInfo: _vm.updateParentGenInfo },
                    })
                  : _vm._e(),
                _vm._v(" "),
                (_vm.displayType === "edit" ||
                  _vm.displayType === "createfromcopy") &&
                _vm.loopForm.id
                  ? _c("loop-geninfo", {
                      attrs: { "parent-loop": _vm.loopForm },
                      on: { updateParentGenInfo: _vm.updateParentGenInfo },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Programs"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.loopForm.id
                  ? _c("loop-program", {
                      attrs: {
                        "parent-programs": _vm.loopForm.programs,
                        "internal-title": _vm.loopForm.internal.title,
                        "parent-id": _vm.loopForm.id,
                      },
                      on: {
                        updateParentProgram: _vm.updateParentProgram,
                        updateSelectShowButton: _vm.updateSelectShowButton,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Staffs"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.loopForm.id
                  ? _c("loop-staff", {
                      attrs: {
                        "parent-id": _vm.loopForm.id,
                        "internal-title": _vm.loopForm.internal.title,
                        loadStaff:
                          this.$route.query.s === undefined
                            ? "true"
                            : this.$route.query.s,
                      },
                      on: { updateParentStaff: _vm.updateParentStaff },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          true
            ? _c("el-tab-pane", [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v("Activation Code"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm.loopForm.id && _vm.displayType === "edit"
                      ? _c("activation-code", {
                          attrs: {
                            "parent-id": _vm.loopForm.id,
                            "internal-title": _vm.loopForm.internal.title,
                            title: _vm.loopForm.title,
                            "code-type": "loop",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Participants"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.loopForm.id && _vm.displayType === "edit"
                  ? _c("loop-participant", {
                      attrs: {
                        "parent-id": _vm.loopForm.id,
                        "internal-title": _vm.loopForm.internal.title,
                        "parent-staff": _vm.oldClientPocList,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Email Settings"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.loopForm.id
                  ? _c("loop-email-setting", {
                      attrs: {
                        "parent-id": _vm.loopForm.id,
                        "parent-automated-email": _vm.loopForm.automatedEmail,
                      },
                      on: {
                        updateParentEmailRuleSet: _vm.updateParentEmailRuleSet,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("draft")
                    },
                  },
                },
                [_vm._v("Create as Draft")]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("preview")
                    },
                  },
                },
                [_vm._v("Save as Preview")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("published")
                    },
                  },
                },
                [_vm._v("Create as Published")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("archived")
                    },
                  },
                },
                [_vm._v("Create as archived")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("draft")
                    },
                  },
                },
                [_vm._v("Create Copy as Draft")]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("preview")
                    },
                  },
                },
                [_vm._v("Save as Preview")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.checkMandateFields(),
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("published")
                    },
                  },
                },
                [_vm._v("Create Copy as Published")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("archived")
                    },
                  },
                },
                [_vm._v("Create Copy as archived")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("draft")
                    },
                  },
                },
                [_vm._v("Save as Draft")]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("preview")
                    },
                  },
                },
                [_vm._v("Save as Preview")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.checkMandateFields(),
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("published")
                    },
                  },
                },
                [_vm._v("Save as Publish")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("archived")
                    },
                  },
                },
                [_vm._v("Save as Archive")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/loop" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }