var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "otr-line-spacing__30",
          attrs: { gutter: 20, width: "100%" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("search-box", {
                attrs: {
                  placeholder:
                    "Keyword Search: ID / Title / Internal Title / Description / SF Num",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search(1)
                  },
                },
                model: {
                  value: _vm.query.q,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "q", $$v)
                  },
                  expression: "query.q",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  ref: "status",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Status", clearable: "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("status")
                    },
                  },
                  model: {
                    value: _vm.query.publish,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "publish", $$v)
                    },
                    expression: "query.publish",
                  },
                },
                _vm._l(_vm.trainingStatus, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  ref: "country",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Country", clearable: "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("country")
                    },
                  },
                  model: {
                    value: _vm.query.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "country", $$v)
                    },
                    expression: "query.country",
                  },
                },
                _vm._l(_vm.countryList, function (item) {
                  return _c("el-option", {
                    key: item.countryCode,
                    attrs: { label: item.country, value: item.countryCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              false
                ? _c(
                    "el-checkbox",
                    {
                      staticStyle: { "padding-top": "10px" },
                      on: { change: _vm.setMyTraining },
                      model: {
                        value: _vm.myProducedTraining,
                        callback: function ($$v) {
                          _vm.myProducedTraining = $$v
                        },
                        expression: "myProducedTraining",
                      },
                    },
                    [_vm._v("My Own Training")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  ref: "producedBy",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Produced By", clearable: "" },
                  on: { change: _vm.setMyTraining },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("producedBy")
                    },
                  },
                  model: {
                    value: _vm.query.staffUuid,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "staffUuid", $$v)
                    },
                    expression: "query.staffUuid",
                  },
                },
                _vm._l(_vm.staffList, function (s) {
                  return _c("el-option", {
                    key: s.user,
                    attrs: { label: s.name, value: s.user },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search(1)
                    },
                  },
                },
                [_vm._v("Search")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/training/create" } } },
                [
                  _c("el-button", { attrs: { type: "success" } }, [
                    _vm._v("Create"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.trainingList,
                    fit: "",
                    "highlight-current-row": "",
                    height: _vm.height,
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "sort-change": _vm.sort,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", fixed: "", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isExipred(scope.row.end.utc)
                              ? _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "mini" } },
                                  [_vm._v("Expired")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(scope.row.id))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "LOC", fixed: "", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.address.country)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.title"),
                      fixed: "",
                      width: "250",
                      sortable: "custom",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              { staticClass: "otr-word-break" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/training/edit/" + scope.row.id,
                                    },
                                  },
                                  [_c("u", [_vm._v(_vm._s(scope.row.title))])]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.internalTitle"),
                      width: "250",
                      sortable: "custom",
                      prop: "internal.title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getInternalTitle")(scope.row.internal)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      sortable: "custom",
                      prop: "internal.opportunityNumber",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm._v("\n            Salesforce"),
                            _c("br"),
                            _vm._v("\n            Opportunity"),
                            _c("br"),
                            _vm._v("\n            Number\n          "),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  typeof scope.row.internal === "undefined"
                                    ? ""
                                    : scope.row.internal.opportunityNumber
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "150", sortable: "custom", prop: "start" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v("UTC Start Time /")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "blue" } }, [
                              _vm._v("Local Start Time"),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.start.utc,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "blue" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.start.local,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "End Time",
                      width: "150",
                      sortable: "custom",
                      prop: "end",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v("UTC End Time /")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "blue" } }, [
                              _vm._v("Local End Time"),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.end.utc,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "blue" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.end.local,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.producer"),
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getProducerStaff")(
                                    scope.row.staff,
                                    _vm.staffList
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.status"),
                      width: "100",
                      sortable: "custom",
                      prop: "publish",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.publish))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otr.updatedAt"),
                      width: "120",
                      sortable: "custom",
                      prop: "updatedAt",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.updatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.actions"),
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/training/edit/" + scope.row.id },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Edit",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "edit" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Copy Event",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "10px 10px" },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDrawer(
                                          scope.row.id,
                                          scope.row.title
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-document-copy",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path:
                                          "/training/feedback/" + scope.row.id,
                                        query: { title: scope.row.title },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-s-comment",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/report/attendance/" + scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Event's Attendance Report",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "peoples" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      "/training/userProgress/" + scope.row.id,
                                    query: { title: scope.row.title },
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "User Progress",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "chart" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Delete Event",
                                  placement: "top",
                                },
                              },
                              [
                                _vm.checkPermission([
                                  "root",
                                  "otr-digital-producer",
                                ])
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteTraining(
                                              scope.row.id,
                                              scope.row.title
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete-solid",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.query.limit,
                      "pager-count": 5,
                      total: _vm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.checked.drawTitle,
            visible: _vm.copyDrawer,
            direction: "ttb",
            "before-close": _vm.handleDrawClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.copyDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "20px" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "title", $$v)
                    },
                    expression: "checked.title",
                  },
                },
                [_vm._v("Title")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.internalTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "internalTitle", $$v)
                    },
                    expression: "checked.internalTitle",
                  },
                },
                [_vm._v("InternalTitle")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "description", $$v)
                    },
                    expression: "checked.description",
                  },
                },
                [_vm._v("Descriptions")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.sessions,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "sessions", $$v)
                    },
                    expression: "checked.sessions",
                  },
                },
                [_vm._v("Sessions (include long and short description)")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.sessionDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "sessionDate", $$v)
                    },
                    expression: "checked.sessionDate",
                  },
                },
                [_vm._v("Session Date")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.resources,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "resources", $$v)
                    },
                    expression: "checked.resources",
                  },
                },
                [_vm._v("Resources")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.webinarInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "webinarInfo", $$v)
                    },
                    expression: "checked.webinarInfo",
                  },
                },
                [_vm._v("Webinar Info")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.clientName,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "clientName", $$v)
                    },
                    expression: "checked.clientName",
                  },
                },
                [_vm._v("ClientName")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "40px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "10px 10px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.copy },
                    },
                    [_vm._v("Copy")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }