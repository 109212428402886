var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { staticStyle: { width: "100%" }, attrs: { data: _vm.spData } },
    [
      _c("el-table-column", {
        attrs: { prop: "tag", label: "Placeholder tag", "min-width": "30%" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          prop: "description",
          label: "Description",
          "min-width": "30%",
        },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "example", label: "Example", "min-width": "40%" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("p", { domProps: { innerHTML: _vm._s(scope.row.example) } }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }