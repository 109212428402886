"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.autoAddParticipant = autoAddParticipant;
exports.createTraining = createTraining;
exports.deleteTrainingById = deleteTrainingById;
exports.getTeamAndUserTraining = getTeamAndUserTraining;
exports.getTrainingById = getTrainingById;
exports.getTrainingList = getTrainingList;
exports.getTrainingManagerReportRecipients = getTrainingManagerReportRecipients;
exports.getTrainingStaff = getTrainingStaff;
exports.getTrainingTeams = getTrainingTeams;
exports.getUserTraining = getUserTraining;
exports.sendManagerReport = sendManagerReport;
exports.unenrolParticipant = unenrolParticipant;
exports.updateTrainingById = updateTrainingById;
exports.updateTrainingManagerReportRecipients = updateTrainingManagerReportRecipients;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTrainingList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getTrainingById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings/' + id,
    method: 'get',
    headers: headers
  });
}
function getTrainingTeams(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings/' + id + '/teams',
    method: 'get',
    headers: headers
  });
}
function getTrainingStaff(token, id, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings/' + id + '/staff',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getTrainingManagerReportRecipients(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/manager-report/trainings/' + id,
    method: 'get',
    headers: headers
  });
}
function updateTrainingManagerReportRecipients(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/manager-report/trainings/' + id,
    method: 'post',
    headers: headers,
    data: data
  });
}
function createTraining(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/trainings',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateTrainingById(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/trainings/' + id,
    method: 'put',
    headers: headers,
    data: data
  });
}
function deleteTrainingById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings/' + id,
    method: 'delete',
    headers: headers
  });
}
function sendManagerReport(token, trainingId, email, participants) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = {
    trainingId: trainingId,
    participants: participants,
    email: email
  };
  return (0, _request.default)({
    url: '/user-statistics/coach/manager-report',
    method: 'post',
    headers: headers,
    data: data
  });
}
function autoAddParticipant(token, account) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var res = (0, _request.default)({
    url: '/user-trainings/register-enroll',
    method: 'post',
    headers: headers,
    data: account
  });
  return res;
}
function getUserTraining(token, userUUid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var query = {
    page: 1,
    sort: '-createdAt',
    user: userUUid,
    limit: 100,
    populate: false
  };
  var res = (0, _request.default)({
    url: '/user-trainings',
    method: 'get',
    headers: headers
  });
  return res;
}
function getTeamAndUserTraining(token, trainingId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var res = (0, _request.default)({
    url: "/trainings/".concat(trainingId, "/teamsWithUserTraining"),
    method: 'get',
    headers: headers
  });
  return res;
}
function unenrolParticipant(token, trainingId, userUuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var res = (0, _request.default)({
    url: "/coach-tools/trainings/".concat(trainingId, "/users/").concat(userUuid, "/unenroll"),
    method: 'delete',
    headers: headers
  });
  return res;
}