"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createQuestion = createQuestion;
exports.deleteQuestion = deleteQuestion;
exports.getQuestion = getQuestion;
exports.getQuestionList = getQuestionList;
exports.updateQuestion = updateQuestion;
var _request = _interopRequireDefault(require("@/utils/request"));
function updateQuestion(token, obj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/questions/' + obj.id,
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'put',
    headers: headers,
    data: obj
  });
}
function createQuestion(token, obj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/questions',
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'post',
    headers: headers,
    data: obj
  });
}
function deleteQuestion(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/questions/".concat(id),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'delete',
    headers: headers
  });
}
function getQuestion(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/questions/".concat(id),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers
  });
}
function getQuestionList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/questions',
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers,
    params: query
  });
}