"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueSplitpane = _interopRequireDefault(require("vue-splitpane"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SplitpaneDemo',
  components: {
    splitPane: _vueSplitpane.default
  },
  methods: {
    resize: function resize() {
      console.log('resize');
    }
  }
};