var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.create(_vm.newDefaultRule)
            },
          },
        },
        [_vm._v("Create Default Rule")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm.defaultRule && _vm.defaultRule.length > 0
                ? _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "1em",
                            "padding-right": "5px",
                          },
                        },
                        [_vm._v("Default:")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.defaultRule[0].rule.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.defaultRule[0].rule, "value", $$v)
                          },
                          expression: "defaultRule[0].rule.value",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }