var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.user
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 6, xs: 24 } },
                  [
                    _vm.user.id
                      ? _c("user-card", { attrs: { user: _vm.user } })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 18, xs: 24 } },
                  [
                    _c(
                      "el-card",
                      [
                        _c(
                          "el-tabs",
                          {
                            model: {
                              value: _vm.activeTab,
                              callback: function ($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab",
                            },
                          },
                          [
                            false
                              ? _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "Activity",
                                      name: "activity",
                                    },
                                  },
                                  [_c("activity")],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "Timeline",
                                      name: "timeline",
                                    },
                                  },
                                  [_c("timeline")],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "General", name: "account" } },
                              [
                                _vm.user.id
                                  ? _c("account", { attrs: { user: _vm.user } })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "Address", name: "address" } },
                              [
                                _vm.user.id
                                  ? _c("Address", { attrs: { user: _vm.user } })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "Education",
                                  name: "education",
                                },
                              },
                              [
                                _vm.user.id
                                  ? _c("Education", {
                                      attrs: { user: _vm.user },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "Badges", name: "badge" } },
                              [
                                _vm.user.id
                                  ? _c("Badge", {
                                      attrs: { badges: _vm.user.badges },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }