"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteFile = deleteFile;
exports.getPublicFileList = getPublicFileList;
exports.uploadFile = uploadFile;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPublicFileList(token, form) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/files/public/list',
    method: 'post',
    headers: headers,
    data: form
  });
}
function uploadFile(token, fileData) {
  var headers = {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'multipart/form-data'
  };
  return (0, _request.default)({
    url: '/files/public',
    method: 'post',
    data: fileData,
    headers: headers
  });
}
function deleteFile(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/files/public',
    method: 'delete',
    data: data,
    headers: headers
  });
}