"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.array.find");
var _cookie = require("@/utils/cookie");
var _hike = require("@/api/hike");
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _lodash = _interopRequireDefault(require("lodash"));
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var defaultValue = [];
var _default = exports.default = {
  name: 'HikeSurvey',
  components: {
    EventTime: _index.default
  },
  props: {
    parentId: {
      type: String,
      default: ''
    },
    parentStages: {
      type: Array,
      default: defaultValue
    },
    survey: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    // console.log('parentId:', this.parentId)
    // console.log('survey:', this.survey)

    return {
      loading: false,
      surveyForm: _lodash.default.cloneDeep(this.survey),
      surveyData: [{
        key: 'benchmarkSelfAssessment',
        label: 'Benchmark Self Assessment Survey',
        downloadLoading: false
      }, {
        key: 'benchmarkBoss',
        label: 'Benchmark Boss Survey',
        downloadLoading: false
      }, {
        key: 'benchmarkPeer',
        label: 'Benchmark Peer Survey',
        downloadLoading: false
      }, {
        key: 'benchmarkDirectReport',
        label: 'Benchmark Direct Report Survey',
        downloadLoading: false
      }, {
        key: 'summitSelfAssessment',
        label: 'Summit Self Assessment Survey',
        downloadLoading: false
      }, {
        key: 'summitBoss',
        label: 'Summit Boss Survey',
        downloadLoading: false
      }, {
        key: 'summitPeer',
        label: 'Summit Peer Survey',
        downloadLoading: false
      }, {
        key: 'summitDirectReport',
        label: 'Summit Direct Report Survey',
        downloadLoading: false
      }],
      surveyResult: null,
      surveyFormChanged: false,
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      // reminder
      collapseActiveNames: ['setting', 'benchmark', 'summit', 'benchmarkReminder', 'summitReminder', 'report'],
      days: [{
        label: 'Monday',
        value: 1
      }, {
        label: 'Tuesday',
        value: 2
      }, {
        label: 'Wednesday',
        value: 3
      }, {
        label: 'Thursday',
        value: 4
      }, {
        label: 'Friday',
        value: 5
      }, {
        label: 'Saturday',
        value: 6
      }, {
        label: 'Sunday',
        value: 0
      }],
      // dialog
      dialogSurveyVisible: false,
      selectedSurveyKey: '',
      surveyQuery: {
        page: 1,
        limit: 99,
        q: undefined,
        sort: 'title'
      },
      surveyOptions: [],
      surveyTableLoading: false
    };
  },
  watch: {
    surveyForm: {
      handler: function handler() {
        this.$emit('updateSurvey', _lodash.default.cloneDeep(this.surveyForm));
      },
      deep: true
    },
    'surveyForm.benchmarkSelfAssessment': 'surveyFormChangedHandler',
    'surveyForm.benchmarkBoss': 'surveyFormChangedHandler',
    'surveyForm.benchmarkPeer': 'surveyFormChangedHandler',
    'surveyForm.benchmarkDirectReport': 'surveyFormChangedHandler',
    'surveyForm.summitSelfAssessment': 'surveyFormChangedHandler',
    'surveyForm.summitBoss': 'surveyFormChangedHandler',
    'surveyForm.summitPeer': 'surveyFormChangedHandler',
    'surveyForm.summitDirectReport': 'surveyFormChangedHandler',
    'surveyForm.start': {
      handler: 'surveyFormChangedHandler',
      deep: true
    },
    'surveyForm.end': {
      handler: 'surveyFormChangedHandler',
      deep: true
    },
    'surveyForm.summitStart': {
      handler: 'surveyFormChangedHandler',
      deep: true
    },
    'surveyForm.summitEnd': {
      handler: 'surveyFormChangedHandler',
      deep: true
    }
  },
  methods: {
    capitalize: function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    openSurveyDialog: function openSurveyDialog(surveyKey) {
      this.dialogSurveyVisible = true;
      this.selectedSurveyKey = surveyKey;
      this.getSurveys();
    },
    getSurveys: function getSurveys() {
      var _this = this;
      this.surveyTableLoading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _hike.getSurveys)(token, this.surveyQuery).then(function (response) {
        var data = response.data;
        _this.surveyOptions = data.rows;
        _this.surveyTableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Survey-API-response', error);
        _this.surveyTableLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    addSurvey: function addSurvey(surveyId) {
      this.surveyForm[this.selectedSurveyKey] = surveyId;
      this.selectedSurveyKey = '';
      this.dialogSurveyVisible = false;
    },
    exportSurveyResult: function exportSurveyResult(survey) {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      survey.downloadLoading = true;
      (0, _hike.getExportSurveyResult)(token, {
        hikeId: this.parentId,
        surveyId: this.surveyForm[survey.key]
      }).then(function (response) {
        (0, _log.showDebugLog)('exportSurveyResult-response', response);
        _this2.surveyResult = response.data;
        if (_this2.surveyResult.length > 0) {
          _this2.header = _this2.surveyResult[0].answers;
        }
        _this2.handleDownload(survey);
      }).catch(function (error) {
        (0, _log.showDebugLog)('hike-ExportSurveyResult-error', error);
        survey.downloadLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    handleDaysInputBlur: function handleDaysInputBlur(type) {
      var reminderSetting = this.surveyForm.reminderSetting[type];
      if (reminderSetting.lastNotifyBeforeEffectiveEnd === undefined) {
        reminderSetting.lastNotifyBeforeEffectiveEnd = 1;
      }
    },
    handleDownload: function handleDownload(survey) {
      var _this3 = this;
      Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var xdata = _this3.buildExcelData();
        excel.export_json_to_excel({
          header: xdata.header,
          data: xdata.data,
          filename: survey.label
        });
        survey.downloadLoading = false;
      });
    },
    buildExcelData: function buildExcelData() {
      var excelData = [];
      var excelHeader = [];
      // build first 4 header rows
      for (var i = 0; i < 4; i++) {
        var row = [];
        row.push(" ");
        row.push(" ");
        row.push(" ");
        row.push(" ");
        row.push(" ");
        if (i == 0) row.push('pageId');
        if (i == 1) row.push('questionId');
        if (i == 2) row.push('questionText');
        if (i == 3) row.push('logicTag');
        if (this.surveyResult.length > 0) {
          for (var j = 0; j < this.header.length; j++) {
            if (i == 0) row.push(this.header[j].pageId + ' ');
            if (i == 1) row.push(this.header[j].questionId + ' ');
            if (i == 2) row.push(this.header[j].questionText + ' ');
            if (i == 3) row.push(this.header[j].logicTag + ' ');
          }
        }
        if (i == 0) {
          excelHeader = row;
        } else {
          excelData.push(row);
        }
      }

      // build data row
      for (var x = -1; x < this.surveyResult.length; x++) {
        var row = [];
        // data row header
        if (x == -1) {
          row.push("hikeId");
          row.push("SurveyId");
          row.push("category");
          row.push("participant Uuid");
          row.push("Participant Name");
          row.push("Responent Email (This is filler email)");
          if (this.surveyResult.length > 0) {
            for (var z = 0; z < this.header.length; z++) {
              row.push('answer');
            }
          }
        } else {
          row.push(this.surveyResult[x].hikeId);
          row.push(this.surveyResult[x].surveyId);
          row.push(this.surveyResult[x].category);
          row.push(this.surveyResult[x].participantUuid);
          row.push(this.surveyResult[x].participantGivenName + ' ' + this.surveyResult[x].participantFamilyName);
          row.push(this.surveyResult[x].participantEmail);
          if (this.surveyResult.length > 0) {
            for (var z = 0; z < this.header.length; z++) {
              var answer = this.surveyResult[x].answers[z] ? this.surveyResult[x].answers[z].answer : '';
              row.push(answer);
            }
          }
        }
        excelData.push(row);
      }
      return {
        data: excelData,
        header: excelHeader
      };
    },
    clearSurvey: function clearSurvey(surveyKey) {
      this.surveyForm[surveyKey] = '';
    },
    copyEndTime: function copyEndTime(type) {
      var stage = this.parentStages.find(function (s) {
        return s.type === type;
      });
      if (stage) {
        if (type === 'benchmark') {
          this.surveyForm.end = _lodash.default.cloneDeep(stage.start);
        } else {
          this.surveyForm.summitEnd = _lodash.default.cloneDeep(stage.start);
        }
      } else {
        (0, _log.displayPopupMessage)("No ".concat(type, " stage"), 'error');
      }
    },
    sendReminderEmail: function sendReminderEmail(type, reminderType) {
      var _this4 = this;
      if (!(this.surveyForm.benchmarkSelfAssessment && this.surveyForm.benchmarkBoss && this.surveyForm.benchmarkPeer && this.surveyForm.benchmarkDirectReport && this.surveyForm.summitSelfAssessment && this.surveyForm.summitBoss && this.surveyForm.summitPeer && this.surveyForm.summitDirectReport)) {
        this.$message({
          message: 'Please fill out all Survey fields before sending the email.',
          type: 'error'
        });
        return;
      }
      if (!(this.surveyForm.start.utc !== '' && this.surveyForm.end.utc !== '' && this.surveyForm.summitStart.utc !== '' && this.surveyForm.summitEnd.utc !== '')) {
        this.$message({
          message: 'Please fill out the Effective Date & Time before sending the email.',
          type: 'error'
        });
        return;
      }
      if (this.surveyFormChanged) {
        this.$message({
          message: 'Please Publish the Hike before sending the email. ',
          type: 'error'
        });
        return;
      }
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.loading = true;
      (0, _hike.reminderTrigger)(token, {
        hikeId: this.parentId,
        surveyCategory: type,
        reminderType: reminderType
      }).then(function (response) {
        if (response.data.message[0].includes('is sent')) {
          var msg = _this4.capitalize(type) + (reminderType === 'weekly' ? ' weekly reminder' : ' final notification') + ' email has been sent';
          (0, _log.displayPopupMessage)(msg, 'success');
        } else {
          (0, _log.displayPopupMessage)(response.data.message[0], 'error');
        }
        _this4.loading = false;
      }).catch(function (error) {
        (0, _log.displayPopupMessage)(error, 'error');
        _this4.loading = false;
      });
    },
    surveyFormChangedHandler: function surveyFormChangedHandler() {
      this.surveyFormChanged = true;
    },
    resetSurveyFormChangedStatus: function resetSurveyFormChangedStatus() {
      this.surveyFormChanged = false;
    }
  }
};