"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DndList = _interopRequireDefault(require("@/components/DndList"));
var _article = require("@/api/article");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DndListDemo',
  components: {
    DndList: _DndList.default
  },
  data: function data() {
    return {
      list1: [],
      list2: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.listLoading = true;
      (0, _article.fetchList)().then(function (response) {
        _this.list1 = response.data.items.splice(0, 5);
        _this.list2 = response.data.items;
      });
    }
  }
};