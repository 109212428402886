var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("About")])]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "pan-thumb",
            {
              attrs: {
                image: _vm.user.profilePic,
                height: "100px",
                width: "100px",
                hoverable: false,
              },
            },
            [_vm._v("\n        Hello\n      ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.user.givenName + " " + _vm.user.familyName) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-upload" },
              on: {
                click: function ($event) {
                  _vm.imagecropperShow = true
                },
              },
            },
            [_vm._v("\n        Change Avatar\n      ")]
          ),
          _vm._v(" "),
          _c("image-cropper", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imagecropperShow,
                expression: "imagecropperShow",
              },
            ],
            key: _vm.imagecropperKey,
            attrs: {
              "no-circle": _vm.noCircle,
              width: 100,
              height: 100,
              "lang-type": "en",
              url: _vm.uploadUrl,
              headers: _vm.headers,
            },
            on: { close: _vm.close, "crop-upload-success": _vm.cropSuccess },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "user-bio" }, [
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "email" } }),
            _c("span", [_vm._v("Email")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v("\n          " + _vm._s(_vm.user.email) + "\n        "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "example" } }),
            _c("span", [_vm._v("Referral Code")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.user.referralCode) +
                " / used " +
                _vm._s(_vm.user.referees.length) +
                " times\n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-skills user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "user" } }),
            _c("span", [_vm._v("Roles")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "user-bio-section-body" },
          _vm._l(_vm.user.role, function (r, index) {
            return _c("div", { key: index, staticClass: "progress-item" }, [
              _c("span", [_vm._v(_vm._s(_vm._f("uppercaseFirst")(r)))]),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "education" } }),
            _c("span", [_vm._v("Account Status")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v("\n          " + _vm._s(_vm.user.status) + "\n        "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "star" } }),
            _c("span", [_vm._v("User Mode")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.user.isGuest === "Y" ? "Guest" : "Registered User") +
                "\n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "guide" } }),
            _c("span", [_vm._v("Step Total")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v("\n          " + _vm._s(_vm.user.stepTotal) + "\n        "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }