var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v("\n     " + _vm._s(_vm.internalTitle) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.openDialog } },
                [_vm._v(_vm._s(_vm.$t("otrTraining.uploadParticipant")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "./template/trainingParticipant.xlsx",
                    download: "",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { "margin-left": "20px", color: "#909399" },
                    },
                    [_c("u", [_vm._v("Excel Template")])]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.myTrainingTeams,
                    fit: "",
                    "highlight-current-row": "",
                    "row-class-name": _vm.tableRowClassName,
                  },
                  on: { "sort-change": _vm.sort_change },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "", type: "index" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Participant ID",
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "uuid",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.uuid))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.name"),
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "givenName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.givenName) +
                                  " " +
                                  _vm._s(scope.row.middleName) +
                                  "\n              " +
                                  _vm._s(scope.row.familyName)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.jobTitle"),
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "jobTitle",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.jobTitle))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.orgName"),
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "orgName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.orgName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Action" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.unenroll(
                                      scope.row.uuid,
                                      scope.row.givenName +
                                        " " +
                                        scope.row.familyName
                                    )
                                  },
                                },
                              },
                              [_vm._v("Un-Enroll")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: {
            visible: _vm.dialogVisible,
            title: this.$i18n.t("otrTraining.uploadParticipant"),
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            dragDialog: _vm.handleDrag,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenloading,
                  expression: "fullscreenloading",
                },
              ],
            },
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("upload-excel-component", {
                        attrs: {
                          "on-success": _vm.handleSuccess,
                          "before-upload": _vm.beforeUpload,
                          drop: _vm.smallDrop,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line_spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px dashed #626366",
                          padding: "10px",
                          margin: "10px 0",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("otrTraining.result")) +
                              ":\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isShowResult
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("otrTraining.noOfPSuccess")) +
                                  ": " +
                                  _vm._s(_vm.noOfSuccess) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isShowResult
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("otrTraining.noOfPFail")) +
                                  ": " +
                                  _vm._s(_vm.noOfFail) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line_spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "span",
                      {
                        staticClass: "otr-form__title",
                        staticStyle: { float: "left", "font-size": "16px" },
                      },
                      [_vm._v(_vm._s(_vm.$t("otrTraining.participantList")))]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("table", { staticClass: "userList" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "30" } }),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "200" } }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("otrTraining.trainingId"))
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "300" } }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("otrTraining.email"))
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "250" } }, [
                            _vm._v(_vm._s(_vm.$t("otrTraining.givenName"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "250" } }, [
                            _vm._v(_vm._s(_vm.$t("otrTraining.familyName"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "200" } }, [
                            _vm._v(_vm._s(_vm.$t("otrTraining.orgName"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "200" } }, [
                            _vm._v(_vm._s(_vm.$t("otrTraining.jobTitle"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "200" } }, [
                            _vm._v(_vm._s(_vm.$t("otrTraining.result"))),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.tableData, function (item, idx) {
                          return _c("tr", { key: idx }, [
                            _c("td", [_vm._v(_vm._s(idx + 1))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.trainingId))]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  class: _vm.EmailFormatErrorStyle(item.email),
                                },
                                [_vm._v(_vm._s(item.email))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.givenName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.familyName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.orgName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.jobTitle))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.result))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doUpload($event)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.upload")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }