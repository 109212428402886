var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c("div", [
        _c("div", { staticStyle: { "font-size": "30px", padding: "20px" } }, [
          _vm._v("\n    Enrollment\n  "),
        ]),
      ])
    : _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "app-container",
        },
        [
          _vm.loop
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "background-color": "#eee",
                    padding: "10px",
                    display: "block",
                  },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-bottom": "10px",
                        padding: "10px 0",
                      },
                    },
                    [_vm._v("ID: " + _vm._s(_vm.loop.id))]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticStyle: { padding: "10px 0" } }, [
                    _vm._v("Title: " + _vm._s(_vm.loop.title)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.loop.internal
                    ? _c("span", { staticStyle: { padding: "10px 0" } }, [
                        _vm._v(
                          "Internal Title: " + _vm._s(_vm.loop.internal.title)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.loop.internal
                    ? _c("span", { staticStyle: { padding: "10px 0" } }, [
                        _vm._v(
                          "Saleforce Opportunity Number: " +
                            _vm._s(_vm.loop.internal.opportunityNumber)
                        ),
                      ])
                    : _vm._e(),
                  _c("br"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "./template/Learning Experience Enrollment Template.csv",
                          download: "",
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#909399" } }, [
                          _c("u", [_vm._v("Batch Enrolment Form template")]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("table", { staticClass: "userList" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "30" } }),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "200" } }, [
                            _vm._v("Participant ID"),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "300" } }, [
                            _vm._v("Name"),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "250" } }, [
                            _vm._v("Title"),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "250" } }, [
                            _vm._v("Company/Organization"),
                          ]),
                          _vm._v(" "),
                          false
                            ? _c("th", { attrs: { width: "100" } })
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.myLoopTeams, function (m, idx) {
                          return _c("tr", { key: idx }, [
                            _c("td", [_vm._v(_vm._s(idx + 1))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(m.uuid))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(m.givenName) +
                                  " " +
                                  _vm._s(m.middleName) +
                                  " " +
                                  _vm._s(m.familyName)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(m.jobTitle))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(m.orgName))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.openDialog },
                        },
                        [_vm._v("Upload Participants")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "10px 10px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.backToLoop },
                        },
                        [_vm._v("Back To Loop")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  directives: [
                    { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
                  ],
                  attrs: {
                    visible: _vm.dialogVisible,
                    title: "Upload Participants",
                    width: "80%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                    dragDialog: _vm.handleDrag,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.fullscreenloading,
                          expression: "fullscreenloading",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("upload-excel-component", {
                                attrs: {
                                  "on-success": _vm.handleSuccess,
                                  "before-upload": _vm.beforeUpload,
                                  drop: _vm.smallDrop,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line_spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "1px dashed #626366",
                                  padding: "10px",
                                  margin: "10px 0",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "5px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("otrTraining.result")) + ":"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isShowResult
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("otrTraining.noOfPSuccess")
                                          ) +
                                          ": " +
                                          _vm._s(_vm.noOfSuccess) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isShowResult
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("otrTraining.noOfPFail")
                                          ) +
                                          ": " +
                                          _vm._s(_vm.noOfFail) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line_spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "span",
                              {
                                staticClass: "otr-form__title",
                                staticStyle: {
                                  float: "left",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v("Participant List")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("table", { staticClass: "userList" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { attrs: { width: "30" } }),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "300" } }, [
                                    _vm._v(_vm._s(_vm.$t("otrTraining.email"))),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "250" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("otrTraining.givenName"))
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "250" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("otrTraining.familyName"))
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "200" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("otrTraining.orgName"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "200" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("otrTraining.jobTitle"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "200" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("otrTraining.result"))
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.tableData, function (item, idx) {
                                  return _c("tr", { key: idx }, [
                                    _c("td", [_vm._v(_vm._s(idx + 1))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          class: _vm.EmailFormatErrorStyle(
                                            item.email
                                          ),
                                        },
                                        [_vm._v(_vm._s(item.email))]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.givenName))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.familyName))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.orgName))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.jobTitle))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.result))]),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doUpload($event)
                            },
                          },
                        },
                        [_vm._v("Upload")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "margin-bottom": "10px", padding: "10px 0" } },
      [_c("u", [_vm._v("Loop Information")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }