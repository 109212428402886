"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _dropzone = _interopRequireDefault(require("dropzone"));
require("dropzone/dist/dropzone.css");
//
//
//
//
//
//

// import { getToken } from 'api/qiniu';

_dropzone.default.autoDiscover = false;
var _default = exports.default = {
  props: {
    id: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    clickable: {
      type: Boolean,
      default: true
    },
    defaultMsg: {
      type: String,
      default: '上传图片'
    },
    acceptedFiles: {
      type: String,
      default: ''
    },
    thumbnailHeight: {
      type: Number,
      default: 200
    },
    thumbnailWidth: {
      type: Number,
      default: 200
    },
    showRemoveLink: {
      type: Boolean,
      default: true
    },
    maxFilesize: {
      type: Number,
      default: 2
    },
    maxFiles: {
      type: Number,
      default: 3
    },
    autoProcessQueue: {
      type: Boolean,
      default: true
    },
    useCustomDropzoneOptions: {
      type: Boolean,
      default: false
    },
    defaultImg: {
      default: '',
      type: [String, Array]
    },
    couldPaste: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dropzone: '',
      initOnce: true
    };
  },
  watch: {
    defaultImg: function defaultImg(val) {
      if (val.length === 0) {
        this.initOnce = false;
        return;
      }
      if (!this.initOnce) return;
      this.initImages(val);
      this.initOnce = false;
    }
  },
  mounted: function mounted() {
    var element = document.getElementById(this.id);
    var vm = this;
    this.dropzone = new _dropzone.default(element, {
      clickable: this.clickable,
      thumbnailWidth: this.thumbnailWidth,
      thumbnailHeight: this.thumbnailHeight,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFilesize,
      dictRemoveFile: 'Remove',
      addRemoveLinks: this.showRemoveLink,
      acceptedFiles: this.acceptedFiles,
      autoProcessQueue: this.autoProcessQueue,
      dictDefaultMessage: '<i style="margin-top: 3em;display: inline-block" class="material-icons">' + this.defaultMsg + '</i><br>Drop files here to upload',
      dictMaxFilesExceeded: '只能一个图',
      previewTemplate: '<div class="dz-preview dz-file-preview">  <div class="dz-image" style="width:' + this.thumbnailWidth + 'px;height:' + this.thumbnailHeight + 'px" ><img style="width:' + this.thumbnailWidth + 'px;height:' + this.thumbnailHeight + 'px" data-dz-thumbnail /></div>  <div class="dz-details"><div class="dz-size"><span data-dz-size></span></div> <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>  <div class="dz-error-message"><span data-dz-errormessage></span></div>  <div class="dz-success-mark"> <i class="material-icons">done</i> </div>  <div class="dz-error-mark"><i class="material-icons">error</i></div></div>',
      init: function init() {
        var _this = this;
        var val = vm.defaultImg;
        if (!val) return;
        if (Array.isArray(val)) {
          if (val.length === 0) return;
          val.map(function (v, i) {
            var mockFile = {
              name: 'name' + i,
              size: 12345,
              url: v
            };
            _this.options.addedfile.call(_this, mockFile);
            _this.options.thumbnail.call(_this, mockFile, v);
            mockFile.previewElement.classList.add('dz-success');
            mockFile.previewElement.classList.add('dz-complete');
            vm.initOnce = false;
            return true;
          });
        } else {
          var mockFile = {
            name: 'name',
            size: 12345,
            url: val
          };
          this.options.addedfile.call(this, mockFile);
          this.options.thumbnail.call(this, mockFile, val);
          mockFile.previewElement.classList.add('dz-success');
          mockFile.previewElement.classList.add('dz-complete');
          vm.initOnce = false;
        }
      },
      accept: function accept(file, done) {
        /* 七牛*/
        // const token = this.$store.getters.token;
        // getToken(token).then(response => {
        //   file.token = response.data.qiniu_token;
        //   file.key = response.data.qiniu_key;
        //   file.url = response.data.qiniu_url;
        //   done();
        // })
        done();
      },
      sending: function sending(file, xhr, formData) {
        // formData.append('token', file.token);
        // formData.append('key', file.key);
        vm.initOnce = false;
      }
    });
    if (this.couldPaste) {
      document.addEventListener('paste', this.pasteImg);
    }
    this.dropzone.on('success', function (file) {
      vm.$emit('dropzone-success', file, vm.dropzone.element);
    });
    this.dropzone.on('addedfile', function (file) {
      vm.$emit('dropzone-fileAdded', file);
    });
    this.dropzone.on('removedfile', function (file) {
      vm.$emit('dropzone-removedFile', file);
    });
    this.dropzone.on('error', function (file, error, xhr) {
      vm.$emit('dropzone-error', file, error, xhr);
    });
    this.dropzone.on('successmultiple', function (file, error, xhr) {
      vm.$emit('dropzone-successmultiple', file, error, xhr);
    });
  },
  destroyed: function destroyed() {
    document.removeEventListener('paste', this.pasteImg);
    this.dropzone.destroy();
  },
  methods: {
    removeAllFiles: function removeAllFiles() {
      this.dropzone.removeAllFiles(true);
    },
    processQueue: function processQueue() {
      this.dropzone.processQueue();
    },
    pasteImg: function pasteImg(event) {
      var items = (event.clipboardData || event.originalEvent.clipboardData).items;
      if (items[0].kind === 'file') {
        this.dropzone.addFile(items[0].getAsFile());
      }
    },
    initImages: function initImages(val) {
      var _this2 = this;
      if (!val) return;
      if (Array.isArray(val)) {
        val.map(function (v, i) {
          var mockFile = {
            name: 'name' + i,
            size: 12345,
            url: v
          };
          _this2.dropzone.options.addedfile.call(_this2.dropzone, mockFile);
          _this2.dropzone.options.thumbnail.call(_this2.dropzone, mockFile, v);
          mockFile.previewElement.classList.add('dz-success');
          mockFile.previewElement.classList.add('dz-complete');
          return true;
        });
      } else {
        var mockFile = {
          name: 'name',
          size: 12345,
          url: val
        };
        this.dropzone.options.addedfile.call(this.dropzone, mockFile);
        this.dropzone.options.thumbnail.call(this.dropzone, mockFile, val);
        mockFile.previewElement.classList.add('dz-success');
        mockFile.previewElement.classList.add('dz-complete');
      }
    }
  }
};