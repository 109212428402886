"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateCode = generateCode;
exports.getCodeList = getCodeList;
exports.updateCode = updateCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function getCodeList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/activation-codes',
    method: 'get',
    headers: headers,
    params: query
  });
}
function generateCode(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/activation-codes/batch',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateCode(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/activation-codes/limit/' + id,
    method: 'put',
    headers: headers,
    data: data
  });
}