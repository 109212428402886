var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "margin-bottom": "10px",
          "background-color": "#eee",
          padding: "4px",
        },
      },
      [_vm._v(" " + _vm._s(_vm.internalTitle))]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { float: "left", "margin-right": "20px" } },
      [
        _c(
          "el-checkbox-group",
          {
            staticStyle: { "margin-bottom": "10px" },
            model: {
              value: _vm.resources,
              callback: function ($$v) {
                _vm.resources = $$v
              },
              expression: "resources",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c("el-input", {
                  attrs: { placeholder: _vm.$t("otrTraining.keywordSearch") },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getResourceLibrary(true)
                    },
                  },
                  model: {
                    value: _vm.query.q,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "q", $$v)
                    },
                    expression: "query.q",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.resourceLibrary, function (item, index) {
              return _c("div", { key: index, staticClass: "res-box" }, [
                _c(
                  "div",
                  { staticClass: "res-box-col1" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: item.id },
                        on: {
                          change: function ($event) {
                            return _vm.changeResourceSelection(
                              $event,
                              item.id,
                              item.title
                            )
                          },
                        },
                      },
                      [_vm._v(" ")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "res-box-col3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("otrTraining.id")) +
                      ": " +
                      _vm._s(item.id)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          Type: " +
                      _vm._s(item.type) +
                      "\n          " +
                      _vm._s(_vm.$t("otrTraining.title")) +
                      ": " +
                      _vm._s(item.title)
                  ),
                  _c("br"),
                ]),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.query.page,
            "page-size": _vm.query.limit,
            total: _vm.total,
            layout: "prev, pager, next",
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "min-height": "600px", display: "inline-block" } },
      [
        _c("div", { staticClass: "heading" }, [
          _vm._v(_vm._s(_vm.$t("otrTraining.selectedRes")) + " "),
          _c("span", { staticStyle: { color: "blue", "font-size": "10px" } }, [
            _vm._v("* You can drag and drop to reorder the resources"),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "list_group",
            attrs: { list: _vm.resourcesWithTitle },
            on: {
              start: function ($event) {
                _vm.dragging = true
              },
              end: function ($event) {
                _vm.dragging = false
              },
            },
          },
          _vm._l(_vm.resourcesWithTitle, function (i, idx) {
            return _c("div", { key: idx, staticClass: "res-selected" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("otrTraining.id")) +
                  ": " +
                  _vm._s(i.id)
              ),
              _c("br"),
              _vm._v(
                "\n        Type: " +
                  _vm._s(i.type) +
                  "\n        " +
                  _vm._s(_vm.$t("otrTraining.title")) +
                  ": " +
                  _vm._s(i.title) +
                  "\n      "
              ),
            ])
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }