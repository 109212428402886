var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.value, function (item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "el-input",
              {
                attrs: { autocomplete: "off" },
                on: {
                  change: function ($event) {
                    return _vm.handleChange(item)
                  },
                },
                model: {
                  value: item.value,
                  callback: function ($$v) {
                    _vm.$set(item, "value", $$v)
                  },
                  expression: "item.value",
                },
              },
              [
                _c(
                  "template",
                  { slot: "append" },
                  [
                    _c("el-button", {
                      attrs: { icon: "el-icon-delete" },
                      on: {
                        click: function ($event) {
                          return _vm.handleRemove(index)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("Add")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }