var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("General")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [_vm._v("ID:")]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", [_c("b", [_vm._v(_vm._s(_vm.hikeAdvReport.id))])]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Title"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Title" },
                    model: {
                      value: _vm.hikeAdvReport.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeAdvReport, "title", $$v)
                      },
                      expression: "hikeAdvReport.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Internal Title:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Internal Title" },
                    model: {
                      value: _vm.hikeAdvReport.tempInternalTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeAdvReport, "tempInternalTitle", $$v)
                      },
                      expression: "hikeAdvReport.tempInternalTitle",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Description:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("vue-editor", {
                    attrs: { editorToolbar: _vm.customToolbar },
                    model: {
                      value: _vm.hikeAdvReport.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeAdvReport, "description", $$v)
                      },
                      expression: "hikeAdvReport.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Salesforce")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("SF Opportunity Number:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "SF Opportunity Number" },
                    model: {
                      value: _vm.hikeAdvReport.internal.opportunityNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.hikeAdvReport.internal,
                          "opportunityNumber",
                          $$v
                        )
                      },
                      expression: "hikeAdvReport.internal.opportunityNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Client Name:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Client Name" },
                    model: {
                      value: _vm.hikeAdvReport.internal.clientName,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeAdvReport.internal, "clientName", $$v)
                      },
                      expression: "hikeAdvReport.internal.clientName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Availability Period")]),
          _vm._v(" "),
          _c("event-time", {
            attrs: {
              start: _vm.hikeAdvReport.start,
              end: _vm.hikeAdvReport.end,
              "is-required": true,
            },
            on: {
              "update:start": function ($event) {
                return _vm.$set(_vm.hikeAdvReport, "start", $event)
              },
              "update:end": function ($event) {
                return _vm.$set(_vm.hikeAdvReport, "end", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Report Settings")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Report"),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.reportLink) } }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Password"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Password to view the report" },
                    model: {
                      value: _vm.hikeAdvReport.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeAdvReport, "password", $$v)
                      },
                      expression: "hikeAdvReport.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Report Sections"),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hikeAdvReport.showNegativeGrowth,
                      expression: "hikeAdvReport.showNegativeGrowth",
                    },
                  ],
                  attrs: { type: "checkbox", id: "showNegativeGrowth" },
                  domProps: {
                    checked: Array.isArray(_vm.hikeAdvReport.showNegativeGrowth)
                      ? _vm._i(_vm.hikeAdvReport.showNegativeGrowth, null) > -1
                      : _vm.hikeAdvReport.showNegativeGrowth,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.hikeAdvReport.showNegativeGrowth,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "showNegativeGrowth",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "showNegativeGrowth",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.hikeAdvReport, "showNegativeGrowth", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "showNegativeGrowth" } }, [
                  _vm._v("Show negative growth"),
                ]),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hikeAdvReport.overallSkillSummary,
                      expression: "hikeAdvReport.overallSkillSummary",
                    },
                  ],
                  attrs: { type: "checkbox", id: "overallSkillSummary" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.hikeAdvReport.overallSkillSummary
                    )
                      ? _vm._i(_vm.hikeAdvReport.overallSkillSummary, null) > -1
                      : _vm.hikeAdvReport.overallSkillSummary,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.hikeAdvReport.overallSkillSummary,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "overallSkillSummary",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "overallSkillSummary",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.hikeAdvReport, "overallSkillSummary", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "overallSkillSummary" } }, [
                  _vm._v("Show overall Performance Indicator summary"),
                ]),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hikeAdvReport.individualBreakdown,
                      expression: "hikeAdvReport.individualBreakdown",
                    },
                  ],
                  attrs: { type: "checkbox", id: "individualBreakdown" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.hikeAdvReport.individualBreakdown
                    )
                      ? _vm._i(_vm.hikeAdvReport.individualBreakdown, null) > -1
                      : _vm.hikeAdvReport.individualBreakdown,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.hikeAdvReport.individualBreakdown,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "individualBreakdown",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "individualBreakdown",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.hikeAdvReport, "individualBreakdown", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "individualBreakdown" } }, [
                  _vm._v("Show individual breakdown"),
                ]),
                _vm._v(" "),
                _vm.hikeAdvReport.individualBreakdown
                  ? _c("span", [
                      _vm._v("\n            ==>  "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.hikeAdvReport.individualReport,
                            expression: "hikeAdvReport.individualReport",
                          },
                        ],
                        attrs: { type: "checkbox", id: "individualReport" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.hikeAdvReport.individualReport
                          )
                            ? _vm._i(_vm.hikeAdvReport.individualReport, null) >
                              -1
                            : _vm.hikeAdvReport.individualReport,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.hikeAdvReport.individualReport,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.hikeAdvReport,
                                    "individualReport",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.hikeAdvReport,
                                    "individualReport",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.hikeAdvReport,
                                "individualReport",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "individualReport" } }, [
                        _vm._v("Show individual report"),
                      ]),
                      _vm._v("\n           / \n          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.hikeAdvReport.showStageAttendance,
                            expression: "hikeAdvReport.showStageAttendance",
                          },
                        ],
                        attrs: { type: "checkbox", id: "showStageAttendance" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.hikeAdvReport.showStageAttendance
                          )
                            ? _vm._i(
                                _vm.hikeAdvReport.showStageAttendance,
                                null
                              ) > -1
                            : _vm.hikeAdvReport.showStageAttendance,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.hikeAdvReport.showStageAttendance,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.hikeAdvReport,
                                    "showStageAttendance",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.hikeAdvReport,
                                    "showStageAttendance",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.hikeAdvReport,
                                "showStageAttendance",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "showStageAttendance" } }, [
                        _vm._v("Show Attendance"),
                      ]),
                      _vm._v("\n           / \n          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.hikeAdvReport.showParticipantRate,
                            expression: "hikeAdvReport.showParticipantRate",
                          },
                        ],
                        attrs: { type: "checkbox", id: "showParticipantRate" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.hikeAdvReport.showParticipantRate
                          )
                            ? _vm._i(
                                _vm.hikeAdvReport.showParticipantRate,
                                null
                              ) > -1
                            : _vm.hikeAdvReport.showParticipantRate,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.hikeAdvReport.showParticipantRate,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.hikeAdvReport,
                                    "showParticipantRate",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.hikeAdvReport,
                                    "showParticipantRate",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.hikeAdvReport,
                                "showParticipantRate",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "showParticipantRate" } }, [
                        _vm._v("Show Completion"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hikeAdvReport.groupHighlights,
                      expression: "hikeAdvReport.groupHighlights",
                    },
                  ],
                  attrs: { type: "checkbox", id: "groupHighlights" },
                  domProps: {
                    checked: Array.isArray(_vm.hikeAdvReport.groupHighlights)
                      ? _vm._i(_vm.hikeAdvReport.groupHighlights, null) > -1
                      : _vm.hikeAdvReport.groupHighlights,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.hikeAdvReport.groupHighlights,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "groupHighlights",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "groupHighlights",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.hikeAdvReport, "groupHighlights", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "groupHighlights" } }, [
                  _vm._v("Show Group Highlights"),
                ]),
                _vm._v(" "),
                _vm.hikeAdvReport.groupHighlights
                  ? _c(
                      "span",
                      [
                        _vm._v(" ==> \n          "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.hikeAdvReport.showSkillCompass,
                              expression: "hikeAdvReport.showSkillCompass",
                            },
                          ],
                          attrs: { type: "checkbox", id: "showSkillCompass" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.hikeAdvReport.showSkillCompass
                            )
                              ? _vm._i(
                                  _vm.hikeAdvReport.showSkillCompass,
                                  null
                                ) > -1
                              : _vm.hikeAdvReport.showSkillCompass,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.hikeAdvReport.showSkillCompass,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.hikeAdvReport,
                                      "showSkillCompass",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.hikeAdvReport,
                                      "showSkillCompass",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.hikeAdvReport,
                                  "showSkillCompass",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "showSkillCompass" } }, [
                          _vm._v("Show PI Compass"),
                        ]),
                        _vm._v(" / \n          Most Active Hikers Max: "),
                        _c("el-input-number", {
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 0,
                            size: "mini",
                            errorColor:
                              _vm.hikeAdvReport.mostActiveHikersMaxCount ==
                                undefined ||
                              _vm.hikeAdvReport.mostActiveHikersMaxCount == "",
                          },
                          model: {
                            value: _vm.hikeAdvReport.mostActiveHikersMaxCount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.hikeAdvReport,
                                "mostActiveHikersMaxCount",
                                $$v
                              )
                            },
                            expression:
                              "hikeAdvReport.mostActiveHikersMaxCount",
                          },
                        }),
                        _vm._v("\n           / Most Improved Hikers Max: "),
                        _c("el-input-number", {
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 0,
                            size: "mini",
                            errorColor:
                              _vm.hikeAdvReport.mostImprovedHikersMaxCount ==
                                undefined ||
                              _vm.hikeAdvReport.mostImprovedHikersMaxCount ==
                                "",
                          },
                          model: {
                            value: _vm.hikeAdvReport.mostImprovedHikersMaxCount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.hikeAdvReport,
                                "mostImprovedHikersMaxCount",
                                $$v
                              )
                            },
                            expression:
                              "hikeAdvReport.mostImprovedHikersMaxCount",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hikeAdvReport.participantFeedback,
                      expression: "hikeAdvReport.participantFeedback",
                    },
                  ],
                  attrs: { type: "checkbox", id: "participantFeedback" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.hikeAdvReport.participantFeedback
                    )
                      ? _vm._i(_vm.hikeAdvReport.participantFeedback, null) > -1
                      : _vm.hikeAdvReport.participantFeedback,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.hikeAdvReport.participantFeedback,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "participantFeedback",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "participantFeedback",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.hikeAdvReport, "participantFeedback", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "participantFeedback" } }, [
                  _vm._v("Show Participant Feedback"),
                ]),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hikeAdvReport.showPiExplanation,
                      expression: "hikeAdvReport.showPiExplanation",
                    },
                  ],
                  attrs: { type: "checkbox", id: "piDesc" },
                  domProps: {
                    checked: Array.isArray(_vm.hikeAdvReport.showPiExplanation)
                      ? _vm._i(_vm.hikeAdvReport.showPiExplanation, null) > -1
                      : _vm.hikeAdvReport.showPiExplanation,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.hikeAdvReport.showPiExplanation,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "showPiExplanation",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.hikeAdvReport,
                              "showPiExplanation",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.hikeAdvReport, "showPiExplanation", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "piDesc" } }, [
                  _vm._v("Show Performance Indicator Descriptions"),
                ]),
                _c("hr"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }