"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dashboard = require("@/api/dashboard");
var _moment = _interopRequireDefault(require("moment"));
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dates: [],
      noOfDays: 7,
      figures: null,
      hikeFigures: null,
      loading: false
    };
  },
  created: function created() {
    this.getFigures();
  },
  methods: {
    getFigures: function getFigures() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.loading = true;
      for (var i = 0; i < this.noOfDays; i++) {
        var date = (0, _moment.default)().utc().add(i, 'days').format('YYYY-MM-DD');
        this.dates.push(date);
      }
      var data = {
        dates: this.dates
      };
      (0, _dashboard.getTotalParticipant)(token, data).then(function (response) {
        _this.figures = response.data ? response.data : {};
        _this.loading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Dashboard-getTotalParticipant-API-error', error);
        _this.loading = false;
      });
      (0, _dashboard.getTotalParticipantHike)(token, data).then(function (response) {
        _this.hikeFigures = response.data ? response.data : {};
        _this.loading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Dashboard-getTotalParticipantHike-API-error', error);
        _this.loading = false;
      });
    }
  }
};