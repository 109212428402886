"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ = _interopRequireDefault(require("@/assets/401_images/401.gif"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Page401',
  data: function data() {
    return {
      errGif: _.default + '?' + +new Date(),
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false
    };
  },
  methods: {
    back: function back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: '/dashboard'
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};