"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSurvey720Result = getSurvey720Result;
exports.updateSurvey720Result = updateSurvey720Result;
var _request = _interopRequireDefault(require("@/utils/request"));
// import { fileURLToPath } from 'url'

function getSurvey720Result(token, hikeId, stageId, userId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/hike-720reports/hikes/".concat(hikeId, "/stages/").concat(stageId, "/lastUpdate"),
    method: 'get',
    headers: headers
  });
}
function updateSurvey720Result(token, hikeId, stageId, type, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: "/hike-720reports/hikes/".concat(hikeId, "/stages/").concat(stageId, "/types/").concat(type, "/uploadScores"),
    method: 'post',
    headers: headers,
    data: data
  });
}