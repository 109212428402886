"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.writeGaEvent = writeGaEvent;
var _moment = _interopRequireDefault(require("moment"));
function writeGaEvent(v, category, action, userId, detail) {
  v.$gtag.event(action, {
    'event_category': category,
    'event_label': "TIME: ".concat((0, _moment.default)().zone(0).format(), ", BY: ").concat(userId, ", DETAIL: ").concat(detail)
  });
}