"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MarkdownEditor = _interopRequireDefault(require("@/components/MarkdownEditor"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var content = "\n**This is test**\n\n* vue\n* element\n* webpack\n\n";
var _default = exports.default = {
  name: 'MarkdownDemo',
  components: {
    MarkdownEditor: _MarkdownEditor.default
  },
  data: function data() {
    return {
      content1: content,
      content2: content,
      content3: content,
      content4: content,
      html: '',
      languageTypeList: {
        'en': 'en_US',
        'zh': 'zh_CN',
        'es': 'es_ES'
      }
    };
  },
  computed: {
    language: function language() {
      return this.languageTypeList['en'];
    }
  },
  methods: {
    getHtml: function getHtml() {
      this.html = this.$refs.markdownEditor.getHtml();
      console.log(this.html);
    }
  }
};