var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      {
        staticStyle: {
          "padding-left": "15px",
          "font-size": "11px",
          color: "#666",
        },
      },
      [_vm._v("Next Expiry Time: " + _vm._s(_vm.getExpiryTime()))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }