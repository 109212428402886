"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lifehikesInitData = require("@/lifehikes-init-data");
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
require("vue-datetime/dist/vue-datetime.css");
var _publicFile = require("@/api/publicFile");
var _cookie = require("@/utils/cookie");
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { Datetime } from 'vue-datetime'

var tmpGenInfo = {};
var defaultValue = [];
var _default = exports.default = {
  name: 'TrainingGenInfo',
  components: {
    Tinymce: _Tinymce.default,
    EventTime: _index.default
  },
  props: {
    parentGenInfo: {
      type: Object,
      default: tmpGenInfo
    },
    parentSession: {
      type: Array,
      default: defaultValue
    }
  },
  data: function data() {
    return {
      validImageExtension: _lifehikesInitData.validImageExtension,
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      countryList: [],
      tagInputVisible: false,
      /* use for Tag component */
      tagInputValue: '',
      /* use for tag component */
      showActiveFileErrMsg: false,
      loading: false,
      startLocalDt: '',
      endLocalDt: '',
      expiryDt: '',
      genInfo: {
        title: '',
        start: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        end: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        expiry: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        channerl: '',
        webinarName: '',
        webinarKey: '',
        webinarCode: '',
        estimatedParticipantCount: 0,
        address: {
          address1: '',
          address2: '',
          city: '',
          region: '',
          postalCode: '',
          country: ''
        },
        locale: 'en',
        description: '',
        imageUrl: '',
        tags: [],
        feedbackUrl: '',
        internal: {
          title: '',
          opportunityNumber: '',
          clientName: '',
          remark: ''
        }
      },
      sessions: []
    };
  },
  computed: {},
  watch: {
    genInfo: {
      handler: function handler(newVal) {
        (0, _log.showDebugLog)('Child emit to parent new value', newVal);
        this.passBackGenInfo(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    (0, _log.showDebugLog)('GenInfo - created hook', this.parentGenInfo);
    console.log('GenInfo - created hook', this.parentGenInfo);
    this.genInfo = this.parentGenInfo;
    this.sessions = this.parentSession;

    // this.genInfo.start.localtime = this.parentGenInfo.start.local // this.calLocalTime(this.genInfo.start.utc, this.genInfo.start.timezone)
    // this.genInfo.end.localtime = this.parentGenInfo.end.local // this.calLocalTime(this.genInfo.end.utc, this.genInfo.end.timezone)

    this.$store.dispatch('facets/getInitData', 'en').then(function () {
      _this.countryList = _this.$store.getters.countries;
    });
  },
  mounted: function mounted() {
    // fix tinymce dynamic set content
    // this.$refs.editor.setContent(this.genInfo.description)
  },
  methods: {
    prefillFeedbackLink: function prefillFeedbackLink() {
      this.genInfo.feedbackUrl = "https://otr.link/".concat(this.genInfo.internal.opportunityNumber);
    },
    passBackGenInfo: function passBackGenInfo(genInfo) {
      // change to UTC time

      // genInfo.start.utc = (genInfo.start.utc === null) ? null : moment.tz(genInfo.start.utc.toString('YYYY-MM-dd HH:mm:ss'), 'Etc/UTC').toString()

      // genInfo.end.utc = (genInfo.end.utc === null) ? null : moment.tz(genInfo.end.utc.toString('YYYY-MM-dd HH:mm:ss'), 'Etc/UTC').toString()
      // genInfo.expiry.utc = (genInfo.expiry.utc === null) ? null : moment.tz(genInfo.expiry.utc.toString('YYYY-MM-dd HH:mm:ss'), 'Etc/UTC').toString()

      // genInfo.start.utc = genInfo.start.utc.totISOString()

      // const startString = genInfo.start.utc.toString('YYYY-MM-dd HH:mm:ss')
      // showDebugLog('child genInfo start string', startString)
      // showDebugLog('child genInfo start', genInfo.start.utc)
      // showDebugLog('child genInfo start 1', moment.tz(genInfo.start.utc.toString('YYYY-MM-dd HH:mm:ss'), 'Etc/UTC').toString())
      genInfo.expiry = genInfo.end;
      this.$emit('updateParentGenInfo', genInfo);
    },
    generateDefaultSession: function generateDefaultSession() {
      if (this.genInfo.start.utc && this.genInfo.end.utc) {
        var currentStartDate = _momentTimezone.default.parseZone(this.genInfo.start.utc).utc();
        var endDate = _momentTimezone.default.parseZone(this.genInfo.end.utc).utc();
        var index = 1;
        (0, _log.showDebugLog)('current Start Date', currentStartDate);
        (0, _log.showDebugLog)('current end Date', endDate);
        while (currentStartDate <= endDate) {
          var currentEndDate = _momentTimezone.default.utc([currentStartDate.get('year'), currentStartDate.get('month'), currentStartDate.get('date'), endDate.get('hour'), endDate.get('minute'), 0, 0]);
          (0, _log.showDebugLog)('current end date utc', currentEndDate.format());
          var s = {
            type: 'moment',
            title: "Session ".concat(index),
            shortDescription: '',
            description: '',
            allowCheckin: true,
            start: {
              utc: currentStartDate.format(),
              timezone: this.genInfo.start.timezone,
              local: this.genInfo.start.local
            },
            end: {
              utc: currentEndDate.format(),
              timezone: this.genInfo.end.timezone,
              local: this.genInfo.end.local
            },
            recordingStart: {
              utc: '',
              timezone: this.genInfo.start.timezone,
              local: ''
            },
            recordingEnd: {
              utc: '',
              timezone: this.genInfo.start.timezone,
              local: ''
            },
            webinarName: '',
            webinarKey: ''
          };
          this.sessions.push(s);
          index += 1;
          currentStartDate.add(1, 'd');
        }
      }
      this.$emit('updateParentSession', this.sessions);
    },
    uploadTrainingImg: function uploadTrainingImg(e) {
      var _this2 = this;
      // file size is greater than 1Mb
      if (e.target.files.length > 0) {
        (0, _log.showDebugLog)('Debug-uploadTrainingImg', e.target.files[0]);
        if (e.target.files[0].size <= 1048576) {
          this.loading = true;
          var iconForm = new FormData();
          iconForm.append('file', e.target.files[0]);
          var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
          (0, _publicFile.uploadFile)(token, iconForm).then(function (response) {
            _this2.loading = false;
            _this2.showActiveFileErrMsg = false;
            _this2.genInfo.imageUrl = response.data.location;
          }).catch(function (error) {
            (0, _log.showDebugLog)('upload-badge-icon-api-error', error);
            _this2.loading = false;
            _this2.showActiveFileErrMsg = true;
          });
        } else {
          (0, _log.displayPopupMessage)(this.$i18n.t('otrTraining.imageLimit'), 'error');
          this.$refs.infile.value = '';
        }
      }
    },
    /* tag event */handleClose: function handleClose(tag) {
      this.genInfo.tags.splice(this.genInfo.tags.indexOf(tag), 1);
    },
    handleTagInputConfirm: function handleTagInputConfirm() {
      var inputValue = this.tagInputValue;
      if (inputValue && this.genInfo.tags.indexOf(inputValue) < 0) {
        this.genInfo.tags.push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    },
    showTagInput: function showTagInput() {
      var _this3 = this;
      this.tagInputVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.saveTagInput.$refs.input.focus();
      });
      /* tag event */
    }
  }
};