var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
            display: "block",
          },
        },
        [
          _c("span", { staticStyle: { padding: "10px 0" } }, [
            _vm._v(" " + _vm._s(_vm.internalTitle)),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "text" },
              on: { click: _vm.addStage },
            },
            [_vm._v("Add new Stage")]
          ),
          _vm._v(" "),
          _vm.hikeStages.length > 0
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "text" },
                  on: { click: _vm.copyLastStage },
                },
                [_vm._v("Copy Last Stage (Type: Stage)")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.firstTab,
                callback: function ($$v) {
                  _vm.firstTab = $$v
                },
                expression: "firstTab",
              },
            },
            _vm._l(_vm.hikeStages, function (s, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index,
                  attrs: {
                    label: "Stage " + (index + 1),
                    name: "stage" + (index + 1),
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 1 } }, [
                        _c("span", [_vm._v("Stage ID:")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 23 } },
                        [
                          _c("span", [_c("b", [_vm._v(_vm._s(s.id))])]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                padding: "3px 0",
                                color: "red",
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeStage(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-circle-close" }),
                              _vm._v(" Remove Stage\n              "),
                            ]
                          ),
                          _vm._v(" "),
                          index !== _vm.hikeStages.length - 1
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                    "margin-right": "10px",
                                    color: "blue",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveNext(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-caret-right",
                                  }),
                                  _vm._v(" Move Forward\n              "),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index !== 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                    "margin-right": "10px",
                                    color: "blue",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.movePrevious(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-caret-left",
                                  }),
                                  _vm._v(" Move Backward\n              "),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c("legend", [_vm._v("Stage Info")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", { staticClass: "otr-form__label" }, [
                              _vm._v("Stage Type:"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 17 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    placeholder: "Type",
                                    disabled:
                                      _vm.parentDisplayType ===
                                      "createfromcopy",
                                  },
                                  model: {
                                    value: s.type,
                                    callback: function ($$v) {
                                      _vm.$set(s, "type", $$v)
                                    },
                                    expression: "s.type",
                                  },
                                },
                                _vm._l(_vm.stageType, function (t) {
                                  return _c("el-option", {
                                    key: t,
                                    attrs: { label: t, value: t },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 4 } }, [
                            false && index === 0 && s.type !== "benchmark"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      border: "1px solid red",
                                      padding: "5px",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-warning" }),
                                    _vm._v(
                                      '\n                  Stage 1 must be "Benchmark" type\n                '
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            false &&
                            index === _vm.hikeStages.length - 1 &&
                            s.type !== "summit"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      border: "1px solid red",
                                      padding: "5px",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-warning" }),
                                    _vm._v(
                                      '\n                  Last stage must be "Summit" type\n                '
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Stage Title :"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Title", clearable: "" },
                                model: {
                                  value: s.title,
                                  callback: function ($$v) {
                                    _vm.$set(s, "title", $$v)
                                  },
                                  expression: "s.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("Short Description :"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 21 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Short Description",
                                      clearable: "",
                                    },
                                    model: {
                                      value: s.shortDescription,
                                      callback: function ($$v) {
                                        _vm.$set(s, "shortDescription", $$v)
                                      },
                                      expression: "s.shortDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", [_vm._v("Description :")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("vue-editor", {
                                attrs: {
                                  id: "stageDesc_" + index,
                                  editorToolbar: _vm.customToolbar,
                                },
                                model: {
                                  value: s.description,
                                  callback: function ($$v) {
                                    _vm.$set(s, "description", $$v)
                                  },
                                  expression: "s.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c(
                        "legend",
                        { staticStyle: { position: "relative" } },
                        [
                          _vm._v("\n              Event Time\n              "),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                padding: "0",
                                color: "yellow",
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyEventTime(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-copy-document" }),
                              _vm._v(
                                " Copy from Hike's Event\n                Time"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index === 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "-180px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  },
                                  attrs: { type: "mini" },
                                  on: { click: _vm.openRecurrenceDialog },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-copy-document",
                                  }),
                                  _vm._v(
                                    " Set repeated schedule\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("event-time", {
                        attrs: {
                          start: s.start,
                          end: s.end,
                          "is-required": false,
                          "is-call-back": true,
                        },
                        on: {
                          "update:start": function ($event) {
                            return _vm.$set(s, "start", $event)
                          },
                          "update:end": function ($event) {
                            return _vm.$set(s, "end", $event)
                          },
                          setTimeZone: function ($event) {
                            return _vm.setTimeZone($event, index)
                          },
                        },
                      }),
                      _vm._v(" "),
                      s.type === "benchmark" || s.type === "summit"
                        ? _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text",
                                    staticStyle: { "margin-bottom": "10px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Rating Deadline - Days after the " +
                                        _vm._s(s.type) +
                                        " session start\n                  date\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c("el-input-number", {
                                      attrs: { min: 0, max: 50 },
                                      model: {
                                        value: s.taskSortDay,
                                        callback: function ($$v) {
                                          _vm.$set(s, "taskSortDay", $$v)
                                        },
                                        expression: "s.taskSortDay",
                                      },
                                    }),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.calDeadlineDay(
                                                  s.type,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Reset default setting")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "\n                    Rating Deadline (Local) is\n                    "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getDeadlineDate(
                                              "local",
                                              s.start.utc,
                                              s.start.timezone,
                                              s.taskSortDay,
                                              s.type,
                                              index
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "\n                    Rating Deadline (UTC) is\n                    "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getDeadlineDate(
                                              "utc",
                                              s.start.utc,
                                              s.start.timezone,
                                              s.taskSortDay,
                                              s.type,
                                              index
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "text red" }, [
                                    _vm._v(
                                      '* Rating Deadline will start effective after "SAVE"\n                  '
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c(
                        "legend",
                        [
                          _vm._v(
                            "\n              Homework Period\n              "
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                padding: "0",
                                color: "yellow",
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyEventTime1(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-copy-document" }),
                              _vm._v(" Auto set\n                 "),
                              _c(
                                "span",
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        title: "Homework Period",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "Homework start time automatically set at stage’s start time minus 30 minutes"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "Homework end time automatically set at stage’s start time plus 1 week 30 minutes"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("svg-icon", {
                                        attrs: {
                                          slot: "reference",
                                          "icon-class": "faq",
                                        },
                                        slot: "reference",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("event-time", {
                        attrs: {
                          start: s.enableStart,
                          end: s.enableEnd,
                          "is-required": false,
                        },
                        on: {
                          "update:start": function ($event) {
                            return _vm.$set(s, "enableStart", $event)
                          },
                          "update:end": function ($event) {
                            return _vm.$set(s, "enableEnd", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c(
                        "legend",
                        [
                          _vm._v("\n              Webinar\n              "),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                padding: "0",
                                color: "yellow",
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyWebinar(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-copy-document" }),
                              _vm._v(
                                " Copy info from Hike's\n                Webinar"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _vm._v("Webinar Software:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Zoom / WebEx / Hangout ...etc",
                                },
                                model: {
                                  value: s.webinarName,
                                  callback: function ($$v) {
                                    _vm.$set(s, "webinarName", $$v)
                                  },
                                  expression: "s.webinarName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _vm._v("Webinar ID:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Webinar ID (e.g 123 456 789)",
                                },
                                model: {
                                  value: s.webinarKey,
                                  callback: function ($$v) {
                                    _vm.$set(s, "webinarKey", $$v)
                                  },
                                  expression: "s.webinarKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _vm._v("Webinar Passcode:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Webinar Passcode" },
                                model: {
                                  value: s.webinarPasscode,
                                  callback: function ($$v) {
                                    _vm.$set(s, "webinarPasscode", $$v)
                                  },
                                  expression: "s.webinarPasscode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c("legend", [_vm._v("Checkin Setting")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", [_vm._v("Allow Checkin :")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-checkbox", {
                                model: {
                                  value: s.allowCheckin,
                                  callback: function ($$v) {
                                    _vm.$set(s, "allowCheckin", $$v)
                                  },
                                  expression: "s.allowCheckin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", { staticClass: "otr-form__label" }, [
                              _vm._v("Check-in Award (Coin):"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, max: 1000 },
                                model: {
                                  value: s.defaultCoin,
                                  callback: function ($$v) {
                                    _vm.$set(s, "defaultCoin", $$v)
                                  },
                                  expression: "s.defaultCoin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", { staticClass: "otr-form__label" }, [
                              _vm._v("Check-in Award (Step):"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, max: 10000 },
                                model: {
                                  value: s.checkinStepsValue,
                                  callback: function ($$v) {
                                    _vm.$set(s, "checkinStepsValue", $$v)
                                  },
                                  expression: "s.checkinStepsValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c(
                        "legend",
                        [
                          _vm._v(
                            "\n              Activity (Readonly)\n              "
                          ),
                          _vm.hikeId && _vm.hikeId !== "copy"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                    padding: "0",
                                    color: "yellow",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogActionSettingPageVisible = true
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                  _vm._v(
                                    " Go To Activity Setting\n                Page"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeCollapseNames,
                            callback: function ($$v) {
                              _vm.activeCollapseNames = $$v
                            },
                            expression: "activeCollapseNames",
                          },
                        },
                        _vm._l(s.actionSets, function (as, idx) {
                          return _c(
                            "el-collapse-item",
                            {
                              key: idx,
                              attrs: {
                                title:
                                  "Activity ID: " +
                                  as.id +
                                  " , Activity Type: " +
                                  as.type,
                                name: idx,
                              },
                            },
                            [
                              _c(
                                "fieldset",
                                { staticClass: "otrfieldset1" },
                                [
                                  _c("legend", [_vm._v("Component")]),
                                  _vm._v(" "),
                                  as.type === "benchmark" ||
                                  as.type === "summit"
                                    ? _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            "Activity Rater Number: " +
                                              _vm._s(as.raterCount)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Dial Testing Weight(%): " +
                                              _vm._s(as.dialTestRate) +
                                              "% / Skill\n                      Compass Weight(%): " +
                                              _vm._s(100 - as.dialTestRate) +
                                              "%"
                                          ),
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Total Weight(%) of " +
                                              _vm._s(_vm.calcVideoCount(as)) +
                                              " video(s):\n                      " +
                                              _vm._s(_vm.calcVideoScore(as)) +
                                              "%"
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(as.actions, function (a, idxa) {
                                    return _c(
                                      "div",
                                      {
                                        key: idxa,
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tabs",
                                          { attrs: { type: "border-card" } },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              { attrs: { label: "Setting" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "blue" },
                                                  [
                                                    _vm._v(
                                                      "Component " +
                                                        _vm._s(idxa + 1) +
                                                        " ID:"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(a.id) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "blue" },
                                                  [_vm._v("Type:")]
                                                ),
                                                _vm._v(
                                                  " " + _vm._s(a.type) + " "
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                a.type !== "benchmarkreport" &&
                                                a.type !== "summitreport"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [_vm._v("Title:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type !== "benchmarkreport" &&
                                                a.type !== "summitreport"
                                                  ? _c("span", [
                                                      _vm._v(_vm._s(a.title)),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [_vm._v("Description:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            a.description
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [_vm._v("Dial Testing:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.enableDialTest
                                                            ? "Enabled"
                                                            : "Disabled"
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "Min. Recording Time (sec):"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.minRecordingTime
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "Ideal Recording Time (sec):"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.maxRecordingTime
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "Auto Cutoff Time (sec):"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.autoCutRecordingTime
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [_vm._v("Step Value:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(a.stepsValue) +
                                                          " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "Time In Minutes:"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.timeInMinutes
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "skill"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [_vm._v("Skill ID:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "skill"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(a.skill) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "resource"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [_vm._v("Resource ID:")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "resource"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.resource
                                                            ? a.resource.id
                                                              ? a.resource.id
                                                              : a.resource
                                                            : ""
                                                        ) +
                                                          "\n                          "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "resource"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "Recording Tips:"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "resource"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          a.recordingTips
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" && false
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "blue",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Recording Text: "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          a.recordingTexts,
                                                          function (
                                                            item,
                                                            textidx
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              { key: textidx },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      item.text
                                                                    ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      item.interval
                                                                    ) +
                                                                    " sec) "
                                                                ),
                                                                _c("br"),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.checkScoreExist(a, as)
                                              ? _c(
                                                  "el-tab-pane",
                                                  { attrs: { label: "Score" } },
                                                  [
                                                    _vm._v(
                                                      "\n                        Video Weight(%): " +
                                                        _vm._s(
                                                          a.scoreConfig.rate
                                                        ) +
                                                        "% /\n                        " +
                                                        _vm._s(
                                                          a.scoreConfig.scores
                                                            .length
                                                        ) +
                                                        " skill(s) Weight(%):\n                        " +
                                                        _vm._s(
                                                          _vm.calcVideoSkillWeight(
                                                            a.scoreConfig
                                                          )
                                                        ) +
                                                        "%\n                        "
                                                    ),
                                                    _c(
                                                      "el-table",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          data: a.scoreConfig
                                                            .scores,
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "ID",
                                                            width: "150",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .key
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "Title",
                                                            width: "400",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .title
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "Weight(%)",
                                                            width: "100",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .rate
                                                                        ) + "%"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "Enable",
                                                            width: "60",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    scope.row
                                                                      .enable
                                                                      ? _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-check",
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-close",
                                                                          }
                                                                        ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "Tips",
                        visible: _vm.dialogActionSettingPageVisible,
                        width: "30%",
                        modal: false,
                        "before-close": _vm.handleActionSettingClose,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogActionSettingPageVisible = $event
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Please save the data before you leave, otherwise you will lost\n              the data."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialogActionSettingPageVisible = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToActionSetPage(
                                    _vm.hikeId,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v("Confirm")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Set repeated schedule",
            visible: _vm.recurrenceDialogVisible,
            width: "1100px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.recurrenceDialogVisible = $event
            },
          },
        },
        [
          _c("schedule-event-time", {
            attrs: {
              start: _vm.recurrenceDialogForm.start,
              end: _vm.recurrenceDialogForm.end,
              "is-required": false,
              recur: _vm.recurrenceDialogForm.recur,
            },
            on: {
              "update:start": function ($event) {
                return _vm.$set(_vm.recurrenceDialogForm, "start", $event)
              },
              "update:end": function ($event) {
                return _vm.$set(_vm.recurrenceDialogForm, "end", $event)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "40px" }, attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("div", [
                  _c("i", { staticClass: "el-icon-refresh" }),
                  _vm._v(" Recurrence"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.recurrenceDialogForm.recur,
                        callback: function ($$v) {
                          _vm.$set(_vm.recurrenceDialogForm, "recur", $$v)
                        },
                        expression: "recurrenceDialogForm.recur",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "daily",
                        attrs: { value: "daily", label: "Repeat daily" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "weekly",
                        attrs: { value: "weekly", label: "Repeat weekly" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "bi-weekly",
                        attrs: {
                          value: "bi-weekly",
                          label: "Repeat bi-weekly",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "monthly",
                        attrs: { value: "monthly", label: "Repeat monthly" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "yearly",
                        attrs: { value: "yearly", label: "Repeat yearly" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setRepeatSchedule },
                },
                [_vm._v("Set")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }