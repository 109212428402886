"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    title: {
      type: String,
      default: 'vue'
    }
  },
  data: function data() {
    return {
      isActive: false
    };
  },
  methods: {
    clickTitle: function clickTitle() {
      this.isActive = !this.isActive;
    }
  }
};