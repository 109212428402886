"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/codebuild/output/src365382019/src/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _user = require("@/api/user");
var _coin = require("@/api/coin");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
var _ga = require("@/utils/ga");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserEdit',
  components: {
    UserCard: _UserCard.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      user: {},
      coin: {},
      totalCoin: 0,
      coinTx: {
        category: 'other',
        amount: 0
      },
      coinCategory: ['new-account', 'reg', 'referral', 'vip', 'daily-redeem', 'baseline', 'practice', 'lifehikes-moment', 'ilt', 'game', 'talk-like-a-pro', 'revise', 'other']
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['name', 'avatar', 'roles', 'introduction', 'email'])),
  created: function created() {
    var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
    var uuid = typeof this.$route.params.id === 'undefined' ? this.user.uuid : this.$route.params.id;

    // console.log('page-useredit-uuid', this.$route.params.id)
    this.getUser(token, uuid);
    this.getUserCoin(token, uuid, false);
  },
  methods: {
    getUser: function getUser(token, uuid) {
      var _this = this;
      (0, _user.getUser)(token, uuid).then(function (response) {
        // console.log('page-useredit-response', response.data)
        _this.user = response.data;
      }).catch(function (err) {
        (0, _log.showDebugLog)('page-user-err', err);
        (0, _log.displayPopupMessage)(err, 'error');
      });
    },
    getUserCoin: function getUserCoin(token, uuid) {
      var _this2 = this;
      (0, _coin.getUserCoin)(token, uuid, false).then(function (response) {
        // console.log('page-useredit-coin-response', response.data)
        _this2.coin = response.data;
        // console.log('coin', this.coin)
        _this2.getTotal();
      }).catch(function (err) {
        (0, _log.showDebugLog)('page-user-err', err);
        (0, _log.displayPopupMessage)(err, 'error');
      });
    },
    getTotal: function getTotal() {
      var _this3 = this;
      this.totalCoin = 0;
      this.coin.transaction.forEach(function (tx) {
        // console.log('coin', tx.amount)
        _this3.totalCoin += tx.amount;
      });
    },
    update: function update() {
      this.dialogVisible = true;
    },
    addCoinTx: function addCoinTx() {
      var _this4 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var uuid = typeof this.$route.params.id === 'undefined' ? this.user.uuid : this.$route.params.id;
      var email = (0, _cookie.getCookie)('EMAIL');
      (0, _coin.updateUserCoin)(token, uuid, this.coinTx).then(function (response) {
        _this4.getUserCoin(token, uuid);
        _this4.dialogVisible = false;
        _this4.coinTx.category = 'other';
        (0, _log.displayPopupMessage)('User\'s Coin has been adjusted successfully', 'success');
        (0, _ga.writeGaEvent)(_this4, 'User', 'Update User Coin', email, "User Profile: ".concat(uuid));
      }).catch(function (error) {
        (0, _log.showDebugLog)('Page-usercoin-update-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    resetCoinTxValue: function resetCoinTxValue() {
      this.coinTx.category = 'other';
      this.coinTx.amount = 0;
    }
  }
};