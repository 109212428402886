"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createArticle = createArticle;
exports.fetchArticle = fetchArticle;
exports.fetchList = fetchList;
exports.fetchPv = fetchPv;
exports.updateArticle = updateArticle;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(query) {
  return (0, _request.default)({
    url: '/vue-element-admin/article/list',
    method: 'get',
    params: query
  });
}
function fetchArticle(id) {
  return (0, _request.default)({
    url: '/vue-element-admin/article/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}
function fetchPv(pv) {
  return (0, _request.default)({
    url: '/vue-element-admin/article/pv',
    method: 'get',
    params: {
      pv: pv
    }
  });
}
function createArticle(data) {
  return (0, _request.default)({
    url: '/vue-element-admin/article/create',
    method: 'post',
    data: data
  });
}
function updateArticle(data) {
  return (0, _request.default)({
    url: '/vue-element-admin/article/update',
    method: 'post',
    data: data
  });
}