"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/utils/log");
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SearchBox",
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      qeruy: this.value
    };
  },
  watch: {
    qeruy: {
      handler: function handler(newVal) {
        (0, _log.showDebugLog)("valChange", newVal);
        this.valChange(newVal);
      },
      deep: true
    }
  },
  methods: {
    // search()
    // {
    //   this.$emit("call-father-search",1);
    // },
    valChange: function valChange(query) {
      this.$emit('input', query);
    }
  }
};