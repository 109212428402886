var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.raterSkillSet,
                    callback: function ($$v) {
                      _vm.raterSkillSet = $$v
                    },
                    expression: "raterSkillSet",
                  },
                },
                _vm._l(_vm.scoringSkillSetList, function (t) {
                  return _c(
                    "el-checkbox",
                    { key: t.key, attrs: { label: t.val } },
                    [_vm._v(_vm._s(t.description))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.checkPermission(["root", "otr-digital-producer"])
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("Update")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-link",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "info", href: "/#/users" },
                },
                [_vm._v("Back")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }