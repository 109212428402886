var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
            display: "block",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticStyle: { padding: "10px 0" } }, [
            _vm._v("Hike ID: " + _vm._s(_vm.hike.id)),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticStyle: { padding: "10px 0" } }, [
            _vm._v("Hike Channel: " + _vm._s(_vm.hike.channel)),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticStyle: { padding: "10px 0" } }, [
            _vm._v("Hike Title: " + _vm._s(_vm.hike.title)),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.hike.internal
            ? _c("span", { staticStyle: { padding: "10px 0" } }, [
                _vm._v("Internal Title: " + _vm._s(_vm.hike.internal.title)),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.stageTabClick },
              model: {
                value: _vm.firstTab,
                callback: function ($$v) {
                  _vm.firstTab = $$v
                },
                expression: "firstTab",
              },
            },
            _vm._l(_vm.hike.stages, function (s, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index,
                  attrs: {
                    label: "Stage " + (index + 1),
                    name: "stage" + (index + 1),
                  },
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c("legend", [_vm._v("Stage Info")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 5 } },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", [_vm._v("Stage ID:")]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 21 } }, [
                            _c("span", [_vm._v(_vm._s(s.id))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 5 } },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", [_vm._v("Stage Type:")]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 21 } }, [
                            _c("span", [_vm._v(_vm._s(s.type))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 5 } },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", [_vm._v("Stage Title:")]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 21 } }, [
                            _c("span", [_vm._v(_vm._s(s.title) + " ")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 5 } },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", [_vm._v("Start Time (Local):")]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 21 } }, [
                            _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(s.start.timezone) +
                                  ") " +
                                  _vm._s(s.start.local)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 5 } },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", [_vm._v("End Time (Local):")]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 21 } }, [
                            _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(s.start.timezone) +
                                  ") " +
                                  _vm._s(s.start.local)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "otrfieldset" },
                    [
                      _c(
                        "legend",
                        [
                          _vm._v("Activity\n            "),
                          _vm.canAddActionSet(s)
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                    padding: "0",
                                    color: "#fff",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addActionSet(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _vm._v("Add"),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.actionsetTab,
                            callback: function ($$v) {
                              _vm.actionsetTab = $$v
                            },
                            expression: "actionsetTab",
                          },
                        },
                        _vm._l(s.actionSets, function (as, idx) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: idx,
                              attrs: {
                                label: "Activity " + (idx + 1),
                                name: "actionset" + (idx + 1),
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { staticClass: "otr-line-spacing__10" },
                                [
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _c("span", [_vm._v("Activity ID: ")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 22 } },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(as.id) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            padding: "3px 0",
                                            color: "red",
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeActionSet(
                                                index,
                                                idx
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-circle-close",
                                          }),
                                          _vm._v(
                                            " Remove Activity\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      idx !== s.actionSets.length - 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                padding: "3px 0",
                                                "margin-right": "10px",
                                                color: "blue",
                                              },
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveNext(
                                                    index,
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-caret-right",
                                              }),
                                              _vm._v(
                                                " Move Forward\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      idx !== 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                padding: "3px 0",
                                                "margin-right": "10px",
                                                color: "blue",
                                              },
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.movePrevious(
                                                    index,
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-caret-left",
                                              }),
                                              _vm._v(
                                                " Move Backward\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { staticClass: "otr-line-spacing__10" },
                                [
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _c("span", [_vm._v("Activity Type: ")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 22 } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "Select",
                                            size: "mini",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.selectAction(
                                                $event,
                                                index,
                                                idx
                                              )
                                            },
                                          },
                                          model: {
                                            value: as.type,
                                            callback: function ($$v) {
                                              _vm.$set(as, "type", $$v)
                                            },
                                            expression: "as.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.getActionSetType(s.type),
                                          function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              as.type === "benchmark" || as.type === "summit"
                                ? _c(
                                    "el-row",
                                    { staticClass: "otr-line-spacing__10" },
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("span", [_vm._v("Rater(s): ")]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 22 } },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 1,
                                              max: 2,
                                              precision: 0,
                                              step: 1,
                                              size: "mini",
                                            },
                                            model: {
                                              value: as.raterCount,
                                              callback: function ($$v) {
                                                _vm.$set(as, "raterCount", $$v)
                                              },
                                              expression: "as.raterCount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              as.type === "benchmark" || as.type === "summit"
                                ? _c(
                                    "el-row",
                                    { staticClass: "otr-line-spacing__10" },
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("span", [_vm._v("Final Score")]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 22 } },
                                        [
                                          _c("vue-slider", {
                                            attrs: {
                                              min: 0,
                                              max: 100,
                                              lazy: true,
                                              "tooltip-formatter":
                                                _vm.rateTooltipFormatter,
                                            },
                                            model: {
                                              value: as.dialTestRate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  as,
                                                  "dialTestRate",
                                                  $$v
                                                )
                                              },
                                              expression: "as.dialTestRate",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "otr-form__description",
                                            },
                                            [
                                              _vm._v(
                                                "Dial Testing Weight: " +
                                                  _vm._s(as.dialTestRate) +
                                                  "% / Skill Compass Weight: " +
                                                  _vm._s(
                                                    100 - as.dialTestRate
                                                  ) +
                                                  "%"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              as.type === "benchmark" || as.type === "summit"
                                ? _c(
                                    "el-row",
                                    { staticClass: "otr-line-spacing__10" },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "otr-line-spacing__20",
                                          attrs: { gutter: 20 },
                                        },
                                        [
                                          _c("el-col", { attrs: { span: 3 } }, [
                                            _c("div", [
                                              _vm._v("Enable 720-Survey"),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 21 } },
                                            [
                                              _c("el-checkbox", {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.config720SurveyRateChange(
                                                      s
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: s.enable720Survey,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      s,
                                                      "enable720Survey",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "s.enable720Survey",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      s.enable720Survey === true
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "otr-line-spacing__20",
                                                  attrs: { gutter: 20 },
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 3 } },
                                                    [
                                                      _c("div", [
                                                        _vm._v("Survey result"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 7 } },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "success",
                                                            icon: "el-icon-upload",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.inputSurveyResult(
                                                                "boss",
                                                                s
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Boss")]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n                  Last update:\n                  " +
                                                          _vm._s(
                                                            _vm.dateFormatter(
                                                              _vm
                                                                .survey720Status[
                                                                "bossUpdatedAt"
                                                              ]
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 7 } },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                            icon: "el-icon-upload",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.inputSurveyResult(
                                                                "directreport",
                                                                s
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Direct Report"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n                  Last update:\n                  " +
                                                          _vm._s(
                                                            _vm.dateFormatter(
                                                              _vm
                                                                .survey720Status[
                                                                "directReportUpdatedAt"
                                                              ]
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 7 } },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "warning",
                                                            icon: "el-icon-upload",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.inputSurveyResult(
                                                                "peer",
                                                                s
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Peers")]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n                  Last update:\n                  " +
                                                          _vm._s(
                                                            _vm.dateFormatter(
                                                              _vm
                                                                .survey720Status[
                                                                "peerUpdatedAt"
                                                              ]
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "otr-line-spacing__20",
                                                  attrs: { gutter: 20 },
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 3 } },
                                                    [
                                                      _c("div", [
                                                        _vm._v("Final Score"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 4 } },
                                                    [
                                                      _vm._v(
                                                        "\n                  Boss:\n                  "
                                                      ),
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          precision: 1,
                                                          step: 1,
                                                          max: 100,
                                                          min: 0,
                                                          size: "mini",
                                                          errorColor:
                                                            s.config720Survey
                                                              .bossRate ==
                                                              undefined ||
                                                            s.config720Survey
                                                              .bossRate == "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.config720SurveyRateChange(
                                                              s
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            s.config720Survey
                                                              .bossRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              s.config720Survey,
                                                              "bossRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "s.config720Survey.bossRate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 4 } },
                                                    [
                                                      _vm._v(
                                                        "\n                  Direct:\n                  "
                                                      ),
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          precision: 1,
                                                          step: 1,
                                                          max: 100,
                                                          min: 0,
                                                          size: "mini",
                                                          errorColor:
                                                            s.config720Survey
                                                              .directRate ==
                                                              undefined ||
                                                            s.config720Survey
                                                              .directRate == "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.config720SurveyRateChange(
                                                              s
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            s.config720Survey
                                                              .directRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              s.config720Survey,
                                                              "directRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "s.config720Survey.directRate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 4 } },
                                                    [
                                                      _vm._v(
                                                        "\n                  Peer:\n                  "
                                                      ),
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          precision: 1,
                                                          step: 1,
                                                          max: 100,
                                                          min: 0,
                                                          size: "mini",
                                                          errorColor:
                                                            s.config720Survey
                                                              .peerRate ==
                                                              undefined ||
                                                            s.config720Survey
                                                              .peerRate == "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.config720SurveyRateChange(
                                                              s
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            s.config720Survey
                                                              .peerRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              s.config720Survey,
                                                              "peerRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "s.config720Survey.peerRate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 4 } },
                                                    [
                                                      _vm._v(
                                                        "\n                  Rater:\n                  "
                                                      ),
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          precision: 1,
                                                          step: 1,
                                                          max: 100,
                                                          min: 0,
                                                          size: "mini",
                                                          errorColor:
                                                            s.config720Survey
                                                              .raterRate ==
                                                              undefined ||
                                                            s.config720Survey
                                                              .raterRate == "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.config720SurveyRateChange(
                                                              s
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            s.config720Survey
                                                              .raterRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              s.config720Survey,
                                                              "raterRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "s.config720Survey.raterRate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      { staticClass: "error" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.survey720RateError
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              as.type === "benchmark" || as.type === "summit"
                                ? _c(
                                    "el-row",
                                    { staticClass: "otr-line-spacing__10" },
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("span", [
                                          _vm._v("Task Credit (Rater)"),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 22 } },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 0,
                                              precision: 0,
                                              step: 1,
                                              size: "mini",
                                            },
                                            model: {
                                              value: as.raterTaskCredit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  as,
                                                  "raterTaskCredit",
                                                  $$v
                                                )
                                              },
                                              expression: "as.raterTaskCredit",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "otr-form__description",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getActionSetVideoTotalLength(
                                                    as
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "el-row",
                                    { staticClass: "otr-line-spacing__10" },
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("span", [_vm._v("Title: ")]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 22 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Title",
                                              size: "mini",
                                            },
                                            model: {
                                              value: as.title,
                                              callback: function ($$v) {
                                                _vm.$set(as, "title", $$v)
                                              },
                                              expression: "as.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                { staticClass: "otrfieldset1" },
                                [
                                  _c(
                                    "legend",
                                    [
                                      _vm._v("Component\n                  "),
                                      _vm.canAddAction(as.type, as.actions)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                margin: "0 10px",
                                                padding: "0",
                                                color: "#fff",
                                              },
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addAction(
                                                    index,
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus",
                                              }),
                                              _vm._v("Add"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  as.type == "benchmark" || as.type == "summit"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                  Please DO NOT edit the components after rating get started.\n                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  as.type == "benchmark" || as.type == "summit"
                                    ? _c(
                                        "span",
                                        {
                                          attrs: {
                                            value: [
                                              (_vm.videoScore =
                                                _vm.calcVideoScore(as)),
                                              (_vm.videoCount =
                                                _vm.calcVideoCount(as)),
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                margin: "10px 0",
                                                color: "#089900",
                                                border: "1px solid #089900",
                                                padding: "3px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    The Overall Skill(s) Weight(%) is " +
                                                  _vm._s(
                                                    100 -
                                                      _vm.suggestWeightForEachVideo
                                                  ) +
                                                  "%, so the suggested Weight(%) for each video is " +
                                                  _vm._s(
                                                    _vm.suggestWeightForEachVideo
                                                  ) +
                                                  "%.\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                margin: "10px 0",
                                                border: "1px solid #0099ff",
                                                padding: "3px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    _vm.videoScore == 100
                                                      ? "color: #0099ff"
                                                      : "color: red",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning",
                                                  }),
                                                  _vm._v(
                                                    "\n                      Current Weight(%) of " +
                                                      _vm._s(_vm.videoCount) +
                                                      " video(s) is " +
                                                      _vm._s(_vm.videoScore) +
                                                      "%\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(as.actions, function (a, idxa) {
                                    return _c(
                                      "div",
                                      { key: idxa, staticClass: "action-box" },
                                      [
                                        _c(
                                          "el-tabs",
                                          { attrs: { type: "border-card" } },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              { attrs: { label: "Settings" } },
                                              [
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticClass:
                                                      "otr-line-spacing__10",
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 3 } },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Component " +
                                                              _vm._s(idxa + 1) +
                                                              " ID: "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 21 } },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(a.id) +
                                                            "\n                          "
                                                        ),
                                                        as.type ===
                                                          "benchmark" ||
                                                        as.type === "summit"
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  float:
                                                                    "right",
                                                                  padding:
                                                                    "3px 0",
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeAction(
                                                                        index,
                                                                        idx,
                                                                        idxa
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-circle-close",
                                                                }),
                                                                _vm._v(
                                                                  " Remove Component\n                          "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        as.type ===
                                                          "benchmark" ||
                                                        as.type === "summit"
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      idxa > 0,
                                                                    expression:
                                                                      "idxa>0",
                                                                  },
                                                                ],
                                                                staticStyle: {
                                                                  float:
                                                                    "right",
                                                                  padding:
                                                                    "3px 10px 0px 0px",
                                                                },
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.moveUp(
                                                                        index,
                                                                        idx,
                                                                        idxa
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-top",
                                                                }),
                                                                _vm._v(
                                                                  " Move Up\n                          "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        as.type ===
                                                          "benchmark" ||
                                                        as.type === "summit"
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      idxa <
                                                                      as.actions
                                                                        .length -
                                                                        1,
                                                                    expression:
                                                                      "idxa<as.actions.length-1",
                                                                  },
                                                                ],
                                                                staticStyle: {
                                                                  float:
                                                                    "right",
                                                                  padding:
                                                                    "3px 10px 0px 0px",
                                                                },
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.moveDown(
                                                                        index,
                                                                        idx,
                                                                        idxa
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-bottom",
                                                                }),
                                                                _vm._v(
                                                                  " Move Down\n                          "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticClass:
                                                      "otr-line-spacing__10",
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 3 } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Type: "),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 21 } },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                "Select",
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeComponentType(
                                                                  $event,
                                                                  idxa,
                                                                  idx,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value: a.type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    a,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "a.type",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.getActionType(
                                                              as.type,
                                                              s.type
                                                            ),
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item,
                                                                  attrs: {
                                                                    label: item,
                                                                    value: item,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticClass:
                                                      "otr-line-spacing__10",
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 3 } },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Title: "),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 21 } },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "Title",
                                                            size: "mini",
                                                          },
                                                          model: {
                                                            value: a.title,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                a,
                                                                "title",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "a.title",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "info" ||
                                                a.type === "skill" ||
                                                a.type === "resource" ||
                                                a.type === "benchmarkreport" ||
                                                a.type === "summitreport"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Description: "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("vue-editor", {
                                                              attrs: {
                                                                id:
                                                                  "actionDesc_" +
                                                                  index +
                                                                  "_" +
                                                                  idx +
                                                                  "_" +
                                                                  idxa,
                                                                editorToolbar:
                                                                  _vm.customToolbar,
                                                              },
                                                              model: {
                                                                value:
                                                                  a.description,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      a,
                                                                      "description",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "a.description",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            false
                                                              ? _c("el-input", {
                                                                  attrs: {
                                                                    type: "textarea",
                                                                    placeholder:
                                                                      "Description",
                                                                    size: "mini",
                                                                    autosize: {
                                                                      minRows: 4,
                                                                      maxRows: 4,
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      a.description,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          a,
                                                                          "description",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "a.description",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                as.type === "benchmarkreport" ||
                                                as.type === "summitreport"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "el-row",
                                                              {
                                                                staticClass:
                                                                  "otr-line-spacing__20",
                                                                attrs: {
                                                                  gutter: 20,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 24,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        "Description below PI Compass"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-row",
                                                              {
                                                                staticClass:
                                                                  "otr-line-spacing__20",
                                                                attrs: {
                                                                  gutter: 20,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 4,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "PI Compass Chart Subtitle:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 20,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          clearable:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            s
                                                                              .report
                                                                              .globalAverage
                                                                              .description,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                s
                                                                                  .report
                                                                                  .globalAverage,
                                                                                "description",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "s.report.globalAverage.description",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-row",
                                                              {
                                                                staticClass:
                                                                  "otr-line-spacing__20",
                                                                attrs: {
                                                                  gutter: 20,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 4,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "PI Compass Explainer - Title"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 20,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          clearable:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            s
                                                                              .report
                                                                              .globalAverage
                                                                              .tipsTitle,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                s
                                                                                  .report
                                                                                  .globalAverage,
                                                                                "tipsTitle",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "s.report.globalAverage.tipsTitle",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-row",
                                                              {
                                                                staticClass:
                                                                  "otr-line-spacing__20",
                                                                attrs: {
                                                                  gutter: 20,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 4,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "PI Compass Explainer - Paragraph Text"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 20,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          type: "textarea",
                                                                          autosize:
                                                                            {
                                                                              minRows: 4,
                                                                            },
                                                                          clearable:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            s
                                                                              .report
                                                                              .globalAverage
                                                                              .tipsDescription,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                s
                                                                                  .report
                                                                                  .globalAverage,
                                                                                "tipsDescription",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "s.report.globalAverage.tipsDescription",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                (as.type === "benchmark" ||
                                                  as.type === "summit") &&
                                                a.type === "video"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Enable Rater Dial Testing: "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    a.enableDialTest,
                                                                  expression:
                                                                    "a.enableDialTest",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "checkbox",
                                                                id: "dialTestStatus",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  Array.isArray(
                                                                    a.enableDialTest
                                                                  )
                                                                    ? _vm._i(
                                                                        a.enableDialTest,
                                                                        null
                                                                      ) > -1
                                                                    : a.enableDialTest,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        a.enableDialTest,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          null,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            a,
                                                                            "enableDialTest",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            a,
                                                                            "enableDialTest",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        a,
                                                                        "enableDialTest",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "dialTestStatus",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    a.enableDialTest
                                                                      ? "Enabled"
                                                                      : "Disabled"
                                                                  ) +
                                                                    " - Rater will DialTest this video in rater tools"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                as.type !== "benchmark" &&
                                                as.type !== "summit" &&
                                                a.type === "video"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Enable P2P Dial Testing: "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    a.enableP2pDialTest,
                                                                  expression:
                                                                    "a.enableP2pDialTest",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "checkbox",
                                                                id: "enableP2pDialTest",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  Array.isArray(
                                                                    a.enableP2pDialTest
                                                                  )
                                                                    ? _vm._i(
                                                                        a.enableP2pDialTest,
                                                                        null
                                                                      ) > -1
                                                                    : a.enableP2pDialTest,
                                                              },
                                                              on: {
                                                                change: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        a.enableP2pDialTest,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          null,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            a,
                                                                            "enableP2pDialTest",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            a,
                                                                            "enableP2pDialTest",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        a,
                                                                        "enableP2pDialTest",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.p2pDialTestChange(
                                                                      a.enableP2pDialTest,
                                                                      index,
                                                                      idx,
                                                                      idxa
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            a.enableP2pDialTest
                                                              ? _c(
                                                                  "span",
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              Minimum:"
                                                                    ),
                                                                    _c(
                                                                      "el-input-number",
                                                                      {
                                                                        attrs: {
                                                                          min: 1,
                                                                          max: 5,
                                                                          precision: 0,
                                                                          step: 1,
                                                                          size: "mini",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            a.p2pDialTestRaterMin,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                a,
                                                                                "p2pDialTestRaterMin",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "a.p2pDialTestRaterMin",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                              Maximum:"
                                                                    ),
                                                                    _c(
                                                                      "el-input-number",
                                                                      {
                                                                        attrs: {
                                                                          min: 1,
                                                                          max: 5,
                                                                          precision: 0,
                                                                          step: 1,
                                                                          size: "mini",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            a.p2pDialTestRaterMax,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                a,
                                                                                "p2pDialTestRaterMax",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "a.p2pDialTestRaterMax",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      "\n                            One video can be rated by at least "
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "large",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "1"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " and at most "
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "large",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "5"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " participant(s)\n                          "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Min. Recording Time (sec): "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "el-popover",
                                                                  {
                                                                    attrs: {
                                                                      placement:
                                                                        "right",
                                                                      title:
                                                                        "Recording Time",
                                                                      width:
                                                                        "600",
                                                                      trigger:
                                                                        "hover",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#303133",
                                                                            "font-size":
                                                                              "14px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Min. Recording Time"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "Videos cannot be shorter than this amount. If the user tries to stop early, the app will ask them to keep recording for at least # seconds."
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#303133",
                                                                            "font-size":
                                                                              "14px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Ideal Recording Time"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        'This is when the recording timer reaches a full circle and starts to flash red. It means "Time\'s up!" but does not cut the user off. '
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#303133",
                                                                            "font-size":
                                                                              "14px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Auto Cutoff Time"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "If the user has not stopped yet, at this time the recording stops automatically."
                                                                      ),
                                                                    ]),
                                                                    _c("br"),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#303133",
                                                                            "font-size":
                                                                              "14px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Example:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        'For a "Two Minute Talk" - the video should be at least 1 minute long, the target length is 2 minutes, but we would let the participant go over by an extra minute. The settings would be:'
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "Min. Recording Time = 60"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "Ideal Recording Time = 120"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "Auto Cutoff Time = 180"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "svg-icon",
                                                                      {
                                                                        attrs: {
                                                                          slot: "reference",
                                                                          "icon-class":
                                                                            "faq",
                                                                        },
                                                                        slot: "reference",
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  max: 9999,
                                                                  precision: 0,
                                                                  step: 1,
                                                                  size: "mini",
                                                                },
                                                                model: {
                                                                  value:
                                                                    a.minRecordingTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        a,
                                                                        "minRecordingTime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "a.minRecordingTime",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Ideal Recording Time (sec): "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  max: 9999,
                                                                  precision: 0,
                                                                  step: 1,
                                                                  size: "mini",
                                                                },
                                                                model: {
                                                                  value:
                                                                    a.maxRecordingTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        a,
                                                                        "maxRecordingTime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "a.maxRecordingTime",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Auto Cutoff Time (sec): "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  max: 9999,
                                                                  precision: 0,
                                                                  step: 1,
                                                                  size: "mini",
                                                                },
                                                                model: {
                                                                  value:
                                                                    a.autoCutRecordingTime,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        a,
                                                                        "autoCutRecordingTime",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "a.autoCutRecordingTime",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "skill" ||
                                                a.type === "resource" ||
                                                a.type === "game"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Step Value "
                                                              ),
                                                              a.type === "game"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "(per round)"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(": "),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  max: 9999,
                                                                  precision: 0,
                                                                  step: 1,
                                                                  size: "mini",
                                                                },
                                                                model: {
                                                                  value:
                                                                    a.stepsValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        a,
                                                                        "stepsValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "a.stepsValue",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" ||
                                                a.type === "skill" ||
                                                a.type === "resource"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Time In Minutes: "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                attrs: {
                                                                  min: 0,
                                                                  max: 9999,
                                                                  size: "mini",
                                                                },
                                                                model: {
                                                                  value:
                                                                    a.timeInMinutes,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        a,
                                                                        "timeInMinutes",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "a.timeInMinutes",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Recording Tips: "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                id:
                                                                  "recordingTips_" +
                                                                  index +
                                                                  "_" +
                                                                  idx +
                                                                  "_" +
                                                                  idxa,
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value:
                                                                  a.recordingTips,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      a,
                                                                      "recordingTips",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "a.recordingTips",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "video" && false
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          border:
                                                            "1px solid #b0b0b0",
                                                          padding: "5px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-row",
                                                          {
                                                            staticClass:
                                                              "otr-line-spacing__10",
                                                            attrs: {
                                                              gutter: 20,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 3,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "otr-form__label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Recording Text:"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 21,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.addRecordingText(
                                                                            index,
                                                                            idx,
                                                                            idxa
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "+ Add Recording Text"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          a.recordingTexts,
                                                          function (
                                                            item,
                                                            textidx
                                                          ) {
                                                            return _c(
                                                              "el-row",
                                                              {
                                                                key: textidx,
                                                                staticClass:
                                                                  "otr-line-spacing__10",
                                                                attrs: {
                                                                  gutter: 20,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 3,
                                                                    },
                                                                  },
                                                                  [
                                                                    textidx ===
                                                                    0
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "otr-form__label",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "[interval(sec)/text]"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "otr-form__label",
                                                                          }
                                                                        ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 21,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input-number",
                                                                      {
                                                                        attrs: {
                                                                          min: 0,
                                                                          max: 9999,
                                                                          precision: 0,
                                                                          step: 1,
                                                                          size: "mini",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.interval,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "interval",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.interval",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "600px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "Recording Text",
                                                                          size: "mini",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.text,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "text",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.text",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "skill"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Skill:"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "230px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Skill Id",
                                                                readonly: "",
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value: a.skill,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      a,
                                                                      "skill",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "a.skill",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openSkillDialog(
                                                                        idxa,
                                                                        idx,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Select")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "danger",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clearSkill(
                                                                        idxa,
                                                                        idx,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Clear")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "game"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Game:"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "230px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Game Id",
                                                                readonly: "",
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value: a.game,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      a,
                                                                      "game",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "a.game",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openGameDialog(
                                                                        idxa,
                                                                        idx,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Select")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "danger",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clearGame(
                                                                        idxa,
                                                                        idx,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Clear")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                a.type === "resource"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "otr-line-spacing__10",
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 3 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Resource:"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 21 },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "230px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "Resource Id",
                                                                readonly: "",
                                                                size: "mini",
                                                              },
                                                              model: {
                                                                value:
                                                                  a.resource,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      a,
                                                                      "resource",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "a.resource",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openResourceDialog(
                                                                        idxa,
                                                                        idx,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Select")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "danger",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clearResource(
                                                                        idxa,
                                                                        idx,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Clear")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.checkScoreExist(a, as)
                                              ? _c(
                                                  "el-tab-pane",
                                                  {
                                                    attrs: { label: "Scores" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Component " +
                                                        _vm._s(idxa + 1) +
                                                        " Weight(%): \n                      "
                                                    ),
                                                    _c("el-input-number", {
                                                      staticStyle: {
                                                        width: "100px",
                                                      },
                                                      attrs: {
                                                        min: 0,
                                                        max: 100,
                                                        size: "mini",
                                                      },
                                                      model: {
                                                        value:
                                                          a.scoreConfig.rate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            a.scoreConfig,
                                                            "rate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "a.scoreConfig.rate",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          float: "right",
                                                        },
                                                        attrs: {
                                                          type: "warning",
                                                          size: "mini",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.resetScore(
                                                              index,
                                                              idx,
                                                              idxa
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Reset Score")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          value: [
                                                            (_vm.totalPercentage =
                                                              _vm.calcSkillFactors(
                                                                a.scoreConfig
                                                                  .scores
                                                              )),
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "10px",
                                                              border:
                                                                "1px solid #0099ff",
                                                              padding: "3px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                style:
                                                                  _vm.totalPercentage ==
                                                                  _vm.suggestWeightForEachVideo
                                                                    ? "color: #0099ff"
                                                                    : "color: red",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-warning",
                                                                }),
                                                                _vm._v(
                                                                  "\n\n                            Current Weight(%) of " +
                                                                    _vm._s(
                                                                      _vm.calcSkillCount(
                                                                        a
                                                                          .scoreConfig
                                                                          .scores
                                                                      )
                                                                    ) +
                                                                    " skill(s) is " +
                                                                    _vm._s(
                                                                      _vm.totalPercentage
                                                                    ) +
                                                                    "%, and the suggested Weight is " +
                                                                    _vm._s(
                                                                      _vm.suggestWeightForEachVideo
                                                                    ) +
                                                                    "%\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-table",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          data: a.scoreConfig
                                                            .scores,
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "Skill Tag",
                                                            width: "400",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .title
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "Weight (%)",
                                                            width: "150",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-input-number",
                                                                      {
                                                                        attrs: {
                                                                          precision: 4,
                                                                          step: 0.1,
                                                                          max: 100,
                                                                          size: "mini",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .rate,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "rate",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row.rate",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "Enable",
                                                            width: "60",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-checkbox",
                                                                      {
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .enable,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "enable",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row.enable",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.canAddAction(as.type, as.actions)
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            margin: "10px 0",
                                            padding: "3px",
                                          },
                                          attrs: { type: "success" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addAction(index, idx)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-circle-plus",
                                          }),
                                          _vm._v("Add Component"),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.save()
                },
              },
            },
            [_vm._v("Save Activity")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "text" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.backToHike()
                },
              },
            },
            [_vm._v("Back to Hike")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: { title: "Skill", visible: _vm.dialogSkillVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSkillVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.skillList } },
            [
              _c("el-table-column", {
                attrs: { label: "Add", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addSkillInAction(
                                  scope.row.id,
                                  scope.row.title
                                )
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Type", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.type))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Title", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: { title: "Resource", visible: _vm.dialogResourceVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogResourceVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: { placeholder: "Search by keyword" },
                    model: {
                      value: _vm.resourceQuery.q,
                      callback: function ($$v) {
                        _vm.$set(_vm.resourceQuery, "q", $$v)
                      },
                      expression: "resourceQuery.q",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getResources()
                        },
                      },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.resourceOptions } },
            [
              _c("el-table-column", {
                attrs: { label: "Add", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addResourceInAction(
                                  scope.row.id,
                                  scope.row.title
                                )
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Category", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.category))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Title", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: { title: "Game", visible: _vm.dialogGameVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGameVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.gameList } },
            [
              _c("el-table-column", {
                attrs: { label: "Add", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addGameInAction(
                                  scope.row.id,
                                  scope.row.title
                                )
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Type", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.type))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Type", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.subType))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Title", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.survey720DialogVisible, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.survey720DialogVisible = $event
            },
          },
        },
        [
          _c("h1", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Upload Survey Result - " + _vm._s(_vm.survey720DialogType)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("upload-excel-component", {
                        attrs: {
                          "on-success": _vm.survey720DataHandleSuccess,
                          "before-upload": _vm.survey720DataBeforeUpload,
                          drop: _vm.survey720SmallDrop,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line_spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "span",
                      {
                        staticClass: "otr-form__title",
                        staticStyle: { float: "left", "font-size": "16px" },
                      },
                      [
                        _vm._v(
                          "Survey Result - " +
                            _vm._s(this.survey720DialogType) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("table", { staticClass: "userList" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "20" } }, [_vm._v("ID")]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "100" } }, [
                            _vm._v("Participant"),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "70" } }, [
                            _vm._v("Survey(s)"),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "70" } }, [
                            _vm._v("Avg. Score"),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "300" } }, [
                            _vm._v("Checking"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            _vm.survey720TableData,
                            function (value, name, index) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(index + 1))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(value.email))]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(value.reports.length)),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("numberFormatter")(
                                        value.averageScore
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  value.checkResult === ""
                                    ? _c("img", {
                                        attrs: {
                                          src: require("@/assets/otr/images/icon_tick.png"),
                                          width: "20",
                                        },
                                      })
                                    : _c("span", { staticClass: "error" }, [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/otr/images/icon_cross.png"),
                                            width: "20",
                                          },
                                        }),
                                        _vm._v(_vm._s(value.checkResult)),
                                      ]),
                                ]),
                              ])
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.survey720ParticipantWithoutSurveyList,
                            function (item, idx) {
                              return _c("tr", { key: idx }, [
                                _c("td", [_vm._v("-")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(item["email"]))]),
                                _vm._v(" "),
                                _c("td", [_vm._v("--")]),
                                _vm._v(" "),
                                _c("td", [_vm._v("--")]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("span", { staticClass: "error" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/otr/images/icon_cross.png"),
                                        width: "20",
                                      },
                                    }),
                                    _vm._v("Survey not found"),
                                  ]),
                                ]),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    Object.keys(_vm.survey720TableData).length > 0
                      ? _c("span", [
                          _c("span", { staticClass: "largeFont" }, [
                            _vm._v(_vm._s(_vm.survey720TableTotalDataCount)),
                          ]),
                          _vm._v("\n            survey(s) for\n            "),
                          _c("span", { staticClass: "largeFont" }, [
                            _vm._v(
                              _vm._s(Object.keys(_vm.survey720TableData).length)
                            ),
                          ]),
                          _vm._v("\n            participant(s)"),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "largeFont" }, [
                            _vm._v(_vm._s(_vm.survey720TableValidDataCount)),
                          ]),
                          _vm._v(
                            "\n            survey(s) will upload to database.\n          "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: Object.keys(_vm.survey720TableData).length === 0,
                    type: "primary",
                    size: "small",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.survey720DataUpload($event)
                    },
                  },
                },
                [_vm._v("Upload")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { padding: "10px 0" } }, [
      _c("u", [_vm._v("Hike Information")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticStyle: { "font-size": "30px" } }, [
        _vm._v("Activity Setting"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }