"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _rater = require("@/api/rater");
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'rulelist',
  data: function data() {
    return {
      dialogCreateFormVisible: false,
      dialogUpdateFormVisible: false,
      loading: false,
      query: {
        page: 1,
        limit: 200,
        sort: 'sort',
        type: 'rater-assignment'
      },
      newSetting: {
        "type": "rater-assignment",
        "rule": {
          "applyTo": "",
          "value": 300
        },
        "description": "Maximium number of video for rating per rater per week",
        "sort": 1
      },
      updatedSetting: null,
      raterRules: null,
      defaultRule: null,
      raterList: null
    };
  },
  created: function created() {
    this.getList();
    this.getRaterList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.raterRules = [];
      (0, _rater.getRuleList)(token, this.query).then(function (response) {
        var data = response.data;
        var tmpRaterRules = _.filter(data.rows, function (o) {
          return !(o.rule.applyTo === 'all');
        });
        _this.defaultRule = _.filter(data.rows, function (o) {
          return o.rule.applyTo === 'all';
        });

        // filter out not a rater
        for (var i = 0; i < tmpRaterRules.length; i++) {
          console.log('rater uuid:', tmpRaterRules[i].rule.applyTo);
          var rater = _.find(_this.raterList, function (u) {
            return u.user === tmpRaterRules[i].rule.applyTo;
          });
          if (rater) {
            _this.raterRules.push(tmpRaterRules[i]);
          }
        }
        _this.loading = false;
      }).catch(function (error) {
        _this.loading = false;
        (0, _log.showDebugLog)('page-question-save-err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    getRaterList: function getRaterList() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var query = {
        page: 1,
        limit: 200,
        role: 'otr-rater',
        sort: 'givenName'
      };
      (0, _user.getUserList)(token, query).then(function (response) {
        // this.list = response.data.items
        _this2.raterList = response.data.rows.map(function (s) {
          return {
            user: s.uuid,
            name: "".concat(s.givenName, " ").concat(s.familyName)
          };
        });
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetUserList-api-error', error);
      });
    },
    getUserName: function getUserName(uuid) {
      var staff = this.raterList ? this.raterList.find(function (u) {
        return u.user === uuid;
      }) : "-";
      if (staff) {
        return staff.name ? staff.name : '-';
      } else {
        return 'Not a rater';
      }
    },
    isRater: function isRater(uuid) {
      var staff = this.raterList ? this.raterList.find(function (u) {
        return u.user === uuid;
      }) : "-";
      if (staff) return "";else return "not-a-rater";
    },
    update: function update(rule) {
      var _this3 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      (0, _rater.updateRule)(token, rule).then(function (response) {
        //this.raterRules[0] = response.data
        (0, _log.displayPopupMessage)('Rater weekly credit setting updated successfully', 'success');
        (0, _ga.writeGaEvent)(_this3, 'Rule', "".concat(_lifehikesInitData.userEvent.raterAssignment.updateIndividualRule), email, "ID: ".concat(response.data.id));
        _this3.getList();
        _this3.dialogUpdateFormVisible = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    create: function create(rule) {
      var _this4 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      (0, _rater.createRule)(token, rule).then(function (response) {
        (0, _log.displayPopupMessage)('Rater weekly credit setting created successfully', 'success');
        (0, _ga.writeGaEvent)(_this4, 'Rule', "".concat(_lifehikesInitData.userEvent.raterAssignment.addIndividualRule), email, "ID: ".concat(response.data.id));
        _this4.getList();
        _this4.dialogCreateFormVisible = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    confirmEdit: function confirmEdit(rule) {
      this.dialogUpdateFormVisible = true;
      this.updatedSetting = rule;
    }
  }
};