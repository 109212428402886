"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lifehikesInitData = require("@/lifehikes-init-data");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SupportedPlaceholders',
  props: {
    programType: String
  },
  data: function data() {
    var placeholders = _lifehikesInitData.hikeEmailSupportedPlaceholders;
    if (this.programType == 'loop') {
      placeholders = _lifehikesInitData.loopEmailSupportedPlaceholders;
    }
    return {
      spData: placeholders
    };
  }
};