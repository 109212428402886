var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.isReady,
          expression: "!isReady",
        },
      ],
      staticClass: "app-container",
    },
    [
      _vm.hike
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "10px",
                "background-color": "#eee",
                padding: "10px",
                display: "block",
              },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-bottom": "10px", padding: "10px 0" } },
                [_vm._v("ID: " + _vm._s(_vm.hike.id))]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticStyle: { padding: "10px 0" } }, [
                _vm._v("Channel: " + _vm._s(_vm.hike.channel)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticStyle: { padding: "10px 0" } }, [
                _vm._v("Title: " + _vm._s(_vm.hike.title)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.hike.internal
                ? _c("span", { staticStyle: { padding: "10px 0" } }, [
                    _vm._v(
                      "Internal Title: " + _vm._s(_vm.hike.internal.title)
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm.isReady
        ? _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", [_vm._v("Participant's Name")]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", [
                      _vm._v("Participant Benchmark "),
                      _c("br"),
                      _vm._v("Video Progress"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c("div", [
                      _vm._v("Benchmark Task Assign Status /"),
                      _c("br"),
                      _vm._v("Rating Status"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", [
                      _vm._v("Participant Summit "),
                      _c("br"),
                      _vm._v("Video Progress"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c("div", [
                      _vm._v("Summit Task Assign Status /"),
                      _c("br"),
                      _vm._v("Rating Status"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.myHikeTeams, function (u, index) {
                return _c(
                  "el-row",
                  {
                    key: index,
                    staticClass: "otr-line-spacing__20",
                    staticStyle: {
                      "border-bottom": "1px solid #111111",
                      "padding-bottom": "5px",
                    },
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 4 } }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(index + 1) +
                            ". " +
                            _vm._s(u.givenName + " " + u.familyName) +
                            " "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(u.email))]),
                      _vm._v(" "),
                      true
                        ? _c("div", { staticStyle: { "font-size": "11px" } }, [
                            _vm._v(_vm._s(u.uuid)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getUserProgressHtml(u.uuid, "benchmark")
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 7 } }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getTaskStatusHtml(u.uuid, "benchmark")
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getUserProgressHtml(u.uuid, "summit")
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 7 } }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getTaskStatusHtml(u.uuid, "summit")
                          ),
                        },
                      }),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isReady
        ? _c("div", [
            _c("div", {
              staticClass: "card",
              domProps: { innerHTML: _vm._s(_vm.calculateSummaryHtml()) },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "margin-bottom": "10px", padding: "10px 0" } },
      [_c("u", [_vm._v("Hike Information")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-bottom": "10px",
          "background-color": "#eee",
          padding: "10px",
          display: "block",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "20px",
              "text-transform": "capitalize",
            },
          },
          [_vm._v("Task Status Report")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }