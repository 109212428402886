var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "20px" } },
    [
      _c(
        "div",
        { staticStyle: { "font-size": "24px", "margin-bottom": "20px" } },
        [_c("u", [_vm._v(_vm._s(_vm.$route.query.title))])]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.myTrainingTeams },
        },
        [
          _c("el-table-column", { attrs: { prop: "uuid", label: "UUID" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "givenName", label: "Given Name", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "familyName", label: "Surname" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "Company" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "jobTitle", label: "Job Title" },
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "email", label: "Email" } }),
          _vm._v(" "),
          _c("el-table-column", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/report/progress/" + scope.row.uuid,
                            query: {
                              name:
                                scope.row.givenName +
                                " " +
                                scope.row.familyName,
                              orgName: scope.row.orgName,
                              email: scope.row.email,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "10px 10px" },
                            attrs: { type: "primary" },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "chart" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }