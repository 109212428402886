"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CountToDemo',
  components: {
    countTo: _vueCountTo.default
  },
  data: function data() {
    return {
      setStartVal: 0,
      setEndVal: 2017,
      setDuration: 4000,
      setDecimals: 0,
      setSeparator: ',',
      setSuffix: ' rmb',
      setPrefix: '¥ '
    };
  },
  computed: {
    _startVal: function _startVal() {
      if (this.setStartVal) {
        return this.setStartVal;
      } else {
        return 0;
      }
    },
    _endVal: function _endVal() {
      if (this.setEndVal) {
        return this.setEndVal;
      } else {
        return 0;
      }
    },
    _duration: function _duration() {
      if (this.setDuration) {
        return this.setDuration;
      } else {
        return 100;
      }
    },
    _decimals: function _decimals() {
      if (this.setDecimals) {
        if (this.setDecimals < 0 || this.setDecimals > 20) {
          alert('digits argument must be between 0 and 20');
          return 0;
        }
        return this.setDecimals;
      } else {
        return 0;
      }
    },
    _separator: function _separator() {
      return this.setSeparator;
    },
    _suffix: function _suffix() {
      return this.setSuffix;
    },
    _prefix: function _prefix() {
      return this.setPrefix;
    }
  },
  methods: {
    start: function start() {
      this.$refs.example.start();
    },
    pauseResume: function pauseResume() {
      this.$refs.example.pauseResume();
    }
  }
};