"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
var _rater = require("@/api/rater");
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _moment = _interopRequireDefault(require("moment"));
var _lodash = _interopRequireDefault(require("lodash"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'report',
  data: function data() {
    return {
      reportType: 'individual',
      raterList: [],
      yearIso: '2021',
      weekOfYear: '1',
      individualYearIso: '',
      individualWeekOfYear: '',
      allYearIso: '',
      allWeekOfYear: '',
      tasYearIso: '',
      tasWeekOfYear: '',
      rater: '',
      errorMsg: '',
      totalJobs: 0,
      totalVideos: 0,
      totalCompletedJob: 0,
      jobs: [],
      summary: [],
      tasCredit: [],
      loading: false,
      downloadLoading: false,
      firstLoad: true,
      totalTaskCredit: 0
    };
  },
  created: function created() {
    var now = new Date();
    this.getRaterList();
    this.yearIso = (0, _moment.default)(now).isoWeekYear();
    this.weekOfYear = (0, _moment.default)(now).isoWeek().toString();
  },
  methods: {
    yearIsoIsValid: function yearIsoIsValid() {
      return /^[0-9]+$/.test(this.yearIso); // Check if it is a positive integer
    },
    weekOfYearIsValid: function weekOfYearIsValid() {
      if (this.reportType !== 'tasCreditReport') {
        return /^[0-9]+$/.test(this.weekOfYear); // Check if it is a positive integer
      } else {
        return this.weekOfYear.split(',').map(function (s) {
          return s.trim();
        }).every(function (s) {
          return /^[0-9]+$/.test(s);
        }); // Check if it is a positive integer
      }
    },
    getStartDate: function getStartDate(yearIso, weekOfYear) {
      var start = (0, _moment.default)().isoWeekYear(yearIso).isoWeeks(weekOfYear).startOf('isoWeek');
      return start.format("yyyy-MM-DD");
    },
    getEndDate: function getEndDate(yearIso, weekOfYear) {
      var end = (0, _moment.default)().isoWeekYear(yearIso).isoWeeks(weekOfYear).endOf('isoWeek');
      return end.format("yyyy-MM-DD");
    },
    getWeekPeriods: function getWeekPeriods(yearIso, weekOfYear) {
      var periods = '';
      var weekOfYearISO = weekOfYear.split(',').map(function (n) {
        return Number(n);
      });
      weekOfYearISO.forEach(function (week) {
        var isoWeek = (0, _moment.default)().isoWeekYear(yearIso).isoWeeks(week);
        periods += isoWeek.startOf('isoWeek').format('yyyy-MM-DD') + ' to ' + isoWeek.endOf('isoWeek').format('yyyy-MM-DD') + ', ';
      });
      return periods.slice(0, -2);
    },
    getRaterList: function getRaterList() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var query = {
        page: 1,
        limit: 200,
        role: 'otr-rater',
        sort: 'givenName'
      };
      (0, _user.getUserList)(token, query).then(function (response) {
        // this.list = response.data.items
        _this.raterList = response.data.rows.map(function (s) {
          return {
            uuid: s.uuid,
            name: "".concat(s.givenName, " ").concat(s.familyName)
          };
        });
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetUserList-api-error', error);
      });
    },
    getUserName: function getUserName(uuid) {
      var staff = this.raterList ? this.raterList.find(function (u) {
        return u.uuid === uuid;
      }) : null;
      return staff ? staff.name : '-';
    },
    checkHasOverallScore: function checkHasOverallScore(userContent) {
      var overallStatus;
      if (userContent.length > 0) {
        overallStatus = userContent[0].hasOverallScore ? 'Yes' : "No";
      } else {
        overallStatus = "-";
      }
      return overallStatus;
    },
    generateReport: function generateReport() {
      if (this.reportType === 'individual' && this.rater === '') {
        (0, _log.displayPopupMessage)("Please select rater.", 'error');
        return;
      }
      if (!this.yearIsoIsValid()) {
        (0, _log.displayPopupMessage)("Please fill in a valid Year(ISO).", 'error');
        return;
      }
      if (!this.weekOfYearIsValid()) {
        (0, _log.displayPopupMessage)("Please fill in a valid Week of Year.", 'error');
        return;
      }
      this.errorMsg = "";
      if (this.reportType === 'individual') {
        this.individualYearIso = this.yearIso;
        this.individualWeekOfYear = this.weekOfYear;
        this.individualReport();
      }
      if (this.reportType === 'all') {
        this.allYearIso = this.yearIso;
        this.allWeekOfYear = this.weekOfYear;
        this.summaryReport();
      }
      if (this.reportType === 'tasCreditReport') {
        this.tasYearIso = this.yearIso;
        this.tasWeekOfYear = this.weekOfYear;
        this.tasCreditReport();
      }
    },
    individualReport: function individualReport() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      this.loading = true;
      (0, _rater.generateIndividualReport)(token, this.yearIso, this.weekOfYear, this.rater).then(function (response) {
        _this2.totalJobs = response.data.count;
        _this2.jobs = response.data.rows;
        var totalvideos = 0;
        var totalCompletedJob = 0;
        var totaltaskCredit = 0;
        _lodash.default.forEach(_this2.jobs, function (j) {
          totalvideos += j.userContents.length;
          totaltaskCredit += j.raterTaskCredit;
          if (j.jobStatus === 'complete') totalCompletedJob += 1;
        });
        _this2.totalVideos = totalvideos;
        _this2.totalCompletedJob = totalCompletedJob;
        _this2.totalTaskCredit = totaltaskCredit;
        _this2.loading = false;
        _this2.firstLoad = false;
        // console.log(response.data)
        // console.log('Done')
      }).catch(function (error) {
        _this2.loading = false;
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    summaryReport: function summaryReport() {
      var _this3 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      this.loading = true;
      (0, _rater.generateSummaryReport)(token, this.yearIso, this.weekOfYear).then(function (response) {
        var totalVideos = 0;
        var totalJobs = 0;
        var totalCompletedJob = 0;
        var raters = response.data.raters;
        var jobs = response.data.rows;
        _this3.summary = [];
        for (var i = 0; i < raters.length; i++) {
          var raterTotalJobs = 0;
          var raterTotalCompleteJobs = 0;
          var raterTotalvideos = 0;
          var raterName = _this3.getUserName(raters[i]);
          for (var j = 0; j < jobs.length; j++) {
            console.log('job Detail', jobs[j]);
            if (jobs[j].rater === raters[i]) {
              totalJobs += 1;
              totalVideos += jobs[j].userContents.length;
              raterTotalJobs += 1;
              raterTotalvideos += jobs[j].userContents.length;
              if (jobs[j].jobStatus === 'complete') raterTotalCompleteJobs += 1;
            }
          }
          totalCompletedJob += raterTotalCompleteJobs;
          _this3.summary.push({
            rater: raterName,
            raterUuid: raters[i],
            totalJob: raterTotalJobs,
            totalCompleteJob: raterTotalCompleteJobs,
            totalVideo: raterTotalvideos
          });
        }
        _this3.totalCompletedJob = totalCompletedJob;
        _this3.totalJobs = totalJobs;
        _this3.totalVideos = totalVideos;
        _this3.loading = false;
        _this3.firstLoad = false;
        // console.log('partition', this.summary)
        // console.log('Done')
      }).catch(function (error) {
        _this3.loading = false;
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    tasCreditReport: function tasCreditReport() {
      var _this4 = this;
      this.loading = true;
      this.generateTASCreditReport().then(function (response) {
        var tasCredit = response.data.raters.map(function (rater) {
          var raterReports = response.data.rows.filter(function (row) {
            return row.rater === rater;
          });
          return {
            rater: _this4.getUserName(rater),
            raterUuid: rater,
            benchmarks: raterReports.filter(function (row) {
              return row.stageType === 'benchmark';
            }).length,
            summits: raterReports.filter(function (row) {
              return row.stageType === 'summit';
            }).length,
            totalCredits: raterReports.reduce(function (previousValue, currentRow) {
              return previousValue + currentRow.raterTaskCredit;
            }, 0)
          };
        });
        _this4.tasCredit = tasCredit;
        _this4.loading = false;
        _this4.firstLoad = false;
      }).catch(function (error) {
        _this4.loading = false;
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    generateTASCreditReport: function generateTASCreditReport() {
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      var weekOfYearISO = this.weekOfYear.split(',').map(function (n) {
        return Number(n);
      }).filter(function (n) {
        return !isNaN(n) && n % 1 === 0; // not NaN & decimal
      });

      return (0, _rater.generateTASCreditReport)(token, {
        year: this.yearIso,
        weekOfYearISO: weekOfYearISO
      });
    },
    releaseRaterJob: function releaseRaterJob(raterId, jobId, userHikeId, stageId) {
      var _this5 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      this.loading = true;
      (0, _rater.releaseJob)(token, raterId, jobId, userHikeId, stageId).then(function (response) {
        _this5.loading = false;
        (0, _ga.writeGaEvent)(_this5, 'RaterAssignment', "".concat(_lifehikesInitData.userEvent.raterAssignment.release), email, "Release Job - raterId: ".concat(raterId, ", jobId: ").concat(jobId, ", userHikeId: ").concat(userHikeId, ", stageId: ").concat(stageId));
        _this5.generateReport();
      }).catch(function (error) {
        _this5.loading = false;
        (0, _log.showDebugLog)(error);
        (0, _log.displayPopupMessage)("Fail to release the task", 'error');
      });
    },
    handleTabChange: function handleTabChange(val) {
      console.log(val);
    },
    cssWordRed: function cssWordRed(val) {
      if (val === 'open') return {
        open: true,
        complete: false
      };else return {
        open: false,
        complete: true
      };
    },
    handleDownload: function handleDownload() {
      var _this6 = this;
      this.downloadLoading = true;
      this.generateTASCreditReport().then(function (response) {
        var tasCredit = response.data.raters.map(function (rater) {
          var raterReports = response.data.rows.filter(function (row) {
            return row.rater === rater;
          });
          return {
            rater: _this6.getUserName(rater),
            raterUuid: rater,
            benchmarks: raterReports.filter(function (row) {
              return row.stageType === 'benchmark';
            }).length,
            summits: raterReports.filter(function (row) {
              return row.stageType === 'summit';
            }).length,
            totalCredits: raterReports.reduce(function (previousValue, currentRow) {
              return previousValue + currentRow.raterTaskCredit;
            }, 0)
          };
        });
        Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/vendor/Export2Excel'));
        }).then(function (excel) {
          var xdata = _this6.buildExcelData(tasCredit);
          excel.export_json_to_excel({
            header: xdata.header,
            data: xdata.data,
            filename: 'Training Assessment Scorer Report'
          });
          _this6.downloadLoading = false;
        });
      }).catch(function (error) {
        _this6.downloadLoading = false;
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    buildExcelData: function buildExcelData(tasCredit) {
      var excelHeader = ['Training Assessment Scorer Report', '', '', '', '']; // header row
      var excelData = [];
      excelData.push(['Year', this.yearIso, '', '', '']);
      excelData.push(['Range Date', this.weekOfYear + '(' + this.getWeekPeriods(this.yearIso, this.weekOfYear) + ')', '', '', '']);
      excelData.push(['', '', '', '', '']);
      excelData.push(['', '', '', '', '']);
      excelData.push(['', 'Benchmarks', 'Summits', 'Total Credits', 'Total $']);

      // build data row
      for (var i = 0, l = tasCredit.length; i < l; i++) {
        var row = [];
        row.push(tasCredit[i].rater + '(' + tasCredit[i].raterUuid + ')');
        row.push(tasCredit[i].benchmarks);
        row.push(tasCredit[i].summits);
        row.push(tasCredit[i].totalCredits);
        row.push('');
        excelData.push(row);
      }
      return {
        data: excelData,
        header: excelHeader
      };
    }
  }
};