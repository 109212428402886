var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "otr-line-spacing__30",
          attrs: { gutter: 20, width: "100%" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("search-box", {
                attrs: { placeholder: "Title" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search(1)
                  },
                },
                model: {
                  value: _vm.query.q,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "q", $$v)
                  },
                  expression: "query.q",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  ref: "status",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Status", clearable: "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("status")
                    },
                  },
                  model: {
                    value: _vm.query.publish,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "publish", $$v)
                    },
                    expression: "query.publish",
                  },
                },
                _vm._l(_vm.trainingStatus, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  ref: "country",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Country", clearable: "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("country")
                    },
                  },
                  model: {
                    value: _vm.query.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "country", $$v)
                    },
                    expression: "query.country",
                  },
                },
                _vm._l(_vm.countryList, function (item) {
                  return _c("el-option", {
                    key: item.countryCode,
                    attrs: { label: item.country, value: item.countryCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              false
                ? _c(
                    "el-checkbox",
                    {
                      staticStyle: { "padding-top": "10px" },
                      on: { change: _vm.setMyTraining },
                      model: {
                        value: _vm.myProducedTraining,
                        callback: function ($$v) {
                          _vm.myProducedTraining = $$v
                        },
                        expression: "myProducedTraining",
                      },
                    },
                    [_vm._v("My Own Training")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  ref: "producedBy",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Produced By", clearable: "" },
                  on: { change: _vm.setMyTraining },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("producedBy")
                    },
                  },
                  model: {
                    value: _vm.query.staffUuid,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "staffUuid", $$v)
                    },
                    expression: "query.staffUuid",
                  },
                },
                _vm._l(_vm.staffList, function (s) {
                  return _c("el-option", {
                    key: s.user,
                    attrs: { label: s.name, value: s.user },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search(1)
                    },
                  },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.trainingList,
                    fit: "",
                    "highlight-current-row": "",
                    height: _vm.height,
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "sort-change": _vm.sort,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "LOC", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.address.country)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.title"),
                      width: "250",
                      sortable: "custom",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.title) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.producer"),
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getProducerStaff")(
                                    scope.row.staff,
                                    _vm.staffList
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.start"),
                      width: "150",
                      sortable: "custom",
                      prop: "start",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                "   " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.start.utc,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.actions"),
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/report/attendance/" + scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "10px 10px" },
                                    attrs: { type: "primary" },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "skill" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.query.limit,
                      "pager-count": 5,
                      total: _vm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }