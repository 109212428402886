var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Participant Report")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 18 } }, [
                _vm.skillCompassDimensions === "fixed"
                  ? _c("span", [
                      _vm._v(
                        "Performance Indicator Compass: Fixed (21 dimensions)"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.skillCompassDimensions === "auto"
                  ? _c("span", [
                      _vm._v("Performance Indicator Compass: Coach Tools"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "svg-icon",
                    {
                      staticStyle: { float: "right" },
                      attrs: { "icon-class": "faq" },
                      on: {
                        click: function ($event) {
                          return _vm.showSkillExplainPopup()
                        },
                      },
                    },
                    [_c("title", [_vm._v("List of PI Tags")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.skillCompassDimensions === "auto"
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: {
                            type: "warning",
                            icon: "el-icon-refresh",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.skillReset()
                            },
                          },
                        },
                        [_vm._v("Reset to default PIs")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.skillCompassDimensions === "auto"
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.report.scoreConfig.overall },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "PI Tag", width: "155" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDefaultSkillTitle(
                                              scope.row.key
                                            )
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3699615959
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "Weight(%)", width: "150" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input-number", {
                                        attrs: {
                                          precision: 1,
                                          step: 0.1,
                                          max: 10,
                                          min: 0.1,
                                          size: "mini",
                                          errorColor:
                                            scope.row.weight == undefined,
                                        },
                                        model: {
                                          value: scope.row.weight,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "weight", $$v)
                                          },
                                          expression: "scope.row.weight",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              826468648
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label:
                                "Overall PIs (" +
                                _vm.report.scoreConfig.overall.length +
                                ")",
                              "min-width": 200,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            size: "mini",
                                            errorColor: scope.row.title == "",
                                          },
                                          model: {
                                            value: scope.row.title,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "title", $$v)
                                            },
                                            expression: "scope.row.title",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "danger",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.skillOverallRemove(
                                                  scope.$index,
                                                  scope.row.title
                                                )
                                              },
                                            },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2397806478
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "left" },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.skillOverallAdd()
                            },
                          },
                        },
                        [_vm._v("Add PI to Overall")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.report.scoreConfig.video },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "PI Tag", width: "155" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDefaultSkillTitle(
                                              scope.row.key
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1353284796
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label:
                                "Video PIs (" +
                                _vm.report.scoreConfig.video.length +
                                ")",
                              "min-width": 200,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            size: "mini",
                                            errorColor: scope.row.title == "",
                                          },
                                          model: {
                                            value: scope.row.title,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "title", $$v)
                                            },
                                            expression: "scope.row.title",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "danger",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.skillVideoRemove(
                                                  scope.$index,
                                                  scope.row.title
                                                )
                                              },
                                            },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3055534736
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "left" },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.skillVideoAdd()
                            },
                          },
                        },
                        [_vm._v("Add PI to Video")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.skillCompassDimensions === "auto"
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("hr"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Performance Indicator Compass: PI-Groups (For radar charts and PI tables)"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.skillCompassDimensions === "auto"
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "otr-form__label" }, [
                      _vm._v("Section Title"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          errorColor: _vm.report.skillGroup.title == "",
                        },
                        model: {
                          value: _vm.report.skillGroup.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.report.skillGroup, "title", $$v)
                          },
                          expression: "report.skillGroup.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.skillCompassDimensions === "auto"
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.skillGroupAdd()
                        },
                      },
                    },
                    [_vm._v("Add New PI-Group")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: {
                        type: "warning",
                        icon: "el-icon-refresh",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.skillGroupReset()
                        },
                      },
                    },
                    [_vm._v("Reset PI-Groups")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.skillCompassDimensions === "auto"
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.report.skillGroup.groups },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (skillGroups) {
                                return [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v("PI-Group Title")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      errorColor: skillGroups.row.title == "",
                                    },
                                    model: {
                                      value: skillGroups.row.title,
                                      callback: function ($$v) {
                                        _vm.$set(skillGroups.row, "title", $$v)
                                      },
                                      expression: "skillGroups.row.title",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v("PI-Group Radar Title Color")]
                                  ),
                                  _vm._v(" "),
                                  _c("table", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("color-picker", {
                                            attrs: {
                                              value:
                                                skillGroups.row.radarTextColor,
                                            },
                                            on: {
                                              input: function (c) {
                                                return (skillGroups.row.radarTextColor =
                                                  c.hex)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            attrs: { size: "mini" },
                                            model: {
                                              value:
                                                skillGroups.row.radarTextColor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  skillGroups.row,
                                                  "radarTextColor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "skillGroups.row.radarTextColor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { data: skillGroups.row.skills },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "PI Tag",
                                          width: "155",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (skills) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getDefaultSkillTitle(
                                                          skills.row.key
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Global Average",
                                          width: "150",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (skills) {
                                                return [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      precision: 1,
                                                      step: 0.1,
                                                      max: 10,
                                                      min: 0.1,
                                                      size: "mini",
                                                      errorColor:
                                                        skills.row.avgScore ==
                                                          undefined ||
                                                        skills.row.avgScore ==
                                                          "",
                                                    },
                                                    model: {
                                                      value:
                                                        skills.row.avgScore,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          skills.row,
                                                          "avgScore",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "skills.row.avgScore",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Display Title",
                                          width: "170",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (skills) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "mini",
                                                      errorColor:
                                                        skills.row.title == "",
                                                    },
                                                    model: {
                                                      value: skills.row.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          skills.row,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "skills.row.title",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: { label: "Description" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (skills) {
                                                return [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value:
                                                          skills.row
                                                            .description,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            skills.row,
                                                            "description",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "skills.row.description",
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          slot: "append",
                                                          type: "danger",
                                                          icon: "el-icon-delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.skillGroupRemoveSkill(
                                                              skillGroups.$index,
                                                              skills.$index,
                                                              skills.row.title
                                                            )
                                                          },
                                                        },
                                                        slot: "append",
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { float: "left" },
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-plus",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.skillGroupAddSkill(
                                            skillGroups.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Add PI to PI-Group")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-refresh",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.skillGroupRemove(
                                            skillGroups.$index,
                                            skillGroups.row.title
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Remove This PI-Group")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("hr"),
                                  _vm._v(" "),
                                  _c("br"),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3783295249
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Manager Report")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Report"),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.reportLink) } }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Password"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Password to view the report" },
                    model: {
                      value: _vm.report.managerPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.report, "managerPassword", $$v)
                      },
                      expression: "report.managerPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Report Sections"),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.report.showNegativeGrowth,
                      expression: "report.showNegativeGrowth",
                    },
                  ],
                  attrs: { type: "checkbox", id: "showNegativeGrowth" },
                  domProps: {
                    checked: Array.isArray(_vm.report.showNegativeGrowth)
                      ? _vm._i(_vm.report.showNegativeGrowth, null) > -1
                      : _vm.report.showNegativeGrowth,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.report.showNegativeGrowth,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.report,
                              "showNegativeGrowth",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.report,
                              "showNegativeGrowth",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.report, "showNegativeGrowth", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "showNegativeGrowth" } }, [
                  _vm._v("Show negative growth"),
                ]),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.report.overallSkillSummary,
                      expression: "report.overallSkillSummary",
                    },
                  ],
                  attrs: { type: "checkbox", id: "overallSkillSummary" },
                  domProps: {
                    checked: Array.isArray(_vm.report.overallSkillSummary)
                      ? _vm._i(_vm.report.overallSkillSummary, null) > -1
                      : _vm.report.overallSkillSummary,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.report.overallSkillSummary,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.report,
                              "overallSkillSummary",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.report,
                              "overallSkillSummary",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.report, "overallSkillSummary", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "overallSkillSummary" } }, [
                  _vm._v("Show overall Performance Indicator summary"),
                ]),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.report.individualBreakdown,
                      expression: "report.individualBreakdown",
                    },
                  ],
                  attrs: { type: "checkbox", id: "individualBreakdown" },
                  domProps: {
                    checked: Array.isArray(_vm.report.individualBreakdown)
                      ? _vm._i(_vm.report.individualBreakdown, null) > -1
                      : _vm.report.individualBreakdown,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.report.individualBreakdown,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.report,
                              "individualBreakdown",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.report,
                              "individualBreakdown",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.report, "individualBreakdown", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "individualBreakdown" } }, [
                  _vm._v("Show individual breakdown"),
                ]),
                _vm._v(" "),
                _vm.report.individualBreakdown
                  ? _c("span", [
                      _vm._v("\n            ==>  "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.report.individualReport,
                            expression: "report.individualReport",
                          },
                        ],
                        attrs: { type: "checkbox", id: "individualReport" },
                        domProps: {
                          checked: Array.isArray(_vm.report.individualReport)
                            ? _vm._i(_vm.report.individualReport, null) > -1
                            : _vm.report.individualReport,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.report.individualReport,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.report,
                                    "individualReport",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.report,
                                    "individualReport",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.report, "individualReport", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "individualReport" } }, [
                        _vm._v("Show individual report"),
                      ]),
                      _vm._v("\n           / \n          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.report.showStageAttendance,
                            expression: "report.showStageAttendance",
                          },
                        ],
                        attrs: { type: "checkbox", id: "showStageAttendance" },
                        domProps: {
                          checked: Array.isArray(_vm.report.showStageAttendance)
                            ? _vm._i(_vm.report.showStageAttendance, null) > -1
                            : _vm.report.showStageAttendance,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.report.showStageAttendance,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.report,
                                    "showStageAttendance",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.report,
                                    "showStageAttendance",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.report, "showStageAttendance", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "showStageAttendance" } }, [
                        _vm._v("Show Attendance"),
                      ]),
                      _vm._v("\n           / \n          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.report.showParticipantRate,
                            expression: "report.showParticipantRate",
                          },
                        ],
                        attrs: { type: "checkbox", id: "showParticipantRate" },
                        domProps: {
                          checked: Array.isArray(_vm.report.showParticipantRate)
                            ? _vm._i(_vm.report.showParticipantRate, null) > -1
                            : _vm.report.showParticipantRate,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.report.showParticipantRate,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.report,
                                    "showParticipantRate",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.report,
                                    "showParticipantRate",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.report, "showParticipantRate", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "showParticipantRate" } }, [
                        _vm._v("Show Completion"),
                      ]),
                      _vm._v("\n           / \n          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.report.show720SurveyData,
                            expression: "report.show720SurveyData",
                          },
                        ],
                        attrs: { type: "checkbox", id: "surveyData720" },
                        domProps: {
                          checked: Array.isArray(_vm.report.show720SurveyData)
                            ? _vm._i(_vm.report.show720SurveyData, null) > -1
                            : _vm.report.show720SurveyData,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.report.show720SurveyData,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.report,
                                    "show720SurveyData",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.report,
                                    "show720SurveyData",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.report, "show720SurveyData", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "surveyData720" } }, [
                        _vm._v("Show 720 Team Survey Data"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.report.groupHighlights,
                      expression: "report.groupHighlights",
                    },
                  ],
                  attrs: { type: "checkbox", id: "groupHighlights" },
                  domProps: {
                    checked: Array.isArray(_vm.report.groupHighlights)
                      ? _vm._i(_vm.report.groupHighlights, null) > -1
                      : _vm.report.groupHighlights,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.report.groupHighlights,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.report,
                              "groupHighlights",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.report,
                              "groupHighlights",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.report, "groupHighlights", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "groupHighlights" } }, [
                  _vm._v("Show Group Highlights"),
                ]),
                _vm._v(" "),
                _vm.report.groupHighlights
                  ? _c(
                      "span",
                      [
                        _vm._v(" ==> \n          "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.report.showSkillCompass,
                              expression: "report.showSkillCompass",
                            },
                          ],
                          attrs: { type: "checkbox", id: "showSkillCompass" },
                          domProps: {
                            checked: Array.isArray(_vm.report.showSkillCompass)
                              ? _vm._i(_vm.report.showSkillCompass, null) > -1
                              : _vm.report.showSkillCompass,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.report.showSkillCompass,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.report,
                                      "showSkillCompass",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.report,
                                      "showSkillCompass",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.report, "showSkillCompass", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "showSkillCompass" } }, [
                          _vm._v("Show PI Compass"),
                        ]),
                        _vm._v(" / \n          Most Active Hikers Max: "),
                        _c("el-input-number", {
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 0,
                            size: "mini",
                            errorColor:
                              _vm.report.mostActiveHikersMaxCount ==
                                undefined ||
                              _vm.report.mostActiveHikersMaxCount == "",
                          },
                          model: {
                            value: _vm.report.mostActiveHikersMaxCount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.report,
                                "mostActiveHikersMaxCount",
                                $$v
                              )
                            },
                            expression: "report.mostActiveHikersMaxCount",
                          },
                        }),
                        _vm._v("\n           / Most Improved Hikers Max: "),
                        _c("el-input-number", {
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 0,
                            size: "mini",
                            errorColor:
                              _vm.report.mostImprovedHikersMaxCount ==
                                undefined ||
                              _vm.report.mostImprovedHikersMaxCount == "",
                          },
                          model: {
                            value: _vm.report.mostImprovedHikersMaxCount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.report,
                                "mostImprovedHikersMaxCount",
                                $$v
                              )
                            },
                            expression: "report.mostImprovedHikersMaxCount",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.report.participantFeedback,
                      expression: "report.participantFeedback",
                    },
                  ],
                  attrs: { type: "checkbox", id: "participantFeedback" },
                  domProps: {
                    checked: Array.isArray(_vm.report.participantFeedback)
                      ? _vm._i(_vm.report.participantFeedback, null) > -1
                      : _vm.report.participantFeedback,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.report.participantFeedback,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.report,
                              "participantFeedback",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.report,
                              "participantFeedback",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.report, "participantFeedback", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "participantFeedback" } }, [
                  _vm._v("Show Participant Feedback"),
                ]),
                _c("hr"),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.report.showPiExplanation,
                      expression: "report.showPiExplanation",
                    },
                  ],
                  attrs: { type: "checkbox", id: "piDesc" },
                  domProps: {
                    checked: Array.isArray(_vm.report.showPiExplanation)
                      ? _vm._i(_vm.report.showPiExplanation, null) > -1
                      : _vm.report.showPiExplanation,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.report.showPiExplanation,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.report,
                              "showPiExplanation",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.report,
                              "showPiExplanation",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.report, "showPiExplanation", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "piDesc" } }, [
                  _vm._v("Show Performance Indicator Descriptions"),
                ]),
                _c("hr"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Summary"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("vue-editor", {
                    attrs: { editorToolbar: _vm.customToolbar },
                    model: {
                      value: _vm.report.managerSummary,
                      callback: function ($$v) {
                        _vm.$set(_vm.report, "managerSummary", $$v)
                      },
                      expression: "report.managerSummary",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("User Feedback"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("vue-editor", {
                    attrs: { editorToolbar: _vm.customToolbar },
                    model: {
                      value: _vm.report.userFeedback,
                      callback: function ($$v) {
                        _vm.$set(_vm.report, "userFeedback", $$v)
                      },
                      expression: "report.userFeedback",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Remove Content",
            visible: _vm.removeSkillDialogVisible,
            width: "300px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.removeSkillDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.removeSkillText))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.removeSkillDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.doSkillRemove } },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "List of PI Tags",
            visible: _vm.skillExplainPopupVisible,
            width: "600px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.skillExplainPopupVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", height: "500px", overflow: "auto" },
              attrs: { data: _vm.scLibrary },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Key in backend system" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.key))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Default Display Title", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Add Performance Indicator",
            visible: _vm.addSkillDialogVisible,
            width: "400px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSkillDialogVisible = $event
            },
          },
        },
        [
          _vm.addSkillError
            ? _c("span", { staticStyle: { color: "#F56C6C" } }, [
                _vm._v(_vm._s(_vm.addSkillError)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", height: "500px", overflow: "auto" },
              attrs: { data: _vm.scLibrary },
            },
            [
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-plus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.doSkillAdd(scope.row)
                            },
                          },
                          slot: "append",
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Display Title (Global Average)",
                  "min-width": 300,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.title))]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#3f7efa" } }, [
                          _vm._v("(" + _vm._s(scope.row.avgScore) + ")"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addSkillDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }