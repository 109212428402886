"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalParticipant = getTotalParticipant;
exports.getTotalParticipantHike = getTotalParticipantHike;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTotalParticipant(token, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/trainings/range',
    method: 'post',
    headers: headers,
    data: data
  });
}
function getTotalParticipantHike(token, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/range',
    method: 'post',
    headers: headers,
    data: data
  });
}