var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        ref: "thumbfile",
        staticStyle: { width: "100%" },
        attrs: { accept: _vm.validImageExtension, type: "file" },
        on: { change: _vm.chooseThumbnailFile },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.doThumbnailUpload()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("otrbutton.upload")))]
      ),
      _vm._v(" "),
      _vm.showThumbnailUploadErrMsg
        ? _c("span", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.$t("otrResource.errMsgUploadFail"))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("el-input", {
        attrs: { placeholder: _vm.$t("otrResource.thumbnailUrl") },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _vm.value
        ? _c("img", {
            staticStyle: {
              "max-width": "20%",
              "max-height": "20%",
              "margin-top": "10px",
            },
            attrs: { src: _vm.value },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }