var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
            display: "block",
          },
        },
        [
          _c("span", { staticStyle: { padding: "10px 0" } }, [
            _vm._v(" " + _vm._s(_vm.internalTitle)),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "text" },
              on: { click: _vm.addProgram },
            },
            [_vm._v("Add new Program")]
          ),
          _vm._v(" "),
          _vm.loopPrograms.length > 0
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "text" },
                  on: { click: _vm.copyLastProgram },
                },
                [_vm._v("Copy Last\n\t\t\tProgram (Type: Program)")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.firstTab,
                callback: function ($$v) {
                  _vm.firstTab = $$v
                },
                expression: "firstTab",
              },
            },
            _vm._l(_vm.loopPrograms, function (s, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index,
                  attrs: {
                    label: "Program" + (index + 1),
                    name: "Program" + (index + 1),
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", [_vm._v("ID:")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c("span", [_c("b", [_vm._v(_vm._s(s.id))])]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                padding: "3px 0",
                                color: "red",
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeProgram(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-circle-close" }),
                              _vm._v(" Remove Program\n\t\t\t\t\t\t"),
                            ]
                          ),
                          _vm._v(" "),
                          index !== _vm.loopPrograms.length - 1
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                    "margin-right": "10px",
                                    color: "blue",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveNext(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-caret-right",
                                  }),
                                  _vm._v(" Move Forward\n\t\t\t\t\t\t"),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index !== 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                    "margin-right": "10px",
                                    color: "blue",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.movePrevious(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-caret-left",
                                  }),
                                  _vm._v(" Move Backward\n\t\t\t\t\t\t"),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v("Type"),
                          _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                          _vm._v(":"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "Type" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectType($event, index)
                                },
                              },
                              model: {
                                value: s.type,
                                callback: function ($$v) {
                                  _vm.$set(s, "type", $$v)
                                },
                                expression: "s.type",
                              },
                            },
                            _vm._l(_vm.programType, function (t) {
                              return _c("el-option", {
                                key: t.key,
                                attrs: { label: t.description, value: t.val },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v("Title"),
                          _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                          _vm._v(":"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Title" },
                            model: {
                              value: s.title,
                              callback: function ($$v) {
                                _vm.$set(s, "title", $$v)
                              },
                              expression: "s.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v("Icon"),
                          _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                          _vm._v(":"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { placeholder: "Icon" },
                              model: {
                                value: s.icon,
                                callback: function ($$v) {
                                  _vm.$set(s, "icon", $$v)
                                },
                                expression: "s.icon",
                              },
                            },
                            _vm._l(_vm.programIcon, function (t) {
                              return _c("el-option", {
                                key: t.key,
                                attrs: { label: t.description, value: t.val },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  s.icon !== "none" && s.icon !== ""
                    ? _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("span", { staticClass: "otr-form__label" }, [
                              _vm._v("Icon color"),
                              _c("span", { staticClass: "otr-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(":"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: { placeholder: "Icon color" },
                                  model: {
                                    value: s.iconColor,
                                    callback: function ($$v) {
                                      _vm.$set(s, "iconColor", $$v)
                                    },
                                    expression: "s.iconColor",
                                  },
                                },
                                _vm._l(_vm.programIconColor, function (t) {
                                  return _c("el-option", {
                                    key: t.key,
                                    attrs: {
                                      label: t.description,
                                      value: t.val,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v("Class time:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "Every Wednesday 1pm to 2:30pm",
                            },
                            model: {
                              value: s.classTime,
                              callback: function ($$v) {
                                _vm.$set(s, "classTime", $$v)
                              },
                              expression: "s.classTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v("Description:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 10, maxRows: 20 },
                              placeholder: "Description",
                            },
                            model: {
                              value: s.shortDescription,
                              callback: function ($$v) {
                                _vm.$set(s, "shortDescription", $$v)
                              },
                              expression: "s.shortDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("event-time", {
                    attrs: { start: s.start, end: s.end, "is-required": true },
                    on: {
                      "update:start": function ($event) {
                        return _vm.$set(s, "start", $event)
                      },
                      "update:end": function ($event) {
                        return _vm.$set(s, "end", $event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  s.type !== ""
                    ? _c("fieldset", { staticClass: "otrfieldset" }, [
                        _c("legend", [_vm._v("Call to Action")]),
                        _vm._v(" "),
                        s.type === "externalAction"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "otr-line-spacing__20",
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 3 } }, [
                                      _c(
                                        "span",
                                        { staticClass: "otr-form__label" },
                                        [
                                          _vm._v("Text on Button"),
                                          _c(
                                            "span",
                                            { staticClass: "otr-red" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(":"),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 21 } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "Text on Button",
                                          },
                                          model: {
                                            value: s.action.text,
                                            callback: function ($$v) {
                                              _vm.$set(s.action, "text", $$v)
                                            },
                                            expression: "s.action.text",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "otr-line-spacing__20",
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 3 } }, [
                                      _c(
                                        "span",
                                        { staticClass: "otr-form__label" },
                                        [
                                          _vm._v("URL"),
                                          _c(
                                            "span",
                                            { staticClass: "otr-red" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(":"),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 21 } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "https://www.lifehikes.com/",
                                          },
                                          model: {
                                            value: s.action.url,
                                            callback: function ($$v) {
                                              _vm.$set(s.action, "url", $$v)
                                            },
                                            expression: "s.action.url",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "otr-line-spacing__20",
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 3 } }, [
                                      _c(
                                        "span",
                                        { staticClass: "otr-form__label" },
                                        [_vm._v("Popup Message")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 21 } },
                                      [
                                        _c("vue-editor", {
                                          attrs: {
                                            editorToolbar: _vm.customToolbar,
                                          },
                                          model: {
                                            value: s.action.message,
                                            callback: function ($$v) {
                                              _vm.$set(s.action, "message", $$v)
                                            },
                                            expression: "s.action.message",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        s.type === "info"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "otr-line-spacing__20",
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 3 } }, [
                                      _c(
                                        "span",
                                        { staticClass: "otr-form__label" },
                                        [
                                          _vm._v("Show button"),
                                          _c(
                                            "span",
                                            { staticClass: "otr-red" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(":"),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 3 } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "filter-item",
                                            attrs: { placeholder: "Icon" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.setShowButtom(
                                                  $event,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectShowButton[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectShowButton,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectShowButton[index]",
                                            },
                                          },
                                          _vm._l(_vm.showButton, function (t) {
                                            return _c("el-option", {
                                              key: t.key,
                                              attrs: {
                                                label: t.description,
                                                value: t.val,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.selectShowButton[index] === "Y"
                                  ? _c(
                                      "el-row",
                                      {
                                        staticClass: "otr-line-spacing__20",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c(
                                            "span",
                                            { staticClass: "otr-form__label" },
                                            [
                                              _vm._v("Text on Button"),
                                              _c(
                                                "span",
                                                { staticClass: "otr-red" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(":"),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 21 } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "Text on Button",
                                              },
                                              model: {
                                                value: s.action.text,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    s.action,
                                                    "text",
                                                    $$v
                                                  )
                                                },
                                                expression: "s.action.text",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.selectShowButton[index] === "Y"
                                  ? _c(
                                      "el-row",
                                      {
                                        staticClass: "otr-line-spacing__20",
                                        attrs: { gutter: 20 },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c(
                                            "span",
                                            { staticClass: "otr-form__label" },
                                            [
                                              _vm._v("Popup Message"),
                                              _vm.selectShowButton[index] ===
                                              "Y"
                                                ? _c(
                                                    "span",
                                                    { staticClass: "otr-red" },
                                                    [_vm._v("*")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(":"),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 21 } },
                                          [
                                            _c("vue-editor", {
                                              attrs: {
                                                editorToolbar:
                                                  _vm.customToolbar,
                                              },
                                              model: {
                                                value: s.action.message,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    s.action,
                                                    "message",
                                                    $$v
                                                  )
                                                },
                                                expression: "s.action.message",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            s.type === "enrollment"
                              ? _c(
                                  "el-row",
                                  {
                                    staticClass: "otr-line-spacing__20",
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 3 } }, [
                                      _c(
                                        "span",
                                        { staticClass: "otr-form__label" },
                                        [
                                          _vm._v("Text on Button"),
                                          _c(
                                            "span",
                                            { staticClass: "otr-red" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(":"),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 21 } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "Text on Button",
                                          },
                                          model: {
                                            value: s.action.text,
                                            callback: function ($$v) {
                                              _vm.$set(s.action, "text", $$v)
                                            },
                                            expression: "s.action.text",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "otrfieldset1" },
                              [
                                _c(
                                  "legend",
                                  [
                                    _vm._v("Options\n\t\t\t\t\t\t\t\t"),
                                    false
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              margin: "0 10px",
                                              padding: "0",
                                              color: "#fff",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addOption(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus",
                                            }),
                                            _vm._v("Add"),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._l(s.options, function (a, idxa) {
                                  return _c(
                                    "div",
                                    { key: idxa, staticClass: "action-box" },
                                    [
                                      _c(
                                        "el-tabs",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                          },
                                          attrs: { type: "border-card" },
                                        },
                                        [
                                          _c(
                                            "el-tab-pane",
                                            {
                                              attrs: {
                                                label: "Option" + (idxa + 1),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "otr-line-spacing__10",
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 3 } },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Option " +
                                                            _vm._s(idxa + 1) +
                                                            " ID: "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 21 } },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(a.id) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      false
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                float: "right",
                                                                padding:
                                                                  "3px 0",
                                                                color: "red",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeOption(
                                                                      index,
                                                                      idxa
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-circle-close",
                                                              }),
                                                              _vm._v(
                                                                " Remove Component\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      false
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    idxa > 0,
                                                                  expression:
                                                                    "idxa>0",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                float: "right",
                                                                padding:
                                                                  "3px 10px 0px 0px",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveOptionUp(
                                                                      index,
                                                                      idxa
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-top",
                                                              }),
                                                              _vm._v(
                                                                " Move Up\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      false
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    idxa <
                                                                    s.options
                                                                      .length -
                                                                      1,
                                                                  expression:
                                                                    "idxa<s.options.length-1",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                float: "right",
                                                                padding:
                                                                  "3px 10px 0px 0px",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveOptionDown(
                                                                      index,
                                                                      idxa
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-bottom",
                                                              }),
                                                              _vm._v(
                                                                " Move Down\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "otr-line-spacing__20",
                                                  attrs: { gutter: 20 },
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 3 } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "otr-form__label",
                                                        },
                                                        [
                                                          _vm._v("Hike"),
                                                          s.type ===
                                                          "enrollment"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "otr-red",
                                                                },
                                                                [_vm._v("*")]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(":"),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 21 } },
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "230px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "Hike Id",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value: a.objectId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              a,
                                                              "objectId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "a.objectId",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "450px",
                                                        },
                                                        attrs: {
                                                          placeholder: "Title",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            a.objectData.title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              a.objectData,
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "a.objectData.title",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "success",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openHikeDialog(
                                                                index,
                                                                idxa
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Select")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "danger",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clearHikeResource(
                                                                index,
                                                                idxa
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Clear")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "Hike", visible: _vm.dialogHikeVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogHikeVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c("search-box", {
                    attrs: { placeholder: "Search by keyword" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search(1)
                      },
                    },
                    model: {
                      value: _vm.hikeQuery.q,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeQuery, "q", $$v)
                      },
                      expression: "hikeQuery.q",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.search(1)
                        },
                      },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.body",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                          modifiers: { body: true },
                        },
                      ],
                      attrs: { data: _vm.hikeList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "Add", width: "60" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addHikeInOptions(
                                          scope.row.id,
                                          scope.row.title
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Add")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "ID", width: "170" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.id))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "Category", width: "150" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getHikeCategory(scope.row.category)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "Title", width: "300" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.title))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "Internal Title", width: "300" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.internal.title)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: { width: "150", prop: "start" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          scope.row.start.utc,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { color: "blue" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            scope.row.start.local,
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("div", [_vm._v("UTC Start Time /")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "blue" } }, [
                              _vm._v("Local Start Time"),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "End Time",
                            width: "150",
                            prop: "end",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          scope.row.end.utc,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { color: "blue" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            scope.row.end.local,
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("div", [_vm._v("UTC End Time /")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "blue" } }, [
                              _vm._v("Local End Time"),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pagination-container" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.hikeQuery.page,
                          "page-sizes": [10, 20, 30, 50, 100],
                          "page-size": _vm.hikeQuery.limit,
                          "pager-count": 5,
                          total: _vm.total,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }