var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Hike(s)")]),
          _vm._v(" "),
          _vm.showWarning
            ? _c("div", [
                _c("hr"),
                _vm._v(" "),
                _vm.showPiWarning
                  ? _c("div", [
                      _c("span", { staticStyle: { color: "darkred" } }, [
                        _vm._v(
                          "These programs do not have matching Performance Indicators. It is recommended that you disable the Performance Indicators section."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showStageWarning
                  ? _c("div", [
                      _c("span", { staticStyle: { color: "darkred" } }, [
                        _vm._v(
                          "These programs do not have matching Modules. It is recommended that you disable the Attendance section."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { float: "left" },
              attrs: { type: "primary", icon: "el-icon-edit", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.reportHikeAdd()
                },
              },
            },
            [_vm._v("Add new Hike")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.hikeParticipants.reports },
            },
            [
              _c("el-table-column", {
                attrs: { label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-bottom": "10px",
                              "background-color": "#eee",
                              padding: "10px",
                              display: "block",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-bottom": "10px",
                                  padding: "10px 0",
                                  "font-size": "large",
                                },
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.getHikeTitle(scope.row.hike))
                                  ),
                                ]),
                                _vm._v("(" + _vm._s(scope.row.hike) + ")"),
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticStyle: { padding: "10px 0" } }, [
                              _vm._v(
                                "Internal Title: " +
                                  _vm._s(
                                    _vm.getHikeInternal(scope.row.hike, "title")
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticStyle: { padding: "10px 0" } }, [
                              _vm._v(
                                "Internal ClientName: " +
                                  _vm._s(
                                    _vm.getHikeInternal(
                                      scope.row.hike,
                                      "clientName"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticStyle: { padding: "10px 0" } }, [
                              _vm._v(
                                "Internal OpportunityNumber: " +
                                  _vm._s(
                                    _vm.getHikeInternal(
                                      scope.row.hike,
                                      "opportunityNumber"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "participant-box" },
                          _vm._l(scope.row.participants, function (p) {
                            return _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "icon-box" }, [
                                _vm.getParticipantAvatar(p)
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.getParticipantAvatar(p),
                                        width: "40",
                                        height: "40",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/otr/images/default_avatar.png"),
                                        width: "40",
                                        height: "40",
                                      },
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.getParticipantName(p))),
                              ]),
                            ])
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("br"),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "", width: "150px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (reports) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { float: "left" },
                            attrs: {
                              type: "primary",
                              icon: "el-icon-edit",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reportHikeEdit(
                                  reports.$index,
                                  reports.row.hike
                                )
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { float: "left" },
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                _vm.reportHikeDelete(
                                  reports.$index,
                                  _vm.getHikeTitle(reports.row.hike)
                                )
                              },
                            },
                          },
                          [_vm._v("Remove")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Remove Hike",
            visible: _vm.removeHikeDialogVisible,
            width: "300px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.removeHikeDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.removeHikeText))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.removeHikeDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.doReportHikeDelete },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.addHikeDialogVisible,
            width: "90%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addHikeDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Step 1: Select a Hike")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "otr-line-spacing__30",
              attrs: { gutter: 20, width: "100%" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      placeholder:
                        "Keyword Search: Title/ Internal Title/ Description/ SF Num",
                    },
                    model: {
                      value: _vm.hikeQuery.q,
                      callback: function ($$v) {
                        _vm.$set(_vm.hikeQuery, "q", $$v)
                      },
                      expression: "hikeQuery.q",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.searchHikeList(1)
                        },
                      },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.addHikeLoading,
                  expression: "addHikeLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.hikeList,
                fit: "",
                "highlight-current-row": "",
                height: "300px",
              },
              on: { "current-change": _vm.handleHikeListSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "", fixed: "", width: "55" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row },
                            model: {
                              value: _vm.hikeSelected,
                              callback: function ($$v) {
                                _vm.hikeSelected = $$v
                              },
                              expression: "hikeSelected",
                            },
                          },
                          [_c("i")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", fixed: "", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isHikeListExipred(scope.row.end.utc)
                          ? _c(
                              "el-tag",
                              { attrs: { type: "danger", size: "mini" } },
                              [_vm._v("Expired")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(scope.row.id))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Channel", fixed: "", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.channel))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Title", prop: "title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "otr-word-break" }, [
                          _vm._v(_vm._s(scope.row.title)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Internal Title", prop: "internal.title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "otr-word-break" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("getInternalTitle")(scope.row.internal)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: { width: "150", prop: "start" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  scope.row.start.utc,
                                  "YYYY-MM-DD HH:mm"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "blue" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  scope.row.start.local,
                                  "YYYY-MM-DD HH:mm"
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [_vm._v("UTC Start Time /")]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { color: "blue" } }, [
                      _vm._v("Local Start Time"),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: { label: "End Time", width: "150", prop: "end" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  scope.row.end.utc,
                                  "YYYY-MM-DD HH:mm"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "blue" } }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  scope.row.end.local,
                                  "YYYY-MM-DD HH:mm"
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [_vm._v("UTC End Time /")]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { color: "blue" } }, [
                      _vm._v("Local End Time"),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.hikeQuery.page,
                  "page-sizes": [10, 20],
                  "page-size": _vm.hikeQuery.limit,
                  "pager-count": 5,
                  total: _vm.hikeTotal,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleHikeListSizeChange,
                  "current-change": _vm.handleHikeListCurrentChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("span", [_vm._v("Step 2: Select participant(s)")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.addParticipantLoading,
                  expression: "addParticipantLoading",
                },
              ],
              staticClass: "participant-box",
            },
            _vm._l(_vm.participantList, function (p) {
              return _c("div", { staticClass: "item" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.participantSelected(p)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.participantSelectedUuids[p.uuid]
                          ? "selected-box"
                          : "",
                      },
                      [
                        _c("div", { staticClass: "icon-box" }, [
                          p.profilePic
                            ? _c("img", {
                                attrs: {
                                  src: p.profilePic,
                                  width: "40",
                                  height: "40",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/otr/images/default_avatar.png"),
                                  width: "40",
                                  height: "40",
                                },
                              }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            _vm._s(p.givenName) + " " + _vm._s(p.familyName)
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.doAddHikeParticipant },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.editHikeDialogVisible,
            width: "90%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editHikeDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.editHikeTitle))]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("span", [_vm._v("Step 2: Select participant(s)")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editParticipantLoading,
                  expression: "editParticipantLoading",
                },
              ],
              staticClass: "participant-box",
            },
            _vm._l(_vm.participantList, function (p) {
              return _c("div", { staticClass: "item" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.participantSelected(p)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.participantSelectedUuids[p.uuid]
                          ? "selected-box"
                          : "",
                      },
                      [
                        _c("div", { staticClass: "icon-box" }, [
                          p.profilePic
                            ? _c("img", {
                                attrs: {
                                  src: p.profilePic,
                                  width: "40",
                                  height: "40",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/otr/images/default_avatar.png"),
                                  width: "40",
                                  height: "40",
                                },
                              }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            _vm._s(p.givenName) + " " + _vm._s(p.familyName)
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.doEditHikeParticipant },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }