"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lifehikesInitData = require("@/lifehikes-init-data");
var _vue2Editor = require("vue2-editor");
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getCookie } from '@/utils/cookie'

var tmpHikeAdvReportInfo = {};
// const defaultValue = []
var _default = exports.default = {
  name: 'HikeAdvReportInfo',
  components: {
    VueEditor: _vue2Editor.VueEditor,
    EventTime: _index.default
  },
  props: {
    parentHikeAdvReport: {
      type: Object,
      default: tmpHikeAdvReportInfo
    }
  },
  data: function data() {
    return {
      customToolbar: [['bold'], [{
        list: 'bullet'
      }]],
      loading: false,
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      countryList: [],
      tagInputVisible: false,
      /* use for Tag component */
      tagInputValue: '',
      /* use for tag component */
      hikeAdvReport: {},
      reportLink: ''
    };
  },
  watch: {
    hikeAdvReport: {
      handler: function handler(newVal) {
        this.passBackParentReportInfo(newVal);
        var reportUrl = process.env.VUE_APP_PUBLIC_DOMAIN + '/advanced/report/' + this.hikeAdvReport['id'];
        this.reportLink = '<a target="_blank" href="' + reportUrl + '">' + reportUrl + '</a>';
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    this.hikeAdvReport = this.parentHikeAdvReport;
    this.reportLink = 'Working on it...';
    this.$store.dispatch('facets/getInitData', 'en').then(function () {
      _this.countryList = _this.$store.getters.countries;
    });
  },
  methods: {
    passBackParentReportInfo: function passBackParentReportInfo(info) {
      this.$emit('updateParentReportInfo', info);
    }
  }
};