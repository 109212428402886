"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/codebuild/output/src365382019/src/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./admin"));
var _editor = _interopRequireDefault(require("./editor"));
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default,
    editorDashboard: _editor.default
  },
  data: function data() {
    return {
      me: null,
      currentRole: 'editorDashboard'
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['roles'])),
  created: function created() {
    this.getMe();
    if (this.roles.includes('root') || this.roles.includes('otr-digital-producer')) {
      this.currentRole = 'adminDashboard';
    }
  },
  methods: {
    getMe: function getMe() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _user.getInfo)(token).then(function (response) {
        _this.me = response.data;
      }).catch(function (err) {
        (0, _log.showDebugLog)('page-profile-err', err);
      });
    }
  }
};