var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullscreenloading,
          expression: "fullscreenloading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.resourceForm.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.resourceForm, "id", $$v)
                  },
                  expression: "resourceForm.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "TITLE" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("otrResource.title") },
                model: {
                  value: _vm.resourceForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.resourceForm, "title", $$v)
                  },
                  expression: "resourceForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "TYPE" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.displayType === "edit",
                    placeholder: "Select",
                  },
                  model: {
                    value: _vm.resourceForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.resourceForm, "type", $$v)
                    },
                    expression: "resourceForm.type",
                  },
                },
                [
                  _vm._l(_vm.typeList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item.toLowerCase() },
                    })
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-option", {
                        attrs: { label: "worksheet", value: "worksheet" },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "CATEGORY" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.resourceForm.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.resourceForm, "category", $$v)
                    },
                    expression: "resourceForm.category",
                  },
                },
                _vm._l(_vm.subtypeList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item.toLowerCase() },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isRichText
            ? _c("div", [
                _c(
                  "label",
                  { staticStyle: { color: "#606266", "line-height": "28px" } },
                  [_vm._v("DESCRIPTION")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("tinymce", {
                      ref: "editor",
                      attrs: { id: "tinymce1", height: 150 },
                      model: {
                        value: _vm.resourceForm.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.resourceForm, "description", $$v)
                        },
                        expression: "resourceForm.description",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c(
                "el-form-item",
                { attrs: { label: "DESCRIPTION" } },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 2,
                      paceholder: _vm.$t("otrResource.description"),
                      type: "textarea",
                    },
                    model: {
                      value: _vm.resourceForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.resourceForm, "description", $$v)
                      },
                      expression: "resourceForm.description",
                    },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "SHAREABLE" } },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.resourceForm.shareable,
                  callback: function ($$v) {
                    _vm.$set(_vm.resourceForm, "shareable", $$v)
                  },
                  expression: "resourceForm.shareable",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "PUBLISHED" } },
            [
              _c("el-checkbox", {
                model: {
                  value: _vm.resourceForm.isPublished,
                  callback: function ($$v) {
                    _vm.$set(_vm.resourceForm, "isPublished", $$v)
                  },
                  expression: "resourceForm.isPublished",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "ICON" } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "If there is no thumnail, the icon will be shown in App.",
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "el-icon-info" })]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.resourceForm.icon,
                    callback: function ($$v) {
                      _vm.$set(_vm.resourceForm, "icon", $$v)
                    },
                    expression: "resourceForm.icon",
                  },
                },
                _vm._l(_vm.icons, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: _vm.iconNames[item], value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "THUMBNAIL URL" } },
            [
              _c("input", {
                ref: "thumbfile",
                staticStyle: { width: "100%" },
                attrs: { accept: _vm.validImageExtension, type: "file" },
                on: { change: _vm.chooseThumbnailFile },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.doThumbnailUpload()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("otrbutton.upload")))]
              ),
              _vm._v(" "),
              _vm.showThumbnailUploadErrMsg
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.$t("otrResource.errMsgUploadFail"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: _vm.$t("otrResource.thumbnailUrl") },
                model: {
                  value: _vm.resourceForm.thumbnailUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.resourceForm, "thumbnailUrl", $$v)
                  },
                  expression: "resourceForm.thumbnailUrl",
                },
              }),
              _vm._v(" "),
              _vm.resourceForm.thumbnailUrl
                ? _c("img", {
                    staticStyle: { width: "20%", height: "20%" },
                    attrs: { src: _vm.resourceForm.thumbnailUrl },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _c(
              "label",
              { staticStyle: { color: "#606266", "line-height": "28px" } },
              [_vm._v("TAGS")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _vm._l(_vm.resourceForm.tags, function (tag) {
                  return _c(
                    "el-tag",
                    {
                      key: tag,
                      attrs: { "disable-transitions": false, closable: "" },
                      on: {
                        close: function ($event) {
                          return _vm.handleClose(tag)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                  )
                }),
                _vm._v(" "),
                _vm.tagInputVisible
                  ? _c("el-input", {
                      ref: "saveTagInput",
                      staticClass: "input-new-tag",
                      on: { blur: _vm.handleTagInputConfirm },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleTagInputConfirm($event)
                        },
                      },
                      model: {
                        value: _vm.tagInputValue,
                        callback: function ($$v) {
                          _vm.tagInputValue = $$v
                        },
                        expression: "tagInputValue",
                      },
                    })
                  : _c(
                      "el-button",
                      {
                        staticClass: "button-new-tag",
                        on: { click: _vm.showTagInput },
                      },
                      [_vm._v("+ New Tag")]
                    ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.resourceForm.type !== "text"
            ? _c("el-form-item", { attrs: { label: "FILE" } }, [
                _c("input", {
                  ref: "file",
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled:
                      !_vm.isRoot && _vm.resourceForm.type === "worksheet",
                    type: "file",
                  },
                  on: { change: _vm.chooseFile },
                }),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "Upload Filename : " +
                      _vm._s(_vm.resourceForm.filename) +
                      " ( " +
                      _vm._s(_vm.resourceForm.mimeType) +
                      " )"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _vm.displayType === "create"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.createButtonDisable,
                        type: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.doAddSave($event)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("otrbutton.addSave")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayType === "edit"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.doSave($event)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("otrbutton.save")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-link",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "info", href: "/#/resources" },
                },
                [_vm._v("Back")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }