var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "10px",
            display: "block",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "16px",
                "margin-bottom": "10px",
                padding: "10px 0",
              },
            },
            [_vm._v("ID: " + _vm._s(_vm.hikeId))]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { "font-size": "16px", padding: "10px 0" } },
            [_vm._v("Title: " + _vm._s(_vm.hikeTitle))]
          ),
          _vm._v(" "),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _vm.reportData && _vm.reportData.length > 0
        ? _c(
            "el-button",
            {
              staticStyle: { margin: "0 0 20px 0" },
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-document",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("\n      Export Excel\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "table_container" }, [
        _vm.reportData && _vm.reportData.length > 0
          ? _c(
              "table",
              { staticClass: "mytable", attrs: { id: "dataTable" } },
              [
                _c(
                  "tr",
                  [
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v("Stage")]),
                    _vm._v(" "),
                    _vm._l(_vm.header, function (s, index) {
                      return _c(
                        "td",
                        {
                          key: index,
                          staticStyle: {
                            color: "#666",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(s.stage))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  [
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v("Activity Type")]),
                    _vm._v(" "),
                    _vm._l(_vm.header, function (s, index) {
                      return _c(
                        "td",
                        {
                          key: index,
                          staticStyle: {
                            color: "#666",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(s.activityType))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  [
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v("Activity Title")]),
                    _vm._v(" "),
                    _vm._l(_vm.header, function (s, index) {
                      return _c(
                        "td",
                        {
                          key: index,
                          staticStyle: {
                            color: "#666",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(s.activityTitle))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  [
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v(" ")]),
                    _c("td", [_vm._v("Activity Steps")]),
                    _vm._v(" "),
                    _vm._l(_vm.header, function (s, index) {
                      return _c(
                        "td",
                        {
                          key: index,
                          staticStyle: {
                            color: "#666",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(s.activityStepValue))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  [
                    _c("td", [_vm._v("Given name")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Surname")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Email")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Trail Selection")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("% of selected stage complete")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Total Steps")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.header, function (s, index) {
                      return _c("td", { key: index }, [_vm._v(" ")])
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._l(_vm.reportData, function (i, idx) {
                  return _c(
                    "tr",
                    { key: idx },
                    [
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v(_vm._s(i.givenName)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v(_vm._s(i.familyName)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v(_vm._s(i.email)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v(_vm._s(i.trail)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v(_vm._s(i.completionPercentage)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v(_vm._s(i.userTotalSteps)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { color: "#666" } }, [
                        _vm._v("-"),
                      ]),
                      _vm._v(" "),
                      _vm._l(i.activityStatus, function (ss, index1) {
                        return _c(
                          "td",
                          {
                            key: index1,
                            class: { myTrail: ss.isMyTrailActivity },
                            staticStyle: {
                              color: "#666",
                              "text-align": "center",
                            },
                          },
                          [
                            ss.isDone === "YES"
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/otr/images/icon_tick.png"),
                                    width: "20",
                                  },
                                })
                              : ss.isDone === "NO"
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/otr/images/icon_cross.png"),
                                    width: "20",
                                  },
                                })
                              : _c("span", [_vm._v("N/A")]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n    Total % of attendance in the Hike : " +
            _vm._s(_vm.totalPercentOfAttendance) +
            "%\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._v("\n    Total % of attendance in the Stage:"),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.totalPercentOfAttendancePerStage, function (a, index) {
            return _c("div", { key: index }, [
              _vm._v(
                "\n      Stage " +
                  _vm._s(index + 1) +
                  ": " +
                  _vm._s(a) +
                  "%\n    "
              ),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "Average Completion Rate of Hike: " +
            _vm._s(_vm.avgOfCompletionRate) +
            "%"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-size": "16px",
          "margin-bottom": "10px",
          padding: "10px 0",
        },
      },
      [_c("u", [_vm._v("Activity Report")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }