"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSurvey = createSurvey;
exports.deleteSurveyById = deleteSurveyById;
exports.getHikeParticipantBySurveyId = getHikeParticipantBySurveyId;
exports.getLPSurvey = getLPSurvey;
exports.getMGHSurveyBossPeerDMList = getMGHSurveyBossPeerDMList;
exports.getRespondentFeedback = getRespondentFeedback;
exports.getSurveyById = getSurveyById;
exports.getSurveyIsInUse = getSurveyIsInUse;
exports.getSurveyList = getSurveyList;
exports.saveLPSurvey = saveLPSurvey;
exports.updateSurveyById = updateSurveyById;
var _request = _interopRequireDefault(require("@/utils/request"));
function getSurveyList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/survey',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'get',
    headers: headers,
    params: query
  });
}
function getSurveyById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/survey/' + id + '/builder',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'get',
    headers: headers
  });
}
function getSurveyIsInUse(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/survey/is-in-use/' + id,
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'get',
    headers: headers
  });
}
function createSurvey(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/survey',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateSurveyById(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/survey/' + id,
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'put',
    headers: headers,
    data: data
  });
}
function deleteSurveyById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/survey/' + id,
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'delete',
    headers: headers
  });
}
function getHikeParticipantBySurveyId(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/survey/' + id + '/participants',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'get',
    headers: headers
  });
}
function getMGHSurveyBossPeerDMList(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/survey/' + id + '/mgh-boss-peer-dm',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'get',
    headers: headers
  });
}
function getLPSurvey(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/user-lp-survey/list',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'get',
    headers: headers,
    params: query
  });
}
function saveLPSurvey(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/user-lp-survey/save',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'post',
    headers: headers,
    data: data
  });
}
function getRespondentFeedback(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/user-survey/mgh/respondent-feedback',
    baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
    method: 'post',
    headers: headers,
    data: data
  });
}