var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullscreenloading,
          expression: "fullscreenloading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
        },
        [
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.genInfo"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.isShowGeneral
                  ? _c("training-gen-info", {
                      attrs: {
                        "parent-gen-info": _vm.genInfo,
                        "parent-session": _vm.trainingForm.sessions,
                      },
                      on: {
                        updateParentGenInfo: _vm.updateParentGenInfo,
                        updateParentSession: _vm.updateParentSession,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Training Sessions"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.trainingForm.id
                  ? _c("training-session", {
                      attrs: {
                        "parent-session": _vm.trainingForm.sessions,
                        "internal-title": _vm.trainingForm.internal.title,
                        channel: _vm.trainingForm.channel,
                        "event-start": _vm.trainingForm.start,
                        "event-end": _vm.trainingForm.end,
                        "webinar-name": _vm.trainingForm.webinarName,
                        "webinar-key": _vm.trainingForm.webinarKey,
                        "webinar-passcode": _vm.trainingForm.webinarPasscode,
                      },
                      on: { updateParentSession: _vm.updateParentSession },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v(" " + _vm._s(_vm.$t("otrTraining.staff"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.trainingForm.id
                  ? _c("training-staff", {
                      attrs: {
                        "parent-id": _vm.trainingForm.id,
                        "internal-title": _vm.trainingForm.internal.title,
                      },
                      on: { updateParentStaff: _vm.updateParentStaff },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("otrTraining.resources") } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("otrTraining.resources"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.trainingForm.id
                    ? _c("training-resource", {
                        attrs: {
                          "parent-resource": _vm.trainingForm.resources,
                          "internal-title": _vm.trainingForm.internal.title,
                          "training-id": _vm.trainingForm.id,
                          "from-id": _vm.fromId,
                        },
                        on: { updateParentResource: _vm.updateParentResource },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Activation Code"),
            ]),
            _vm._v(" "),
            true
              ? _c(
                  "div",
                  [
                    _vm.trainingForm.id
                      ? _c("activation-code", {
                          attrs: {
                            "parent-id": _vm.trainingForm.id,
                            "internal-title": _vm.trainingForm.internal.title,
                            title: _vm.trainingForm.title,
                            "code-type": "training",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Participants"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: !_vm.teams,
                    expression: "!teams",
                  },
                ],
              },
              [
                _vm.teams
                  ? _c("training-team", {
                      attrs: {
                        "parent-teams": _vm.teams,
                        "internal-title": _vm.trainingForm.internal.title,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("draft")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsDraft")))]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isCreatedRecord, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("preview")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsPreview")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("published")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsPublish")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("archived")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsArchived")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("draft")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsDraft")))]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isCreatedRecord, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("preview")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsPreview")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.checkMandateFields(),
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("published")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsPublish")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doAddSave("archived")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsArchived")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit" && !_vm.isPublish
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("draft")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsDraft")))]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("preview")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsPreview")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.checkMandateFields(),
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("published")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsPublish")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("archived")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrTraining.saveAsArchived")))]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCancel($event)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("otrbutton.cancel")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/training" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }