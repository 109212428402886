"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HikeAdvReportMain = _interopRequireDefault(require("./components/HikeAdvReportMain"));
//
//
//
//
var _default = exports.default = {
  name: 'HikeAdvReportEdit',
  components: {
    HikeAdvReportMain: _HikeAdvReportMain.default
  }
};