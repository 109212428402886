import { render, staticRenderFns } from "./TrainingGenInfo.vue?vue&type=template&id=febe36ba&"
import script from "./TrainingGenInfo.vue?vue&type=script&lang=js&"
export * from "./TrainingGenInfo.vue?vue&type=script&lang=js&"
import style0 from "./TrainingGenInfo.vue?vue&type=style&index=0&id=febe36ba&rel=stylesheet%2Fscss&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src582379239/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('febe36ba')) {
      api.createRecord('febe36ba', component.options)
    } else {
      api.reload('febe36ba', component.options)
    }
    module.hot.accept("./TrainingGenInfo.vue?vue&type=template&id=febe36ba&", function () {
      api.rerender('febe36ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/lifehikes/training/components/TrainingGenInfo.vue"
export default component.exports