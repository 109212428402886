"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.autoTableHeight = void 0;
var autoTableHeight = exports.autoTableHeight = {
  data: function data() {
    return {
      height: "200px",
      heightVal: 200
    };
  },
  mounted: function mounted() {
    this.getAutoHeight();
    var self = this;
    window.onresize = function () {
      self.getAutoHeight();
    };
  },
  methods: {
    getAutoHeight: function getAutoHeight() {
      var _this = this;
      //window height
      var winclientHeight = window.innerHeight;
      // top bar height
      var topEle = document.querySelector(".main-container>:first-child");
      var topEleHeight = topEle.offsetHeight;
      // table filter height
      var searchlient = document.querySelector(".app-container>:first-child"),
        searchlientHeight = searchlient.offsetHeight;
      //console.info(searchlientHeight);
      //pagination container height
      var pageclient = document.querySelector(".pagination-container"),
        pageclientHeight = pageclient.offsetHeight ? pageclient.offsetHeight : 0;
      //console.info(pageclientHeight);
      setTimeout(function () {
        var heightVal = winclientHeight - (topEleHeight + searchlientHeight + pageclientHeight + 88);
        _this.height = heightVal + "px";
        _this.heightVal = heightVal;
      }, 500);
      setTimeout(function () {
        //let table_fixed = document.querySelector(".el-table__fixed");
        var table_fixed = document.querySelector(".el-table__fixed");
        //no scroll
        var no_scroll = document.querySelector(".is-scrolling-none");
        if (table_fixed !== null && no_scroll === null) {
          var table_fixedHeight = table_fixed.offsetHeight;
          //console.info("table_fixedHeight:" + table_fixedHeight);
          table_fixed.style.height = _this.heightVal - 17 + "px";
          table_fixedHeight = table_fixed.offsetHeight;
          // console.info("table_fielx:" + table_fixedHeight);
          //console.info(this.heightVal)
        }
      }, 1000);
      // this.$nextTick(() => {

      // });
    }
  }
};