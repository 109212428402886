"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isString = isString;
exports.isUTF8 = isUTF8;
exports.tagMapping = tagMapping;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.validLowerCase = validLowerCase;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.to-string");
var _lhJsCommonLib = require("@lifehikes/lh-js-common-lib");
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
/**
 * @param {Array} arg
 * @returns {Array}
 */
function tagMapping(arg) {
  var keys = arg.map(function (item) {
    return item.key;
  });
  var intersection = _lhJsCommonLib.piTags.filter(function (p) {
    return keys.indexOf(p.key) > -1;
  });
  var result = arg.concat(intersection).reduce(function (acc, cur) {
    var index = acc.findIndex(function (item) {
      return item.key === cur.key;
    });
    if (index === -1) {
      acc.push(cur);
    } else {
      acc[index] = Object.assign({}, acc[index], cur);
    }
    return acc;
  }, []);
  console.info('old mapping data:');
  console.info(arg);
  console.info('new mapping data:');
  console.log(result);
  return result;
}
function isUTF8(bytes) {
  var i = 0;
  while (i < bytes.length) {
    if (
    // ASCII
    bytes[i] == 0x09 || bytes[i] == 0x0A || bytes[i] == 0x0D || 0x20 <= bytes[i] && bytes[i] <= 0x7E) {
      i += 1;
      continue;
    }
    if (
    // non-overlong 2-byte
    0xC2 <= bytes[i] && bytes[i] <= 0xDF && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xBF) {
      i += 2;
      continue;
    }
    if (
    // excluding overlongs
    bytes[i] == 0xE0 && 0xA0 <= bytes[i + 1] && bytes[i + 1] <= 0xBF && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF ||
    // straight 3-byte
    (0xE1 <= bytes[i] && bytes[i] <= 0xEC || bytes[i] == 0xEE || bytes[i] == 0xEF) && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xBF && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF ||
    // excluding surrogates
    bytes[i] == 0xED && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x9F && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF) {
      i += 3;
      continue;
    }
    if (
    // planes 1-3
    bytes[i] == 0xF0 && 0x90 <= bytes[i + 1] && bytes[i + 1] <= 0xBF && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF && 0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xBF ||
    // planes 4-15
    0xF1 <= bytes[i] && bytes[i] <= 0xF3 && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xBF && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF && 0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xBF ||
    // plane 16
    bytes[i] == 0xF4 && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x8F && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF && 0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xBF) {
      i += 4;
      continue;
    }
    return false;
  }
  return true;
}