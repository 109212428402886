"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _SocialSignin = _interopRequireDefault(require("./components/SocialSignin"));
var _releaseNote = _interopRequireDefault(require("@/log/release-note"));
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _authPopup = require("./office365/authPopup");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Login",
  components: {
    SocialSign: _SocialSignin.default
  },
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error("The password can not be less than 6 digits"));
      } else {
        callback();
      }
    };
    return {
      copyright: "\xA9".concat(new Date().getFullYear(), " Blue Planet Training, Inc. All rights reserved."),
      loginForm: {
        username: "",
        password: ""
      },
      loginRules: {
        username: [{
          required: true,
          trigger: "change"
        }],
        password: [{
          required: true,
          trigger: "change",
          validator: validatePassword
        }]
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      isProduction: process.env.VUE_APP_ENV === "production",
      isChrome: false
    };
  },
  computed: {
    latestNoteVersion: function latestNoteVersion() {
      return _releaseNote.default[0].version + " (" + process.env.VUE_APP_ENV + ")";
    }
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // window.addEventListener('storage', this.afterQRScan)
    this.$store.dispatch("facets/getInitData", "en");
    this.loginForm.username = (0, _authPopup.selectAccount)();
    var userAgent = navigator.userAgent;
    var isChrome = userAgent.indexOf("Chrome") > -1;
    var isEdge = userAgent.indexOf("Edge") > -1;
    if (isEdge === true) {
      isChrome = false;
    }
    if (isChrome === false) {
      isChrome = false;
    }
    this.isChrome = isChrome;
  },
  mounted: function mounted() {
    if (this.loginForm.username === "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.$store.dispatch("user/login", _this2.loginForm).then(function () {
            (0, _ga.writeGaEvent)(_this2, "Auth", "Login", _this2.loginForm.username, "");
            // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            _this2.$router.push({
              path: "/dashboard"
            });
            _this2.loading = false;
          }).catch(function (err) {
            _this2.loading = false;
            (0, _log.showDebugLog)("Login-API-error", err);
            (0, _log.displayPopupMessage)(err, "error");
            (0, _ga.writeGaEvent)(_this2, "Auth", "Login", _this2.loginForm.username, "");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handelUserNameClick: function handelUserNameClick() {
      if (this.loginForm.username === "" || this.loginForm.username === undefined) {
        (0, _authPopup.signIn)().then(this.handleResponse).catch(function (error) {
          console.error(error);
        });
      } else {
        (0, _authPopup.signOut)();
      }
    },
    handleResponse: function handleResponse(response) {
      if (response !== null) {
        (0, _authPopup.setMsalUsername)(response.account.username);
        this.loginForm.username = response.account.username;
      } else {
        (0, _authPopup.selectAccount)();
      }
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  }
};