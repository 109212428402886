var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "20px" } },
    [
      _c("div", { staticStyle: { "font-size": "20px" } }, [
        _vm._v("Name: " + _vm._s(_vm.$route.query.name)),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "font-size": "20px" } }, [
        _vm._v("Company: " + _vm._s(_vm.$route.query.orgName) + " "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "font-size": "20px", "margin-bottom": "20px" } },
        [_vm._v("Email: " + _vm._s(_vm.$route.query.email) + " ")]
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c("el-tab-pane", { attrs: { label: "Chart" } }, [
            _c(
              "div",
              { staticClass: "chart-container" },
              [
                _vm.isDataReady
                  ? _c("chart", {
                      attrs: {
                        height: "100%",
                        width: "100%",
                        "universal-score": _vm.universalScore,
                        "voice-range-score": _vm.voiceRangeScore,
                        "weak-lang-score": _vm.weakLangScore,
                        "x-axis": _vm.xAxis,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "Raw Data" } }, [
            _c("table", { staticClass: "userList" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { width: "60" } }, [_vm._v("Video ID")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "100" } }, [_vm._v("Time")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "200" } }, [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "200" } }, [_vm._v("Title")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "60" } }, [
                    _vm._v("Universal Score"),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "60" } }, [
                    _vm._v("Voice Range Score"),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "60" } }, [
                    _vm._v("Weak Language Score"),
                  ]),
                  _vm._v(" "),
                  false
                    ? _c("th", { attrs: { width: "500" } }, [
                        _vm._v("Coach Review"),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.xAxis, function (item, idx) {
                  return _c("tr", { key: idx }, [
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.videoId[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.xAxis[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.vType[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.videoTitle[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.universalScore[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.voiceRangeScore[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    idx >= 0
                      ? _c("td", [_vm._v(_vm._s(_vm.weakLangScore[idx]))])
                      : _vm._e(),
                    _vm._v(" "),
                    false
                      ? _c("td", [_vm._v(_vm._s(_vm.coachReview[idx]))])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }