"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _survey = require("@/api/survey");
var _cookie = require("@/utils/cookie");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SurveyDebug',
  props: {
    category: String,
    subcategory: String
  },
  data: function data() {
    return {
      domain: 'https://survey-dev.ownthelab.com/',
      fullscreenloading: false,
      participantList: [],
      surveyId: ''
    };
  },
  created: function created() {
    console.log('Load Debug Page');
    this.surveyId = this.$route.params.id;
    if (this.subcategory === 'self-assessment') {
      this.getPartcipants();
    } else {
      this.getMGHSurveyBossPeerDMs();
    }
  },
  methods: {
    getPartcipants: function getPartcipants() {
      var _this = this;
      var id = this.$route.params.id;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.fullscreenloading = true;
      (0, _survey.getHikeParticipantBySurveyId)(token, id).then(function (response) {
        var d = response.data;
        _this.participantList = d;
        console.log('participant List', _this.participantList);
        _this.fullscreenloading = false;
      }).catch(function (error) {
        showDebugLog('survey-getSurveyById-error', error);
        _this.fullscreenloading = false;
        displayPopupMessage(error, 'error');
      });
    },
    getMGHSurveyBossPeerDMs: function getMGHSurveyBossPeerDMs() {
      var _this2 = this;
      var id = this.$route.params.id;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.fullscreenloading = true;
      (0, _survey.getMGHSurveyBossPeerDMList)(token, id).then(function (response) {
        var d = response.data;
        _this2.participantList = d;
        console.log('participant List', _this2.participantList);
        _this2.fullscreenloading = false;
      }).catch(function (error) {
        showDebugLog('survey-getSurveyById-error', error);
        _this2.fullscreenloading = false;
        displayPopupMessage(error, 'error');
      });
    }
  }
};