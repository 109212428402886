var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.surveyForm
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenloading,
              expression: "fullscreenloading",
            },
          ],
          staticClass: "app-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "surveyForm",
              attrs: {
                model: _vm.surveyForm,
                rules: _vm.surveyFormRules,
                "label-width": "200px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Survey Info" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "ID:", prop: "id" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.surveyForm.id) +
                              "\n          "
                          ),
                          _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isInUse === "Y",
                                  expression: "isInUse === 'Y'",
                                },
                              ],
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "danger", size: "small" },
                            },
                            [_vm._v("IN USE")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Type:", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.surveyForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.surveyForm, "type", $$v)
                                },
                                expression: "surveyForm.type",
                              },
                            },
                            _vm._l(_vm.typeOptions, function (type) {
                              return _c("el-option", {
                                key: type.value,
                                attrs: { label: type.label, value: type.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Category:", prop: "category" } },
                        [
                          _vm.surveyForm.type == "manager720"
                            ? _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.surveyForm.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.surveyForm, "category", $$v)
                                    },
                                    expression: "surveyForm.category",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "benchmark",
                                      value: "benchmark",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "summit", value: "summit" },
                                  }),
                                ],
                                1
                              )
                            : _c("el-input", {
                                model: {
                                  value: _vm.surveyForm.category,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.surveyForm, "category", $$v)
                                  },
                                  expression: "surveyForm.category",
                                },
                              }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Sub-Category:",
                            prop: "subcategory",
                          },
                        },
                        [
                          _vm.surveyForm.type == "manager720"
                            ? _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.surveyForm.subcategory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.surveyForm,
                                        "subcategory",
                                        $$v
                                      )
                                    },
                                    expression: "surveyForm.subcategory",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "self-assessment",
                                      value: "self-assessment",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "boss", value: "boss" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "peer", value: "peer" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: {
                                      label: "direct-report",
                                      value: "direct-report",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("el-input", {
                                model: {
                                  value: _vm.surveyForm.subcategory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.surveyForm, "subcategory", $$v)
                                  },
                                  expression: "surveyForm.subcategory",
                                },
                              }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Title:", prop: "title" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.surveyForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "title", $$v)
                              },
                              expression: "surveyForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Description:", prop: "description" },
                        },
                        [
                          _c("tinymce", {
                            ref: "descriptionEditor",
                            attrs: {
                              id: "descriptionEditor",
                              toolbar: ["bold bullist link code"],
                              height: 300,
                            },
                            model: {
                              value: _vm.surveyForm.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "description", $$v)
                              },
                              expression: "surveyForm.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Access Method:",
                            prop: "accessMethod",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.surveyForm.accessMethod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.surveyForm, "accessMethod", $$v)
                                },
                                expression: "surveyForm.accessMethod",
                              },
                            },
                            _vm._l(
                              _vm.accessMethodOptions,
                              function (accessMethod) {
                                return _c("el-option", {
                                  key: accessMethod.value,
                                  attrs: {
                                    label: accessMethod.label,
                                    value: accessMethod.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.surveyForm.accessMethod === "key"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Access Key:",
                                prop: "accessKey",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.surveyForm.accessKey,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.surveyForm, "accessKey", $$v)
                                  },
                                  expression: "surveyForm.accessKey",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Icon Img:", prop: "iconImg" } },
                        [
                          _c("survey-image-input", {
                            on: {
                              "upload-start": function ($event) {
                                _vm.fullscreenloading = true
                              },
                              "upload-end": function ($event) {
                                _vm.fullscreenloading = false
                              },
                            },
                            model: {
                              value: _vm.surveyForm.iconImg,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "iconImg", $$v)
                              },
                              expression: "surveyForm.iconImg",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Icon Disable Img:",
                            prop: "iconDisableImg",
                          },
                        },
                        [
                          _c("survey-image-input", {
                            on: {
                              "upload-start": function ($event) {
                                _vm.fullscreenloading = true
                              },
                              "upload-end": function ($event) {
                                _vm.fullscreenloading = false
                              },
                            },
                            model: {
                              value: _vm.surveyForm.iconDisableImg,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "iconDisableImg", $$v)
                              },
                              expression: "surveyForm.iconDisableImg",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Background Color:",
                            prop: "backgroundColor",
                          },
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.surveyForm.backgroundColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "backgroundColor", $$v)
                              },
                              expression: "surveyForm.backgroundColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Times Take (in minutes):",
                            prop: "timesTake",
                          },
                        },
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.surveyForm.timesTake,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "timesTake", $$v)
                              },
                              expression: "surveyForm.timesTake",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Publish Status:", prop: "publish" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.surveyForm.publish) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Pages & Questions" } },
                    [
                      _c("survey-page", {
                        attrs: {
                          pages: _vm.surveyForm.pages,
                          isInUse: _vm.isInUse,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Email Template" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Need Send Notify Email:",
                            prop: "needNotifyEmail",
                          },
                        },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.surveyForm.needNotifyEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyForm, "needNotifyEmail", $$v)
                              },
                              expression: "surveyForm.needNotifyEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Subject:", prop: "subject" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm.surveyForm.needNotifyEmail,
                            },
                            model: {
                              value: _vm.surveyForm.emailTemplate.subject,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.surveyForm.emailTemplate,
                                  "subject",
                                  $$v
                                )
                              },
                              expression: "surveyForm.emailTemplate.subject",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Email Template:",
                            prop: "emailTemplate",
                          },
                        },
                        [
                          _c("tinymce", {
                            ref: "emailTemplateEditor",
                            attrs: {
                              id: "emailTemplateEditor",
                              toolbar: [
                                "formatselect bold italic underline strikethrough alignleft aligncenter alignright alignjustify outdent indent blockquote removeformat code codesample hr bullist numlist link image charmap media forecolor backcolor fullscreen",
                              ],
                              height: 300,
                              disabled: !_vm.surveyForm.needNotifyEmail,
                            },
                            model: {
                              value: _vm.surveyForm.emailTemplate.mailBody,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.surveyForm.emailTemplate,
                                  "mailBody",
                                  $$v
                                )
                              },
                              expression: "surveyForm.emailTemplate.mailBody",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Variable Label:" } },
                        [
                          _vm._v(
                            "\n          [[name]] / [[email]] / [[link]] / [[duedate]]\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { color: "#606266" } }, [
                        _c("u", [_vm._v("Remark: ")]),
                        _c("br"),
                        _vm._v("\n         [[name]] : Participant's name "),
                        _c("br"),
                        _vm._v(
                          "\n         [[email]] : Participant's Hike Id / Email "
                        ),
                        _c("br"),
                        _vm._v("\n         [[link]] : Survey URL"),
                        _c("br"),
                        _vm._v("\n         [[duedate]]: Survey Due date "),
                        _c("br"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _vm.displayType === "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doCreate("draft")
                            },
                          },
                        },
                        [_vm._v("Create as Draft")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doCreate("published")
                            },
                          },
                        },
                        [_vm._v("Create as Published")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doCreate("archived")
                            },
                          },
                        },
                        [_vm._v("Create as Archived")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "createfromcopy"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doCreate("draft")
                            },
                          },
                        },
                        [_vm._v("Create as Draft")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "createfromcopy"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doCreate("published")
                            },
                          },
                        },
                        [_vm._v("Create as Published")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "createfromcopy"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doCreate("archived")
                            },
                          },
                        },
                        [_vm._v("Create as Archived")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "edit"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.checkAndSave("draft")
                            },
                          },
                        },
                        [_vm._v("Save as Draft")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "edit"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.checkAndSave("published")
                            },
                          },
                        },
                        [_vm._v("Save as Published")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayType === "edit"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.checkAndSave("archived")
                            },
                          },
                        },
                        [_vm._v("Save as Archived")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-link",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "info", href: "/#/survey" },
                    },
                    [_vm._v("back")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }