var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.internalTitle))]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { round: "", type: "success" },
              on: { click: _vm.addSession },
            },
            [_vm._v("Add Session")]
          ),
          _vm._v(" "),
          _vm.sessions.length > 0
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "success" },
                  on: { click: _vm.copyLastSession },
                },
                [_vm._v("Copy Last Session")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.firstTab,
                callback: function ($$v) {
                  _vm.firstTab = $$v
                },
                expression: "firstTab",
              },
            },
            _vm._l(_vm.sessions, function (s, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index,
                  attrs: {
                    label: "Session " + (index + 1),
                    name: "session" + (index + 1),
                  },
                },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card-w" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Session " +
                                _vm._s(index + 1) +
                                " - " +
                                _vm._s(s.id)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right", padding: "3px 0" },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeSession(index)
                                },
                              },
                            },
                            [_vm._v("Remove Session")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Type : "),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: { placeholder: "Select" },
                                  model: {
                                    value: s.type,
                                    callback: function ($$v) {
                                      _vm.$set(s, "type", $$v)
                                    },
                                    expression: "s.type",
                                  },
                                },
                                _vm._l(_vm.sessionTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Title :"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Title", clearable: "" },
                                model: {
                                  value: s.title,
                                  callback: function ($$v) {
                                    _vm.$set(s, "title", $$v)
                                  },
                                  expression: "s.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", [_vm._v("Short Description :")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "Short Description",
                                  rows: 3,
                                },
                                model: {
                                  value: s.shortDescription,
                                  callback: function ($$v) {
                                    _vm.$set(s, "shortDescription", $$v)
                                  },
                                  expression: "s.shortDescription",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", [_vm._v("Description :")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("tinymce", {
                                ref: "smartTip",
                                refInFor: true,
                                attrs: { id: "smartTip_" + index, height: 150 },
                                model: {
                                  value: s.description,
                                  callback: function ($$v) {
                                    _vm.$set(s, "description", $$v)
                                  },
                                  expression: "s.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Check-in Award:"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, max: 999 },
                                model: {
                                  value: s.defaultCoin,
                                  callback: function ($$v) {
                                    _vm.$set(s, "defaultCoin", $$v)
                                  },
                                  expression: "s.defaultCoin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c("div", [_vm._v("Allow checkin :")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 21 } },
                            [
                              _c("el-checkbox", {
                                model: {
                                  value: s.allowCheckin,
                                  callback: function ($$v) {
                                    _vm.$set(s, "allowCheckin", $$v)
                                  },
                                  expression: "s.allowCheckin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #dcdfe6",
                            padding: "10px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _vm._v("Webinar Info "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        round: "",
                                        size: "mini",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyWebinarFromEvent(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Same as Event")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("Webinar Name :"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 21 } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Webinar Name" },
                                    model: {
                                      value: s.webinarName,
                                      callback: function ($$v) {
                                        _vm.$set(s, "webinarName", $$v)
                                      },
                                      expression: "s.webinarName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("Webinar Key :"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 21 } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Webinar Key" },
                                    model: {
                                      value: s.webinarKey,
                                      callback: function ($$v) {
                                        _vm.$set(s, "webinarKey", $$v)
                                      },
                                      expression: "s.webinarKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("Webinar Passcode :"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 21 } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Webinar Passcode" },
                                    model: {
                                      value: s.webinarPasscode,
                                      callback: function ($$v) {
                                        _vm.$set(s, "webinarPasscode", $$v)
                                      },
                                      expression: "s.webinarPasscode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #dcdfe6",
                            padding: "10px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _vm._v("Training Session Time "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        round: "",
                                        size: "mini",
                                        type: "warning",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyTimeFromEvent(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Same as Event Time")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("event-time", {
                            attrs: {
                              start: s.start,
                              end: s.end,
                              "is-required": false,
                              "start-placeholder": "utc session start time",
                              endPlaceholder: "utc session end time",
                            },
                            on: {
                              "update:start": function ($event) {
                                return _vm.$set(s, "start", $event)
                              },
                              "update:end": function ($event) {
                                return _vm.$set(s, "end", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #dcdfe6",
                            padding: "10px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "otr-line-spacing__20",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _vm._v("Recording Time Period "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        round: "",
                                        size: "mini",
                                        type: "success",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setRecordingTime(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Same as Session time")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("event-time", {
                            attrs: {
                              start: s.recordingStart,
                              end: s.recordingEnd,
                              "is-required": false,
                              "start-placeholder": "utc recording start time",
                              endPlaceholder: "utc recording end time",
                            },
                            on: {
                              "update:start": function ($event) {
                                return _vm.$set(s, "recordingStart", $event)
                              },
                              "update:end": function ($event) {
                                return _vm.$set(s, "recordingEnd", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", round: "" },
              on: { click: _vm.addSession },
            },
            [_vm._v("Add Session")]
          ),
          _vm._v(" "),
          _vm.sessions.length > 0
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "success" },
                  on: { click: _vm.copyLastSession },
                },
                [_vm._v("Copy Last Session")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }