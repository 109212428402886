"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mockjs = _interopRequireDefault(require("mockjs"));
var _default = exports.default = [{
  url: '/loops1/[A-Za-z0-9]/users/[A-Za-z0-9]/unenroll',
  type: 'post',
  // 必须和你接口定义的类型一样
  response: function response(req, res) {
    return {
      status: "ok"
    };
  }
}, {
  url: '/loops1/[A-Za-z0-9]+/participants',
  type: 'get',
  response: function response(req, res) {
    return {
      "count": 11,
      "rows": [{
        "uuid": "ab8330b0-c8cb-11eb-b756-5789e2d050a5",
        "givenName": "chen",
        "middleName": "",
        "familyName": "yang",
        "profilePic": "",
        "jobTitle": "",
        "isLoop": "N",
        "orgName": "",
        "email": "ben_chen@owntheroom.com",
        "baselineId": "",
        "badges": []
      }, {
        "uuid": "c7a97a70-2173-11ea-b5f7-d753a96bb53b",
        "givenName": "Leung",
        "middleName": "Yin",
        "familyName": "Yin dev",
        "profilePic": "https://lifehikes-public-assets-dev.s3.us-west-2.amazonaws.com/avatar/b682850fdea346687785d86a9e11e88dbae2d0b3/pic_0362d236b45931505f773db7173f8999f660a2ef",
        "jobTitle": "title b",
        "isLoop": "Y",
        "orgName": "OTR",
        "email": "sky.leung@lifehikes.com",
        "baselineId": "5e6aba814b393600255ed752",
        "badges": ["5e5618d805d496441476e089", "5e5618d805d496441476e08d", "5e5618d805d496441476e08c", "5e5618d805d496441476e085", "5e5618d805d496441476e084"]
      }, {
        "uuid": "6d8ee400-ec19-11ea-8971-45e11e6cd952",
        "givenName": "Rachel",
        "middleName": "",
        "familyName": "bai",
        "profilePic": "https://lifehikes-public-assets-dev.s3.us-west-2.amazonaws.com/avatar/38ae41021f160fe023d4742d84bd7e15524b642c/pic_2956b473c33ef692896bd20b40568a1b3f476c0b",
        "jobTitle": "error message ",
        "isLoop": "Y",
        "orgName": "hhhhhyyyghh",
        "email": "rachel_bai@owntheroom.com",
        "baselineId": "5fb33bee3d917f0026a8e8f3",
        "badges": ["5e5618d805d496441476e08e", "5e5618d805d496441476e08f", "5e5618d805d496441476e090", "5e5618d805d496441476e08c", "5e5618d805d496441476e085", "5e5618d805d496441476e086", "5e5618d805d496441476e087", "5e5618d805d496441476e08d"]
      }, {
        "uuid": "d2dfaeb0-4a5d-11ea-acd0-c37d8037ec2a",
        "givenName": "Rater 1",
        "middleName": "12",
        "familyName": "Fan",
        "profilePic": "",
        "jobTitle": "Potato",
        "isLoop": "N",
        "orgName": "OTR",
        "email": "sky+12@owntheroom.com",
        "baselineId": "6098df600d3cc6536c8c3f6c",
        "badges": []
      }, {
        "uuid": "0e1ea600-2408-11eb-911d-53e0335070fc",
        "givenName": "Sky",
        "middleName": "",
        "familyName": "101",
        "profilePic": "",
        "jobTitle": "",
        "isLoop": "N",
        "orgName": "",
        "email": "sky+101@owntheroom.com",
        "baselineId": "6073aadc3bd6db4763554c3c",
        "badges": []
      }, {
        "uuid": "a39ed080-1af7-11ea-9d85-59c93fd31c38",
        "givenName": "Sky",
        "middleName": "AAAAAB",
        "familyName": "Leung 06",
        "profilePic": "https://lifehikes-public-assets-dev.s3.us-west-2.amazonaws.com/avatar/e2fb46cf26ee74bf35e917e74b9fccc218990e79/pic_10ae8d421863183d0c74f27a45ba45acf7fc8610",
        "jobTitle": "Small Potato",
        "isLoop": "N",
        "orgName": "OTR",
        "email": "sky+06@owntheroom.com",
        "baselineId": "6073a1de3bd6db4763554940",
        "badges": ["5e5618d805d496441476e084"]
      }, {
        "uuid": "b4d4f6d0-4992-11ea-bbc7-63d11179b06e",
        "givenName": "Sky Tester 10",
        "middleName": "",
        "familyName": "Leung",
        "profilePic": "",
        "jobTitle": "",
        "isLoop": "N",
        "orgName": "",
        "email": "sky+10@owntheroom.com",
        "baselineId": "6073ae1365be92498bc307b6",
        "badges": []
      }, {
        "uuid": "243a0ea0-4a5d-11ea-857b-5592813b42cc",
        "givenName": "Sky Tester 11",
        "middleName": "",
        "familyName": "Leung",
        "profilePic": "",
        "jobTitle": "",
        "isLoop": "N",
        "orgName": "",
        "email": "sky+11@owntheroom.com",
        "baselineId": "6073b3b0c992df725e2179fd",
        "badges": []
      }, {
        "uuid": "9289fbd0-4d6a-11ea-830b-a3caf1232d18",
        "givenName": "Sky Tester 13",
        "middleName": "",
        "familyName": "Leung",
        "profilePic": "",
        "jobTitle": "",
        "isLoop": "N",
        "orgName": "",
        "email": "sky+13@owntheroom.com",
        "baselineId": "6098de6c68add552bccb94f2",
        "badges": []
      }, {
        "uuid": "88777b30-56c1-11ea-9b3c-6f678a1879e4",
        "givenName": "Sky Tester 21",
        "middleName": "",
        "familyName": "Yin",
        "profilePic": "",
        "jobTitle": "",
        "isLoop": "N",
        "orgName": "",
        "email": "sky+21@owntheroom.com",
        "baselineId": "",
        "badges": []
      }, {
        "uuid": "0004aab0-2a9d-11eb-ab13-2b01ad39aa0d",
        "givenName": "zekai",
        "middleName": "",
        "familyName": "liu",
        "profilePic": "https://lifehikes-public-assets-dev.s3.us-west-2.amazonaws.com/avatar/94eeb56cdfed6957ed4f98389147f41310620d1d/pic_f8596fc5e71aee030e70db5d0c6bb46387706d38",
        "jobTitle": "Test",
        "isLoop": "Y",
        "orgName": "",
        "email": "zekai@owntheroom.com",
        "baselineId": "5fb6c6f5aa14760024a0cf62",
        "badges": ["5e5618d805d496441476e08e"]
      }]
    };
  }
}, {
  // url 必须能匹配你的接口路由
  // 比如 fetchComments 对应的路由可能是 /article/1/comments 或者 /article/2/comments
  // 所以你需要通过正则来进行匹配
  url: '/loops/[A-Za-z0-9]+$',
  type: 'get',
  // 必须和你接口定义的类型一样
  response: function response(req, res) {
    // 返回的结果
    // req and res detail see
    // https://expressjs.com/zh-cn/api.html#req
    return {
      "id": "61ea23eb0f83123c28830a8b",
      "title": "The 1st LOOP",
      "publish": "draft",
      "type": "alacarte",
      "color": "#FFEBC0",
      "start": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-09-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-09-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8c"
      },
      "end": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-10-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-10-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8d"
      },
      "locale": "en",
      "description": "This is the 1st LOOP",
      "programs": [{
        "id": "61ea38dc3ad02f47e467247d",
        "type": "externalAction",
        "title": "Holiday Break",
        "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
        "start": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-09-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-09-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8c"
        },
        "end": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-10-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-10-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8d"
        },
        "action": {
          "text": "Details and Registration",
          "url": "https://lifeHikes.com"
        },
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z"
      }, {
        "id": "61ea38dc3ad02f47e467247d",
        "type": "info",
        "title": "Holiday Break",
        "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
        "start": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-09-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-09-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8c"
        },
        "end": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-10-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-10-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8d"
        },
        "action": {
          "text": "",
          "url": ""
        },
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z"
      }, {
        "type": "hike",
        "title": "Your Hike",
        "shortDescription": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
        "options": [{
          "title": "Hike",
          "description": "desc",
          "shortDescription": "short desc",
          "iconUrl": "https://dvjj6k77zv297.cloudfront.net/images/loop/loop_icon_08.png",
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z",
          "id": "61ea38dc3ad02f47e467247e"
        }],
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z",
        "id": "61ea38dc3ad02f47e467247d"
      }],
      "staff": [{
        "role": "manager",
        "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
        "createdAt": "2022-05-13T08:15:44.260Z",
        "updatedAt": "2022-05-13T08:15:44.260Z",
        "id": "627e13b0458ee750e45d9df5"
      }, {
        "role": "contact",
        "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
        "createdAt": "2022-05-13T08:15:44.260Z",
        "updatedAt": "2022-05-13T08:15:44.260Z",
        "id": "627e13b0458ee750e45d9df6"
      }],
      "welcomeBox": {
        "coachUuid": "c7a97a70-2173-11ea-b5f7-d753a96bb53b",
        "coachHtmlTips": "Welcome to your LifeHikes Loop!<br />We are excited to take you on this journey of professional and personal growth.<br />Let’s start with our orientation video.",
        "resource": "5e7f4a08c3cb5d0024945b22",
        "coachUgivenName": "Tim Fortescue: eewewe",
        "resourceTitle": "zekai liu"
      },
      "internal": {
        "title": "LHL2122PRM",
        "opportunityNumber": "19199",
        "clientName": "LifeHikes",
        "remark": ""
      },
      "modifiedBy": "067cc460-9814-11eb-ac8e-4d822f47abd8",
      "createdAt": "2022-01-21T03:09:31.481Z",
      "updatedAt": "2022-02-11T08:39:35.690Z"
    };
  }
}, {
  url: '/loops/[A-Za-z0-9]',
  type: 'put',
  // 必须和你接口定义的类型一样
  body: {
    "publish": "markdelete"
  },
  response: function response(req, res) {
    return {
      status: "ok"
    };
  }
}, {
  url: '/loops/[A-Za-z0-9]',
  type: 'put',
  // 必须和你接口定义的类型一样
  body: {
    "id": "61ea23eb0f83123c28830a8b",
    "title": "The 1st LOOP",
    "publish": "draft",
    "type": "alacarte",
    "color": "#FFEBC0",
    "start": {
      "timezone": "Asia/Hong_Kong",
      "utc": "2020-09-01T01:00:00.000Z",
      "createdAt": "2022-01-21T03:09:31.480Z",
      "updatedAt": "2022-01-21T03:09:31.480Z",
      "local": "2020-09-01T09:00:00",
      "id": "61ea23eb0f83123c28830a8c"
    },
    "end": {
      "timezone": "Asia/Hong_Kong",
      "utc": "2020-10-01T01:00:00.000Z",
      "createdAt": "2022-01-21T03:09:31.480Z",
      "updatedAt": "2022-01-21T03:09:31.480Z",
      "local": "2020-10-01T09:00:00",
      "id": "61ea23eb0f83123c28830a8d"
    },
    "locale": "en",
    "description": "This is the 1st LOOP",
    "programs": [{
      "id": "61ea38dc3ad02f47e467247d",
      "type": "externalAction",
      "title": "Holiday Break",
      "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
      "start": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-09-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-09-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8c"
      },
      "end": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-10-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-10-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8d"
      },
      "action": {
        "text": "Details and Registration",
        "url": "https://lifeHikes.com"
      },
      "createdAt": "2022-02-11T08:39:35.687Z",
      "updatedAt": "2022-02-11T08:39:35.687Z"
    }, {
      "id": "61ea38dc3ad02f47e467247d",
      "type": "info",
      "title": "Holiday Break",
      "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
      "start": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-09-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-09-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8c"
      },
      "end": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-10-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-10-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8d"
      },
      "action": {
        "text": "",
        "url": ""
      },
      "createdAt": "2022-02-11T08:39:35.687Z",
      "updatedAt": "2022-02-11T08:39:35.687Z"
    }, {
      "type": "hike",
      "title": "Your Hike",
      "shortDescription": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
      "options": [{
        "title": "Hike",
        "description": "desc",
        "shortDescription": "short desc",
        "iconUrl": "https://dvjj6k77zv297.cloudfront.net/images/loop/loop_icon_08.png",
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z",
        "id": "61ea38dc3ad02f47e467247e"
      }],
      "createdAt": "2022-02-11T08:39:35.687Z",
      "updatedAt": "2022-02-11T08:39:35.687Z",
      "id": "61ea38dc3ad02f47e467247d"
    }],
    "staff": [{
      "role": "manager",
      "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
      "createdAt": "2022-05-13T08:15:44.260Z",
      "updatedAt": "2022-05-13T08:15:44.260Z",
      "id": "627e13b0458ee750e45d9df5"
    }, {
      "role": "contact",
      "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
      "createdAt": "2022-05-13T08:15:44.260Z",
      "updatedAt": "2022-05-13T08:15:44.260Z",
      "id": "627e13b0458ee750e45d9df6"
    }],
    "welcomeBox": {
      "coachUuid": "c7a97a70-2173-11ea-b5f7-d753a96bb53b",
      "coachHtmlTips": "Welcome to your LifeHikes Loop!<br />We are excited to take you on this journey of professional and personal growth.<br />Let’s start with our orientation video.",
      "resource": "5e7f4a08c3cb5d0024945b22",
      "coachUgivenName": "Tim Fortescue: eewewe",
      "resourceTitle": "zekai liu"
    },
    "internal": {
      "title": "LHL2122PRM",
      "opportunityNumber": "19199",
      "clientName": "LifeHikes",
      "remark": ""
    },
    "modifiedBy": "067cc460-9814-11eb-ac8e-4d822f47abd8",
    "createdAt": "2022-01-21T03:09:31.481Z",
    "updatedAt": "2022-02-11T08:39:35.690Z"
  },
  response: function response(req, res) {
    // 返回的结果
    // req and res detail see
    // https://expressjs.com/zh-cn/api.html#req
    return {
      "id": "61ea23eb0f83123c28830a8b",
      "title": "The 1st LOOP",
      "publish": "draft",
      "type": "alacarte",
      "color": "#FFEBC0",
      "start": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-09-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-09-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8c"
      },
      "end": {
        "timezone": "Asia/Hong_Kong",
        "utc": "2020-10-01T01:00:00.000Z",
        "createdAt": "2022-01-21T03:09:31.480Z",
        "updatedAt": "2022-01-21T03:09:31.480Z",
        "local": "2020-10-01T09:00:00",
        "id": "61ea23eb0f83123c28830a8d"
      },
      "locale": "en",
      "description": "This is the 1st LOOP",
      "programs": [{
        "id": "61ea38dc3ad02f47e467247d",
        "type": "externalAction",
        "title": "Holiday Break",
        "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
        "start": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-09-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-09-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8c"
        },
        "end": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-10-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-10-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8d"
        },
        "action": {
          "text": "Details and Registration",
          "url": "https://lifeHikes.com"
        },
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z"
      }, {
        "id": "61ea38dc3ad02f47e467247d",
        "type": "info",
        "title": "Holiday Break",
        "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
        "start": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-09-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-09-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8c"
        },
        "end": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-10-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-10-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8d"
        },
        "action": {
          "text": "",
          "url": ""
        },
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z"
      }, {
        "type": "hike",
        "title": "Your Hike",
        "shortDescription": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
        "options": [{
          "title": "Hike",
          "description": "desc",
          "shortDescription": "short desc",
          "iconUrl": "https://dvjj6k77zv297.cloudfront.net/images/loop/loop_icon_08.png",
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z",
          "id": "61ea38dc3ad02f47e467247e"
        }],
        "createdAt": "2022-02-11T08:39:35.687Z",
        "updatedAt": "2022-02-11T08:39:35.687Z",
        "id": "61ea38dc3ad02f47e467247d"
      }],
      "staff": [{
        "role": "manager",
        "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
        "createdAt": "2022-05-13T08:15:44.260Z",
        "updatedAt": "2022-05-13T08:15:44.260Z",
        "id": "627e13b0458ee750e45d9df5"
      }, {
        "role": "contact",
        "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
        "createdAt": "2022-05-13T08:15:44.260Z",
        "updatedAt": "2022-05-13T08:15:44.260Z",
        "id": "627e13b0458ee750e45d9df6"
      }],
      "welcomeBox": {
        "coachUuid": "c7a97a70-2173-11ea-b5f7-d753a96bb53b",
        "coachHtmlTips": "Welcome to your LifeHikes Loop!<br />We are excited to take you on this journey of professional and personal growth.<br />Let’s start with our orientation video.",
        "resource": "5e7f4a08c3cb5d0024945b22",
        "coachUgivenName": "Tim Fortescue: eewewe",
        "resourceTitle": "zekai liu"
      },
      "internal": {
        "title": "LHL2122PRM",
        "opportunityNumber": "19199",
        "clientName": "LifeHikes",
        "remark": ""
      },
      "modifiedBy": "067cc460-9814-11eb-ac8e-4d822f47abd8",
      "createdAt": "2022-01-21T03:09:31.481Z",
      "updatedAt": "2022-02-11T08:39:35.690Z"
    };
  }
}, {
  url: '/loops',
  type: 'get',
  response: function response(config) {
    return {
      count: 2,
      rows: [{
        "id": "61ea3c1e36a63a48d10f4f3f",
        "title": "The 1st LOOP",
        "publish": "draft",
        "type": "alacarte",
        "color": "#FFEBC0",
        "start": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-09-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-09-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8c"
        },
        "end": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-10-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-10-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8d"
        },
        "locale": "en",
        "description": "This is the 1st LOOP",
        "programs": [{
          "id": "61ea38dc3ad02f47e467247d",
          "type": "externalAction",
          "title": "Holiday Break",
          "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
          "start": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-09-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-09-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8c"
          },
          "end": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-10-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-10-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8d"
          },
          "action": {
            "text": "Details and Registration",
            "url": "https://lifeHikes.com"
          },
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z"
        }, {
          "id": "61ea38dc3ad02f47e467247d",
          "type": "info",
          "title": "Holiday Break",
          "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
          "start": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-09-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-09-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8c"
          },
          "end": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-10-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-10-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8d"
          },
          "action": {
            "text": "",
            "url": ""
          },
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z"
        }, {
          "type": "hike",
          "title": "Your Hike",
          "shortDescription": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
          "options": [{
            "title": "Hike",
            "description": "desc",
            "shortDescription": "short desc",
            "iconUrl": "https://dvjj6k77zv297.cloudfront.net/images/loop/loop_icon_08.png",
            "createdAt": "2022-02-11T08:39:35.687Z",
            "updatedAt": "2022-02-11T08:39:35.687Z",
            "id": "61ea38dc3ad02f47e467247e"
          }],
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z",
          "id": "61ea38dc3ad02f47e467247d"
        }],
        "staff": [{
          "role": "manager",
          "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
          "createdAt": "2022-05-13T08:15:44.260Z",
          "updatedAt": "2022-05-13T08:15:44.260Z",
          "id": "627e13b0458ee750e45d9df5"
        }, {
          "role": "contact",
          "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
          "createdAt": "2022-05-13T08:15:44.260Z",
          "updatedAt": "2022-05-13T08:15:44.260Z",
          "id": "627e13b0458ee750e45d9df6"
        }],
        "welcomeBox": {
          "coachUuid": "c7a97a70-2173-11ea-b5f7-d753a96bb53b",
          "coachHtmlTips": "Welcome to your LifeHikes Loop!<br />We are excited to take you on this journey of professional and personal growth.<br />Let’s start with our orientation video.",
          "resource": "5e7f4a08c3cb5d0024945b22",
          "coachUgivenName": "Tim Fortescue: eewewe",
          "resourceTitle": "zekai liu"
        },
        "internal": {
          "title": "LHL2122PRM",
          "opportunityNumber": "19199",
          "clientName": "LifeHikes",
          "remark": ""
        },
        "modifiedBy": "067cc460-9814-11eb-ac8e-4d822f47abd8",
        "createdAt": "2022-01-21T03:09:31.481Z",
        "updatedAt": "2022-02-11T08:39:35.690Z"
      }, {
        "id": "61ea23eb0f83123c28830a8b",
        "title": "The 1st LOOP",
        "publish": "draft",
        "type": "alacarte",
        "color": "#FFEBC0",
        "start": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-09-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-09-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8c"
        },
        "end": {
          "timezone": "Asia/Hong_Kong",
          "utc": "2020-10-01T01:00:00.000Z",
          "createdAt": "2022-01-21T03:09:31.480Z",
          "updatedAt": "2022-01-21T03:09:31.480Z",
          "local": "2020-10-01T09:00:00",
          "id": "61ea23eb0f83123c28830a8d"
        },
        "locale": "en",
        "description": "This is the 1st LOOP",
        "programs": [{
          "id": "61ea38dc3ad02f47e467247d",
          "type": "externalAction",
          "title": "Holiday Break",
          "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
          "start": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-09-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-09-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8c"
          },
          "end": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-10-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-10-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8d"
          },
          "action": {
            "text": "Details and Registration",
            "url": "https://lifeHikes.com"
          },
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z"
        }, {
          "id": "61ea38dc3ad02f47e467247d",
          "type": "info",
          "title": "Holiday Break",
          "description": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
          "start": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-09-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-09-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8c"
          },
          "end": {
            "timezone": "Asia/Hong_Kong",
            "utc": "2020-10-01T01:00:00.000Z",
            "createdAt": "2022-01-21T03:09:31.480Z",
            "updatedAt": "2022-01-21T03:09:31.480Z",
            "local": "2020-10-01T09:00:00",
            "id": "61ea23eb0f83123c28830a8d"
          },
          "action": {
            "text": "",
            "url": ""
          },
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z"
        }, {
          "type": "hike",
          "title": "Your Hike",
          "shortDescription": "March 2 - May 4, 2022\nWednesdays from 12:00 PM - 1:30 PM",
          "options": [{
            "title": "Hike",
            "description": "desc",
            "shortDescription": "short desc",
            "iconUrl": "https://dvjj6k77zv297.cloudfront.net/images/loop/loop_icon_08.png",
            "createdAt": "2022-02-11T08:39:35.687Z",
            "updatedAt": "2022-02-11T08:39:35.687Z",
            "id": "61ea38dc3ad02f47e467247e"
          }],
          "createdAt": "2022-02-11T08:39:35.687Z",
          "updatedAt": "2022-02-11T08:39:35.687Z",
          "id": "61ea38dc3ad02f47e467247d"
        }],
        "staff": [{
          "role": "manager",
          "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
          "createdAt": "2022-05-13T08:15:44.260Z",
          "updatedAt": "2022-05-13T08:15:44.260Z",
          "id": "627e13b0458ee750e45d9df5"
        }, {
          "role": "contact",
          "user": "3bfdbbd0-0c55-11eb-bb10-dd6c32d015c6",
          "createdAt": "2022-05-13T08:15:44.260Z",
          "updatedAt": "2022-05-13T08:15:44.260Z",
          "id": "627e13b0458ee750e45d9df6"
        }],
        "welcomeBox": {
          "coachUuid": "c7a97a70-2173-11ea-b5f7-d753a96bb53b",
          "coachHtmlTips": "Welcome to your LifeHikes Loop!<br />We are excited to take you on this journey of professional and personal growth.<br />Let’s start with our orientation video.",
          "resource": "5e7f4a08c3cb5d0024945b22",
          "coachUgivenName": "Tim Fortescue: eewewe",
          "resourceTitle": "zekai liu"
        },
        "internal": {
          "title": "LHL2122PRM",
          "opportunityNumber": "19199",
          "clientName": "LifeHikes",
          "remark": ""
        },
        "modifiedBy": "067cc460-9814-11eb-ac8e-4d822f47abd8",
        "createdAt": "2022-01-21T03:09:31.481Z",
        "updatedAt": "2022-02-11T08:39:35.690Z"
      }]
    };
  }
}];