"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _rater = require("@/api/rater");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'rulelist',
  data: function data() {
    return {
      dialogCreateFormVisible: false,
      dialogUpdateFormVisible: false,
      loading: false,
      query: {
        page: 1,
        limit: 200,
        sort: 'sort',
        type: 'rater-assignment'
      },
      newDefaultRule: {
        "type": "rater-assignment",
        "rule": {
          "applyTo": "all",
          "value": 300
        },
        "description": "Default Rule Maximium number of video for rating per rater per week",
        "sort": 1
      },
      updatedSetting: null,
      raterRules: null,
      defaultRule: null,
      raterList: null
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _rater.getRuleList)(token, this.query).then(function (response) {
        var data = response.data;
        if (data) {
          _this.defaultRule = _.filter(data.rows, function (o) {
            return o.rule.applyTo === 'all';
          });
          // console.log('data', defaultrule)
          //this.defaultRule 
        } else {
          console.log("Missing Default Rule");
        }
        _this.loading = false;
      }).catch(function (error) {
        _this.loading = false;
        //showDebugLog('page-question-save-err', error)
        //displayPopupMessage(error, 'error')
      });
    },
    create: function create(rule) {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      (0, _rater.createRule)(token, rule).then(function (response) {
        (0, _log.displayPopupMessage)('Default rule setting created successfully', 'success');
        (0, _ga.writeGaEvent)(_this2, 'Rule', "".concat(_lifehikesInitData.userEvent.raterAssignment.addIndividualRule), email, "ID: ".concat(response.data.id));
        _this2.getList();
        _this2.dialogCreateFormVisible = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Update Fail:', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    confirmEdit: function confirmEdit(rule) {
      this.dialogUpdateFormVisible = true;
      this.updatedSetting = rule;
    }
  }
};