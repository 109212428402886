var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-multiple-option-input" },
    [
      _vm._l(_vm.value, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "survey-multiple-option-input__row" },
          [
            _c(
              "div",
              { staticClass: "survey-multiple-option-input__input" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Value", autocomplete: "off" },
                  on: {
                    change: function ($event) {
                      return _vm.handleValueChange(item)
                    },
                  },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "survey-multiple-option-input__input" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Label", autocomplete: "off" },
                  on: {
                    change: function ($event) {
                      return _vm.handleLabelChange(item)
                    },
                  },
                  model: {
                    value: item.label,
                    callback: function ($$v) {
                      _vm.$set(item, "label", $$v)
                    },
                    expression: "item.label",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "survey-multiple-option-input__delete" },
              [
                _c("el-button", {
                  attrs: { icon: "el-icon-delete" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRemove(index)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "survey-multiple-option-input__add" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("Add")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }