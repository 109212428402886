var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullscreenloading,
          expression: "fullscreenloading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
        },
        [
          _c("el-tab-pane", [
            _c(
              "span",
              { attrs: { slot: "label" }, slot: "label" },
              [
                _c("svg-icon", { attrs: { "icon-class": "hike" } }),
                _vm._v("Hike Info"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("hike-geninfo", {
                  attrs: { "parent-hike": _vm.hikeForm },
                  on: { updateParentGenInfo: _vm.updateParentGenInfo },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Stages"),
            ]),
            _vm._v(" "),
            false ? _c("div", [_vm._v("\n        Stages\n      ")]) : _vm._e(),
            _vm._v(" "),
            true
              ? _c(
                  "div",
                  [
                    _vm.hikeForm.id
                      ? _c("hike-stage", {
                          attrs: {
                            "parent-id": _vm.hikeForm.id,
                            "internal-title": _vm.hikeForm.internal.title,
                            "parent-stages": _vm.hikeForm.stages,
                            "parent-webinar-name": _vm.hikeForm.webinarName,
                            "parent-webinar-key": _vm.hikeForm.webinarKey,
                            "parent-webinar-passcode":
                              _vm.hikeForm.webinarPasscode,
                            "parent-event-start-time": _vm.hikeForm.start,
                            "parent-event-end-time": _vm.hikeForm.end,
                            "parent-flag-remind-save": _vm.flagRemindSave,
                            "parent-display-type": _vm.displayType,
                          },
                          on: { updateParentStage: _vm.updateParentStage },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Staffs"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.hikeForm.id
                  ? _c("hike-staff", {
                      attrs: {
                        "parent-id": _vm.hikeForm.id,
                        "internal-title": _vm.hikeForm.internal.title,
                      },
                      on: { updateParentStaff: _vm.updateParentStaff },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Resources"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.hikeForm.id
                  ? _c("hike-resource", {
                      attrs: {
                        "parent-resource": _vm.hikeForm.resources,
                        "internal-title": _vm.hikeForm.internal.title,
                        "hike-id": _vm.hikeForm.id,
                        "from-id": _vm.fromId,
                      },
                      on: { updateParentResource: _vm.updateParentResource },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Activation Code"),
            ]),
            _vm._v(" "),
            true
              ? _c(
                  "div",
                  [
                    _vm.hikeForm.id
                      ? _c("activation-code", {
                          attrs: {
                            "parent-id": _vm.hikeForm.id,
                            "internal-title": _vm.hikeForm.internal.title,
                            title: _vm.hikeForm.title,
                            "code-type": "hike",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Participants"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.hikeForm.id
                  ? _c("hike-participant", {
                      attrs: {
                        "parent-id": _vm.hikeForm.id,
                        "internal-title": _vm.hikeForm.internal.title,
                        "parent-staff": _vm.oldClientPocList,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.showReportTab()
            ? _c("el-tab-pane", [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v("Report"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm.hikeForm.id
                      ? _c("hike-report", {
                          attrs: {
                            "parent-id": _vm.hikeForm.id,
                            "internal-title": _vm.hikeForm.internal.title,
                            "parent-hike-report": _vm.hikeForm.report,
                            "parent-hike-skill-compass-dimensions":
                              _vm.hikeForm.skillCompassDimensions,
                          },
                          on: {
                            updateReport: _vm.updateReport,
                            updateSkillCompassDimensions:
                              _vm.updateSkillCompassDimensions,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hikeForm.category === "manager" && _vm.hikeForm.survey
            ? _c("el-tab-pane", [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v("Survey"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("hike-survey", {
                      ref: "hikeSurvey",
                      attrs: {
                        "parent-id": _vm.hikeForm.id,
                        "parent-stages": _vm.hikeForm.stages,
                        survey: _vm.hikeForm.survey,
                      },
                      on: { updateSurvey: _vm.updateSurvey },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Email Settings"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.hikeForm.id
                  ? _c("hike-email-setting", {
                      attrs: {
                        "parent-id": _vm.hikeForm.id,
                        "parent-email-rule-set": _vm.hikeForm.emailRuleSet,
                        "parent-automated-email": _vm.hikeForm.automatedEmail,
                      },
                      on: {
                        updateParentEmailRuleSet: _vm.updateParentEmailRuleSet,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("draft")
                    },
                  },
                },
                [_vm._v("Create as Draft")]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("preview")
                    },
                  },
                },
                [_vm._v("Save as Preview")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("published")
                    },
                  },
                },
                [_vm._v("Create as Published")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("archived")
                    },
                  },
                },
                [_vm._v("Create as archived")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("draft")
                    },
                  },
                },
                [_vm._v("Create Copy as Draft")]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("preview")
                    },
                  },
                },
                [_vm._v("Save as Preview")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.checkMandateFields(),
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("published")
                    },
                  },
                },
                [_vm._v("Create Copy as Published")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "createfromcopy"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("archived")
                    },
                  },
                },
                [_vm._v("Create Copy as archived")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("draft")
                    },
                  },
                },
                [_vm._v("Save as Draft")]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-button",
                {
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("preview")
                    },
                  },
                },
                [_vm._v("Save as Preview")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.checkMandateFields(),
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("published")
                    },
                  },
                },
                [_vm._v("Save as Publish")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("archived")
                    },
                  },
                },
                [_vm._v("Save as Archive")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/hike" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }