"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _ImageCropper = _interopRequireDefault(require("@/components/ImageCropper"));
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _user = require("@/api/user");
var _log = require("@/utils/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ImageCropper: _ImageCropper.default,
    PanThumb: _PanThumb.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          givenName: "",
          familyName: "",
          profilePic: "",
          email: "",
          role: "",
          roles: [],
          bio: "",
          referees: []
        };
      }
    }
  },
  data: function data() {
    return {
      imagecropperShow: false,
      imagecropperKey: 0,
      uploadUrl: process.env.VUE_APP_BASE_AUTH_API + "/users/id/image",
      headers: {
        Authorization: "Bearer ",
        "Content-Type": "application/octet-stream"
      },
      noCircle: true,
      isLoop: this.user.isLoop
    };
  },
  created: function created() {
    var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
    this.headers.Authorization = "Bearer " + token;
    this.uploadUrl = process.env.VUE_APP_BASE_AUTH_API + "/users/".concat(this.user.uuid, "/image");
  },
  methods: {
    cropSuccess: function cropSuccess(resData) {
      var email = (0, _cookie.getCookie)("EMAIL");

      // console.log('page-user-uploadimage', resData)
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.user.profilePic = resData.profilePic;
      (0, _ga.writeGaEvent)(this, "User", "Update User Picture", email, "User Profile: ".concat(resData.uuid));
    },
    close: function close() {
      this.imagecropperShow = false;
    }
  }
};