"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elDragDialog = _interopRequireDefault(require("@/directive/el-drag-dialog"));
var _lifehikesInitData = require("@/lifehikes-init-data");
var _resource = require("@/api/resource");
var _log = require("@/utils/log");
var _moment = _interopRequireDefault(require("moment"));
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _index = _interopRequireDefault(require("../../../components/SearchBox/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ResourceList',
  components: {
    SearchBox: _index.default
  },
  directives: {
    elDragDialog: _elDragDialog.default
  },
  filters: {
    truncate: function truncate(value, length) {
      return value.substring(0, length) + '......';
    }
  },
  data: function data() {
    return {
      resourceType: _lifehikesInitData.resourceType,
      fullscreenloading: false,
      isDialogShow: false,
      resourceList: null,
      subtypeList: ['event-resource', 'learn-grow-content', 'bubble-coach-content'],
      statuses: ['published', 'archived'],
      total: 0,
      loading: false,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        q: undefined,
        type: undefined,
        category: undefined,
        status: 'published',
        sort: '-updatedAt'
      }
    };
  },
  computed: {
    isRoot: function isRoot() {
      var roles = (0, _cookie.getCookie)('ROLES');
      return roles.indexOf('root') >= 0;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    doCreate: function doCreate() {
      this.$router.push('/resource/create');
    },
    doSearch: function doSearch(pageNo) {
      this.listQuery.page = pageNo;
      this.listQuery.type = this.listQuery.type === '' ? this.listQuery.type = undefined : this.listQuery.type;
      this.listQuery.q = this.listQuery.q === '' ? this.listQuery.q = undefined : this.listQuery.q;
      this.listQuery.category = this.listQuery.category === '' ? this.listQuery.category = undefined : this.listQuery.category;
      this.listQuery.status = this.listQuery.status === '' ? this.listQuery.status = undefined : this.listQuery.status;
      this.getList();
    },
    doDelete: function doDelete(id, title) {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');
      this.$confirm("".concat(this.$i18n.t('otrResource.deleteResource'), " [ ").concat(title, " ]. ").concat(this.$i18n.t('otrResource.continue'), "?"), 'Warning', {
        confirmButtonText: this.$i18n.t('otrResource.ok'),
        cancelButtonText: this.$i18n.t('otrResource.cancel'),
        type: 'warning'
      }).then(function () {
        (0, _resource.deleteResourceById)(token, id).then(function (response) {
          _this.doSearch(_this.listQuery.page);
          var msg = _this.$i18n.t('otrResource.delete');
          (0, _log.displayPopupMessage)(msg, 'success');
          (0, _ga.writeGaEvent)(_this, 'Resource', "".concat(_lifehikesInitData.userEvent.resource.delete), oEmail, "Resource ID: ".concat(id));
        }).catch(function (error) {
          (0, _log.displayPopupMessage)(error, 'error');
        });
      }).catch(function () {
        var msg = _this.$i18n.t('otrResource.cancelDelete');
        (0, _log.displayPopupMessage)(msg, 'info');
      });
    },
    doView: function doView(id) {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _resource.getResourceById)(token, id).then(function (response) {
        var data = response.data;
        var url = typeof data.url === 'undefined' ? '' : data.url;
        if (url) {
          window.open(url, '_blank');
        } else {
          var msg = _this2.$i18n.t('otrResource.errNoFile');
          (0, _log.displayPopupMessage)(msg, 'error');
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('Resource-GetResource-API-Error', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _resource.fetchList)(token, this.listQuery).then(function (response) {
        var data = response.data;
        (0, _log.showDebugLog)('Resource-API-response', response.data);
        _this3.resourceList = data.rows;
        _this3.total = data.count;
        _this3.listLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('Resource-API-response', error);
        _this3.listLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$parent.$emit('onSelectionChange', val);
    },
    sort: function sort(data) {
      var prop = data.prop,
        order = data.order;
      if (prop === 'title') {
        order === 'ascending' ? this.listQuery.sort = '+title' : this.listQuery.sort = '-title';
      }
      if (prop === 'type') {
        order === 'ascending' ? this.listQuery.sort = '+type' : this.listQuery.sort = '-type';
      }
      if (prop === 'filename') {
        order === 'ascending' ? this.listQuery.sort = '+filename' : this.listQuery.sort = '-filename';
      }
      if (prop === 'createdAt') {
        order === 'ascending' ? this.listQuery.sort = '+createdAt' : this.listQuery.sort = '-createdAt';
      }
      if (prop === 'updatedAt') {
        order === 'ascending' ? this.listQuery.sort = '+updatedAt' : this.listQuery.sort = '-updatedAt';
      }
      if (prop === 'category') {
        order === 'ascending' ? this.listQuery.sort = '+category' : this.listQuery.sort = '-category';
      }
      this.doSearch(1);
    },
    disableVisble: function disableVisble(id) {
      this.$refs[id].visible = false;
    }
  }
};