var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("General")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Rule Set"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Rule Set" },
                      on: { change: _vm.ruleSettingChange },
                      model: {
                        value: _vm.emailRuleSet,
                        callback: function ($$v) {
                          _vm.emailRuleSet = $$v
                        },
                        expression: "emailRuleSet",
                      },
                    },
                    _vm._l(_vm.ruleSet, function (t) {
                      return _c("el-option", {
                        key: t.key,
                        attrs: { label: t.description, value: t.key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("The following email rules will be"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.emailSetting },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "topic",
                  label: "Email topic",
                  "min-width": "30%",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "triggerDescription",
                  label: "Tigger",
                  "min-width": "30%",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "remarks",
                  label: "Remarks",
                  "min-width": "40%",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.emailSetting, function (es, index) {
        return _c("div", { key: index }, [
          _c(
            "fieldset",
            { staticClass: "otrfieldset" },
            [
              _c("legend", [_vm._v("Email Topic : " + _vm._s(es.topic) + " ")]),
              _vm._v(" "),
              _vm.haveLoop && !es.enableWhenHikeInLoop
                ? _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v(
                            "This Hike is within a Loop. THIS EMAIL WILL NOT BE SENT because it will only be sent while it is not during a Loop."
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.haveLoop && !es.enableWhenHikeNotInLoop
                ? _c(
                    "el-row",
                    {
                      staticClass: "otr-line-spacing__20",
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("span", { staticClass: "otr-form__label" }, [
                          _vm._v(
                            "This Hike is not within a Loop. THIS EMAIL WILL NOT BE SENT because it will only be sent during a Loop."
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "otr-form__label" }, [
                      _vm._v("Subject:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Email Subject" },
                        model: {
                          value: es.defaultSubject,
                          callback: function ($$v) {
                            _vm.$set(es, "defaultSubject", $$v)
                          },
                          expression: "es.defaultSubject",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "otr-form__label" }, [
                      _vm._v("Content:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("tinymce", {
                        ref: "descriptionEditor" + index,
                        refInFor: true,
                        attrs: {
                          id: "descriptionEditor" + index,
                          toolbar: ["bold bullist link code"],
                          height: 300,
                        },
                        model: {
                          value: es.defaultContent,
                          callback: function ($$v) {
                            _vm.$set(es, "defaultContent", $$v)
                          },
                          expression: "es.defaultContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "otr-form__label" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.resetSubjectContent(es.name, index)
                            },
                          },
                        },
                        [_vm._v("Reset Subject & Content")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.switchPlaceholders(
                                !es.showPlaceholders,
                                index
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              es.showPlaceholders
                                ? "Hide Supported Placeholders"
                                : "Show Supported Placeholders"
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              es.showPlaceholders
                ? _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "otr-line-spacing__20",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "otr-form__label" }, [
                              _vm._v(
                                "You can insert the following placeholders into email content:"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("supported-placeholders"),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }