var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Setting")]),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { "label-position": "left" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Type", "label-width": "80px" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Select" },
                              model: {
                                value: _vm.reportType,
                                callback: function ($$v) {
                                  _vm.reportType = $$v
                                },
                                expression: "reportType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "individual",
                                attrs: {
                                  label: "Individual",
                                  value: "individual",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: "all",
                                attrs: { label: "All", value: "all" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: "tasCreditReport",
                                attrs: {
                                  label: "TAS Credit Report",
                                  value: "tasCreditReport",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.reportType === "individual"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "Rater", "label-width": "80px" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "Select" },
                                  model: {
                                    value: _vm.rater,
                                    callback: function ($$v) {
                                      _vm.rater = $$v
                                    },
                                    expression: "rater",
                                  },
                                },
                                _vm._l(_vm.raterList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.uuid,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Year (ISO)", "label-width": "80px" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { maxlength: 4 },
                            model: {
                              value: _vm.yearIso,
                              callback: function ($$v) {
                                _vm.yearIso =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "yearIso",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Week of Year",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              maxlength:
                                _vm.reportType !== "tasCreditReport" ? 3 : 50,
                            },
                            model: {
                              value: _vm.weekOfYear,
                              callback: function ($$v) {
                                _vm.weekOfYear =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "weekOfYear",
                            },
                          }),
                          _vm._v(" "),
                          _vm.reportType === "tasCreditReport"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#39d",
                                    "line-height": "28px",
                                  },
                                },
                                [
                                  _vm._v(
                                    'Support input multiple weeks, separated by ",", e.g. "1,2"'
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-button", { on: { click: _vm.generateReport } }, [
                        _vm._v("Generate"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.reportType === "tasCreditReport",
                              expression: "reportType === 'tasCreditReport'",
                            },
                          ],
                          attrs: { loading: _vm.downloadLoading },
                          on: { click: _vm.handleDownload },
                        },
                        [_vm._v("Export to Excel")]
                      ),
                      _vm._v(" "),
                      _vm.reportType !== "tasCreditReport"
                        ? [
                            _vm.yearIsoIsValid() && _vm.weekOfYearIsValid()
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "20px" } },
                                  [
                                    _vm._v(
                                      "( Report Period: " +
                                        _vm._s(
                                          _vm.getStartDate(
                                            _vm.yearIso,
                                            _vm.weekOfYear
                                          )
                                        ) +
                                        " to " +
                                        _vm._s(
                                          _vm.getEndDate(
                                            _vm.yearIso,
                                            _vm.weekOfYear
                                          )
                                        ) +
                                        " )"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : [
                            _vm.yearIsoIsValid() && _vm.weekOfYearIsValid()
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "20px" } },
                                  [
                                    _vm._v(
                                      "( Report Period: " +
                                        _vm._s(
                                          _vm.getWeekPeriods(
                                            _vm.yearIso,
                                            _vm.weekOfYear
                                          )
                                        ) +
                                        " )"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.errorMsg))]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.reportType === "individual" && _vm.jobs.length > 0
        ? _c(
            "div",
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Individual Tasks Report")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text item" },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("strong", [_vm._v("Period:")]),
                            _vm._v(" Year "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.individualYearIso)),
                            ]),
                            _vm._v(", Week "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.individualWeekOfYear)),
                            ]),
                            _vm._v(
                              " ( " +
                                _vm._s(
                                  _vm.getStartDate(
                                    _vm.individualYearIso,
                                    _vm.individualWeekOfYear
                                  )
                                ) +
                                " to " +
                                _vm._s(
                                  _vm.getEndDate(
                                    _vm.individualYearIso,
                                    _vm.individualWeekOfYear
                                  )
                                ) +
                                " ) "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("b", [_vm._v("Rater:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.getUserName(_vm.rater)) +
                                " (" +
                                _vm._s(_vm.rater) +
                                ")"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("strong", [_vm._v("Total Task Credit:")]),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(" " + _vm._s(_vm.totalTaskCredit)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("b", [
                              _vm._v("Completed Task(s) / Total Task(s):"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "complete" }, [
                              _vm._v(_vm._s(_vm.totalCompletedJob)),
                            ]),
                            _vm._v(" / "),
                            _c("span", { staticClass: "open" }, [
                              _vm._v(_vm._s(_vm.totalJobs)),
                            ]),
                            _vm._v(" Task(s) includes "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.totalVideos)),
                            ]),
                            _vm._v(" Video(s)"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.jobs, function (item, index) {
                      return _c(
                        "el-row",
                        {
                          key: index,
                          staticStyle: {
                            "margin-bottom": "5px",
                            border: "1px solid #dbdbdb",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { padding: "12px 10px" },
                              attrs: { span: 10 },
                            },
                            [
                              _c("div", [
                                _c("b", [_vm._v("Task ID:")]),
                                _vm._v(" " + _vm._s(item.id)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [_vm._v("Hike:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(item.hikeTitle) +
                                    " (" +
                                    _vm._s(item.hikeId) +
                                    ") "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [_vm._v("Hike Category:")]),
                                _vm._v(" " + _vm._s(item.hikeCategory) + " "),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [_vm._v("Stage Type:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(item.stageType) +
                                    " (" +
                                    _vm._s(item.stageId) +
                                    ")"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [_vm._v("Task Credit:")]),
                                _vm._v(" " + _vm._s(item.raterTaskCredit)),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [_vm._v("Task Status: ")]),
                                _c(
                                  "span",
                                  { class: [_vm.cssWordRed(item.jobStatus)] },
                                  [_vm._v(_vm._s(item.jobStatus))]
                                ),
                              ]),
                              _vm._v(" "),
                              item.jobStatus === "open"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-top": "5px" },
                                          attrs: {
                                            type: "danger",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.releaseRaterJob(
                                                item.raterId,
                                                item.id,
                                                item.userHikeId,
                                                item.stageId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Release it to task pool")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { padding: "12px 0" } },
                                [
                                  _vm._v("With Overall Score: "),
                                  _c("span", { staticClass: "w_blue" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.checkHasOverallScore(
                                            item.userContents
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-bottom": "5px",
                                  },
                                  attrs: { data: item.userContents },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "index", width: "50" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Video Rating",
                                      width: "80",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.hasScore
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-check complete",
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "el-icon-close open",
                                                  }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  false
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "Overall Rating",
                                          width: "100",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.hasOverallScore
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-check complete",
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "el-icon-close open",
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Dial Test",
                                      width: "80",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.enableDialTest
                                                ? _c("div", [
                                                    scope.row.hasDialTest
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-check complete",
                                                        })
                                                      : _c("i", {
                                                          staticClass:
                                                            "el-icon-close open",
                                                        }),
                                                  ])
                                                : _c("div", [
                                                    _c("span", [_vm._v("-")]),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.reportType === "individual" && _vm.jobs.length <= 0
        ? _c("div", [
            !_vm.firstLoad ? _c("div", [_vm._v("Data not found!")]) : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reportType === "all" && _vm.summary.length > 0
        ? _c(
            "div",
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Rater Task Summary Report")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text item" },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("strong", [_vm._v("Period:")]),
                            _vm._v(" Year "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.allYearIso)),
                            ]),
                            _vm._v(", Week "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.allWeekOfYear)),
                            ]),
                            _vm._v(
                              " ( " +
                                _vm._s(
                                  _vm.getStartDate(
                                    _vm.allYearIso,
                                    _vm.allWeekOfYear
                                  )
                                ) +
                                " to " +
                                _vm._s(
                                  _vm.getEndDate(
                                    _vm.allYearIso,
                                    _vm.allWeekOfYear
                                  )
                                ) +
                                " ) "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticStyle: { "margin-bottom": "20px" } },
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("b", [
                              _vm._v("Completed Task(s) / Total Task(s):"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "complete" }, [
                              _vm._v(_vm._s(_vm.totalCompletedJob)),
                            ]),
                            _vm._v(" / "),
                            _c("span", { staticClass: "open" }, [
                              _vm._v(_vm._s(_vm.totalJobs)),
                            ]),
                            _vm._v(" Task(s) includes "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.totalVideos)),
                            ]),
                            _vm._v(" Video(s)"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticStyle: { "margin-bottom": "5px" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.summary, border: "" },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { type: "index", width: "50" },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Rater", width: "220" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(_vm._s(scope.row.rater)),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(scope.row.raterUuid)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3302707101
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Total Completed Task(s)",
                                    width: "130",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.totalCompleteJob
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    860148394
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Total Task(s)",
                                    width: "80",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.totalJob)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2256050979
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.reportType === "all" && _vm.summary.length <= 0
        ? _c("div", [
            !_vm.firstLoad ? _c("div", [_vm._v("Data not found!")]) : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reportType === "tasCreditReport" && _vm.tasCredit.length > 0
        ? _c(
            "div",
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Training Assessment Scorer Report")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text item" },
                  [
                    _c(
                      "el-row",
                      { staticStyle: { "margin-bottom": "20px" } },
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c("span", [
                            _c("strong", [_vm._v("Period:")]),
                            _vm._v(" Year "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.tasYearIso)),
                            ]),
                            _vm._v(", Week "),
                            _c("span", { staticClass: "w_blue" }, [
                              _vm._v(_vm._s(_vm.tasWeekOfYear)),
                            ]),
                            _vm._v(
                              " ( " +
                                _vm._s(
                                  _vm.getWeekPeriods(
                                    _vm.tasYearIso,
                                    _vm.tasWeekOfYear
                                  )
                                ) +
                                ") "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticStyle: { "margin-bottom": "5px" } },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.tasCredit, border: "" },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "", width: "220" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(_vm._s(scope.row.rater)),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(scope.row.raterUuid)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3302707101
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Benchmarks",
                                    prop: "benchmarks",
                                    width: "120",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Summits",
                                    prop: "summits",
                                    width: "120",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Total Credits",
                                    prop: "totalCredits",
                                    width: "120",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Total $", width: "120" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.reportType === "tasCreditReport" && _vm.tasCredit.length <= 0
        ? _c("div", [
            !_vm.firstLoad ? _c("div", [_vm._v("Data not found!")]) : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }