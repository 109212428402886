"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUserOverallScore = addUserOverallScore;
exports.addUserScore = addUserScore;
exports.addUserScoreNew = addUserScoreNew;
exports.createHike = createHike;
exports.deleteUserOverallScore = deleteUserOverallScore;
exports.deleteUserScore = deleteUserScore;
exports.deleteUserScoreNew = deleteUserScoreNew;
exports.downloadTASRatingExcel = downloadTASRatingExcel;
exports.editComments = editComments;
exports.enrollHike = enrollHike;
exports.getAttendantReport = getAttendantReport;
exports.getDialTestData = getDialTestData;
exports.getEmailLog = getEmailLog;
exports.getExportSurveyResult = getExportSurveyResult;
exports.getFeedbackResults = getFeedbackResults;
exports.getHikeActivityReport = getHikeActivityReport;
exports.getHikeById = getHikeById;
exports.getHikeList = getHikeList;
exports.getHikeStaff = getHikeStaff;
exports.getParticipant = getParticipant;
exports.getParticipantsSteps = getParticipantsSteps;
exports.getResourceByHikeId = getResourceByHikeId;
exports.getSkillList = getSkillList;
exports.getSpiderData = getSpiderData;
exports.getSurveyTeamEmails = getSurveyTeamEmails;
exports.getSurveys = getSurveys;
exports.getTaskStatus = getTaskStatus;
exports.getVideoUrl = getVideoUrl;
exports.hikeNotifyHikeClientPOC = hikeNotifyHikeClientPOC;
exports.hikeRelatedLoops = hikeRelatedLoops;
exports.reminderTrigger = reminderTrigger;
exports.removeScores = removeScores;
exports.scoreCheckingReport = scoreCheckingReport;
exports.scoreCheckingReportNew = scoreCheckingReportNew;
exports.sendMail = sendMail;
exports.unenrollParticipant = unenrollParticipant;
exports.updateHikeById = updateHikeById;
exports.updateHikeParticipantEnrolledBy = updateHikeParticipantEnrolledBy;
exports.updateParticipantStep = updateParticipantStep;
exports.updateUserOverallScore = updateUserOverallScore;
exports.updateUserScore = updateUserScore;
exports.updateUserScoreNew = updateUserScoreNew;
var _request = _interopRequireDefault(require("@/utils/request"));
function getHikeList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getHikeById(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id,
    method: 'get',
    headers: headers
  });
}
function createHike(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/hikes',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateHikeById(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/hikes/' + id,
    method: 'put',
    headers: headers,
    data: data
  });
}
function getHikeStaff(token, id, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/staff',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getResourceByHikeId(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/resources',
    method: 'get',
    headers: headers
  });
}
function getParticipant(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/participants',
    method: 'get',
    headers: headers
  });
}
function getFeedbackResults(token, hikeId, stageId, linkedResource) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var url = null;
  if (hikeId && stageId && !linkedResource) {
    url = '/survey/hike-feedback-results?hikeId=' + hikeId + '&stageId=' + stageId;
  } else if (linkedResource) {
    url = '/survey/hike-feedback-results?linkedResource=' + linkedResource;
  }
  if (url) {
    return (0, _request.default)({
      url: url,
      baseURL: process.env.VUE_APP_SURVEY_API_DOMAIN,
      method: 'get',
      headers: headers
    });
  }
  return false;
}
function getParticipantsSteps(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/participantsSteps',
    method: 'get',
    headers: headers
  });
}
function updateParticipantStep(token, id, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/participantStep',
    method: 'patch',
    headers: headers,
    data: data
  });
}
function enrollHike(token, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/user-hikes/register-enroll',
    method: 'post',
    headers: headers,
    data: data
  });
}
function unenrollParticipant(token, hikeId, userUuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/coach-tools/hikes/' + hikeId + '/users/' + userUuid + '/unenroll',
    method: 'get',
    headers: headers
  });
}
function scoreCheckingReport(token, hikeId, stageId, userUuid, type) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/type/").concat(type, "/scores"),
    method: 'get',
    headers: headers
  });
}
function scoreCheckingReportNew(token, hikeId, stageId, userUuid, type) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/type/").concat(type, "/scores-new"),
    method: 'get',
    headers: headers
  });
}
function getSpiderData(token, hikeId, stageId, userUuid, type) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/type/").concat(type, "/scores"),
    method: 'get',
    headers: headers
  });
}
function getVideoUrl(token, contentId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/contents/".concat(contentId),
    method: 'get',
    headers: headers
  });
}
function removeScores(token, hikeId, stageId, userUuid, type, raterId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/raters/").concat(raterId, "/remove-all-scores"),
    method: 'delete',
    headers: headers
  });
}
function editComments(token, userHikeReportId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/userhikereports/".concat(userHikeReportId, "/edit-comments"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function addUserScore(token, hikeId, stageId, userUuid, type, actionId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/type/").concat(type, "/action/").concat(actionId, "/new-scores"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function addUserScoreNew(token, hikeId, stageId, userUuid, type, actionId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/type/").concat(type, "/action/").concat(actionId, "/new-scores-new"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateUserScore(token, hikeId, stageId, userUuid, actionId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/action/").concat(actionId, "/scores"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateUserScoreNew(token, hikeId, stageId, userUuid, actionId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/action/").concat(actionId, "/scores-new"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function deleteUserScore(token, hikeId, stageId, userUuid, actionId, raterId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/action/").concat(actionId, "/rater/").concat(raterId, "/scores"),
    method: 'delete',
    headers: headers
  });
}
function deleteUserScoreNew(token, hikeId, stageId, userUuid, actionId, raterId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/action/").concat(actionId, "/rater/").concat(raterId, "/scores-new"),
    method: 'delete',
    headers: headers
  });
}
function addUserOverallScore(token, hikeId, stageId, userUuid, type, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/").concat(type, "/overalls"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateUserOverallScore(token, hikeId, stageId, userUuid, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/overalls"),
    method: 'post',
    headers: headers,
    data: data
  });
}
function deleteUserOverallScore(token, hikeId, stageId, userUuid, raterId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/rater/").concat(raterId, "/overalls"),
    method: 'delete',
    headers: headers
  });
}
function getSkillList(token) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/skills/hike",
    method: 'get',
    headers: headers
  });
}
function getTaskStatus(token, hikeId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/rater-assignment/job-in-hike-report/".concat(hikeId),
    method: 'get',
    headers: headers
  });
}
function getAttendantReport(token, hikeId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/attendance-report/hikes/".concat(hikeId, "/checkinattendance"),
    method: 'get',
    headers: headers
  });
}
function getHikeActivityReport(token, hikeId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/attendance-report/hikes/".concat(hikeId, "/activity-report"),
    method: 'get',
    headers: headers
  });
}
function getDialTestData(token, hikeId, stageId, userUuid, contentId, raterId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/coach-tools/hikes/".concat(hikeId, "/stages/").concat(stageId, "/users/").concat(userUuid, "/contents/").concat(contentId, "/dial-test/raters/").concat(raterId),
    method: 'get',
    headers: headers
  });
}
function getSurveys(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/getsurveys',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getSurveyTeamEmails(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  console.log('query:', query);
  return (0, _request.default)({
    url: '/hikes/getSurveyTeamEmails',
    method: 'get',
    headers: headers,
    params: query
  });
}
function getEmailLog(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/get-email-log',
    method: 'get',
    headers: headers,
    params: query
  });
}
function sendMail(token, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/survey/mgh/sendmail',
    method: 'post',
    headers: headers,
    data: data
  });
}
function getExportSurveyResult(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/hikes/exportSurveyResult",
    method: 'get',
    headers: headers,
    params: query
  });
}
function downloadTASRatingExcel(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/scores-new',
    method: 'get',
    headers: headers
  });
}
function reminderTrigger(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/hikes/survey/mgh/reminder-trigger',
    method: 'post',
    headers: headers,
    data: data
  });
}
function hikeRelatedLoops(token, id) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/related-loops',
    method: 'get',
    headers: headers
  });
}
function hikeNotifyHikeClientPOC(token, id, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/hikes/' + id + '/staffs/notify',
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateHikeParticipantEnrolledBy(token, userId, hikeId, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/user-hikes/users/' + userId + '/hikes/' + hikeId + '/enrolledBy',
    method: 'post',
    headers: headers,
    data: data
  });
}