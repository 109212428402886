var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      staticClass: "filter-item",
      attrs: { placeholder: _vm.placeholder },
      model: {
        value: _vm.qeruy,
        callback: function ($$v) {
          _vm.qeruy = $$v
        },
        expression: "qeruy",
      },
    },
    [
      _c(
        "template",
        { slot: "append" },
        [
          _c("svg-icon", {
            directives: [
              { name: "popover", rawName: "v-popover:popover", arg: "popover" },
            ],
            attrs: { "icon-class": "book-solid" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }