"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _log = require("@/utils/log");
var _training = require("@/api/training");
var yup = _interopRequireWildcard(require("yup"));
var _index = require("./index");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _lifehikesInitData = require("@/lifehikes-init-data");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _index2 = _interopRequireDefault(require("../../../../components/ActivationCode/index.vue"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TrainingDetail',
  components: {
    TrainingGenInfo: _index.TrainingGenInfo,
    TrainingResource: _index.TrainingResource,
    TrainingCode: _index.TrainingCode,
    TrainingTeam: _index.TrainingTeam,
    TrainingStaff: _index.TrainingStaff,
    TrainingSession: _index.TrainingSession,
    TrainingBaseline: _index.TrainingBaseline,
    ActivationCode: _index2.default
  },
  props: {
    displayType: {
      type: String,
      default: 'create'
    }
  },
  data: function data() {
    return {
      fullscreenloading: false,
      teams: null,
      // participants
      reportRecipients: null,
      isCreatedRecord: false,
      // editType: this.displayType,
      fromId: '',
      trainingForm: {
        id: '',
        title: '',
        publish: '',
        start: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        end: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        expiry: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        channel: '',
        webinarName: '',
        webinarKey: '',
        webinarPasscode: '',
        estimatedParticipantCount: 0,
        address: {
          address1: '',
          address2: '',
          city: '',
          region: '',
          postalCode: '',
          country: ''
        },
        internal: {
          title: '',
          opportunityNumber: '',
          clientName: '',
          remark: ''
        },
        feedbackUrl: '',
        locale: 'en',
        imageUrl: '',
        description: '',
        skills: [],
        resources: [],
        assessments: [],
        /* no use now */
        coachAssessments: [],
        /* no use now */
        staff: [],
        tags: [],
        sessions: []
        /* teams: [] */ /* do not pass the element to add or save request, otherwise it will overide the value in DB */
      }
    };
  },

  computed: {
    validateSchema: function validateSchema() {
      return yup.object().shape({
        title: yup.string().required(),
        description: yup.string().required()
      });
    },
    genInfo: function genInfo() {
      return {
        id: this.trainingForm.id,
        title: this.trainingForm.title,
        publish: this.trainingForm.publish,
        start: this.trainingForm.start,
        end: this.trainingForm.end,
        // expiry: this.trainingForm.expiry,
        expiry: this.trainingForm.end,
        address: this.trainingForm.address,
        locale: 'en',
        imageUrl: this.trainingForm.imageUrl,
        description: this.trainingForm.description,
        tags: this.trainingForm.tags,
        internal: this.trainingForm.internal,
        feedbackUrl: this.trainingForm.feedbackUrl,
        channel: this.trainingForm.channel,
        webinarName: this.trainingForm.webinarName,
        webinarKey: this.trainingForm.webinarKey,
        webinarPasscode: this.trainingForm.webinarPasscode,
        estimatedParticipantCount: this.trainingForm.estimatedParticipantCount
      };
    },
    isShowGeneral: function isShowGeneral() {
      if (this.displayType === 'create') {
        return true;
      } else {
        return this.trainingForm.id;
      }
    },
    isShowModule: function isShowModule() {
      if (this.displayType === 'create') {
        return true;
      } else {
        return this.trainingForm.id;
      }
    },
    isPublish: function isPublish() {
      var user = (0, _cookie.getCookie)('ROLES');
      // override the rule if it is root
      if (user.indexOf('root') >= 0) return false;
      return this.trainingForm.publish === 'published' || this.trainingForm.publish === 'archived';
    }
  },
  created: function created() {
    if (this.displayType === 'edit') {
      var id = typeof this.$route.params.id === 'undefined' ? this.trainingForm.id : this.$route.params.id;
      this.editType = 'edit';
      this.fromId = typeof this.$route.query.fromId === 'undefined' ? '' : this.$route.query.fromId;
      this.getTraining(id, false); // copy mode is false
      // console.log('Log', this.trainingForm)
      // console.log('Parent created hook run edit', this.trainingForm)
    } else if (this.displayType === 'createfromcopy') {
      this.fromId = typeof this.$route.query.fromId === 'undefined' ? '' : this.$route.query.fromId;
      this.getTraining(this.fromId, true);
      // console.log('copy Form', this.trainingForm)
    } else {
      // create from new
      this.teams = [];
      this.reportRecipients = [];
      this.fromId = '';
      // console.log('Parent created hook run new', this.trainingForm)
    }
  },

  methods: {
    doBack: function doBack() {
      this.$router.push('/training/index');
    },
    doAddSave: function doAddSave(status) {
      var _this = this;
      this.fullscreenloading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');
      this.trainingForm.publish = status;

      // validate
      if (this.trainingForm.title === '') {
        this.fullscreenloading = false;
        var msg = this.$i18n.t('otrTraining.errorMessage1');
        (0, _log.displayPopupMessage)(msg, 'error');
        return;
      }
      if (this.trainingForm.start.utc === '' || this.trainingForm.end.utc === '' || this.trainingForm.expiry.utc === '') {
        this.fullscreenloading = false;
        var _msg = this.$i18n.t('otrTraining.errorMessage2');
        (0, _log.displayPopupMessage)(_msg, 'error');
        return;
      }
      if (this.trainingForm.start.timezone === '' || this.trainingForm.end.timezone === '' || this.trainingForm.expiry.timezone === '') {
        this.fullscreenloading = false;
        var _msg2 = this.$i18n.t('otrTraining.errorMessage3');
        (0, _log.displayPopupMessage)(_msg2, 'error');
        return;
      }

      // Reset Id to '' if copy
      if (this.trainingForm.id === 'copy') {
        this.trainingForm.id = '';
      }

      // submit
      if (this.trainingForm.id === '') {
        (0, _training.createTraining)(token, this.trainingForm).then(function (response) {
          (0, _log.showDebugLog)('training-create-response', response);
          _this.fullscreenloading = false;
          _this.trainingForm.id = response.data.id;
          _this.trainingForm.publish = response.data.publish;

          // console.log('Success create', this.displayType)
          var msg = "".concat(_this.$i18n.t('otrTraining.training'), " ").concat(response.data.id, " ").concat(_this.$i18n.t('otrTraining.isCreated'));
          (0, _log.displayPopupMessage)(msg, 'success');
          _this.isCreatedRecord = true;
          if (_this.displayType === 'createfromcopy' || _this.displayType === 'create') {
            _this.$router.push("/training/edit/".concat(response.data.id));
          }
          (0, _ga.writeGaEvent)(_this, 'Training', "".concat(_lifehikesInitData.userEvent.training.create), oEmail, "Training Id: ".concat(_this.trainingForm.id));
        }).catch(function (error) {
          (0, _log.showDebugLog)('training-create-error', error);
          _this.fullscreenloading = false;
          // this.$message({ type: 'error', message: error })
          (0, _log.displayPopupMessage)(error, 'error');
          // this.$message({ type: 'error', showClose: true, duration: 5000, message: error.response.data.message })
        });
      } // else {
      // this.doSave(status)
      // }
    },
    doSave: function doSave(status) {
      var _this2 = this;
      this.fullscreenloading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');
      var id = typeof this.$route.params.id === 'undefined' ? this.trainingForm.id : this.$route.params.id;
      this.trainingForm.publish = status;

      // validate
      if (this.trainingForm.title === '') {
        this.fullscreenloading = false;
        var msg = this.$i18n.t('otrTraining.errorMessage1');
        (0, _log.displayPopupMessage)(msg, 'error');
        return;
      }
      if (this.trainingForm.start.utc === '' || this.trainingForm.end.utc === '' || this.trainingForm.expiry.utc === '') {
        this.fullscreenloading = false;
        var _msg3 = this.$i18n.t('otrTraining.errorMessage2');
        (0, _log.displayPopupMessage)(_msg3, 'error');
        return;
      }

      // submit
      (0, _log.showDebugLog)('Save Form', this.trainingForm);
      (0, _training.updateTrainingById)(token, id, this.trainingForm).then(function (response) {
        (0, _log.showDebugLog)('training-create-response', response);
        _this2.fullscreenloading = false;
        _this2.trainingForm.id = response.data.id;
        _this2.trainingForm.publish = response.data.publish;
        var msg = "".concat(_this2.$i18n.t('otrTraining.training'), " ").concat(response.data.id, " ").concat(_this2.$i18n.t('otrTraining.isUpdated'));
        (0, _log.displayPopupMessage)(msg, 'success');
        (0, _ga.writeGaEvent)(_this2, 'Training', "".concat(_lifehikesInitData.userEvent.training.edit), oEmail, "Training Id: ".concat(_this2.trainingForm.id));
      }).catch(function (error) {
        (0, _log.showDebugLog)('training-create-error', error);
        _this2.fullscreenloading = false;
        // this.$message({ type: 'error', message: error })
        (0, _log.displayPopupMessage)(error, 'error');
        // this.$message({ type: 'error', showClose: true, message: error.response.data.message })
      });
    },
    doCancel: function doCancel() {
      this.getTraining();
    },
    getTraining: function getTraining(id, isCopy) {
      var _this3 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.fullscreenloading = true;
      (0, _training.getTrainingById)(token, id).then(function (response) {
        var d = response.data;

        // check error and return immediate
        if (typeof d.id === 'undefined') {
          _this3.fullscreenloading = false;
          var msg = _this3.$i18n.t('otrTraining.cannotGetTraining');
          (0, _log.displayPopupMessage)(msg, 'error');
          // this.$message({ type: 'error', message: 'Cannot get the training, please contact to system administrator' })

          return;
        }
        (0, _log.showDebugLog)('Training-getTrainingById-response', d);
        _this3.trainingForm.id = isCopy ? 'copy' : d.id;

        // title
        if (isCopy) {
          _this3.trainingForm.title = _this3.$route.query.t === 'true' ? d.title : '';
        } else {
          _this3.trainingForm.title = typeof d.title === 'undefined' ? '' : d.title;
        }

        // internal title
        if (isCopy) {
          _this3.trainingForm.internal.title = _this3.$route.query.it === 'true' ? d.internal.title : '';
        } else {
          _this3.trainingForm.internal.title = typeof d.internal === 'undefined' ? '' : d.internal.title;
        }

        // description
        if (isCopy) {
          _this3.trainingForm.description = _this3.$route.query.d === 'true' ? d.description : '';
        } else {
          _this3.trainingForm.description = typeof d.description === 'undefined' ? '' : d.description;
        }

        // client Name
        if (isCopy) {
          _this3.trainingForm.internal.clientName = _this3.$route.query.cn === 'true' ? d.internal.clientName : '';
        } else {
          _this3.trainingForm.internal.clientName = typeof d.internal === 'undefined' ? '' : d.internal.clientName;
        }

        // resources
        if (isCopy) {
          _this3.trainingForm.resources = _this3.$route.query.r === 'true' ? d.resources : [];
        } else {
          _this3.trainingForm.resources = typeof d.resources === 'undefined' ? [] : d.resources;
        }

        // tags
        if (isCopy) {
          _this3.trainingForm.tags = _this3.$route.query.tag === 'true' ? d.tags : [];
        } else {
          _this3.trainingForm.tags = typeof d.tags === 'undefined' ? [] : d.tags;
        }

        // channel : webinar Info or Address
        if (isCopy) {
          _this3.trainingForm.channel = _this3.$route.query.w === 'true' ? d.channel : '';
          _this3.trainingForm.webinarName = _this3.$route.query.w === 'true' ? d.webinarName : '';
          _this3.trainingForm.webinarKey = _this3.$route.query.w === 'true' ? d.webinarKey : '';
          _this3.trainingForm.webinarPasscode = _this3.$route.query.w === 'true' ? d.webinarPasscode : '';
          if (_this3.$route.query.w === 'true') {
            _this3.trainingForm.address.address1 = d.address.address1;
            _this3.trainingForm.address.address2 = d.address.address2;
            _this3.trainingForm.address.city = d.address.city;
            _this3.trainingForm.address.region = d.address.region;
            _this3.trainingForm.address.postalCode = d.address.postalCode;
            _this3.trainingForm.address.country = d.address.country;
          } else {
            _this3.trainingForm.address = {
              address1: '',
              address2: '',
              city: '',
              region: '',
              postalCode: '',
              country: ''
            };
          }
        } else {
          _this3.trainingForm.channel = typeof d.channel === 'undefined' ? '' : d.channel;
          _this3.trainingForm.webinarName = typeof d.webinarName === 'undefined' ? '' : d.webinarName;
          _this3.trainingForm.webinarKey = typeof d.webinarKey === 'undefined' ? '' : d.webinarKey;
          _this3.trainingForm.webinarPasscode = typeof d.webinarPasscode === 'undefined' ? '' : d.webinarPasscode;
          _this3.trainingForm.address.address1 = typeof d.address.address1 === 'undefined' ? '' : d.address.address1;
          _this3.trainingForm.address.address2 = typeof d.address.address2 === 'undefined' ? '' : d.address.address2;
          _this3.trainingForm.address.city = typeof d.address.city === 'undefined' ? '' : d.address.city;
          _this3.trainingForm.address.region = typeof d.address.region === 'undefined' ? '' : d.address.region;
          _this3.trainingForm.address.postalCode = typeof d.address.postalCode === 'undefined' ? '' : d.address.postalCode;
          _this3.trainingForm.address.country = typeof d.address.country === 'undefined' ? '' : d.address.country;
        }

        // remark
        _this3.trainingForm.internal.remark = isCopy ? '' : typeof d.internal === 'undefined' ? '' : d.internal.remark;

        // feedback url
        _this3.trainingForm.feedbackUrl = isCopy ? '' : typeof d.feedbackUrl === 'undefined' ? '' : d.feedbackUrl;

        // opportunityNumber
        _this3.trainingForm.internal.opportunityNumber = isCopy ? '' : typeof d.internal === 'undefined' ? '' : d.internal.opportunityNumber;

        // estimated Participant Count
        _this3.trainingForm.estimatedParticipantCount = isCopy ? 0 : typeof d.estimatedParticipantCount === 'undefined' ? 0 : d.estimatedParticipantCount;

        // publish
        _this3.trainingForm.publish = isCopy ? '' : typeof d.publish === 'undefined' ? '' : d.publish;

        // start date and end date
        if (isCopy) {
          if (_this3.$route.query.e === 'true') {
            _this3.trainingForm.start.utc = typeof d.start.utc === 'undefined' ? '' : d.start.utc;
            _this3.trainingForm.start.local = typeof d.start.local === 'undefined' ? '' : d.start.local; // moment(d.start.local).tz(moment.tz.guess()).toString()
            _this3.trainingForm.start.timezone = typeof d.start.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.start.timezone;
            _this3.trainingForm.end.utc = typeof d.end.utc === 'undefined' ? '' : d.end.utc; // d.end.utc.toISOString()
            _this3.trainingForm.end.local = typeof d.end.local === 'undefined' ? '' : d.end.local; // moment(d.end.local).tz(moment.tz.guess()).toString()
            _this3.trainingForm.end.timezone = typeof d.end.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.end.timezone;
          } else {
            _this3.trainingForm.start = {
              utc: '',
              timezone: _momentTimezone.default.tz.guess(),
              local: ''
            };
            _this3.trainingForm.end = {
              utc: '',
              timezone: _momentTimezone.default.tz.guess(),
              local: ''
            };
          }
        } else {
          // start date
          if (typeof d.start === 'undefined') {
            _this3.trainingForm.start = {
              utc: '',
              timezone: _momentTimezone.default.tz.guess(),
              local: ''
            };
          } else {
            _this3.trainingForm.start.utc = typeof d.start.utc === 'undefined' ? '' : d.start.utc;
            _this3.trainingForm.start.local = typeof d.start.local === 'undefined' ? '' : d.start.local; // moment(d.start.local).tz(moment.tz.guess()).toString()
            _this3.trainingForm.start.timezone = typeof d.start.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.start.timezone;
          }

          // end date
          if (typeof d.end === 'undefined') {
            _this3.trainingForm.end = {
              utc: '',
              timezone: _momentTimezone.default.tz.guess(),
              local: ''
            };
          } else {
            _this3.trainingForm.end.utc = typeof d.end.utc === 'undefined' ? '' : d.end.utc; // d.end.utc.toISOString()
            _this3.trainingForm.end.local = typeof d.end.local === 'undefined' ? '' : d.end.local; // moment(d.end.local).tz(moment.tz.guess()).toString()
            _this3.trainingForm.end.timezone = typeof d.end.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.end.timezone;
          }
        }

        // training session
        if (isCopy) {
          if (_this3.$route.query.s === 'true') {
            _this3.trainingForm.sessions = d.sessions;
            // training - session event date
            if (_this3.$route.query.sd === 'true') {
              for (var s = 0; s < d.sessions.length; s++) {
                delete d.sessions[s].id;
                if (typeof d.sessions[s].start.utc === 'undefined' || d.sessions[s].start.utc == null) {
                  d.sessions[s].start.utc = '';
                } else {
                  d.sessions[s].start.utc = typeof d.sessions[s].start.utc === 'undefined' ? '' : d.sessions[s].start.utc; // d.end.utc.toISOString()
                  d.sessions[s].start.local = typeof d.sessions[s].start.local === 'undefined' ? '' : d.sessions[s].start.local; // moment(d.sessions[s].start.local).tz(moment.tz.guess()).toString()
                  d.sessions[s].start.timezone = typeof d.sessions[s].start.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.sessions[s].start.timezone;
                }
                if (typeof d.sessions[s].end.utc === 'undefined' || d.sessions[s].end.utc == null) {
                  d.sessions[s].end.utc = '';
                } else {
                  d.sessions[s].end.utc = typeof d.sessions[s].end.utc === 'undefined' ? '' : d.sessions[s].end.utc; // d.end.utc.toISOString()
                  d.sessions[s].end.local = typeof d.sessions[s].end.local === 'undefined' ? '' : d.sessions[s].end.local; // moment(d.sessions[s].end.local).tz(moment.tz.guess()).toString()
                  d.sessions[s].end.timezone = typeof d.sessions[s].end.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.sessions[s].end.timezone;
                }
                if (typeof d.sessions[s].recordingStart.utc === 'undefined' || d.sessions[s].recordingStart.utc == null) {
                  d.sessions[s].recordingStart.utc = '';
                } else {
                  d.sessions[s].recordingStart.utc = typeof d.sessions[s].recordingStart.utc === 'undefined' ? '' : d.sessions[s].recordingStart.utc; // d.end.utc.toISOString()
                  d.sessions[s].recordingStart.local = typeof d.sessions[s].recordingStart.local === 'undefined' ? '' : d.sessions[s].recordingStart.local; // moment(d.sessions[s].recordingStart.local).tz(moment.tz.guess()).toString()
                  d.sessions[s].recordingStart.timezone = typeof d.sessions[s].recordingStart.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.sessions[s].recordingStart.timezone;
                }
                if (typeof d.sessions[s].recordingEnd.utc === 'undefined' || d.sessions[s].recordingEnd.utc == null) {
                  d.sessions[s].recordingEnd.utc = '';
                } else {
                  d.sessions[s].recordingEnd.utc = typeof d.sessions[s].recordingEnd.utc === 'undefined' ? '' : d.sessions[s].recordingEnd.utc; // d.end.utc.toISOString()
                  d.sessions[s].recordingEnd.local = typeof d.sessions[s].recordingEnd.local === 'undefined' ? '' : d.sessions[s].recordingEnd.local; // moment(d.sessions[s].recordingEnd.local).tz(moment.tz.guess()).toString()
                  d.sessions[s].recordingEnd.timezone = typeof d.sessions[s].recordingEnd.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.sessions[s].recordingEnd.timezone;
                }
              }
            } else {
              for (s = 0; s < d.sessions.length; s++) {
                d.sessions[s].start = {
                  utc: '',
                  timezone: _momentTimezone.default.tz.guess(),
                  local: ''
                };
                d.sessions[s].end = {
                  utc: '',
                  timezone: _momentTimezone.default.tz.guess(),
                  local: ''
                };
                d.sessions[s].recordingStart = {
                  utc: '',
                  timezone: _momentTimezone.default.tz.guess(),
                  local: ''
                };
                d.sessions[s].recordingEnd = {
                  utc: '',
                  timezone: _momentTimezone.default.tz.guess(),
                  local: ''
                };
                delete d.sessions[s].id;
              }
            }
          } else {
            _this3.trainingForm.sessions = [];
          }
        } else {
          _this3.trainingForm.sessions = typeof d.sessions === 'undefined' ? [] : d.sessions;
          for (s = 0; s < _this3.trainingForm.sessions.length; s++) {
            if (typeof _this3.trainingForm.sessions[s].start.utc === 'undefined' || _this3.trainingForm.sessions[s].start.utc == null) {
              _this3.trainingForm.sessions[s].start.utc = '';
            } else {
              _this3.trainingForm.sessions[s].start.utc = typeof _this3.trainingForm.sessions[s].start.utc === 'undefined' ? '' : _this3.trainingForm.sessions[s].start.utc; // d.end.utc.toISOString()
              _this3.trainingForm.sessions[s].start.local = typeof _this3.trainingForm.sessions[s].start.local === 'undefined' ? '' : _this3.trainingForm.sessions[s].start.local; // moment(this.trainingForm.sessions[s].start.local).tz(moment.tz.guess()).toString()
              _this3.trainingForm.sessions[s].start.timezone = typeof _this3.trainingForm.sessions[s].start.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : _this3.trainingForm.sessions[s].start.timezone;
            }
            if (typeof _this3.trainingForm.sessions[s].end.utc === 'undefined' || _this3.trainingForm.sessions[s].end.utc == null) {
              _this3.trainingForm.sessions[s].end.utc = '';
            } else {
              _this3.trainingForm.sessions[s].end.utc = typeof _this3.trainingForm.sessions[s].end.utc === 'undefined' ? '' : _this3.trainingForm.sessions[s].end.utc; // d.end.utc.toISOString()
              _this3.trainingForm.sessions[s].end.local = typeof _this3.trainingForm.sessions[s].end.local === 'undefined' ? '' : _this3.trainingForm.sessions[s].end.local; // moment(this.trainingForm.sessions[s].end.local).tz(moment.tz.guess()).toString()
              _this3.trainingForm.sessions[s].end.timezone = typeof _this3.trainingForm.sessions[s].end.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : _this3.trainingForm.sessions[s].end.timezone;
            }
            if (typeof _this3.trainingForm.sessions[s].recordingStart.utc === 'undefined' || _this3.trainingForm.sessions[s].recordingStart.utc == null) {
              _this3.trainingForm.sessions[s].recordingStart.utc = '';
            } else {
              _this3.trainingForm.sessions[s].recordingStart.utc = typeof _this3.trainingForm.sessions[s].recordingStart.utc === 'undefined' ? '' : _this3.trainingForm.sessions[s].recordingStart.utc; // d.end.utc.toISOString()
              _this3.trainingForm.sessions[s].recordingStart.local = typeof _this3.trainingForm.sessions[s].recordingStart.local === 'undefined' ? '' : _this3.trainingForm.sessions[s].recordingStart.local; // moment(this.trainingForm.sessions[s].recordingStart.local).tz(moment.tz.guess()).toString()
              _this3.trainingForm.sessions[s].recordingStart.timezone = typeof _this3.trainingForm.sessions[s].recordingStart.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : _this3.trainingForm.sessions[s].recordingStart.timezone;
            }
            if (typeof _this3.trainingForm.sessions[s].recordingEnd.utc === 'undefined' || _this3.trainingForm.sessions[s].recordingEnd.utc == null) {
              _this3.trainingForm.sessions[s].recordingEnd.utc = '';
            } else {
              _this3.trainingForm.sessions[s].recordingEnd.utc = typeof _this3.trainingForm.sessions[s].recordingEnd.utc === 'undefined' ? '' : _this3.trainingForm.sessions[s].recordingEnd.utc; // d.end.utc.toISOString()
              _this3.trainingForm.sessions[s].recordingEnd.local = typeof _this3.trainingForm.sessions[s].recordingEnd.local === 'undefined' ? '' : _this3.trainingForm.sessions[s].recordingEnd.local; // moment(this.trainingForm.sessions[s].recordingEnd.local).tz(moment.tz.guess()).toString()
              _this3.trainingForm.sessions[s].recordingEnd.timezone = typeof _this3.trainingForm.sessions[s].recordingEnd.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : _this3.trainingForm.sessions[s].recordingEnd.timezone;
            }
          }
          // console.log('training form session', this.trainingForm.sessions)
        }

        if (isCopy) {
          _this3.trainingForm.staff = [];
          _this3.reportRecipients = [];
          _this3.teams = [];
        } else {
          _this3.trainingForm.staff = d.staff;
          _this3.getTeam(token, d.id);
          _this3.reportRecipients = [];
          // this.getReportRecipients(token, d.id)
        }

        // no used in v.1.1 - expiry date
        if (isCopy) {
          _this3.trainingForm.expiry = {
            utc: '',
            timezone: _momentTimezone.default.tz.guess(),
            local: ''
          };
        } else {
          if (typeof d.expiry === 'undefined') {
            _this3.trainingForm.expiry = {
              utc: '',
              timezone: _momentTimezone.default.tz.guess(),
              local: ''
            };
          } else {
            _this3.trainingForm.expiry.utc = typeof d.expiry.utc === 'undefined' ? '' : d.expiry.utc; // d.expiry.utc.toISOString()
            _this3.trainingForm.expiry.timezone = typeof d.expiry.timezone === 'undefined' ? _momentTimezone.default.tz.guess() : d.expiry.timezone;
          }
        }
        // no used in v.1.1
        _this3.trainingForm.locale = typeof d.locale === 'undefined' ? 'en' : d.locale;
        _this3.trainingForm.imageUrl = typeof d.imageUrl === 'undefined' ? '' : d.imageUrl;
        _this3.trainingForm.skills = typeof d.skills === 'undefined' ? [] : d.skills;

        // console.log('copy ilt item', this.trainingForm)
        _this3.fullscreenloading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('training-getTrainingById-error', error);
        _this3.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
        // const msg = (typeof error.response === 'undefined') ? error : error.response.data.message
        // this.$message({ type: 'error', showClose: true, message: msg })
      });
    },
    getTeam: function getTeam(token, id) {
      var _this4 = this;
      (0, _training.getTrainingTeams)(token, id).then(function (response) {
        (0, _log.showDebugLog)('ShowTeam', response.data.rows);
        _this4.teams = response.data.rows;
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetTeam-API-error', error);
      });
    },
    getReportRecipients: function getReportRecipients(token, id) {
      var _this5 = this;
      (0, _training.getTrainingManagerReportRecipients)(token, id).then(function (response) {
        (0, _log.showDebugLog)('ShowReportRecipients', response.data.recipients);
        _this5.reportRecipients = response.data.recipients;
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetReportRecipients-API-error', error);
      });
    },
    updateParentGenInfo: function updateParentGenInfo(info) {
      // do something
      this.trainingForm.id = info.id;
      this.trainingForm.title = info.title;
      this.trainingForm.publish = info.publish;
      this.trainingForm.start = info.start;
      this.trainingForm.end = info.end;
      this.trainingForm.expiry = info.expiry;
      this.trainingForm.address = info.address;
      this.trainingForm.locale = info.locale;
      this.trainingForm.imageUrl = info.imageUrl;
      this.trainingForm.description = info.description;
      this.trainingForm.tags = info.tags;
      this.trainingForm.feedbackUrl = info.feedbackUrl;
      this.trainingForm.channel = info.channel;
      this.trainingForm.webinarName = info.webinarName;
      this.trainingForm.webinarKey = info.webinarKey;
      this.trainingForm.webinarPasscode = info.webinarPasscode;
      this.trainingForm.internal = info.internal;
      this.trainingForm.estimatedParticipantCount = info.estimatedParticipantCount;
      (0, _log.showDebugLog)('Update trainingForm', this.trainingForm);
    },
    updateParentModule: function updateParentModule(mod) {
      this.trainingForm.skills = mod;
      (0, _log.showDebugLog)('Update ParentModule', mod);
    },
    updateParentResource: function updateParentResource(res) {
      (0, _log.showDebugLog)('Parent Resource', res);
      this.trainingForm.resources = res;
    },
    updateParentSession: function updateParentSession(session) {
      // showDebugLog('Parent Session', session)
      this.trainingForm.sessions = session;
    },
    updateParentStaff: function updateParentStaff(staff) {
      (0, _log.showDebugLog)('Parent Staff', staff);
      this.trainingForm.staff = staff;
    },
    /* for el-tab */handleClick: function handleClick(tab, event) {
      // do something later
      // auto save when change tab
    },
    checkMandateFields: function checkMandateFields() {
      // console.log("form", this.trainingForm.staff)
      var producer = _.find(this.trainingForm.staff, function (s) {
        return s.role === 'producer';
      });
      // console.log("producer", producer)
      // console.log("string length", _.trim(this.trainingForm.internal.opportunityNumber).length)
      if (typeof producer === 'undefined' || _.trim(this.trainingForm.internal.opportunityNumber).length == 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};