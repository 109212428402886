var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { directives: [{ name: "loading", rawName: "v-loading" }] },
    [
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v("Coming " + _vm._s(_vm.noOfDays) + "-day event's figure"),
      ]),
      _vm._v(" "),
      _vm.figures && _vm.hikeFigures
        ? _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            _vm._l(_vm.dates, function (d) {
              return _c("el-card", { key: d, staticClass: "box-card" }, [
                _c("div", { staticClass: "text item" }, [
                  _c("span", { staticClass: "text_block right_margin w150" }, [
                    _vm._v(_vm._s(d)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "text_block right_margin w150" },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "education" } }),
                      _vm._v(
                        " Events: " + _vm._s(_vm.figures[d].totalTrainings)
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_block right_margin w150" }, [
                    _c("i", { staticClass: "el-icon-s-custom" }),
                    _vm._v(" Enrolled: " + _vm._s(_vm.figures[d].totalStaffs)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_block right_margin w250" }, [
                    _c("i", { staticClass: "el-icon-user-solid" }),
                    _vm._v(
                      " Estimated Participants: " +
                        _vm._s(_vm.figures[d].totalEstimatedParticipantCount)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "text_block right_margin w150 white_color",
                      },
                      [_vm._v(_vm._s(d))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "text_block right_margin w150" },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "hike" } }),
                        _vm._v(
                          " Hikes : " + _vm._s(_vm.hikeFigures[d].totalHikes)
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "text_block right_margin w150" },
                      [
                        _c("i", { staticClass: "el-icon-s-custom" }),
                        _vm._v(
                          " Enrolled: " + _vm._s(_vm.hikeFigures[d].totalStaffs)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "text_block right_margin w250" },
                      [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(
                          " Estimated Participants: " +
                            _vm._s(
                              _vm.hikeFigures[d].totalEstimatedParticipantCount
                            )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }