"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _progressChart = _interopRequireDefault(require("./components/progressChart"));
var _report = require("@/api/report");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserProgressChart',
  components: {
    Chart: _progressChart.default
  },
  data: function data() {
    return {
      videoId: [],
      videoTitle: [],
      universalScore: [],
      voiceRangeScore: [],
      weakLangScore: [],
      xAxis: [],
      vType: [],
      coachReview: [],
      isDataReady: false
    };
  },
  created: function created() {
    this.getUserContent();
  },
  methods: {
    test: function test() {
      console.log('Hello');
    },
    getUserInfo: function getUserInfo() {},
    getUserContent: function getUserContent() {
      var _this = this;
      var uuid = typeof this.$route.params.id === 'undefined' ? this.trainingForm.id : this.$route.params.id;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _report.userContent)(token, uuid).then(function (response) {
        var data = response.data.rows;
        var seq = 1;
        for (var i = 0; i < data.length; i++) {
          // console.log('odata',data[i])
          if (data[i].analyzeType !== 'none' && data[i].analyzeMeta.report && data[i].analyzeMeta.report.status) {
            // console.log('data',data[i])
            // console.log('status' , data[i].analyzeMeta.report.status )
            if (data[i].analyzeMeta.report.status === 'completed') {
              if (typeof data[i].analyzeMeta.report.universal.score === 'undefined') {
                _this.universalScore.push(0);
              } else {
                _this.universalScore.push(data[i].analyzeMeta.report.universal.score);
              }
              if (typeof data[i].analyzeMeta.report.voiceRange.score === 'undefined') {
                _this.voiceRangeScore.push(data[i].analyzeMeta.report.voiceRange.score);
              } else {
                _this.voiceRangeScore.push(data[i].analyzeMeta.report.voiceRange.score);
              }
              if (typeof data[i].analyzeMeta.report.weakLanguage.score === 'undefined') {
                _this.weakLangScore.push(0);
              } else {
                _this.weakLangScore.push(data[i].analyzeMeta.report.weakLanguage.score);
              }
              if (typeof data[i].coachReview === 'undefined') {
                _this.coachReview.push('');
              } else {
                _this.coachReview.push(data[i].coachReview);
              }
              _this.xAxis.push((0, _momentTimezone.default)(data[i].createdAt).format('YYYY-MMM-DD HH:mmZ'));
              _this.videoId.push(data[i].id);
              _this.videoTitle.push(data[i].title);
              _this.vType.push(data[i].analyzeType);
              seq += 1;
            }
          }
        }
        _this.isDataReady = true;
        // console.log('uscore', this.universalScore)
        // console.log('vscore', this.voiceRangeScore)
        // console.log('wscore',this.weakLangScore)
        // console.log('xAxis',this.xAxis)
      }).catch(function (error) {
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};