"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  route: {
    dashboard: 'Dashboard',
    Welcome: 'Welcome',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    componentIndex: 'Introduction',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'CountTo',
    componentMixin: 'Mixin',
    backToTop: 'BackToTop',
    dragDialog: 'Drag Dialog',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    Table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    treeTable: 'Tree Table',
    customTreeTable: 'Custom TreeTable',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    // OTR
    course: 'Trainings',
    createCourse: 'Create Training',
    editCourse: 'Edit Training',
    courseList: 'Search Training',
    courseTemplateList: 'Template List',
    editCourseRecipent: 'Edit Report Recipient',
    activity: 'Activities',
    createActivity: 'Create Activity',
    editActivity: 'Edit Activity',
    activityList: 'Search Activity',
    exportActivity: 'Export',
    user: 'User Accounts',
    userCreate: 'Create User',
    userEdit: 'Edit User',
    userList: 'Search User',
    coach: 'Coaches',
    coachEdit: 'Coach Edit',
    myProfile: 'My Profile',
    createCoach: 'Create Coach',
    resource: 'Resources',
    createResource: 'Create Resource',
    editResource: 'Edit Resource',
    resourceList: 'Search Resource',
    viewResource: 'View Resource',
    uploadResource: 'Upload Resource',
    notification: 'Notification',
    createNotification: 'Create Notification',
    editNotification: 'Edit Notification',
    notificationList: 'Notification List',
    settings: 'Settings',
    assessment: 'Assessments',
    assessmentList: 'Search Assessment',
    assessmentCreate: 'Create Assessment',
    assessmentEdit: 'Edit Assessment',
    activationCode: 'Activation Codes',
    activationCodeList: 'Search Code',
    activationCodeCreate: 'Generate Code',
    report: 'Report',
    reportIndex: 'Report',
    badge: 'Badges',
    badgeList: 'Search Badge',
    badgeCreate: 'Create Badge',
    badgeEdit: 'Edit Badge',
    publicfile: 'Public Files',
    publicFileList: 'Public Files',
    publicFileUpload: 'Upload File',
    uploadParticipant: 'Upload Participant',
    feedback: 'Feedback',
    reenroll: 'Re-Enroll',
    setting: 'Setting',
    eventlog: 'Event Log'
  },
  otrbutton: {
    add: 'Add',
    addSave: 'Add and Save',
    delete: 'Delete',
    save: 'Save',
    view: 'View',
    cancel: 'Cancel',
    update: 'Update',
    edit: 'Edit',
    saveAsDraft: 'Save as Draft',
    saveAsTemplate: 'Save as Template',
    search: 'Search',
    send: 'Send',
    upload: 'Upload',
    create: 'Create',
    back: 'Back',
    export: 'Export',
    reload: 'Reload',
    confirm: 'Confirm'
  },
  otrnavbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    screenfull: 'screenfull',
    theme: 'theme',
    myprofile: 'My Profile',
    changePassword: 'Change Password',
    refreshToken: 'Refresh Session',
    sessionRemindText: 'Your working session will refresh after',
    tokenIsRefresh: 'Your token is refreshed'
  },
  otr: {
    changePassword: 'Change Password',
    changePasswordFormContent: 'You can use the new password in next login.',
    currentPasswordPlaceHolder: 'Please input current Password',
    newPasswordPlaceHolder: 'Please input new Password',
    errorMsgCurrentPasswordEmpty: 'Current password cannot be empty',
    errorMsgNewPasswordEmpty: 'New password cannot be empty',
    errorMsgAccountDisable: 'Sorry, your account is disabled',
    errorMsgLoginFail: 'Oops, Login fail',
    hiddenPassword: 'Hidden password',
    showPassword: 'Show password',
    resetPwdTitle: 'FORGOT YOU PASSWORD?',
    resetPwdHint: 'Enter your registered email and click Send. A message will be sent to that address containing a 6 digit-verification code. For security the code can only be used for one hour.',
    resetPwdHint1: 'Please use a more secure password. That is the password must be minimum 8 characters and at least one lowercase letter and least one number.',
    resetPwdEmail: 'Email',
    resetPwdVerifyCode: '6-digit Verification Code',
    resetPwdPassword: 'Password',
    resetPwdConfirmPassword: 'Confirm Password',
    resetPwdErrMsgInvalidEmail: 'Email format is incorrect',
    resetPwdErrMsgSixDigit: 'Please enter 6-digits verification code',
    resetPwdErrInvalidPwd: 'Please enter valid password',
    resetPwdErrPwdNotMatch: 'Password and confirmed Password is not matched',
    resetPwdMsgResetOK: 'Password was reset',
    resetPwdMsgResetFail: 'Reset password is fail',
    msgEmailIsSentTo: 'The email is sent to ',
    updatedAt: 'Updated At',
    userGuide: 'User Guide',
    back: 'Back',
    lastModBy: 'Last Modified By',
    createdBy: 'Created By',
    guideRegexStartwithString: '^ Start with string',
    guideRegexStartwithString_usage: 'Usage: ^communication',
    guideRegexStartwithString_example: 'Example: Get records that start with "communication"',
    guideRegexEndwithString: '$ End with string',
    guideRegexEndwithString_usage: 'Usage: title$',
    guideRegexEndwithString_example: 'Example: Get records that end with "title"',
    guideRegexAnyChar: '. Any character',
    guideRegexAnyChar_usage: 'Usage: b.r',
    guideRegexAnyChar_example: 'Example: Get records that match "bar" , "ber" ....',
    guideRegexZeroMore: '* Zero or More',
    guideRegexZeroMore_usage: 'Usage: OTR .*Training',
    guideRegexZeroMore_example: 'Example: Get records that match "OTR Training" or "OTR New Training" ..... ',
    guideRegexOneMore: '+ One or more',
    guideRegexOneMore_usage: 'Usage: OTR .+Training',
    guideRegexOneMore_example: 'Example: Get records that match "OTR New Training" ..... '
  },
  otrWelcome: {
    welcomeMsg: 'Welcome to LifeHikes Web Admin Portal.',
    totalTraining: 'Total no. of Published Training',
    totalParticipant: 'Total no. of Active Participants',
    totalCoach: 'Total no. of Coaches',
    topTenTraining: 'Top 10 Latest Published Trainings',
    releaseHistory: 'Latest 10 Release History',
    address: 'Address',
    localTime: 'Local Time',
    title: 'Title',
    description: 'Description',
    date: 'Date (HKT)',
    version: 'Version'
  },
  otrMyProfile: {
    name: 'Name',
    familyName: 'Surname',
    middleName: 'Middle Name',
    givenName: 'Given Name',
    select: 'Select',
    displayName: 'Display Name',
    gender: 'Gender',
    orgName: 'Company Name',
    jobTitle: 'Job Title',
    region: 'Region',
    country: 'Country',
    phone: 'Phone',
    bio: 'Bio',
    errMsgCannotGetInfo: 'Cannot get my profile info',
    msgSaveSuccess: 'Your profile is updated successfully',
    save: 'Save'
  },
  otrResource: {
    id: 'ID',
    heading: 'Resources',
    type: 'Type',
    subtype: 'Sub-Type',
    title: 'Title',
    free: 'Free',
    caption: 'Caption',
    description: 'Description',
    encoding: 'Encoding',
    file: 'File',
    createdate: 'Create Date',
    updatedate: 'Update Date',
    actions: 'Action',
    keywordSearch: 'Keyword Search: ID / Title / Description / Tag',
    thumbnailUrl: 'ThumbnailUrl',
    tags: 'Tags',
    uploadedFileName: 'Uploaded File Name',
    errMsgUploadFail: 'Upload file fail !',
    thumbnail: 'Thumbnail Image',
    successMsg: 'Resource is created successfully',
    updateOKMsg: 'Resource is updated successfully',
    s3UploadFail: 'File Upload to S3 failed',
    deleteResource: 'This will permanently delete the Badge',
    continue: 'Continue',
    ok: 'OK',
    cancel: 'Cancel',
    delete: 'Resource is deleted successfully',
    cancelDelete: 'Delete',
    errNoFile: 'No file',
    more: 'more'
  },
  otrActivity: {
    id: 'ID',
    title: 'Title',
    skill: 'Skill',
    tier: 'Tier',
    type: 'Type',
    image: 'Activity Image',
    shortDesc: 'Short Description',
    description: 'Description',
    steps: 'Steps',
    stepsValue: 'Steps Value',
    timeInMin: 'Time in minutes',
    status: 'Publish Status',
    note: 'Note',
    tags: 'Tags',
    actions: 'Actions',
    genInfo: 'Activity General Information',
    stepcontent: 'Step\'s Content',
    resource: 'Resource',
    theme: 'Theme',
    privacy: 'Privacy',
    badge: 'Badge',
    unlock: 'Unlock next tier',
    videoUrl: 'Video Url',
    attachmentType: 'Attachment Type',
    attachment: 'Attachment',
    keysearchTitleShortDesc: 'Keyword search: Title/ Description/ Short Description',
    saveAsDraft: 'Save as Draft',
    saveAsReview: 'Save as Review',
    saveAsApproved: 'Save as Approved',
    saveAsPublished: 'Save as Published',
    saveAsArchived: 'Save as Archived',
    bgActive: 'Background URL (Active)',
    bgInactive: 'Background URL (In-Active)',
    feedDescription: 'Feed Description',
    deleteComplete: 'Activity is deleted',
    deleteCancel: 'Delete canceled',
    uploadFileFail: 'Upload File Fail!',
    safetySize: 'Image safety size: 700 x 200',
    recordRange: 'Record Range',
    exportActivityToExcel: 'Export Activity to Excel Format',
    total: 'total',
    records: 'record(s)',
    export: 'Export',
    noActivityRecord: 'No Activity Record',
    more: 'more'
  },
  otrAssessment: {
    keywordSearchPlaceHolder: 'Keyword Search of Title and Description',
    id: 'ID',
    title: 'Title',
    byCoach: 'By Coach',
    type: 'Type',
    description: 'Description',
    question: 'Question',
    questions: 'Questions',
    genInfo: 'Assesment General Information',
    questionType: 'Question Type',
    questionText: 'Question Text',
    hint: 'Hints',
    displayFormat: 'Answer Display Format',
    multiline: 'Multiline',
    ansChoices: 'Answer\'s Choice',
    leftLabel: 'Left Label',
    rightLabel: 'Right Label',
    skill: 'Skill',
    score: 'Score',
    choicehint: 'Please type the value and press enter',
    hintShowLabel: 'Show the label on the scroll bar',
    errMsg1: 'Title and Type cannot be empty',
    publishStatus: 'Publish Status',
    saveAsDraft: 'Save as Draft',
    saveAsReview: 'Save as Review',
    saveAsApproved: 'Save as Approved',
    saveAsPublished: 'Save as Published',
    saveAsArchived: 'Save as Archived',
    assessment: 'Assessment ',
    isCreated: ' is created.',
    isUpdated: ' is updated.',
    permanentDelete: 'This will permanent delete assessment',
    continue: 'Continue',
    ok: 'OK',
    cancel: 'Cancel',
    deleted: 'Assessment is deleted',
    deletedCancel: 'Delete canceled',
    status: 'Status',
    dynamicText: 'Use Dynamic Label',
    more: 'more'
  },
  otrBadge: {
    id: 'ID',
    title: 'Title',
    description: 'Description',
    iconUrl: 'Icon Url',
    thumbnailUrl: 'Thumbnail Url',
    thumbnail: 'Thumbnail',
    status: 'Publish Status',
    requirement: 'Requirement',
    stepsValue: 'Steps Value',
    titleSearchPlaceholder: 'Keyword search: Title/Description',
    actions: 'Actions',
    saveAsDraft: 'Save as Draft',
    saveAsPreview: 'Save as Preview',
    saveAsPublish: 'Save as Published',
    saveAsArchived: 'Save as Archived',
    genInfo: 'Badge Information',
    badge: 'Badge',
    permanentDelete: 'This will permanently delete the Badge',
    continue: 'Continue',
    ok: 'OK',
    cancel: 'Cancel',
    deleted: 'Badge is deleted',
    deletedCancel: 'Delete canceled',
    failUpload: 'Upload File Fail!'
  },
  otrTraining: {
    id: 'ID',
    title: 'Title',
    internalTitle: 'Internal Title',
    sfOPNum: 'Salesforce Opportunity Number',
    sfOPNum1: 'Salesforce Opportunity Number (5 digits)',
    clientName: 'Client Name',
    description: 'Description',
    start: 'UTC Start Time',
    end: 'UTC End Time',
    expiry: 'UTC Expiry Time',
    status: 'Status',
    actions: 'Actions',
    genInfo: 'General Information',
    skills: 'Skills',
    modules: 'Modules',
    moduleRule: 'Module Rules',
    assessment: 'Assessment',
    coachAssessment: 'Coach Assessment',
    resources: 'Resources',
    staff: 'Staff',
    team: 'Team',
    activationCode: 'Activation Code',
    select: 'Select',
    selectStartDT: 'Select start date and time',
    selectEndDT: 'Select end date and time',
    selectExpiryDT: 'Select expiry data and time',
    localTimeZone: 'Local Time Zone',
    localTime: 'Local Time (Event)',
    localStartTime: 'Local Start Time',
    localEndTime: 'Local End Time',
    localExpiryTime: 'Local Expiry Time',
    location: 'Event Location',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    region: 'Region/State/Province',
    postalcode: 'Postal Code',
    country: 'Country',
    trainingImg: 'Event Image',
    selectImg: 'Please provide the image URL',
    tags: 'Tags',
    publishStatus: 'Publish Status',
    saveAsDraft: 'Save as Draft',
    saveAsPreview: 'Save as Preview',
    saveAsPublish: 'Save as Published',
    saveAsArchived: 'Save as Archived',
    pretraining: 'Pre-Training Module',
    createModuleAt: 'Create Module at Position',
    tierRuleUnlock: 'No Unlock Setting',
    tierRuleCoach: 'Cannot only contain coach assessment in a tier',
    skill: 'Skill',
    t1: 'Tier 1',
    t2: 'Tier 2',
    t3: 'Tier 3',
    t4: 'Tier 4',
    t5: 'Tier 5',
    t6: 'Tier 6',
    t7: 'Tier 7',
    t8: 'Tier 8',
    tmt: 'Two-minutes Talks',
    selectSkill: 'Please select skill',
    pleaseIconUrl: 'Please enter the icon URL',
    selectType: 'Please select type',
    iconUrl: 'Icon URL',
    modType: 'Module Type',
    ruleOf: 'Locking Rules of',
    defaultLock: 'Default is locked',
    unlockAll: 'System will unlock all modules and all activities in every tier when particiant earned the badge from this module (This will overide the rule No.2)',
    unlockskill: 'Unlock following modules after particiant earned the badge for this module',
    refresh: 'Refresh',
    badgeEarn: 'Please select the badge for this module',
    errorMessage1: 'Please enter the title',
    errorMessage2: 'Start Time , End Time or Expiry Time cannot be empty',
    errorMessage3: 'Timezone cannot be empty',
    titleSearchPlaceholder: 'Keyword search: Title / Description',
    participantid: 'ID',
    name: 'Name',
    orgName: 'Organization',
    jobTitle: 'Job Title',
    reportRecipient: 'Report Recipients',
    email: 'Email',
    userId: 'User ID',
    trainingRes: 'Training Resources',
    selectedRes: 'Selected Resource ID(s)',
    code: 'Code',
    codeGenOk: 'Code is generated',
    codeGenFail: 'Code generation failed',
    noOfCode: 'Please input no. of code',
    genCode: 'Generate Activation Code',
    ok: 'OK',
    cancel: 'Cancel',
    invalidValue: 'Invalid value',
    failGenerate: 'Fail to generate',
    cancelGen: 'Cancel generate code',
    supportTeam: 'Support Team',
    coach: 'Coach',
    userName: 'User Name',
    training: 'Training',
    isCreated: 'is created.',
    isUpdated: 'is updated',
    cannotGetTraining: 'Cannot get the training, please contact to system administrator',
    activityLabrary: 'Activity Library',
    type: 'Type',
    unlockTier: 'Un-Lock Next Tier',
    trainingId: 'Training ID',
    finalReportSendDate: 'Final Report Send Date (UTC)',
    week1ReportSendDate: 'Week 1 Report Send Date (UTC)',
    recipients: 'Recipients',
    addRecipients: 'Add receipient',
    saveRecipients: 'Save receipient list',
    msgSaveRecipientList: 'Report recipient list is saved',
    permanentDelete: 'This will permanently delete the',
    continue: 'Continue',
    cancelDelete: 'Cancel Delete',
    completeDelete: 'Training is deleted',
    reuseTimes: 'Reuse Times',
    generate: 'Generate',
    remainQuota: 'Remain quota',
    uploadParticipant: 'Upload Participant',
    upload: 'Upload',
    participantList: 'Participant List',
    template: 'Download Template',
    msgEmptyRecord: 'No Participant Record',
    tipUpload: 'Upload',
    tipUploadStep1: 'Step 1',
    tipUploadStep1Content: 'Download the template file',
    tipUploadStep2: 'Step 2',
    tipUploadStep2Content: 'Upload the template file with email content',
    tipUploadStep3: 'Step 3',
    tipUploadStep3Content: 'Click Upload Button',
    tipResult: 'Notification Email',
    tipResult1: 'System will send out 2 noticification emails to participant if upload successful',
    tipEmail1: 'Registration acknowledgement email (with temperary password)',
    tipEmail2: 'Training enrollment acknowledgement email',
    staffLibrary: 'Staff Library',
    staffTip: 'Active OTR coarch and digital producer.',
    tip: 'Tips',
    button: 'Button(s)',
    editButton: 'Edit or view',
    addRecipientButton: 'Manage report recipient',
    copyButton: 'Copy as new training record',
    deleteButton: 'Delete record (only root)',
    createButton: 'Create new training course record',
    feedbackButton: 'Get Feedback form content',
    closeButton: 'Close',
    keywordSearch: 'Keyword Search',
    givenName: 'Given Name',
    familyName: 'Surname',
    result: 'Result',
    noOfPSuccess: 'No. of participant successfully enrolled',
    noOfPFail: 'No. of participant fail to enrolled',
    trainingFeedback: 'Training feedback of',
    noFeedBackRecord: 'No Feedback Record',
    getOrReload: 'Get / Reload',
    imageLimit: 'Image size should not be exceeded 1M',
    refreshParticipant: 'Refresh Participant List',
    addEmailGroup: 'Add Email Group',
    removeEmailGroup: 'Remove Email Group',
    setExpiryDate: 'Set as Training Expiry Date',
    setStartDate: 'Set as Training Start Date',
    trainingParticipant: 'Training Participants',
    producer: 'Producer',
    myProducedTraining: 'My produced Training',
    unenrollment: 'Un-Enrollment',
    producedby: 'Produced By',
    systemRole: 'System Role',
    regexpNote: 'How to use regular expressions',
    more: 'more',
    inputRecipientName: 'Please input recipient\'s name',
    inputEmail: 'Please input recipient\'s email',
    onlyFinalManager: 'Only Final Manager Report is sent when both report send dates are same.'
  },
  otrUser: {
    keywordSearch: 'Keyword search: Given Name/Surname/Display Name/Email/Org Name/Job Title',
    status: 'Status',
    id: 'ID',
    givenName: 'Given Name',
    middleName: 'Middle Name',
    familyName: 'Surname',
    gender: 'Gender',
    displayName: 'Display Name',
    email: 'Email',
    region: 'Region',
    orgName: 'Organization',
    jobTitle: 'Job Title',
    profilePic: 'Profile Picture',
    bio: 'Bio',
    phone: 'Phone',
    role: 'Role',
    badge: 'Badge',
    msgSaveOK: 'User is updated successfully',
    error: 'Error',
    msgUserIs: 'User is ',
    msgDisableOK: 'User is disabled',
    msgActiveOK: 'User is actived',
    coachName: 'Coach Name',
    isDisableUser: 'This user account will be disabled. Continue?',
    isEnableUser: 'This  user account will be enabled. Continue?',
    ok: 'OK',
    cancel: 'Cancel',
    warning: 'Warning',
    unconfirmedMeaining: 'User account is registered, but the account is not verified.',
    confirmedMeaning: 'User account is verified, but the agreement term has not been confirmed.',
    activeMeaning: 'All agreements are confirmed.',
    disableMeaning: 'User account is disabled.',
    editUserProfile: 'Edit user profile',
    enableUser: 'Enable User Account',
    disableUser: 'Disable User Account',
    buttons: 'Button(s)',
    userStatus: 'User Status',
    promoteCoach: 'How to promote the normal user to a coach',
    stepSelectCoach: 'Step 1: Select <b>OTR Coach</b> in user profile and save it.',
    editCoachProfile: 'Edit Coach Profile',
    lastLoginDate: 'Last Login Date',
    reenrollTraining: 'User Enrollment Status',
    confirmRemoveParticipant: 'Are you sure remove this user from the training',
    cancelRemove: 'Remove User from Training is cancel',
    resumeTraining: 'Resume Training Status',
    removeUserFromTraining: 'Remove the user but keep their progress and activity in the feed',
    training: 'Training',
    enrollStatus: 'Enroll Status',
    removeAndDeleteUserData: 'Remove the user and delete all of their progress and feed content in this program.',
    myTraining: 'My Training'
  },
  otrCode: {
    training: 'Training',
    status: 'Status',
    id: 'ID',
    code: 'Code',
    userLimit: 'Reuse Times'
  },
  otrPFile: {
    title: 'Public File',
    refresh: 'Refresh',
    link: 'Link',
    image: 'Image',
    lastModified: 'Last Modified',
    size: 'Size (bytes)',
    action: 'Action',
    copiedClipboad: 'Copy Link to Clipboard',
    loadMore: 'Load More ...',
    copied: 'Copied to clipboard',
    failedCopied: 'Fail to copy',
    uploadFile: 'Upload Public File',
    confirmUpload: 'Confirm',
    maxSize: 'Maximum file size 1M',
    upload: 'Upload',
    failUpload: 'Fail Upload'
  },
  login: {
    title: 'LifeHikes Admin Portal',
    logIn: 'Log in',
    username: 'Username',
    password: 'Password',
    any: 'any',
    forgotpassword: 'Forgot password?',
    resetpassword: 'Reset Password',
    resetpasswordHint: 'Enter your registered email and click Send. A message will be sent to that address containing a link to reset your password. For security reasons the link can only be used for one hour.',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    roles: 'Your roles',
    switchRoles: 'Switch roles'
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.',
    dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    code: 'Code',
    codeStatus: 'Code Status',
    sendInvitationCode: 'Send Invitation Code',
    send: 'Send',
    addParticipant: 'Add Participant',
    firstName: 'Given name',
    middleName: 'Middle Name',
    lastName: 'Surname',
    company: 'Company',
    email: 'Email',
    role: 'Role',
    selectFromLibrary: 'Select from Library...'
  },
  // participant: {
  //   firstName: 'Given name',
  //   middleName: 'Middle Name',
  //   lastName: 'Surname',
  //   company: 'Company',
  //   title: 'Title',
  //   email: 'Email',
  //   role: 'Role',
  //   code: 'Code'
  // },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name(default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name(default file)'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  }
};