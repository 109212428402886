"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SurveyMultipleTextbox',
  props: {
    value: Array
  },
  methods: {
    handleAdd: function handleAdd() {
      this.value.push({
        value: ''
      });
    },
    handleRemove: function handleRemove(index) {
      this.value.splice(index, 1);
    },
    handleChange: function handleChange(item) {
      item.value = item.value.trim();
    }
  }
};