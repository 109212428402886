"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/utils/log");
//
//
//
//
//
//
var _default = exports.default = {
  name: "App",
  created: function created() {
    // var userAgent = navigator.userAgent;
    // var isChrome = userAgent.indexOf("Chrome") > -1;
    // var isEdge = userAgent.indexOf("Edge") > -1;

    // if (isEdge === true) {
    //   isChrome = false;
    // }
    // if (isChrome === false) {
    //   displayPopupMessage(
    //     "The Admin portal is optimised on Chrome. Please use Chrome.",
    //     "info"
    //   );
    // }
  }
};