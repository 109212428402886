var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("General")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [_vm._v("ID:")]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", [_c("b", [_vm._v(_vm._s(_vm.hike.id))])]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Category"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Category" },
                      model: {
                        value: _vm.hike.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.hike, "category", $$v)
                        },
                        expression: "hike.category",
                      },
                    },
                    _vm._l(_vm.HikeCategory, function (t) {
                      return _c("el-option", {
                        key: t.key,
                        attrs: { label: t.description, value: t.val },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Rubric ID:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    staticStyle: { width: "190px" },
                    attrs: { placeholder: "Rubric Id" },
                    model: {
                      value: _vm.hike.rubricId,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "rubricId", $$v)
                      },
                      expression: "hike.rubricId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("PID:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    staticStyle: { width: "190px" },
                    attrs: { placeholder: "e.g. TOP-VILTYURH-JKMVB-BGHGI" },
                    model: {
                      value: _vm.hike.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "pid", $$v)
                      },
                      expression: "hike.pid",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Channel:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Channel" },
                      model: {
                        value: _vm.hike.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.hike, "channel", $$v)
                        },
                        expression: "hike.channel",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "ilt",
                        attrs: { label: "ilt", value: "ilt" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        key: "vilt",
                        attrs: { label: "vilt", value: "vilt" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Title"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Title" },
                    model: {
                      value: _vm.hike.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "title", $$v)
                      },
                      expression: "hike.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Internal Title:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Internal Title" },
                    model: {
                      value: _vm.hike.tempInternalTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "tempInternalTitle", $$v)
                      },
                      expression: "hike.tempInternalTitle",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Description:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("vue-editor", {
                    attrs: { editorToolbar: _vm.customToolbar },
                    model: {
                      value: _vm.hike.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "description", $$v)
                      },
                      expression: "hike.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Estimated Participants:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, max: 10000 },
                    model: {
                      value: _vm.hike.estimatedParticipantCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "estimatedParticipantCount", $$v)
                      },
                      expression: "hike.estimatedParticipantCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Salesforce")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("SF Opportunity Number:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "SF Opportunity Number" },
                    model: {
                      value: _vm.hike.internal.opportunityNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike.internal, "opportunityNumber", $$v)
                      },
                      expression: "hike.internal.opportunityNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Client Name:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Client Name" },
                    model: {
                      value: _vm.hike.internal.clientName,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike.internal, "clientName", $$v)
                      },
                      expression: "hike.internal.clientName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Event Time")]),
          _vm._v(" "),
          _c("event-time", {
            attrs: {
              start: _vm.hike.start,
              end: _vm.hike.end,
              "is-required": true,
            },
            on: {
              "update:start": function ($event) {
                return _vm.$set(_vm.hike, "start", $event)
              },
              "update:end": function ($event) {
                return _vm.$set(_vm.hike, "end", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("fieldset", { staticClass: "otrfieldset" }, [
        _c("legend", [_vm._v("Venue (Location)")]),
        _vm._v(" "),
        _vm.hike.channel === "ilt"
          ? _c(
              "div",
              [
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _vm._v("Address 1 :"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Address1" },
                          model: {
                            value: _vm.hike.address.address1,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike.address, "address1", $$v)
                            },
                            expression: "hike.address.address1",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _vm._v("Address 2 :"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Address2" },
                          model: {
                            value: _vm.hike.address.address2,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike.address, "address2", $$v)
                            },
                            expression: "hike.address.address2",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [_vm._v("City :")]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "City" },
                          model: {
                            value: _vm.hike.address.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike.address, "city", $$v)
                            },
                            expression: "hike.address.city",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [_vm._v("Region :")]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Region" },
                          model: {
                            value: _vm.hike.address.region,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike.address, "region", $$v)
                            },
                            expression: "hike.address.region",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [_vm._v("Country :")]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            attrs: { filterable: "", placeholder: "select" },
                            model: {
                              value: _vm.hike.address.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.hike.address, "country", $$v)
                              },
                              expression: "hike.address.country",
                            },
                          },
                          _vm._l(_vm.countryList, function (item) {
                            return _c("el-option", {
                              key: item.countryCode,
                              attrs: {
                                label: item.country,
                                value: item.countryCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _vm._v("Webinar Software:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "Zoom / WebEx / Hangout ...etc",
                          },
                          model: {
                            value: _vm.hike.webinarName,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike, "webinarName", $$v)
                            },
                            expression: "hike.webinarName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _vm._v("Webinar ID:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "Webinar ID (e.g 123 456 789)",
                          },
                          model: {
                            value: _vm.hike.webinarKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike, "webinarKey", $$v)
                            },
                            expression: "hike.webinarKey",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _vm._v("Webinar Passcode:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Webinar Passcode" },
                          model: {
                            value: _vm.hike.webinarPasscode,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike, "webinarPasscode", $$v)
                            },
                            expression: "hike.webinarPasscode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _vm._v("Zoom Invite link:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "e.g.https://lifehikes.zoom.us/j/3315262859",
                          },
                          model: {
                            value: _vm.hike.zoomUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.hike, "zoomUrl", $$v)
                            },
                            expression: "hike.zoomUrl",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Feedback Survey")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("In-app Feedback:"),
                _c(
                  "span",
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          title: "In-app Feedback",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("p", { staticStyle: { "word-break": "normal" } }, [
                          _vm._v(
                            "Turning off in-app feedback hides the post-module survey and the end of Summit survey from the Hike UI."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("svg-icon", {
                          attrs: { slot: "reference", "icon-class": "faq" },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.hike.isFeedbackEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "isFeedbackEnabled", $$v)
                      },
                      expression: "hike.isFeedbackEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("Noscript"),
                  _vm._v("Non-App Feedback:"),
                  _c(
                    "span",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            title: "Non-App Feedback",
                            trigger: "hover",
                          },
                        },
                        [
                          _c("p", { staticStyle: { "word-break": "normal" } }, [
                            _vm._v(
                              "Turning on non-app feedback generates a Survey link which allows participant to fill in feedback in website."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("svg-icon", {
                            attrs: { slot: "reference", "icon-class": "faq" },
                            slot: "reference",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.hike.isPostProgramFeedbackEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "isPostProgramFeedbackEnabled", $$v)
                      },
                      expression: "hike.isPostProgramFeedbackEnabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hike.postProgramFeedbackSurveyLandingPageUrl
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v("Survey Landing Page:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "" },
                        model: {
                          value:
                            _vm.hike.postProgramFeedbackSurveyLandingPageUrl,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.hike,
                              "postProgramFeedbackSurveyLandingPageUrl",
                              $$v
                            )
                          },
                          expression:
                            "hike.postProgramFeedbackSurveyLandingPageUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click:
                              _vm.copyPostProgramFeedbackSurveyLandingPageLink,
                          },
                        },
                        [_vm._v("Copy Link")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: _vm.openPostProgramFeedbackSurveyLandingPage,
                          },
                        },
                        [_vm._v("Open")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.hike.postProgramFeedbackSurveyLandingPageUrl
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v("Survey Landing Page:"),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _vm._v(
                      'After "Non-App Feedback" is enabled and saved, the page link will be shown.'
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Others")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("Feedback Form Link:"),
                _c(
                  "span",
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          title: "Feedback Form Link",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("p", { staticStyle: { "word-break": "normal" } }, [
                          _vm._v(
                            "This is an old field that is no longer used. Please leave this field blank."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("svg-icon", {
                          attrs: { slot: "reference", "icon-class": "faq" },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Feedback form link" },
                    model: {
                      value: _vm.hike.feedbackUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "feedbackUrl", $$v)
                      },
                      expression: "hike.feedbackUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("Session Zero Link:"),
                _c(
                  "span",
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          title: "Session Zero Link",
                          width: "500",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("p", { staticStyle: { "word-break": "normal" } }, [
                          _vm._v(
                            "This field is available to link to a webpage or blog post that describes Session Zero. If a weblink is not available in your documentation then leave the field blank."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("svg-icon", {
                          attrs: { slot: "reference", "icon-class": "faq" },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "e.g.https://lifehikes.com/" },
                    model: {
                      value: _vm.hike.orientationUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "orientationUrl", $$v)
                      },
                      expression: "hike.orientationUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("Trail Guide Link:"),
                _c(
                  "span",
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          title: "Trail Guide Link",
                          width: "500",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("p", { staticStyle: { "word-break": "normal" } }, [
                          _vm._v(
                            "Trail Guides will be available as a resource within the Resource Center Webpage for each of the core products. Paste the link to the Resource Center for this product. The participant will receive the link as a part of their automated email communications and will find their Trail Guide and other program materials in a single webpage."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticStyle: { "word-break": "normal" } }, [
                          _vm._v(
                            "If the Resource Center Webpage is not available at the time of your Admin Portal set up, please use the participant-facing weblink from the Released Product Engine for this field."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("svg-icon", {
                          attrs: { slot: "reference", "icon-class": "faq" },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "e.g.https://lifehikes.com/" },
                    model: {
                      value: _vm.hike.trailGuideUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "trailGuideUrl", $$v)
                      },
                      expression: "hike.trailGuideUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v("Loop Schedule View Link:"),
                _c(
                  "span",
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          title: "Loop Schedule View Link",
                          width: "500",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("p", { staticStyle: { "word-break": "normal" } }, [
                          _vm._v(
                            "This field is available to link to a webpage or blog post that describes the Loop Schedule View in the LifeHikes Mobile App. If a weblink is not available in your documentation then leave the field blank."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("svg-icon", {
                          attrs: { slot: "reference", "icon-class": "faq" },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "e.g.https://lifehikes.com/" },
                    model: {
                      value: _vm.hike.loopScheduleViewUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.hike, "loopScheduleViewUrl", $$v)
                      },
                      expression: "hike.loopScheduleViewUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Tags:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _vm._l(_vm.hike.tags, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        staticStyle: { "margin-left": "5px" },
                        attrs: { "disable-transitions": false, closable: "" },
                        on: {
                          close: function ($event) {
                            return _vm.handleClose(tag)
                          },
                        },
                      },
                      [_vm._v("\n           " + _vm._s(tag) + "\n         ")]
                    )
                  }),
                  _vm._v(" "),
                  _vm.tagInputVisible
                    ? _c("el-input", {
                        ref: "saveTagInput",
                        staticClass: "input-new-tag",
                        staticStyle: { width: "200px" },
                        on: { blur: _vm.handleTagInputConfirm },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleTagInputConfirm($event)
                          },
                        },
                        model: {
                          value: _vm.tagInputValue,
                          callback: function ($$v) {
                            _vm.tagInputValue = $$v
                          },
                          expression: "tagInputValue",
                        },
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-tag",
                          on: { click: _vm.showTagInput },
                        },
                        [_vm._v("+ New Tag")]
                      ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Rating Method:"),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v(_vm._s(_vm.hike.ratingMethod)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "otr-form__label", attrs: { span: 3 } }, [
            _vm._v(_vm._s(_vm.$t("otrTraining.publishStatus")) + " :"),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 21 } }, [
            _vm._v(_vm._s(_vm.hike.publish)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hike.createdAt
        ? _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "otr-form__label", attrs: { span: 3 } },
                [_vm._v("Created At :")]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _vm._v(_vm._s(_vm.showUTCDate(_vm.hike.createdAt)) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hike.updatedAt
        ? _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "otr-form__label", attrs: { span: 3 } },
                [_vm._v("Updated At :")]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _vm._v(_vm._s(_vm.showUTCDate(_vm.hike.updatedAt))),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }