var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.collapseActiveNames,
            callback: function ($$v) {
              _vm.collapseActiveNames = $$v
            },
            expression: "collapseActiveNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "Survey Setting", name: "setting" } },
            _vm._l(_vm.surveyData, function (survey) {
              return _c(
                "el-row",
                {
                  key: survey.key,
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { gutter: 10 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticStyle: { "line-height": "36px" } }, [
                      _vm._v(_vm._s(survey.label)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.surveyForm[survey.key],
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticStyle: { display: "flex" }, attrs: { span: 14 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openSurveyDialog(survey.key)
                            },
                          },
                        },
                        [_vm._v("Select")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.surveyForm[survey.key],
                            type: "primary",
                            loading: survey.downloadLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.exportSurveyResult(survey)
                            },
                          },
                        },
                        [_vm._v("Export")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.clearSurvey(survey.key)
                            },
                          },
                        },
                        [_vm._v("Clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm._l(["benchmark", "summit"], function (type) {
            return _c(
              "el-collapse-item",
              {
                key: type,
                attrs: {
                  title: _vm.capitalize(type) + " Survey Effective Period",
                  name: type,
                },
              },
              [
                _c(
                  "p",
                  { staticStyle: { color: "#1890ff", margin: "8px 0 28px" } },
                  [
                    _vm._v("\n        " + _vm._s(_vm.capitalize(type))),
                    _c("br"),
                    _vm._v(
                      "\n        Start Time: " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.surveyForm[
                              type === "benchmark" ? "start" : "summitStart"
                            ].utc,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        ) +
                        " (UTC)"
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        End Time: " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.surveyForm[
                              type === "benchmark" ? "end" : "summitEnd"
                            ].utc,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        ) +
                        " (UTC)\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("event-time", {
                  attrs: {
                    start:
                      _vm.surveyForm[
                        type === "benchmark" ? "start" : "summitStart"
                      ],
                    end: _vm.surveyForm[
                      type === "benchmark" ? "end" : "summitEnd"
                    ],
                    "is-required": true,
                  },
                  on: {
                    "update:start": function ($event) {
                      return _vm.$set(
                        _vm.surveyForm,
                        type === "benchmark" ? "start" : "summitStart",
                        $event
                      )
                    },
                    "update:end": function ($event) {
                      return _vm.$set(
                        _vm.surveyForm,
                        type === "benchmark" ? "end" : "summitEnd",
                        $event
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.copyEndTime(type)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "Copy start date of " +
                        _vm._s(type) +
                        " stage to end date"
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._l(["benchmark", "summit"], function (type) {
            return _c(
              "el-collapse-item",
              {
                key: type + "Reminder",
                attrs: {
                  title: _vm.capitalize(type) + " Reminder Email Setting",
                  name: type + "Reminder",
                },
              },
              [
                _c(
                  "p",
                  { staticStyle: { color: "#1890ff", margin: "8px 0 24px" } },
                  [
                    _vm._v(
                      "Notification Period between " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.surveyForm[
                              type === "benchmark" ? "start" : "summitStart"
                            ].utc,
                            "YYYY-MM-DD"
                          )
                        ) +
                        " (UTC) to " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.surveyForm[
                              type === "benchmark" ? "end" : "summitEnd"
                            ].utc,
                            "YYYY-MM-DD"
                          )
                        ) +
                        " (UTC). (Same as " +
                        _vm._s(_vm.capitalize(type)) +
                        " Survey Effective Period)"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "24px" } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value:
                                _vm.surveyForm.reminderSetting[type]
                                  .isWeeklyNotify,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.surveyForm.reminderSetting[type],
                                  "isWeeklyNotify",
                                  $$v
                                )
                              },
                              expression:
                                "surveyForm.reminderSetting[type].isWeeklyNotify",
                            },
                          },
                          [_vm._v("Enable Weekly Notification")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c("el-col", [
                      _vm._v(
                        "Weekly Notification (Notification email will be sent out at UTC 3:00 AM)"
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value:
                                _vm.surveyForm.reminderSetting[type]
                                  .notifyWeekday,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.surveyForm.reminderSetting[type],
                                  "notifyWeekday",
                                  $$v
                                )
                              },
                              expression:
                                "surveyForm.reminderSetting[type].notifyWeekday",
                            },
                          },
                          _vm._l(_vm.days, function (day) {
                            return _c(
                              "el-checkbox",
                              {
                                key: day.value,
                                attrs: {
                                  disabled:
                                    !_vm.surveyForm.reminderSetting[type]
                                      .isWeeklyNotify,
                                  label: day.value,
                                },
                              },
                              [_vm._v(_vm._s(day.label))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "24px" } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value:
                                _vm.surveyForm.reminderSetting[type]
                                  .isLastNotify,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.surveyForm.reminderSetting[type],
                                  "isLastNotify",
                                  $$v
                                )
                              },
                              expression:
                                "surveyForm.reminderSetting[type].isLastNotify",
                            },
                          },
                          [_vm._v("Enable Final Notification")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c("el-col", [
                      _vm._v(
                        "Final Notification (Notification email will be sent out at UTC 3:00 AM)"
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _c("el-input-number", {
                          attrs: {
                            disabled:
                              !_vm.surveyForm.reminderSetting[type]
                                .isLastNotify,
                            min: 0,
                            max: 99,
                            size: "small",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.handleDaysInputBlur(type)
                            },
                          },
                          model: {
                            value:
                              _vm.surveyForm.reminderSetting[type]
                                .lastNotifyBeforeEffectiveEnd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.surveyForm.reminderSetting[type],
                                "lastNotifyBeforeEffectiveEnd",
                                $$v
                              )
                            },
                            expression:
                              "surveyForm.reminderSetting[type].lastNotifyBeforeEffectiveEnd",
                          },
                        }),
                        _vm._v(
                          " days before " +
                            _vm._s(_vm.capitalize(type)) +
                            " Survey effective end date\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "28px" } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.sendReminderEmail(type, "weekly")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Manually trigger " +
                                _vm._s(_vm.capitalize(type)) +
                                " weekly reminder email"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.sendReminderEmail(type, "last")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Manually trigger " +
                                _vm._s(_vm.capitalize(type)) +
                                " final notification email"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-collapse-item",
            { attrs: { title: "Reports", name: "report" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push(
                        "/hike/response-status-report/" + _vm.parentId
                      )
                    },
                  },
                },
                [_vm._v("MGH Survey Response Status Report")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Survey",
            visible: _vm.dialogSurveyVisible,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSurveyVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      disabled: _vm.surveyTableLoading,
                      placeholder: "Search by keyword",
                    },
                    model: {
                      value: _vm.surveyQuery.q,
                      callback: function ($$v) {
                        _vm.$set(_vm.surveyQuery, "q", $$v)
                      },
                      expression: "surveyQuery.q",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        type: "primary",
                        disabled: _vm.surveyTableLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getSurveys()
                        },
                      },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.surveyTableLoading,
                  expression: "surveyTableLoading",
                },
              ],
              attrs: { data: _vm.surveyOptions },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Add", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addSurvey(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Category", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.category))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }