var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group", {
        on: { handleSetLineChartData: _vm.handleSetLineChartData },
      }),
      _vm._v(" "),
      _c("el-row", { attrs: { gutter: 8 } }, [_c("participants-card")], 1),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "padding-right": "8px", "margin-bottom": "30px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
              },
            },
            [_c("transaction-table")],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: {
                    xs: { span: 24 },
                    sm: { span: 12 },
                    md: { span: 12 },
                    lg: { span: 6 },
                    xl: { span: 6 },
                  },
                },
                [_c("todo-list")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "30px" },
                  attrs: {
                    xs: { span: 24 },
                    sm: { span: 12 },
                    md: { span: 12 },
                    lg: { span: 6 },
                    xl: { span: 6 },
                  },
                },
                [_c("box-card")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "16px 16px 0",
                "margin-bottom": "32px",
              },
            },
            [_c("line-chart", { attrs: { "chart-data": _vm.lineChartData } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "el-row",
            { attrs: { gutter: 32 } },
            [
              _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
                _c(
                  "div",
                  { staticClass: "chart-wrapper" },
                  [_c("raddar-chart")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
                _c(
                  "div",
                  { staticClass: "chart-wrapper" },
                  [_c("pie-chart")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
                _c(
                  "div",
                  { staticClass: "chart-wrapper" },
                  [_c("bar-chart")],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }