var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullscreenloading,
          expression: "fullscreenloading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
        },
        [
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Hike Cohort Report Info"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("hike-adv-report-info", {
                  attrs: { "parent-hike-adv-report": _vm.hikeAdvReportForm },
                  on: { updateParentReportInfo: _vm.updateParentReportInfo },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-tab-pane", [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Participants"),
            ]),
            _vm._v(" "),
            false
              ? _c("div", [
                  _vm._v("\n        Please create Cohort Report first\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            true
              ? _c(
                  "div",
                  [
                    _vm.hikeAdvReportForm.id
                      ? _c("hike-adv-report-advanced-report", {
                          attrs: {
                            "parent-id": _vm.hikeAdvReportForm.id,
                            "parent-reports": _vm.hikeAdvReportForm.reports,
                          },
                          on: { updateParentReports: _vm.updateParentReports },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _vm.displayType === "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doCreate("published")
                    },
                  },
                },
                [_vm._v("Create\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayType === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.doSave("published")
                    },
                  },
                },
                [_vm._v("Save\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/hike-advanced-report" },
            },
            [_vm._v("Back\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }