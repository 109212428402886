"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _init = require("@/api/init");
var state = {
  countries: [],
  genders: [],
  coachTools: {}
};
var mutations = {
  SET_COUNTRIES: function SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_GENDERS: function SET_GENDERS(state, genders) {
    state.genders = genders;
  },
  SET_COACHTOOLS: function SET_COACHTOOLS(state, coachTools) {
    state.coachTools = coachTools;
  }
};
var actions = {
  getInitData: function getInitData(_ref, lang) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch;
    return new Promise(function (resolve, reject) {
      (0, _init.getInitData)(lang).then(function (response) {
        var data = response.data;
        commit('SET_COUNTRIES', data.facets.countries);
        commit('SET_GENDERS', data.facets.genders);
        commit('SET_COACHTOOLS', data.coachTools);
        resolve();
      }).catch(function (error) {
        // console.log('store-getInitData', error)
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};