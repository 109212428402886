var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    _vm._l(_vm.emailSetting, function (es, index) {
      return _c("div", { key: index }, [
        es.remarks === ""
          ? _c(
              "fieldset",
              { staticClass: "otrfieldset" },
              [
                _c("legend", [_vm._v("Email Topic : " + _vm._s(es.topic))]),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("span", { staticClass: "otr-form__label" }, [
                        _vm._v("Subject:"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Internal Title" },
                          model: {
                            value: es.defaultSubject,
                            callback: function ($$v) {
                              _vm.$set(es, "defaultSubject", $$v)
                            },
                            expression: "es.defaultSubject",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("span", { staticClass: "otr-form__label" }, [
                        _vm._v("Content:"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c("tinymce", {
                          ref: "descriptionEditor" + index,
                          refInFor: true,
                          attrs: {
                            id: "descriptionEditor" + index,
                            toolbar: ["bold bullist link code"],
                            height: 300,
                          },
                          model: {
                            value: es.defaultContent,
                            callback: function ($$v) {
                              _vm.$set(es, "defaultContent", $$v)
                            },
                            expression: "es.defaultContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "otr-line-spacing__20",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("span", { staticClass: "otr-form__label" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.resetSubjectContent(es.name, index)
                              },
                            },
                          },
                          [_vm._v("Reset Subject & Content")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.switchPlaceholders(
                                  !es.showPlaceholders,
                                  index
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                es.showPlaceholders
                                  ? "Hide Supported Placeholders"
                                  : "Show Supported Placeholders"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                es.showPlaceholders
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "otr-line-spacing__20",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c("span", { staticClass: "otr-form__label" }, [
                                _vm._v(
                                  "You can insert the following placeholders into email content:"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("supported-placeholders", {
                          attrs: { programType: "loop" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }