"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _MDinput = _interopRequireDefault(require("@/components/MDinput"));
var _Mallki = _interopRequireDefault(require("@/components/TextHoverEffect/Mallki"));
var _DropdownMenu = _interopRequireDefault(require("@/components/Share/DropdownMenu"));
var _index = _interopRequireDefault(require("@/directive/waves/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 水波纹指令
var _default = exports.default = {
  name: 'ComponentMixinDemo',
  components: {
    PanThumb: _PanThumb.default,
    MdInput: _MDinput.default,
    Mallki: _Mallki.default,
    DropdownMenu: _DropdownMenu.default
  },
  directives: {
    waves: _index.default
  },
  data: function data() {
    var validate = function validate(rule, value, callback) {
      if (value.length !== 6) {
        callback(new Error('请输入六个字符'));
      } else {
        callback();
      }
    };
    return {
      demo: {
        title: ''
      },
      demoRules: {
        title: [{
          required: true,
          trigger: 'change',
          validator: validate
        }]
      },
      articleList: [{
        title: '基础篇',
        href: 'https://juejin.im/post/59097cd7a22b9d0065fb61d2'
      }, {
        title: '登录权限篇',
        href: 'https://juejin.im/post/591aa14f570c35006961acac'
      }, {
        title: '实战篇',
        href: 'https://juejin.im/post/593121aa0ce4630057f70d35'
      }, {
        title: 'vue-admin-template 篇',
        href: 'https://juejin.im/post/595b4d776fb9a06bbe7dba56'
      }, {
        title: 'v4.0 篇',
        href: 'https://juejin.im/post/5c92ff94f265da6128275a85'
      }, {
        title: '优雅的使用 icon',
        href: 'https://juejin.im/post/59bb864b5188257e7a427c09'
      }]
    };
  }
};