"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 权限判断指令
var _default2 = exports.default = {
  directives: {
    permission: _index.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          uuid: '',
          certifications: '',
          educationLevel: ''
        };
      }
    }
  },
  data: function data() {
    return {
      educationList: ['No schooling completed', 'Grade school', 'Some high school, no diploma', 'High school graduate', 'Diploma or the equivalent (for example: GED)', 'Some college credit, no degree', 'Trade/technical/vocational training', 'Associate degree', 'Bachelor\’s degree', 'Master\’s degree', 'Professional degree', 'Doctorate degree'],
      me: {}
    };
  },
  created: function created() {
    // console.log('page-user-acount-user', this.user)
    this.me = {
      id: this.user.id,
      uuid: this.user.uuid,
      educationLevel: this.user.educationLevel,
      certifications: this.user.certifications
    };
  },
  methods: {
    checkPermission: _permission.default,
    save: function save() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');

      // console.log('token', token)
      (0, _user.updateMyProfile)(token, this.me).then(function (response) {
        var data = response.data;
        // console.log('page-user-save', response)
        _this.me.certifications = data.certifications;
        _this.me.educationLevel = data.educationLevel;
        (0, _log.displayPopupMessage)('User\'s educations information has been updated successfully', 'success');
        (0, _ga.writeGaEvent)(_this, 'User', 'Update User Profile', email, "User Profile: ".concat(data.uuid));
      }).catch(function (error) {
        (0, _log.showDebugLog)('Page-user-Save-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};