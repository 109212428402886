"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/utils/log");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _cookie = require("@/utils/cookie");
var _publicFile = require("@/api/publicFile");
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SurveyImageInput',
  props: {
    value: String
  },
  data: function data() {
    return {
      validImageExtension: _lifehikesInitData.validImageExtension,
      thumbnailFile: null,
      showThumbnailUploadErrMsg: false
    };
  },
  methods: {
    chooseThumbnailFile: function chooseThumbnailFile(e) {
      if (e.target.files.length > 0) {
        (0, _log.showDebugLog)('Debug-uploadImg', e.target.files[0]);
        if (e.target.files[0].size <= 1048576) {
          this.thumbnailFile = e.target.files[0];
        } else {
          (0, _log.displayPopupMessage)(this.$i18n.t('otrTraining.imageLimit'), 'error');
          this.$refs.thumbfile.value = '';
        }
      }
    },
    doThumbnailUpload: function doThumbnailUpload() {
      var _this = this;
      (0, _log.showDebugLog)('Debug-uploadImgFile', this.thumbnailFile);
      this.$emit('upload-start');
      var thumbnailIconForm = new FormData();
      thumbnailIconForm.append('file', this.thumbnailFile);
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _publicFile.uploadFile)(token, thumbnailIconForm).then(function (response) {
        _this.$emit('upload-end');
        _this.showThumbnailUploadErrMsg = false;
        _this.$emit('input', response.data.location);
        _this.$emit('input', response.data.location);
      }).catch(function (error) {
        (0, _log.showDebugLog)('upload-img-api-error', error);
        _this.$emit('upload-end');
        _this.showThumbnailUploadErrMsg = true;
      });
    }
  }
};