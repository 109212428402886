var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.hike
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "10px",
                "background-color": "#eee",
                padding: "10px",
                display: "block",
              },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-bottom": "10px", padding: "10px 0" } },
                [_vm._v("ID: " + _vm._s(_vm.hike.id))]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticStyle: { padding: "10px 0" } }, [
                _vm._v("Channel: " + _vm._s(_vm.hike.channel)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticStyle: { padding: "10px 0" } }, [
                _vm._v("Title: " + _vm._s(_vm.hike.title)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.hike.internal
                ? _c("span", { staticStyle: { padding: "10px 0" } }, [
                    _vm._v(
                      "Internal Title: " + _vm._s(_vm.hike.internal.title)
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "10px",
            display: "block",
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "20px",
                "text-transform": "capitalize",
                cursor: "pointer",
              },
              on: { click: _vm.sortHikeTeams },
            },
            [
              _vm._v(_vm._s(_vm.reportType) + " Scoring "),
              _c("i", {
                class: { "el-icon-top": _vm.sort, "el-icon-bottom": !_vm.sort },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-collapse",
                    {
                      attrs: { accordion: "" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.activeNames,
                        callback: function ($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames",
                      },
                    },
                    _vm._l(_vm.myHikeTeams, function (u, index) {
                      return _c(
                        "el-collapse-item",
                        {
                          key: index,
                          attrs: {
                            title:
                              index +
                              1 +
                              ". " +
                              u.givenName +
                              " " +
                              u.familyName +
                              " (" +
                              u.email +
                              ")",
                            name: u.uuid,
                            disabled: _vm.tabLoading,
                          },
                        },
                        [
                          _vm.hike.skillCompassDimensions === "auto" &&
                          Object.keys(_vm.raterData).length > 0 &&
                          _vm.hike.ratingMethod === "by_assignment"
                            ? _c(
                                "div",
                                { staticStyle: { "padding-bottom": "4px" } },
                                [
                                  _c("div", [
                                    _c("strong", [_vm._v("Remove scores:")]),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.raterData, function (value, key) {
                                    return _c(
                                      "span",
                                      {
                                        key: key,
                                        staticStyle: {
                                          "padding-right": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { padding: "4px" },
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showRemoveRaterScoresAlert(
                                                  key,
                                                  value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                value.raterName
                                                  ? value.raterName
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("hr"),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hike.skillCompassDimensions === "auto" &&
                          Object.keys(_vm.raterData).length > 0 &&
                          _vm.hike.ratingMethod === "by_assignment"
                            ? _c(
                                "div",
                                { staticStyle: { "padding-bottom": "4px" } },
                                [
                                  _c("div", [
                                    _vm._v("What you did well"),
                                    _c("br"),
                                    _c("pre", [_vm._v(_vm._s(_vm.didWell))]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "Possible opportunities for improvement"
                                    ),
                                    _c("br"),
                                    _c("pre", [
                                      _vm._v(_vm._s(_vm.forImprovement)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "4px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showEditRaterCommentAlert()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "el-icon-edit" }),
                                      _vm._v("Edit Overall comments"),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("hr"),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.scoreData, function (s, index) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.tabLoading,
                                    expression: "tabLoading",
                                  },
                                ],
                                key: index,
                              },
                              [
                                _vm.hike.skillCompassDimensions !== "auto"
                                  ? _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { data: s.videoScores },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "Title",
                                            width: "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.title
                                                        ) +
                                                          " (" +
                                                          _vm._s(
                                                            scope.row.weight
                                                          ) +
                                                          "%)"
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "Rater",
                                            width: "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(scope.row.rater)
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _vm.checkPermission([
                                          "root",
                                          "otr-digital-producer",
                                        ])
                                          ? _c("el-table-column", {
                                              attrs: {
                                                label: "Actions",
                                                width: "160",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Add Score",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    scope.row
                                                                      .disableAddScoreActions,
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleAddScoreClick(
                                                                        scope
                                                                          .row
                                                                          .scoreConfig,
                                                                        scope
                                                                          .row
                                                                          .actionData
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-finished",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Play Video",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    scope.row
                                                                      .disablePlayActions,
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlePlayClick(
                                                                        scope
                                                                          .row
                                                                          .playInfo
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-video-play",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Edit Score",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    scope.row
                                                                      .disableEditOrDeleteActions,
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditScoreClick(
                                                                        scope
                                                                          .row
                                                                          .scoreResults,
                                                                        scope
                                                                          .row
                                                                          .actionData
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "edit",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Delete Score",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    scope.row
                                                                      .disableEditOrDeleteActions,
                                                                  type: "danger",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleDeleteScoreClick(
                                                                        scope
                                                                          .row
                                                                          .scoreResults,
                                                                        scope
                                                                          .row
                                                                          .actionData
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-delete-solid",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          { attrs: { label: "21 factor(s)" } },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Opening",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "opening",
                                                                  "score"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Language Strength",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "language_strength"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Different",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "different"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Interesting Relevant",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "interesting_relevant"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Structure Clarity",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "structure_clarity"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "memorable",
                                                label: "Memorable",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "memorable"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "stories",
                                                label: "Stories",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "stories"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Data",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "data"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Closing",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "closing"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "React",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "react"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Engagement",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "engagement"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Eye Contact",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "eye_contact"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Personalization",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "personalization"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Authenticity",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "authenticity"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "2D",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "2d"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "3D",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "3d"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "4D",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "4d"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Visuals",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "visuals"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Didatic/Socratis",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "didatic_socratis"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "change",
                                                label: "Change",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "change"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "Props",
                                                width: "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getScoreAndRate(
                                                                  scope.row
                                                                    .scoreResults,
                                                                  "props"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hike.skillCompassDimensions === "auto"
                                  ? _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { data: s.videoScores },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "Title",
                                            width: "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.title
                                                        ) +
                                                          " (" +
                                                          _vm._s(
                                                            scope.row.weight
                                                          ) +
                                                          "%)"
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "Rater",
                                            width: "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(scope.row.rater)
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _vm.checkPermission([
                                          "root",
                                          "otr-digital-producer",
                                        ])
                                          ? _c("el-table-column", {
                                              attrs: {
                                                label: "Actions",
                                                "min-width": "160",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _vm.hike
                                                          .ratingMethod ===
                                                          undefined ||
                                                        _vm.hike
                                                          .ratingMethod ===
                                                          "by_hike"
                                                          ? _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "Add Score",
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        padding:
                                                                          "4px",
                                                                      },
                                                                    attrs: {
                                                                      disabled:
                                                                        scope
                                                                          .row
                                                                          .disableAddScoreActions,
                                                                      type: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAddScoreClick(
                                                                            scope
                                                                              .row
                                                                              .scoreConfig,
                                                                            scope
                                                                              .row
                                                                              .actionData
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-finished",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Play Video",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    scope.row
                                                                      .disablePlayActions,
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlePlayClick(
                                                                        scope
                                                                          .row
                                                                          .playInfo
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-video-play",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Edit Score",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    scope.row
                                                                      .disableEditOrDeleteActions,
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditScoreClick(
                                                                        scope
                                                                          .row
                                                                          .scoreResults,
                                                                        scope
                                                                          .row
                                                                          .actionData
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("svg-icon", {
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "edit",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.hike
                                                          .ratingMethod ===
                                                          undefined ||
                                                        _vm.hike
                                                          .ratingMethod ===
                                                          "by_hike"
                                                          ? _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "Delete Score",
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        padding:
                                                                          "4px",
                                                                      },
                                                                    attrs: {
                                                                      disabled:
                                                                        scope
                                                                          .row
                                                                          .disableEditOrDeleteActions,
                                                                      type: "danger",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleDeleteScoreClick(
                                                                            scope
                                                                              .row
                                                                              .scoreResults,
                                                                            scope
                                                                              .row
                                                                              .actionData
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-delete-solid",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "DialTest Score",
                                            "min-width": "95",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.dialTestAvg
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    !scope.row
                                                      .disableDialTestDownloadActions
                                                      ? _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "Download DialTest",
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "4px",
                                                                },
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleDownloadDialTestClick(
                                                                        scope
                                                                          .row
                                                                          .playInfo,
                                                                        scope
                                                                          .row
                                                                          .actionData
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-download",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: _vm.autoVideoTagsTitle,
                                            },
                                          },
                                          _vm._l(
                                            _vm.autoVideoTagsList,
                                            function (tag, index) {
                                              return _c("el-table-column", {
                                                key: index,
                                                attrs: {
                                                  label: tag.title,
                                                  "min-width": "215",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.getScoreAndRateForAutoType(
                                                                    scope.row
                                                                      .scoreResults,
                                                                    tag.key,
                                                                    "score"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: _vm.autoOverallTagsTitle,
                                            },
                                          },
                                          _vm._l(
                                            _vm.autoOverallTagList,
                                            function (tag, index) {
                                              return _c("el-table-column", {
                                                key: index,
                                                attrs: {
                                                  label: tag.title,
                                                  "min-width": "160",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.getScoreAndRateForAutoType(
                                                                    scope.row
                                                                      .scoreResults,
                                                                    tag.key,
                                                                    "score"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                false
                                  ? _c("div", [_vm._v("Spider Chart Data")])
                                  : _vm._e(),
                                _vm._v(" "),
                                false
                                  ? _c(
                                      "table",
                                      {
                                        staticClass: "userList",
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Opening")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Language Strength")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Different")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Interesting Relevant")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Structure Clarity")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Memorable")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Stories")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Data")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Closing")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("React")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Engagement")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Eye Contact")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Personalization")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Authenticity")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("2D")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("3D")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("4D")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Visuals")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Didatic/Socratis")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Change")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { width: "150" } },
                                              [_vm._v("Props")]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _vm.spiderData[index].scores
                                            ? _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "opening"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "language_strength"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "different"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "interesting_relevant"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "structure_clarity"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "memorable"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "stories"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "data"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "closing"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "react"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "engagement"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "eye_contact"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "personalization"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "authenticity"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "2d"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "3d"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "4d"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "visuals"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "didatic_socratis"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "change"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSpiderScore(
                                                        _vm.spiderData[index]
                                                          .scores,
                                                        "props"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                s.videoScores.length > 0 && false
                                  ? _c("div", [
                                      _vm._v(
                                        "Overall Score: " +
                                          _vm._s(s.overallScore)
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "10px 10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.backToHike },
                    },
                    [_vm._v("Back To Hike")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Add Score",
            visible: _vm.dialogAddScoreVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddScoreVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                _vm._l(_vm.scoreToAdd.score, function (score, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticStyle: { padding: "5px 15px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { float: "left" }, attrs: { span: 6 } },
                        [
                          _vm._v(
                            _vm._s(
                              score.key
                                .split("_")
                                .map(function (word) {
                                  return (
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                  )
                                })
                                .join(" ")
                            ) +
                              "(" +
                              _vm._s(score.rate) +
                              "%):"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: {
                          span: 6,
                          min: 1,
                          max: 10,
                          placeholder: "1-10",
                        },
                        model: {
                          value: score.score,
                          callback: function ($$v) {
                            _vm.$set(score, "score", $$v)
                          },
                          expression: "score.score",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAddScoreVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAdd } },
                [_vm._v("Add")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Edit Score",
            visible: _vm.dialogEditScoreVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditScoreVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                _vm._l(_vm.scoreToEdit.score, function (score, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticStyle: { padding: "5px 15px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { float: "left" }, attrs: { span: 6 } },
                        [
                          _vm._v(
                            _vm._s(
                              score.key
                                .split("_")
                                .map(function (word) {
                                  return (
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                  )
                                })
                                .join(" ")
                            ) +
                              "(" +
                              _vm._s(score.rate) +
                              "%):"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: {
                          span: 6,
                          min: 1,
                          max: 10,
                          placeholder: "1-10",
                        },
                        model: {
                          value: score.score,
                          callback: function ($$v) {
                            _vm.$set(score, "score", $$v)
                          },
                          expression: "score.score",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogEditScoreVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogVideoTitle,
            visible: _vm.dialogVideoPlayerVisible,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVideoPlayerVisible = $event
            },
            close: function ($event) {
              _vm.dialogVideoUrl = ""
            },
          },
        },
        [
          _c("video", {
            staticClass: "video",
            attrs: {
              src: _vm.dialogVideoUrl,
              controls: "",
              autoplay: "",
              width: "100%",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Remove Score",
            visible: _vm.dialogRemoveScoreVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRemoveScoreVisible = $event
            },
          },
        },
        [
          _c("h3", [_vm._v("This is permanent.")]),
          _vm._v("\n    Please double check before remove the scores from "),
          _c("strong", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.raterToRemove.raterName) + "."),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogRemoveScoreVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.showConfirmRemoveRaterScores },
                },
                [_vm._v("Remove")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Remove Score",
            visible: _vm.dialogRemoveConfirmScoreVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRemoveConfirmScoreVisible = $event
            },
          },
        },
        [
          _c("h2", [
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("This is permanent."),
            ]),
          ]),
          _vm._v("\n    Please double check before remove the scores from "),
          _c("strong", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.raterToRemove.raterName) + "."),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogRemoveConfirmScoreVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.handleRemoveRaterScores },
                },
                [_vm._v("Confirm Remove")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Edit comments",
            visible: _vm.dialogEditCommentVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditCommentVisible = $event
            },
          },
        },
        [
          _c("h3", [_vm._v("What you did well")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              type: "textarea",
              size: "mini",
              autosize: { minRows: 4, maxRows: 4 },
            },
            model: {
              value: _vm.newDidWell,
              callback: function ($$v) {
                _vm.newDidWell = $$v
              },
              expression: "newDidWell",
            },
          }),
          _vm._v(" "),
          _c("h3", [_vm._v("Possible opportunities for improvement")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              type: "textarea",
              size: "mini",
              autosize: { minRows: 4, maxRows: 4 },
            },
            model: {
              value: _vm.newForImprovement,
              callback: function ($$v) {
                _vm.newForImprovement = $$v
              },
              expression: "newForImprovement",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogEditCommentVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.handleEditRaterComments },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "margin-bottom": "10px", padding: "10px 0" } },
      [_c("u", [_vm._v("Hike Information")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }