var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("search-box", {
                attrs: {
                  placeholder:
                    "Search Keyword: Given Name / Surname / Email / Company / Job Title / UUID",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.doSearch(1)
                  },
                },
                model: {
                  value: _vm.query.q,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "q", $$v)
                  },
                  expression: "query.q",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-select",
                {
                  ref: "status",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Status", clearable: "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.doSearch(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("status")
                    },
                  },
                  model: {
                    value: _vm.query.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.doSearch(1)
                    },
                  },
                },
                [_c("svg-icon", { attrs: { "icon-class": "search" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.userList,
                    fit: "",
                    "highlight-current-row": "",
                    height: _vm.height,
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "sort-change": _vm.sort,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "UUID", fixed: "", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.uuid))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "GIVEN NAME",
                      fixed: "",
                      width: "150",
                      sortable: "custom",
                      prop: "givenName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.givenName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: { label: "MIDDLE NAME", width: "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.middleName)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2326195212
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "SURNAME",
                      width: "150",
                      fixed: "",
                      sortable: "custom",
                      prop: "familyName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.familyName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "EMAIL",
                      width: "300",
                      sortable: "custom",
                      prop: "email",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/users/edit/" + scope.row.uuid },
                              },
                              [_c("u", [_vm._v(_vm._s(scope.row.email))])]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "COMPANY",
                      width: "200",
                      sortable: "custom",
                      prop: "orgName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.orgName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "STATUS",
                      width: "150",
                      sortable: "custom",
                      prop: "status",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.status))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "LAST LOGIN DATE",
                      width: "170",
                      sortable: "custom",
                      prop: "lastLoginDate",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.lastLoginDate,
                                    "YYYY-MM-DD"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "UPDATED AT",
                      width: "170",
                      sortable: "custom",
                      prop: "updatedAt",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.updatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "CREATED AT",
                      width: "170",
                      sortable: "custom",
                      prop: "createdAt",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.createdAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "ACTIONS", "min-width": 220 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/users/edit/" + scope.row.uuid },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Edit",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "edit" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path: "/report/progress/" + scope.row.uuid,
                                    query: {
                                      name:
                                        scope.row.givenName +
                                        " " +
                                        scope.row.familyName,
                                      orgName: scope.row.orgName,
                                      email: scope.row.email,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Progress Report",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "chart" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.hasPermission(["root", "otr-digital-producer"])
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/users/coin/" + scope.row.uuid,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Coin Edit",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              padding: "10px 10px",
                                            },
                                            attrs: { type: "primary" },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "example",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Roles Setting",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "10px 10px" },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "peoples" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Delete User",
                                  placement: "top",
                                },
                              },
                              [
                                _vm.hasPermission(["root"])
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          padding: "10px 10px",
                                          "margin-left": "0px",
                                        },
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.doDelete(scope.row.uuid)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.query.limit,
                      "pager-count": 5,
                      total: _vm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: "Edit Role" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.userRole,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c("p", { staticClass: "custom-p" }, [_vm._v("LifeHikes")]),
              _vm._v(" "),
              _c("span", { staticClass: "custom-label" }, [
                _vm._v("Apps & Admin Portal"),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "LifeHikesRolles",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.newRoleList.filter(function (p) {
                      return p.type === "LifeHikes"
                    }),
                  },
                  on: { "selection-change": _vm.LifeHikesSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "val", label: "Name", width: "150" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "description", label: "Description" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "custom-p" }, [_vm._v("Delphi")]),
              _vm._v(" "),
              _c("span", { staticClass: "custom-label" }, [
                _vm._v("Delphi Admin Portal"),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "DelphiRolles",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.newRoleList.filter(function (p) {
                      return p.type === "Delphi"
                    }),
                  },
                  on: { "selection-change": _vm.DelphiSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "val", label: "Name", width: "150" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "description", label: "Description" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmRole(
                        _vm.userRole.uuid,
                        _vm.userRole.role
                      )
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }