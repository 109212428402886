"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Sticky = _interopRequireDefault(require("@/components/Sticky"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StickyDemo',
  components: {
    Sticky: _Sticky.default
  },
  data: function data() {
    return {
      time: '',
      url: '',
      platforms: ['a-platform'],
      platformsOptions: [{
        key: 'a-platform',
        name: 'platformA'
      }, {
        key: 'b-platform',
        name: 'platformB'
      }, {
        key: 'c-platform',
        name: 'platformC'
      }],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  }
};