"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkPermission;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = _store.default.getters && _store.default.getters.roles;
    var permissionRoles = value;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}