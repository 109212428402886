"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _vue2Editor = require("vue2-editor");
var _vueColor = require("vue-color");
var _lhJsCommonLib = require("@lifehikes/lh-js-common-lib");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var tmpHikeReport = {
  'managerPassword': '',
  'userFeedback': '',
  'managerSummary': '',
  'overallSkillSummary': true,
  'individualBreakdown': true,
  'individualReport': true,
  'groupHighlights': true,
  'participantFeedback': true,
  'showPiExplanation': true,
  'showSkillCompass': true,
  'show720SurveyData': false,
  'showNegativeGrowth': false,
  'mostActiveHikersMaxCount': 3,
  'mostImprovedHikersMaxCount': 3,
  'showStageAttendance': false,
  'showParticipantRate': false,
  'scoreConfig': {
    'overall': (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassOverallDefault),
    'video': (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassVideoDefault)
  },
  'skillGroup': {
    'groups': [{
      'title': '1. Content (What you say)',
      'radarTextColor': '#2D5F86',
      'skills': (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassGroupContentDefault)
    }, {
      'title': '2. Delivery (How you say it)',
      'radarTextColor': '#2D863E',
      'skills': (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassGroupDeliveryDefault)
    }],
    'title': 'How your scores compare:'
  }
};
var _default2 = exports.default = {
  name: 'HikeReport',
  components: {
    VueEditor: _vue2Editor.VueEditor,
    'color-picker': _vueColor.Slider
  },
  props: {
    parentId: {
      type: String,
      default: ''
    },
    internalTitle: {
      type: String,
      default: ''
    },
    parentHikeReport: {
      type: Object,
      default: function _default() {
        return _lodash.default.cloneDeep(tmpHikeReport);
      }
    },
    parentHikeSkillCompassDimensions: {
      type: String,
      default: 'auto'
    }
  },
  data: function data() {
    return {
      customToolbar: [['bold', 'italic', 'underline'], [{
        list: 'ordered'
      }, {
        list: 'bullet'
      }], [{
        color: []
      }, {
        background: []
      }], ['clean']],
      loading: false,
      participantList: [],
      report: {},
      reportLink: '',
      skillCompassDimensions: '',
      removeSkillDialogVisible: false,
      removeSkillIndex: -1,
      removeSkillIndex2: -1,
      removeSkillText: '',
      removeSkillType: '',
      addSkillDialogVisible: false,
      skillExplainPopupVisible: false,
      addSkillType: '',
      addSkillGroupIndex: -1,
      addSkillError: '',
      scLibrary: (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassLibrary)
    };
  },
  watch: {
    report: {
      handler: function handler(newVal) {
        this.passBackReport(newVal);
      },
      deep: true
    },
    skillCompassDimensions: {
      handler: function handler(newVal) {
        this.passBackSkillCompassDimensions(newVal);
      }
    }
  },
  created: function created() {
    var id = this.parentId;
    this.skillCompassDimensions = this.parentHikeSkillCompassDimensions + '';
    if (id !== 'copy') {
      var reportUrl = process.env.VUE_APP_PUBLIC_DOMAIN + '/manager/report/' + id;
      this.reportLink = '<a target="_blank" href="' + reportUrl + '">' + reportUrl + '</a>';
      this.report = _lodash.default.cloneDeep(this.parentHikeReport);
      if (this.report.length === 0) {
        this.report = _lodash.default.cloneDeep(tmpHikeReport);
      } else {
        if (!this.report['skillGroup']) {
          this.report['skillGroup'] = _lodash.default.cloneDeep(tmpHikeReport['skillGroup']);
        }
        if (!this.report['scoreConfig']) {
          this.report['scoreConfig'] = _lodash.default.cloneDeep(tmpHikeReport['scoreConfig']);
        }
        if (this.report['overallSkillSummary'] === undefined) {
          this.$set(this.report, 'overallSkillSummary', true);
        }
        if (this.report['showNegativeGrowth'] === undefined) {
          this.$set(this.report, 'showNegativeGrowth', false);
        }
        if (this.report['individualBreakdown'] === undefined) {
          this.$set(this.report, 'individualBreakdown', true);
        }
        if (this.report['individualReport'] === undefined) {
          this.$set(this.report, 'individualReport', true);
        }
        if (this.report['groupHighlights'] === undefined) {
          this.$set(this.report, 'groupHighlights', true);
        }
        if (this.report['mostActiveHikersMaxCount'] === undefined) {
          this.$set(this.report, 'mostActiveHikersMaxCount', 3);
        }
        if (this.report['mostImprovedHikersMaxCount'] === undefined) {
          this.$set(this.report, 'mostImprovedHikersMaxCount', 3);
        }
        if (this.report['participantFeedback'] === undefined) {
          this.$set(this.report, 'participantFeedback', true);
        }
        if (this.report['showStageAttendance'] === undefined) {
          this.$set(this.report, 'showStageAttendance', false);
        }
        if (this.report['showParticipantRate'] === undefined) {
          this.$set(this.report, 'showParticipantRate', false);
        }
        if (this.report['showSkillCompass'] === undefined) {
          this.$set(this.report, 'showSkillCompass', true);
        }
        if (this.report['showPiExplanation'] === undefined) {
          this.$set(this.report, 'showPiExplanation', true);
        }
        if (this.report['show720SurveyData'] === undefined) {
          this.$set(this.report, 'show720SurveyData', false);
        }
      }
      console.log('this report', this.report);
    } else {
      if (this.parentHikeReport !== undefined && Object.keys(this.parentHikeReport).length > 0) {
        var tmp = _lodash.default.cloneDeep(this.parentHikeReport);
        tmp['userFeedback'] = '';
        tmp['managerSummary'] = '';
        this.report = tmp;
      } else {
        this.report = _lodash.default.cloneDeep(tmpHikeReport);
      }
    }
  },
  methods: {
    skillOverallRemove: function skillOverallRemove(index, title) {
      this.removeSkillIndex = index;
      this.removeSkillText = title;
      this.removeSkillDialogVisible = true;
      this.removeSkillType = 'overall';
    },
    skillVideoRemove: function skillVideoRemove(index, title) {
      this.removeSkillIndex = index;
      this.removeSkillText = title;
      this.removeSkillDialogVisible = true;
      this.removeSkillType = 'video';
    },
    doSkillRemove: function doSkillRemove() {
      if (this.removeSkillType === 'overall') {
        this.report.scoreConfig.overall.splice(this.removeSkillIndex, 1);
      } else if (this.removeSkillType === 'video') {
        this.report.scoreConfig.video.splice(this.removeSkillIndex, 1);
      } else if (this.removeSkillType === 'group') {
        this.report.skillGroup.groups.splice(this.removeSkillIndex, 1);
      } else if (this.removeSkillType === 'groupSkill') {
        if (this.report.skillGroup.groups) {
          var g = this.report.skillGroup.groups[this.removeSkillIndex];
          if (g['skills']) {
            g['skills'].splice(this.removeSkillIndex2, 1);
          }
        }
      }
      // Reset
      this.removeSkillDialogVisible = false;
      this.removeSkillIndex = -1;
      this.removeSkillIndex2 = -1;
      this.removeSkillText = '';
      this.removeSkillType = '';
    },
    skillOverallAdd: function skillOverallAdd() {
      this.addSkillDialogVisible = true;
      this.addSkillType = 'overall';
      this.addSkillError = '';
    },
    showSkillExplainPopup: function showSkillExplainPopup() {
      this.skillExplainPopupVisible = true;
      this.addSkillType = 'overall';
      this.addSkillError = '';
    },
    skillVideoAdd: function skillVideoAdd() {
      this.addSkillDialogVisible = true;
      this.addSkillType = 'video';
      this.addSkillError = '';
    },
    doSkillAdd: function doSkillAdd(skill) {
      var newSkill = _lodash.default.cloneDeep(skill);
      delete newSkill.newKey;
      if (this.addSkillType === 'overall') {
        if (this.report.scoreConfig.overall) {
          var found = this.report.scoreConfig.overall.find(function (element) {
            return element['key'] === newSkill['key'];
          });
          if (found === undefined) {
            this.report.scoreConfig.overall.push(newSkill);
          } else {
            this.addSkillError = 'Skill existed.';
          }
        } else {
          this.report.scoreConfig.overall = [newSkill];
        }
      } else if (this.addSkillType === 'video') {
        if (this.report.scoreConfig.video) {
          var _found = this.report.scoreConfig.video.find(function (element) {
            return element['key'] === newSkill['key'];
          });
          if (_found === undefined) {
            this.report.scoreConfig.video.push(newSkill);
          } else {
            this.addSkillError = 'Skill existed.';
          }
        } else {
          this.report.scoreConfig.video = [newSkill];
        }
      } else if (this.addSkillType === 'group') {
        if (this.report.skillGroup.groups) {
          var g = this.report.skillGroup.groups[this.addSkillGroupIndex];
          if (g['skills']) {
            var _found2 = g['skills'].find(function (element) {
              return element['key'] === newSkill['key'];
            });
            if (_found2 === undefined) {
              g['skills'].push(newSkill);
            } else {
              this.addSkillError = 'Skill existed.';
            }
          }
        }
      }
      console.info('scLibrary', this.scLibrary);
    },
    skillReset: function skillReset() {
      if (this.report) {
        this.report['scoreConfig'] = {
          'overall': (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassOverallDefault),
          'video': (0, _lhJsCommonLib.tagMapping)(_lifehikesInitData.skillCompassVideoDefault)
        };
      } else {
        this.report = _lodash.default.cloneDeep(tmpHikeReport);
      }
    },
    skillGroupReset: function skillGroupReset() {
      if (this.report) {
        this.$set(this.report, 'skillGroup', _lodash.default.cloneDeep(tmpHikeReport['skillGroup']));
      } else {
        this.report = _lodash.default.cloneDeep(tmpHikeReport);
      }
    },
    skillGroupRemoveSkill: function skillGroupRemoveSkill(groupIndex, skillIndex, title) {
      this.removeSkillIndex = groupIndex;
      this.removeSkillIndex2 = skillIndex;
      this.removeSkillText = title;
      this.removeSkillDialogVisible = true;
      this.removeSkillType = 'groupSkill';
    },
    skillGroupAddSkill: function skillGroupAddSkill(index) {
      this.addSkillDialogVisible = true;
      this.addSkillType = 'group';
      this.addSkillGroupIndex = index;
      this.addSkillError = '';
    },
    skillGroupAdd: function skillGroupAdd() {
      if (this.report.skillGroup.groups) {
        this.report.skillGroup.groups.push({
          'skills': [],
          'title': '',
          'radarTextColor': '#19204D'
        });
      }
    },
    skillGroupRemove: function skillGroupRemove(index, title) {
      this.removeSkillIndex = index;
      this.removeSkillText = title;
      this.removeSkillDialogVisible = true;
      this.removeSkillType = 'group';
    },
    passBackReport: function passBackReport(report) {
      this.$emit('updateReport', report);
    },
    passBackSkillCompassDimensions: function passBackSkillCompassDimensions(value) {
      this.$emit('updateSkillCompassDimensions', value);
    },
    skillShowNewKey: function skillShowNewKey(key) {
      var skill = this.scLibrary.find(function (p) {
        return p.key === key;
      });
      if (skill) {
        return skill.newKey ? skill.newKey : skill.key;
      } else {
        return key;
      }
    },
    getDefaultSkillTitle: function getDefaultSkillTitle(key) {
      var skill = this.scLibrary.find(function (p) {
        return p.key === key;
      });
      return skill.title;
    }
  }
};