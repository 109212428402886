"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _hikeAdvancedReport = require("@/api/hike-advanced-report");
var _log = require("@/utils/log");
var _hike = require("@/api/hike");
var _cookie = require("@/utils/cookie");
var _lodash = _interopRequireDefault(require("lodash"));
var _moment = _interopRequireDefault(require("moment"));
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var tmpHikeAdvReportInfo = {};
var tmpArray = [];
var _default = exports.default = {
  name: 'HikeAdvReportAdvancedReport',
  components: {},
  props: {
    parentId: {
      type: String,
      default: tmpHikeAdvReportInfo
    },
    parentReports: {
      type: Array,
      default: tmpArray
    }
  },
  filters: {
    getInternalTitle: function getInternalTitle(value) {
      // console.log('row value', value)
      return typeof value === 'undefined' ? '' : value.title;
    }
  },
  data: function data() {
    return {
      showWarning: false,
      showStageWarning: false,
      showPiWarning: false,
      customToolbar: [['bold', 'italic', 'underline'], [{
        list: 'ordered'
      }, {
        list: 'bullet'
      }], [{
        color: []
      }, {
        background: []
      }], ['clean']],
      loading: false,
      hikeParticipants: {},
      reports: [],
      reportId: '',
      removeHikeDialogVisible: false,
      removeHikeIndex: -1,
      removeHikeText: '',
      addHikeDialogVisible: false,
      addHikeLoading: false,
      hikeSelected: false,
      hikeTotal: 0,
      hikeList: [],
      hikeQuery: {
        page: 1,
        limit: 10,
        sort: '-start.utc',
        q: undefined,
        publish: undefined,
        staffUuid: undefined,
        staffRole: undefined
      },
      addParticipantLoading: false,
      participantList: [],
      participantSelectedUuids: {},
      editHikeDialogVisible: false,
      editParticipantLoading: false,
      editHikeDialogIndex: -1,
      editHikeDialogHikeId: '',
      editHikeTitle: ''
    };
  },
  watch: {
    reports: {
      handler: function handler(newVal) {
        this.passBackReport(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    this.reportId = this.parentId;
    this.reports = _lodash.default.cloneDeep(this.parentReports);
    this.getReportHikeParticipantsDetails();
  },
  methods: {
    getHikeTitle: function getHikeTitle(hikeId) {
      var hike = this.hikeParticipants['hikes'][hikeId];
      return hike ? hike['title'] ? hike['title'] : '' : '';
    },
    getHikeInternal: function getHikeInternal(hikeId, key) {
      var hike = this.hikeParticipants['hikes'][hikeId];
      return hike ? hike['internal'] ? hike['internal'][key] ? hike['internal'][key] : '' : '' : '';
    },
    getParticipantName: function getParticipantName(uuid) {
      var p = this.hikeParticipants['participants'][uuid];
      return p['givenName'] + ' ' + p['familyName'];
    },
    getParticipantAvatar: function getParticipantAvatar(uuid) {
      var p = this.hikeParticipants['participants'][uuid];
      return p['profilePic'];
    },
    resetAll: function resetAll() {
      this.addHikeDialogVisible = false;
      this.addHikeDialogLoading = false;
      this.hikeSelected = {};
      this.participantSelectedUuids = {};
      this.participantList = [];
      this.editHikeDialogVisible = false;
      this.editParticipantLoading = false;
      this.editHikeDialogIndex = -1;
      this.editHikeDialogHikeId = '';
      this.editHikeTitle = '';
      this.removeHikeDialogVisible = false;
      this.removeHikeText = '';
    },
    reportHikeAdd: function reportHikeAdd() {
      this.resetAll();
      this.addHikeDialogVisible = true;
      this.addHikeDialogLoading = true;
      this.loadHikeList();
    },
    reportHikeEdit: function reportHikeEdit(index, hikeId) {
      var _this = this;
      this.resetAll();
      this.editHikeDialogVisible = true;
      this.editHikeDialogLoading = true;
      this.editHikeDialogIndex = index;
      this.editHikeDialogHikeId = hikeId;
      this.editHikeTitle = this.getHikeTitle(hikeId);

      // Put current selected UUID
      var report = this.reports[this.editHikeDialogIndex];
      var _iterator = _createForOfIteratorHelper(report['participants']),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var p = _step.value;
          this.participantSelectedUuids[p] = 'good';
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.editParticipantLoading = true;
      (0, _hike.getParticipant)(token, hikeId).then(function (response) {
        _this.editParticipantLoading = false;
        _this.participantList = response.data.rows;
      }).catch(function (error) {
        _this.editParticipantLoading = false;
        (0, _log.showDebugLog)('Hike-getParticipant-API-error', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    reportHikeDelete: function reportHikeDelete(index, title) {
      this.removeHikeDialogVisible = true;
      this.removeHikeIndex = index;
      this.removeHikeText = title;
    },
    doReportHikeDelete: function doReportHikeDelete() {
      this.removeHikeDialogVisible = false;
      this.removeHikeText = '';
      if (this.removeHikeIndex < this.reports.length) {
        this.reports.splice(this.removeHikeIndex, 1);
        this.getReportHikeParticipantsDetails();
        this.resetAll();
      }
    },
    getReportHikeParticipantsDetails: function getReportHikeParticipantsDetails() {
      var _this2 = this;
      this.loading = true;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _hikeAdvancedReport.getAdvancedReportHikeParticipants)(token, {
        'reports': this.reports
      }).then(function (response) {
        _this2.loading = false;
        _this2.hikeParticipants = response.data;
        _this2.showWarning = false;
        _this2.showStageWarning = false;
        _this2.showPiWarning = false;
        var warnings = _this2.hikeParticipants['warnings'];
        if (warnings['stage'] === 'warning') {
          _this2.showStageWarning = true;
        }
        if (warnings['skill'] === 'warning') {
          _this2.showPiWarning = true;
        }
        if (_this2.showStageWarning || _this2.showPiWarning) {
          _this2.showWarning = true;
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('hike-getHikeById-error', error);
        _this2.loading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    passBackReport: function passBackReport(report) {
      this.$emit('updateParentReports', report);
    },
    loadHikeList: function loadHikeList() {
      var _this3 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.addHikeLoading = true;
      (0, _hike.getHikeList)(token, this.hikeQuery).then(function (response) {
        (0, _log.showDebugLog)('Hike-Search-response', response);
        _this3.hikeList = response.data.rows;
        _this3.hikeTotal = response.data.count;
        _this3.addHikeLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)('hike-Search-error', error);
        _this3.addHikeDialogLoading = false;
        (0, _log.displayPopupMessage)(error, 'error');
        // this.$message({ type: 'error', showClose: true, duration: 5000, message: error.response })
      });
    },
    isHikeListExipred: function isHikeListExipred(endUtc) {
      var now = _moment.default.utc();
      var end = _moment.default.utc(endUtc);
      return now > end;
    },
    handleHikeListCurrentChange: function handleHikeListCurrentChange(val) {
      this.hikeQuery.page = val;
      this.loadHikeList();
    },
    handleHikeListSizeChange: function handleHikeListSizeChange(val) {
      this.hikeQuery.limit = val;
      this.loadHikeList();
    },
    handleHikeListSelectionChange: function handleHikeListSelectionChange(val) {
      this.hikeSelected = val;
      this.participantList = [];
      this.participantSelectedUuids = {};
      this.loadHikeParticipants();
    },
    searchHikeList: function searchHikeList(pageNo) {
      this.hikeQuery.page = pageNo;
      this.hikeQuery.q = this.hikeQuery.q === '' ? undefined : this.hikeQuery.q;
      this.hikeQuery.publish = this.hikeQuery.publish === '' ? undefined : this.hikeQuery.publish;
      this.loadHikeList();
    },
    loadHikeParticipants: function loadHikeParticipants() {
      var _this4 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var hikeId = this.hikeSelected['id'];
      this.addParticipantLoading = true;
      (0, _hike.getParticipant)(token, hikeId).then(function (response) {
        _this4.addParticipantLoading = false;
        _this4.participantList = response.data.rows;
      }).catch(function (error) {
        _this4.addParticipantLoading = false;
        (0, _log.showDebugLog)('Hike-getParticipant-API-error', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    participantSelected: function participantSelected(p) {
      if (this.participantSelectedUuids[p['uuid']]) {
        this.$delete(this.participantSelectedUuids, p['uuid']);
      } else {
        this.$set(this.participantSelectedUuids, p['uuid'], 'good');
      }
    },
    doAddHikeParticipant: function doAddHikeParticipant() {
      this.reports.push({
        'hike': this.hikeSelected['id'],
        'participants': Object.keys(this.participantSelectedUuids)
      });
      this.resetAll();
      this.getReportHikeParticipantsDetails();
    },
    doEditHikeParticipant: function doEditHikeParticipant() {
      if (this.editHikeDialogIndex > -1 && this.editHikeDialogIndex < this.reports.length) {
        this.reports[this.editHikeDialogIndex] = {
          'hike': this.editHikeDialogHikeId,
          'participants': Object.keys(this.participantSelectedUuids)
        };
      }
      this.resetAll();
      this.getReportHikeParticipantsDetails();
    }
  }
};