"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRole = addRole;
exports.deleteRole = deleteRole;
exports.getRoles = getRoles;
exports.getRoutes = getRoutes;
exports.updateRole = updateRole;
exports.updateUserRole = updateUserRole;
var _request = _interopRequireDefault(require("@/utils/request"));
// lifehikes
function updateUserRole(token, uuid, userRole) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = {
    role: userRole
  };
  return (0, _request.default)({
    url: "/users/".concat(uuid, "/role"),
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    headers: headers,
    data: data
  });
}

// no use
function getRoutes() {
  return (0, _request.default)({
    url: '/vue-element-admin/routes',
    method: 'get'
  });
}
function getRoles() {
  return (0, _request.default)({
    url: '/vue-element-admin/roles',
    method: 'get'
  });
}
function addRole(data) {
  return (0, _request.default)({
    url: '/vue-element-admin/role',
    method: 'post',
    data: data
  });
}
function updateRole(id, data) {
  return (0, _request.default)({
    url: "/vue-element-admin/role/".concat(id),
    method: 'put',
    data: data
  });
}
function deleteRole(id) {
  return (0, _request.default)({
    url: "/vue-element-admin/role/".concat(id),
    method: 'delete'
  });
}