var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-page" },
    [
      _vm.pages.length === 0
        ? _c(
            "div",
            { staticStyle: { "text-align": "center", padding: "50px 0 80px" } },
            [
              _c("p", [_vm._v("No Data")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.handleAddPage },
                },
                [_vm._v("Add New Page")]
              ),
            ],
            1
          )
        : _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 5 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.handleAddPage },
                      },
                      [_vm._v("Add New Page")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isInUse === "Y",
                            expression: "isInUse === 'Y'",
                          },
                        ],
                        staticStyle: { float: "right" },
                        attrs: { type: "danger" },
                      },
                      [_vm._v("IN USE")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "page-list" },
                  _vm._l(_vm.pages, function (page, index) {
                    return _c(
                      "li",
                      {
                        key: page.label,
                        staticClass: "page-list__item",
                        class: {
                          "page-list__item--current": page === _vm.currentPage,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handlePageClick(page)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "page-list__item-label" }, [
                          _vm._v(_vm._s(page.label)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  title: "Move Up",
                                  disabled: index === 0,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handlePageMoveUp(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-top" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  title: "Move Down",
                                  disabled: index === _vm.pages.length - 1,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handlePageMoveDown(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-bottom" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  title: "Delete",
                                  disabled: !page.isDeletable,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handlePageDelete(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 19 } },
                _vm._l(_vm.pages, function (page, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: page === _vm.currentPage,
                          expression: "page === currentPage",
                        },
                      ],
                      key: index,
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(page.label))]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "pageForm" + index,
                          refInFor: true,
                          attrs: {
                            model: page,
                            "label-width": "200px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Title:" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: page.title,
                                  callback: function ($$v) {
                                    _vm.$set(page, "title", $$v)
                                  },
                                  expression: "page.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Header Img:" } },
                            [
                              _c("survey-image-input", {
                                on: {
                                  "upload-start": function ($event) {
                                    _vm.fullscreenloading = true
                                  },
                                  "upload-end": function ($event) {
                                    _vm.fullscreenloading = false
                                  },
                                },
                                model: {
                                  value: page.headerImg,
                                  callback: function ($$v) {
                                    _vm.$set(page, "headerImg", $$v)
                                  },
                                  expression: "page.headerImg",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Questions:" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-circle-plus-outline",
                                  },
                                  on: { click: _vm.handleAddQuestion },
                                },
                                [_vm._v("Add New Question")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: page.questions },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "questionFormat",
                                      label: "Question Format",
                                      width: "120",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "questionText",
                                      label: "Question Text",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.questionText.join(
                                                      " "
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "answerFormat",
                                      label: "Answer Format",
                                      width: "120",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      fixed: "right",
                                      label: "Actions",
                                      width: "220",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    title: "Edit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditQuestion(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class": "edit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      scope.$index === 0,
                                                    type: "primary",
                                                    size: "mini",
                                                    title: "Move Up",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.moveUp(
                                                        page.questions,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-top",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      scope.$index ===
                                                      page.questions.length - 1,
                                                    type: "primary",
                                                    size: "mini",
                                                    title: "Move Down",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.moveDown(
                                                        page.questions,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-bottom",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    size: "mini",
                                                    title: "Delete",
                                                    disabled:
                                                      !scope.row.isDeletable,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDeleteQuestion(
                                                        page.questions,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete-solid",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.editingQuestionIndex === null
                ? "Add New Question"
                : "Edit Question",
            visible: _vm.questionDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.questionDialogVisible = $event
            },
            close: _vm.cancelQuestion,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "questionForm",
              attrs: {
                model: _vm.questionForm,
                rules: _vm.questionFormRules,
                "label-width": "120px",
                "label-position": "left",
              },
            },
            [
              false
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Subcategory" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.questionForm.subcategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.questionForm, "subcategory", $$v)
                          },
                          expression: "questionForm.subcategory",
                        },
                      }),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-select",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.questionForm.subcategory,
                                callback: function ($$v) {
                                  _vm.$set(_vm.questionForm, "subcategory", $$v)
                                },
                                expression: "questionForm.subcategory",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "Communication Skills",
                                  value: "Communication Skills",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: "1:1 Conversations111",
                                  value: "1:1 Conversations111",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Question Format", prop: "questionFormat" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.questionForm.questionFormat,
                        callback: function ($$v) {
                          _vm.$set(_vm.questionForm, "questionFormat", $$v)
                        },
                        expression: "questionForm.questionFormat",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "text", value: "text" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "video", value: "video" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "image", value: "image" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Question Text" } },
                [
                  _c("survey-multiple-textbox", {
                    attrs: { value: _vm.questionForm.questionTextObjects },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Answer Format", prop: "answerFormat" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.questionForm.answerFormat,
                        callback: function ($$v) {
                          _vm.$set(_vm.questionForm, "answerFormat", $$v)
                        },
                        expression: "questionForm.answerFormat",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "textarea", value: "textarea" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "textbox", value: "textbox" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "textbox array",
                          value: "textboxArray",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "integer textbox",
                          value: "integerTextbox",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "scale", value: "scale" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "radio", value: "radio" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "radio and text",
                          value: "radioAndText",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "select", value: "select" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "checkbox", value: "checkbox" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.questionForm.answerFormat === "textbox" ||
              _vm.questionForm.answerFormat === "textboxArray" ||
              _vm.questionForm.answerFormat === "textarea"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Placeholder" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.questionForm.answerTextPlaceHolder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.questionForm,
                              "answerTextPlaceHolder",
                              $$v
                            )
                          },
                          expression: "questionForm.answerTextPlaceHolder",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.questionForm.answerFormat === "textboxArray"
                ? _c("el-form-item", { attrs: { label: "No of Textbox" } }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.questionForm.noOfTextbox,
                              callback: function ($$v) {
                                _vm.$set(_vm.questionForm, "noOfTextbox", $$v)
                              },
                              expression: "questionForm.noOfTextbox",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.questionForm.answerFormat === "radio" ||
              _vm.questionForm.answerFormat === "radioAndText" ||
              _vm.questionForm.answerFormat === "select" ||
              _vm.questionForm.answerFormat === "checkbox"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Answer Options" } },
                    [
                      _c("survey-multiple-option-input", {
                        attrs: { value: _vm.questionForm.answerOptions },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.questionForm.answerFormat === "scale" ||
              _vm.questionForm.answerFormat === "range" ||
              _vm.questionForm.answerFormat === "integerTextbox"
                ? _c("el-form-item", { attrs: { label: "Answer Max Value" } }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.questionForm.answerMaxValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.questionForm,
                                  "answerMaxValue",
                                  $$v
                                )
                              },
                              expression: "questionForm.answerMaxValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.questionForm.answerFormat === "scale"
                        ? _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Label" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleMaxValueLabelChange(
                                      _vm.questionForm
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.questionForm.maxValueLabel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.questionForm,
                                      "maxValueLabel",
                                      $$v
                                    )
                                  },
                                  expression: "questionForm.maxValueLabel",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.questionForm.answerFormat === "scale" ||
              _vm.questionForm.answerFormat === "range" ||
              _vm.questionForm.answerFormat === "integerTextbox"
                ? _c("el-form-item", { attrs: { label: "Answer Min Value" } }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.questionForm.answerMinValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.questionForm,
                                  "answerMinValue",
                                  $$v
                                )
                              },
                              expression: "questionForm.answerMinValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.questionForm.answerFormat === "scale"
                        ? _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Label" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleMinValueLabelChange(
                                      _vm.questionForm
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.questionForm.minValueLabel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.questionForm,
                                      "minValueLabel",
                                      $$v
                                    )
                                  },
                                  expression: "questionForm.minValueLabel",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Show Question" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": "Y", "false-label": "N" },
                    model: {
                      value: _vm.questionForm.isEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.questionForm, "isEnabled", $$v)
                      },
                      expression: "questionForm.isEnabled",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Is Required" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": "Y", "false-label": "N" },
                    model: {
                      value: _vm.questionForm.isRequired,
                      callback: function ($$v) {
                        _vm.$set(_vm.questionForm, "isRequired", $$v)
                      },
                      expression: "questionForm.isRequired",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Logic Tags" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.questionForm.tags,
                        callback: function ($$v) {
                          _vm.$set(_vm.questionForm, "tags", $$v)
                        },
                        expression: "questionForm.tags",
                      },
                    },
                    [
                      _c(
                        "el-option-group",
                        { attrs: { label: "Email Logic" } },
                        [
                          _c("el-option", {
                            attrs: { label: "boss_email", value: "boss_email" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "dm_email", value: "dm_email" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "peer_email", value: "peer_email" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-option-group",
                        { attrs: { label: "Report Logic" } },
                        [
                          _c("el-option", {
                            attrs: { label: "average", value: "average" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              label: "kpi_alignment",
                              value: "kpi_alignment",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              label: "role_alignment",
                              value: "role_alignment",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "delegation", value: "delegation" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              label: "reading_and_responding",
                              value: "reading_and_responding",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "time_based", value: "time_based" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              label: "plan_for_role_dev",
                              value: "plan_for_role_dev",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "candor", value: "candor" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "eq", value: "eq" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-option-group",
                        { attrs: { label: "Other Logic" } },
                        [
                          _c("el-option", {
                            attrs: { label: "select_3", value: "select_3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelQuestion } }, [
                _vm._v("Cancel"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmQuestion()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }