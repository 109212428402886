var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "margin-bottom": "20px" }, attrs: { span: 24 } },
            [
              _c("span", { staticStyle: { "font-size": "1em" } }, [
                _vm._v("Default Weekly Credits"),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm.defaultRule
                ? _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "1em",
                            "padding-right": "5px",
                          },
                        },
                        [_vm._v("Default:")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.defaultRule[0].rule.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.defaultRule[0].rule, "value", $$v)
                          },
                          expression: "defaultRule[0].rule.value",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.update(_vm.defaultRule[0])
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogCreateFormVisible = true
                    },
                  },
                },
                [_vm._v("Add Individual Weekly Credit Setting")]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Rater Weekly Credit Setting",
                    visible: _vm.dialogCreateFormVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogCreateFormVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.newSetting } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Rater", "label-width": "120px" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "-- Select --" },
                              model: {
                                value: _vm.newSetting.rule.applyTo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newSetting.rule, "applyTo", $$v)
                                },
                                expression: "newSetting.rule.applyTo",
                              },
                            },
                            _vm._l(_vm.raterList, function (item) {
                              return _c("el-option", {
                                key: item.user,
                                attrs: { label: item.name, value: item.user },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Credits", "label-width": "120px" } },
                        [
                          _c("el-input-number", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.newSetting.rule.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.newSetting.rule, "value", $$v)
                              },
                              expression: "newSetting.rule.value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogCreateFormVisible = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.create(_vm.newSetting)
                            },
                          },
                        },
                        [_vm._v("Confirm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.raterRules,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "Type", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.type))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "300px", align: "center", label: "User ID/Rater" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { class: _vm.isRater(row.rule.applyTo) }, [
                      _c("span", [_vm._v(_vm._s(row.rule.applyTo))]),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.getUserName(row.rule.applyTo))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120px", align: "center", label: "Credit" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.rule.value))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "Created At",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.createdAt))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "Updated At",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.updatedAt))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmEdit(row)
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "Rater Weekly Credit Setting",
                          visible: _vm.dialogUpdateFormVisible,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogUpdateFormVisible = $event
                          },
                        },
                      },
                      [
                        _c(
                          "el-form",
                          { attrs: { model: _vm.updatedSetting } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Rater",
                                  "label-width": "120px",
                                },
                              },
                              [
                                _vm.updatedSetting
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getUserName(
                                            _vm.updatedSetting.rule.applyTo
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Credits",
                                  "label-width": "120px",
                                },
                              },
                              [
                                _vm.updatedSetting
                                  ? _c("el-input-number", {
                                      attrs: { size: "medium" },
                                      model: {
                                        value: _vm.updatedSetting.rule.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updatedSetting.rule,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "updatedSetting.rule.value",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.dialogUpdateFormVisible = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.update(_vm.updatedSetting)
                                  },
                                },
                              },
                              [_vm._v("Confirm")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }