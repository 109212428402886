var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "10px",
            display: "block",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "16px",
                "margin-bottom": "10px",
                padding: "10px 0",
              },
            },
            [_vm._v("ID: " + _vm._s(_vm.hikeId))]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { "font-size": "16px", padding: "10px 0" } },
            [_vm._v("Title: " + _vm._s(_vm.hikeTitle))]
          ),
          _vm._v(" "),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _vm.report
        ? _c(
            "el-button",
            {
              staticStyle: { margin: "0 0 20px 0" },
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-document",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("\n      Export Excel\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.report
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.report,
                fit: "",
                "highlight-current-row": "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Given name", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.firstName))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3673566267
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Surname", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.lastName))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  559970763
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Email", width: "250" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.email))])]
                      },
                    },
                  ],
                  null,
                  false,
                  2278809098
                ),
              }),
              _vm._v(" "),
              _vm._l(_vm.column, function (s, index) {
                return _c(
                  "el-table-column",
                  {
                    key: index,
                    attrs: { width: "100", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row["stage" + (index + 1)]
                                ? _c("i", {
                                    staticClass: "el-icon-check",
                                    staticStyle: { color: "green" },
                                  })
                                : _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: { color: "red" },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v("\n          " + _vm._s("Stage" + (index + 1))),
                      _c("br"),
                      _vm._v("\n          " + _vm._s(s) + "\n        "),
                    ]),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-size": "16px",
          "margin-bottom": "10px",
          padding: "10px 0",
        },
      },
      [_c("u", [_vm._v("Attendance Report")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }