"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.myMSALObj = exports.msalConfig = exports.loginRequest = void 0;
var _msalBrowser = require("@azure/msal-browser");
// Config object to be passed to Msal on creation
var msalConfig = exports.msalConfig = {
  auth: {
    clientId: 'e055b7b1-d157-4160-b8d7-f2f53d2b7c68',
    authority: 'https://login.microsoftonline.com/228a1194-9dbe-4068-b480-f565dd4f18e6/',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    // This configures where your cache will be stored [localStorage /sessionStorage]
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },

  system: {
    loggerOptions: {
      loggerCallback: function loggerCallback(level, message, containsPii) {
        if (containsPii) {
          return;
        }
        switch (level) {
          case _msalBrowser.LogLevel.Error:
            console.error(message);
            return;
          case _msalBrowser.LogLevel.Info:
            console.info(message);
            return;
          case _msalBrowser.LogLevel.Verbose:
            console.debug(message);
            return;
          case _msalBrowser.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: _msalBrowser.LogLevel.Verbose
    }
  }
};
var myMSALObj = exports.myMSALObj = new _msalBrowser.PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
var loginRequest = exports.loginRequest = {
  responseMode: 'query',
  scopes: ['User.Read'],
  redirectUri: window.location.origin + "/blank.html"
};