var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "Level of Education" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.educationLevel,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "educationLevel", $$v)
                },
                expression: "selectedUser.educationLevel",
              },
            },
            _vm._l(_vm.educationList, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Certifications" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "Please input",
              autosize: { minRows: 10, maxRows: 10 },
              maxlength: "3000",
              "show-word-limit": "",
            },
            model: {
              value: _vm.selectedUser.certifications,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "certifications", $$v)
              },
              expression: "selectedUser.certifications",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _vm.checkPermission(["root", "otr-digital-producer"])
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("Update")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/users" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }