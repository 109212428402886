"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    coin: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          user: '',
          nextRedeem: '',
          transaction: []
        };
      }
    }
  },
  data: function data() {
    return {
      list: this.coin.transaction,
      listLoading: false,
      totalCoin: 0
    };
  },
  created: function created() {
    // console.log('transaction', this.list)
    this.getTotal();
  },
  methods: {
    getTotal: function getTotal() {
      var _this = this;
      this.coin.transaction.forEach(function (tx) {
        // console.log('coin', tx.amount)
        _this.totalCoin += tx.amount;
      });
    }
  }
};