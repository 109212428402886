"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/codebuild/output/src365382019/src/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; } /** When your routing table is too long, you can split it into small modules **/
var componentsRouter = {
  path: '/components',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'ComponentDemo',
  meta: {
    title: 'Components',
    icon: 'component'
  },
  children: [{
    path: 'tinymce',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/tinymce'));
      });
    },
    name: 'TinymceDemo',
    meta: {
      title: 'Tinymce'
    }
  }, {
    path: 'markdown',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/markdown'));
      });
    },
    name: 'MarkdownDemo',
    meta: {
      title: 'Markdown'
    }
  }, {
    path: 'json-editor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/json-editor'));
      });
    },
    name: 'JsonEditorDemo',
    meta: {
      title: 'JSON Editor'
    }
  }, {
    path: 'split-pane',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/split-pane'));
      });
    },
    name: 'SplitpaneDemo',
    meta: {
      title: 'SplitPane'
    }
  }, {
    path: 'avatar-upload',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/avatar-upload'));
      });
    },
    name: 'AvatarUploadDemo',
    meta: {
      title: 'Upload'
    }
  }, {
    path: 'dropzone',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/dropzone'));
      });
    },
    name: 'DropzoneDemo',
    meta: {
      title: 'Dropzone'
    }
  }, {
    path: 'sticky',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/sticky'));
      });
    },
    name: 'StickyDemo',
    meta: {
      title: 'Sticky'
    }
  }, {
    path: 'count-to',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/count-to'));
      });
    },
    name: 'CountToDemo',
    meta: {
      title: 'Count To'
    }
  }, {
    path: 'mixin',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/mixin'));
      });
    },
    name: 'ComponentMixinDemo',
    meta: {
      title: 'Component Mixin'
    }
  }, {
    path: 'back-to-top',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/back-to-top'));
      });
    },
    name: 'BackToTopDemo',
    meta: {
      title: 'Back To Top'
    }
  }, {
    path: 'drag-dialog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/drag-dialog'));
      });
    },
    name: 'DragDialogDemo',
    meta: {
      title: 'Drag Dialog'
    }
  }, {
    path: 'drag-select',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/drag-select'));
      });
    },
    name: 'DragSelectDemo',
    meta: {
      title: 'Drag Select'
    }
  }, {
    path: 'dnd-list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/dnd-list'));
      });
    },
    name: 'DndListDemo',
    meta: {
      title: 'Dnd List'
    }
  }, {
    path: 'drag-kanban',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/components-demo/drag-kanban'));
      });
    },
    name: 'DragKanbanDemo',
    meta: {
      title: 'Drag Kanban'
    }
  }]
};
var _default = exports.default = componentsRouter;