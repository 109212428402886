var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v("\n\t\t\t " + _vm._s(_vm.internalTitle) + "\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.loading,
                      expression: "loading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.participantList,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "sort-change": _vm.sort_change },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "", "min-width": "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.profilePic
                              ? _c("img", {
                                  attrs: {
                                    src: scope.row.profilePic,
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("@/assets/otr/images/default_avatar.png"),
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Name",
                      "min-width": "250",
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "givenName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.givenName) +
                                  " " +
                                  _vm._s(scope.row.familyName)
                              ),
                              _c("br"),
                              _vm._v(_vm._s(scope.row.uuid)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Email",
                      "min-width": "200",
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "email",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.email))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Company",
                      "min-width": "250",
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                      prop: "orgName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.orgName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Un-enroll", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.unEnrollParticipant(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Un-Enroll")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Enrolled by", "min-width": "280" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.haveLoop === true
                              ? _c("div", [
                                  scope.row.enrolledBy
                                    ? _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              scope.row.enrolledBy.givenName
                                            ) +
                                            " " +
                                            _vm._s(
                                              scope.row.enrolledBy.familyName
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "Select",
                                          size: "small",
                                        },
                                        model: {
                                          value: scope.row.enrolledByVal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "enrolledByVal",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.enrolledByVal",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "N/A", value: null },
                                        }),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.staff,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.profile
                                                  ? item.profile.givenName +
                                                    " " +
                                                    item.profile.familyName
                                                  : "",
                                                value: item.profile.uuid,
                                              },
                                            })
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.haveLoop === false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "Reassign Enrolled By",
                          "min-width": "250",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.staff.length === 0,
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.UpdateHikeParticipantEnrolledBy(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Update")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2247265907
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Un-enroll participant",
            visible: _vm.unEnrollParticipantVisible1,
            width: "350px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.unEnrollParticipantVisible1 = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "Are you sure you want to un-enroll this user: (" +
                _vm._s(_vm.unEnrollParticipantName) +
                ")?"
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.unEnrollParticipantVisible1 = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.unEnrollParticipantFirstConfirm()
                    },
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Un-enroll participant",
            visible: _vm.unEnrollParticipantVisible2,
            width: "350px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.unEnrollParticipantVisible2 = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "WARNING, this action cannot be undone.\n\t\t\t\t" +
                _vm._s(_vm.unEnrollParticipantName) +
                " will lose all access to this\n\t\t\t\tevent/program."
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.unEnrollParticipantVisible2 = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.doUnEnrollParticipant()
                    },
                  },
                },
                [_vm._v("Un-enroll")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }