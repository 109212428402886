var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.user
        ? _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, xs: 24 } },
                    [
                      _vm.user.id
                        ? _c("user-card", { attrs: { user: _vm.user } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18, xs: 24 } },
                    [
                      _c(
                        "el-card",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.update },
                            },
                            [_vm._v("Adjustment")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-top": "10px" } }, [
                            _vm._v(
                              "Total Coin(s) : " + _vm._s(_vm.totalCoin) + " "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              key: "0",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.coin.transaction,
                                height: "500",
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "TX Date",
                                  prop: "txDate",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(row.txDate)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  566936124
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Type",
                                  prop: "ategory",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(row.category)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2711811604
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Amount",
                                  prop: "amount",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(row.amount)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2170510984
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Created At",
                                  prop: "createdAt",
                                  width: "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(row.createdAt)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  400856593
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: "Coins Adjustment" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                { staticClass: "custom-label", staticStyle: { width: "50px" } },
                [_vm._v("Type : ")]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.coinTx.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.coinTx, "category", $$v)
                    },
                    expression: "coinTx.category",
                  },
                },
                _vm._l(_vm.coinCategory, function (c) {
                  return _c("el-option", {
                    key: c,
                    attrs: { label: c, value: c },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                { staticClass: "custom-label", staticStyle: { width: "50px" } },
                [_vm._v("Amount : ")]
              ),
              _vm._v(" "),
              _c("el-input-number", {
                model: {
                  value: _vm.coinTx.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.coinTx, "amount", $$v)
                  },
                  expression: "coinTx.amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addCoinTx()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }