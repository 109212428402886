"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _ImageCropper = _interopRequireDefault(require("@/components/ImageCropper"));
var _coin = require("@/api/coin");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ImageCropper: _ImageCropper.default,
    PanThumb: _PanThumb.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          givenName: '',
          familyName: '',
          profilePic: '',
          email: '',
          role: '',
          roles: [],
          bio: ''
        };
      }
    }
  },
  data: function data() {
    return {
      imagecropperShow: false,
      imagecropperKey: 0,
      uploadUrl: process.env.VUE_APP_BASE_AUTH_API + '/users/me/image',
      headers: {
        'Authorization': 'Bearer ',
        'Content-Type': 'application/octet-stream'
      },
      noCircle: true,
      totalCoin: 0
    };
  },
  created: function created() {
    var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
    this.headers.Authorization = 'Bearer ' + token;
    // this.getUserCoin()
  },

  methods: {
    cropSuccess: function cropSuccess(resData) {
      var email = (0, _cookie.getCookie)('EMAIL');

      // console.log('page-profile-uploadimage', resData)
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.user.profilePic = resData.profilePic;
      (0, _ga.writeGaEvent)(this, 'User', 'Update My Picture', email, "User Profile: ".concat(resData.uuid));
    },
    close: function close() {
      this.imagecropperShow = false;
    },
    getUserCoin: function getUserCoin() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var uuid = (0, _cookie.getCookie)('UUID');
      var coin = null;
      (0, _coin.getUserCoin)(token, uuid, false).then(function (response) {
        // console.log('page-useredit-coin-response', response.data)
        coin = response.data;
        coin.transaction.forEach(function (tx) {
          // console.log('coin', tx.amount)
          _this.totalCoin += tx.amount;
        });
      }).catch(function (err) {
        (0, _log.showDebugLog)('page-user-err', err);
        (0, _log.displayPopupMessage)(err, 'error');
      });
    }
  }
};