"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.export_json_to_excel = export_json_to_excel;
exports.export_json_to_excel_with_multiple_sheets = export_json_to_excel_with_multiple_sheets;
exports.export_table_to_excel = export_table_to_excel;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.typed.uint8-array");
var _fileSaver = require("file-saver");
var _xlsx = _interopRequireDefault(require("xlsx"));
/* eslint-disable */

function generateArray(table) {
  var out = [];
  var rows = table.querySelectorAll('tr');
  var ranges = [];
  for (var R = 0; R < rows.length; ++R) {
    var outRow = [];
    var row = rows[R];
    var columns = row.querySelectorAll('td');
    for (var C = 0; C < columns.length; ++C) {
      var cell = columns[C];
      var colspan = cell.getAttribute('colspan');
      var rowspan = cell.getAttribute('rowspan');
      var cellValue = cell.innerText;
      if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

      //Skip ranges
      ranges.forEach(function (range) {
        if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
          for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
        }
      });

      //Handle Row Span
      if (rowspan || colspan) {
        rowspan = rowspan || 1;
        colspan = colspan || 1;
        ranges.push({
          s: {
            r: R,
            c: outRow.length
          },
          e: {
            r: R + rowspan - 1,
            c: outRow.length + colspan - 1
          }
        });
      }
      ;

      //Handle Value
      outRow.push(cellValue !== "" ? cellValue : null);

      //Handle Colspan
      if (colspan) for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
    }
    out.push(outRow);
  }
  return [out, ranges];
}
;
function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}
function sheet_from_array_of_arrays(data, opts) {
  var ws = {};
  var range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  for (var R = 0; R != data.length; ++R) {
    for (var C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = {
        v: data[R][C]
      };
      if (cell.v == null) continue;
      var cell_ref = _xlsx.default.utils.encode_cell({
        c: C,
        r: R
      });
      if (typeof cell.v === 'number') cell.t = 'n';else if (typeof cell.v === 'boolean') cell.t = 'b';else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = _xlsx.default.SSF._table[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';
      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = _xlsx.default.utils.encode_range(range);
  return ws;
}
function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
function export_table_to_excel(id) {
  var theTable = document.getElementById(id);
  var oo = generateArray(theTable);
  var ranges = oo[1];

  /* original data */
  var data = oo[0];
  var ws_name = "SheetJS";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);

  /* add ranges to worksheet */
  // ws['!cols'] = ['apple', 'banan'];
  ws['!merges'] = ranges;

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;
  var wbout = _xlsx.default.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  });
  (0, _fileSaver.saveAs)(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), "test.xlsx");
}
function export_json_to_excel() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$multiHeader = _ref.multiHeader,
    multiHeader = _ref$multiHeader === void 0 ? [] : _ref$multiHeader,
    header = _ref.header,
    data = _ref.data,
    filename = _ref.filename,
    _ref$merges = _ref.merges,
    merges = _ref$merges === void 0 ? [] : _ref$merges,
    _ref$autoWidth = _ref.autoWidth,
    autoWidth = _ref$autoWidth === void 0 ? true : _ref$autoWidth,
    _ref$bookType = _ref.bookType,
    bookType = _ref$bookType === void 0 ? 'xlsx' : _ref$bookType;
  /* original data */
  filename = filename || 'excel-list';
  data = (0, _toConsumableArray2.default)(data);
  data.unshift(header);
  for (var i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i]);
  }
  var ws_name = "SheetJS";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);
  if (merges.length > 0) {
    if (!ws['!merges']) ws['!merges'] = [];
    merges.forEach(function (item) {
      ws['!merges'].push(_xlsx.default.utils.decode_range(item));
    });
  }
  if (autoWidth) {
    /*设置worksheet每列的最大宽度*/
    var colWidth = data.map(function (row) {
      return row.map(function (val) {
        /*先判断是否为null/undefined*/
        if (val == null) {
          return {
            'wch': 10
          };
        }
        /*再判断是否为中文*/else if (val.toString().charCodeAt(0) > 255) {
          return {
            'wch': val.toString().length * 2
          };
        } else {
          return {
            'wch': val.toString().length
          };
        }
      });
    });
    /*以第一行为初始值*/
    var result = colWidth[0];
    for (var _i = 1; _i < colWidth.length; _i++) {
      for (var j = 0; j < colWidth[_i].length; j++) {
        if (result[j]['wch'] < colWidth[_i][j]['wch']) {
          result[j]['wch'] = colWidth[_i][j]['wch'];
        }
      }
    }
    ws['!cols'] = result;
  }

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;
  var wbout = _xlsx.default.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  (0, _fileSaver.saveAs)(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), "".concat(filename, ".").concat(bookType));
}

/**
 * Referring to the function export_json_to_excel().
 * Add by Eric Tang.
 */
function export_json_to_excel_with_multiple_sheets() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    sheets = _ref2.sheets,
    filename = _ref2.filename,
    _ref2$autoWidth = _ref2.autoWidth,
    autoWidth = _ref2$autoWidth === void 0 ? true : _ref2$autoWidth,
    _ref2$bookType = _ref2.bookType,
    bookType = _ref2$bookType === void 0 ? 'xlsx' : _ref2$bookType;
  /* original data */
  filename = filename || 'excel-list';
  var wb = new Workbook();
  sheets.forEach(function (sheet) {
    var data = (0, _toConsumableArray2.default)(sheet.data);
    data.unshift(sheet.header);
    if (sheet.multiHeader && sheet.multiHeader.length) {
      for (var i = sheet.multiHeader.length - 1; i > -1; i--) {
        data.unshift(sheet.multiHeader[i]);
      }
    }
    var ws = sheet_from_array_of_arrays(data);
    if (sheet.merges && sheet.merges.length && sheet.merges.length > 0) {
      if (!ws['!merges']) ws['!merges'] = [];
      sheet.merges.forEach(function (item) {
        ws['!merges'].push(_xlsx.default.utils.decode_range(item));
      });
    }
    if (autoWidth) {
      /*设置worksheet每列的最大宽度*/
      var colWidth = data.map(function (row) {
        return row.map(function (val) {
          /*先判断是否为null/undefined*/
          if (val == null) {
            return {
              'wch': 10
            };
          }
          /*再判断是否为中文*/else if (val.toString().charCodeAt(0) > 255) {
            return {
              'wch': val.toString().length * 2
            };
          } else {
            return {
              'wch': val.toString().length
            };
          }
        });
      });
      /*以第一行为初始值*/
      var result = colWidth[0];
      for (var _i2 = 1; _i2 < colWidth.length; _i2++) {
        for (var j = 0; j < colWidth[_i2].length; j++) {
          if (result[j]['wch'] < colWidth[_i2][j]['wch']) {
            result[j]['wch'] = colWidth[_i2][j]['wch'];
          }
        }
      }
      ws['!cols'] = result;
    }

    /* add worksheet to workbook */
    wb.SheetNames.push(sheet.name);
    wb.Sheets[sheet.name] = ws;
  });
  var wbout = _xlsx.default.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  (0, _fileSaver.saveAs)(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), "".concat(filename, ".").concat(bookType));
}