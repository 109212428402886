"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserCoin = getUserCoin;
exports.updateUserCoin = updateUserCoin;
var _request = _interopRequireDefault(require("@/utils/request"));
function getUserCoin(token, uuid, isSummary) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/user-coins/".concat(uuid, "?isSummary=").concat(isSummary),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers
  });
}
function updateUserCoin(token, uuid, tx) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/user-coins/".concat(uuid),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'patch',
    headers: headers,
    data: tx
  });
}