var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "Prefix" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.prefix,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "prefix", $$v)
                },
                expression: "selectedUser.prefix",
              },
            },
            _vm._l(_vm.prefixList, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "*Given Name" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.givenName,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "givenName", $$v)
              },
              expression: "selectedUser.givenName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Middle Name" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.middleName,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "middleName", $$v)
              },
              expression: "selectedUser.middleName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "*Surname" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.familyName,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "familyName", $$v)
              },
              expression: "selectedUser.familyName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Suffix" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.suffix,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "suffix", $$v)
                },
                expression: "selectedUser.suffix",
              },
            },
            _vm._l(_vm.suffixList, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Gender" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.genderCode,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "genderCode", $$v)
                },
                expression: "selectedUser.genderCode",
              },
            },
            _vm._l(_vm.genderList, function (item) {
              return _c("el-option", {
                key: item.genderCode,
                attrs: { label: item.gender, value: item.genderCode },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Race / Ethnicity" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.race,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "race", $$v)
              },
              expression: "selectedUser.race",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Nationality" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.nationality,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "nationality", $$v)
              },
              expression: "selectedUser.nationality",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Phone" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.phone,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "phone", $$v)
              },
              expression: "selectedUser.phone",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Mobile" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.mobile,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "mobile", $$v)
              },
              expression: "selectedUser.mobile",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Website" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.website,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "website", $$v)
              },
              expression: "selectedUser.website",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Birthday" } },
        [
          _c("el-date-picker", {
            staticStyle: { width: "100%" },
            attrs: {
              type: "date",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              placeholder: "Select date",
            },
            model: {
              value: _vm.selectedUser.birthday,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "birthday", $$v)
              },
              expression: "selectedUser.birthday",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Title" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.jobTitle,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "jobTitle", $$v)
              },
              expression: "selectedUser.jobTitle",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Department" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.department,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "department", $$v)
              },
              expression: "selectedUser.department",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Company" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.orgName,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "orgName", $$v)
              },
              expression: "selectedUser.orgName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Number of Years in Role" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.yrInRole,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "yrInRole", $$v)
                },
                expression: "selectedUser.yrInRole",
              },
            },
            _vm._l(_vm.yearInRoleList, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Bio" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 5, placeholder: "Please input" },
            model: {
              value: _vm.selectedUser.bio,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "bio", $$v)
              },
              expression: "selectedUser.bio",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "span",
            { staticClass: "custom-label", staticStyle: { width: "100%" } },
            [_vm._v("Other Email")]
          ),
          _vm._v(" "),
          _vm._l(_vm.selectedUser.othEmail, function (e, idx) {
            return _c(
              "div",
              { key: idx, staticStyle: { width: "100%" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "20%" },
                    attrs: { placeholder: "Select" },
                    model: {
                      value: e.emailType,
                      callback: function ($$v) {
                        _vm.$set(e, "emailType", $$v)
                      },
                      expression: "e.emailType",
                    },
                  },
                  _vm._l(_vm.typeList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "78%" },
                  model: {
                    value: e.value,
                    callback: function ($$v) {
                      _vm.$set(e, "value", $$v)
                    },
                    expression: "e.value",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _vm.checkPermission(["root", "otr-digital-producer"])
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("Update")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/users" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }