"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 权限判断指令
var _default2 = exports.default = {
  directives: {
    permission: _index.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          uuid: '',
          prefix: '',
          givenName: '',
          middleName: '',
          familyName: '',
          suffix: '',
          genderCode: '',
          race: '',
          nationality: '',
          phone: '',
          mobile: '',
          birthday: '',
          website: '',
          jobTitle: '',
          department: '',
          orgName: '',
          yrInRole: '',
          bio: '',
          othEmail: [{
            emailType: 'Work',
            value: ''
          }, {
            emailType: 'Work',
            value: ''
          }]
        };
      }
    }
  },
  data: function data() {
    return {
      countryList: [],
      genderList: [],
      prefixList: ['', 'Mr', 'Miss', 'Mrs', 'Ms', 'Dr', 'Prof', 'Sir', 'Lady'],
      suffixList: ['', 'Sr.', 'Jr.', 'II', 'III'],
      yearInRoleList: ['0-1', '2-5', '6-10', '11-15', '15+'],
      typeList: ['Home', 'Work', 'Other'],
      selectedUser: {}
    };
  },
  created: function created() {
    var _this = this;
    // console.log('page-user-acount-user', this.user)
    var tmpEmail = this.user.othEmail.length > 0 ? this.user.othEmail : [{
      emailType: 'Work',
      value: ''
    }, {
      emailType: 'Work',
      value: ''
    }];
    this.selectedUser = {
      id: this.user.id,
      uuid: this.user.uuid,
      prefix: this.user.prefix,
      givenName: this.user.givenName,
      middleName: this.user.middleName,
      familyName: this.user.familyName,
      suffix: this.user.suffix,
      genderCode: this.user.genderCode,
      race: this.user.race,
      nationality: this.user.nationality,
      phone: this.user.phone,
      mobile: this.user.mobile,
      website: this.user.website,
      birthday: this.user.birthday,
      jobTitle: this.user.jobTitle,
      department: this.user.department,
      orgName: this.user.orgName,
      yrInRole: this.user.yrInRole,
      bio: this.user.bio,
      othEmail: tmpEmail
    };
    this.$store.dispatch('facets/getInitData', 'en').then(function () {
      _this.countryList = _this.$store.getters.countries;
      _this.genderList = _this.$store.getters.genders;
    });
  },
  methods: {
    checkPermission: _permission.default,
    save: function save() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      if (this.selectedUser.givenName === "") {
        var msg = "Given name is missing";
        (0, _log.displayPopupMessage)(msg, "error");
        return;
      }
      if (this.selectedUser.familyName === "") {
        var _msg = "Surname is missing";
        (0, _log.displayPopupMessage)(_msg, "error");
        return;
      }
      // console.log('token', token)
      (0, _user.updateUserProfile)(token, this.selectedUser).then(function (response) {
        var data = response.data;
        // console.log('page-user-save', response)
        _this2.selectedUser.prefix = data.prefix;
        _this2.selectedUser.givenName = data.givenName;
        _this2.selectedUser.middleName = data.middleName;
        _this2.selectedUser.familyName = data.familyName;
        _this2.selectedUser.suffix = data.suffix;
        _this2.selectedUser.genderCode = data.genderCode;
        _this2.selectedUser.race = data.race;
        _this2.selectedUser.nationality = data.nationality;
        _this2.selectedUser.phone = data.phone;
        _this2.selectedUser.mobile = data.mobile;
        _this2.selectedUser.website = data.website;
        _this2.selectedUser.birthday = data.birthday;
        _this2.selectedUser.genderCode = data.genderCode;
        _this2.selectedUser.jobTitle = data.jobTitle;
        _this2.selectedUser.department = data.department;
        _this2.selectedUser.orgName = data.orgName;
        _this2.selectedUser.yrInRole = data.yrInRole;
        _this2.selectedUser.bio = data.bio;
        _this2.selectedUser.othEmail = data.othEmail;
        (0, _log.displayPopupMessage)('User information has been updated successfully', 'success');
        (0, _ga.writeGaEvent)(_this2, 'User', 'Update User Profile', email, "User Profile: ".concat(data.uuid));
      }).catch(function (error) {
        (0, _log.showDebugLog)('Page-user-Save-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};