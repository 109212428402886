"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _training = require("@/api/training");
var _cookie = require("@/utils/cookie");
var _log = require("@/utils/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TrainingUserProgress',
  data: function data() {
    return {
      tableData: [],
      myTrainingTeams: []
    };
  },
  created: function created() {
    this.getTeam();
  },
  methods: {
    getTeam: function getTeam() {
      var _this = this;
      var id = typeof this.$route.params.id === 'undefined' ? '' : this.$route.params.id;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _training.getTrainingTeams)(token, id).then(function (response) {
        (0, _log.showDebugLog)('ShowTeam', response.data.rows);
        if (response.data.rows.length > 0) {
          _this.myTrainingTeams = response.data.rows[0].members;
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('GetTeam-API-error', error);
      });
    }
  }
};