var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.internalTitle))]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary", disabled: _vm.haveLoop },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.sendEnrollmentToPOC()
            },
          },
        },
        [_vm._v("Send enrollment form to Client POC")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "activity-kanban" }, [
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c(
              "div",
              { staticClass: "board-column-header library" },
              [
                _vm._v("Staff Library\n        "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      content: "OTR coach and digital producer.",
                      title: "Tip",
                      placement: "right",
                      width: "400",
                      trigger: "hover",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "otr-guide",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "question-circle-regular" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "board-column-search search" },
              [
                _c("el-input", {
                  attrs: { placeholder: "Keyword Search", size: "mini" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getStaffList($event)
                    },
                  },
                  model: {
                    value: _vm.keywordSearch,
                    callback: function ($$v) {
                      _vm.keywordSearch = $$v
                    },
                    expression: "keywordSearch",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  list: _vm.staffList,
                  options: {
                    group: { name: "staff", pull: "clone", put: false },
                  },
                  clone: _vm.cloneLibrary,
                },
                on: { change: _vm.updateLibrary },
              },
              _vm._l(_vm.staffList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c("div", { staticClass: "item" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v("Coach"),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "coach",
                  list: _vm.coachList,
                  options: { group: "staff" },
                },
                on: { change: _vm.updateCoachList },
              },
              _vm._l(_vm.coachList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item blue",
                      staticStyle: { float: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.removeCoach(idx, _vm.coachList)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.producer"))),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "assistant",
                  list: _vm.assistantList,
                  options: { group: "staff" },
                },
                on: { change: _vm.updateAssitantList },
              },
              _vm._l(_vm.assistantList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item blue",
                      staticStyle: { float: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.removeAssistant(idx, _vm.assistantList)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v("CSM"),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "producer",
                  list: _vm.producerList,
                  options: { group: "staff" },
                },
                on: { change: _vm.updateProducerList },
              },
              _vm._l(_vm.producerList, function (element, idx) {
                return _c("div", { key: idx, staticClass: "board-item" }, [
                  _c(
                    "div",
                    { staticClass: "item", staticStyle: { float: "right" } },
                    [
                      !_vm.isDproducer(element.sysRole)
                        ? _c("svg-icon", {
                            staticStyle: { color: "red" },
                            attrs: { "icon-class": "lightbulb-solid" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.removeProducer(idx, _vm.producerList)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("ID: " + _vm._s(element.user)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item blue" }, [
                    _vm._v("Name: " + _vm._s(element.name)),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "board-column" },
          [
            _c("div", { staticClass: "board-column-header t1" }, [
              _vm._v("Client POC"),
            ]),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "board-column-content",
                attrs: {
                  id: "contact",
                  list: _vm.clientPocList,
                  options: { group: "staff" },
                  disabled: _vm.haveLoop,
                },
                on: { change: _vm.updateClientPocList },
              },
              _vm._l(_vm.clientPocList, function (element, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "board-item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item blue",
                        staticStyle: { float: "right" },
                      },
                      [
                        !_vm.haveLoop
                          ? _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.removeClientPoc(
                                    idx,
                                    _vm.clientPocList
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item blue" }, [
                      _vm._v("ID: " + _vm._s(element.user)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item blue" }, [
                      _vm._v("Name: " + _vm._s(element.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item blue" }, [
                      _vm._v("Enrollment Quota:"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      attrs: { min: 0, disabled: _vm.haveLoop },
                      model: {
                        value: element.enrollmentQuota,
                        callback: function ($$v) {
                          _vm.$set(element, "enrollmentQuota", $$v)
                        },
                        expression: "element.enrollmentQuota",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }