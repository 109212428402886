"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.webContentTypeList = exports.validImageExtension = exports.userStatusList = exports.userEvent = exports.trainingStatus = exports.stageType = exports.stageActionType = exports.stageActionSetType = exports.skillCustomDefault = exports.skillCustom1887Default = exports.skillCompassVideoDefault = exports.skillCompassSaleDefault = exports.skillCompassOverallDefault = exports.skillCompassLibrary = exports.skillCompassLeadershipDefault = exports.skillCompassGroupDeliveryDefault = exports.skillCompassGroupContentDefault = exports.showButton = exports.scoringSkillSetList = exports.salesForceTimeZone = exports.roleList = exports.resourceType = exports.resourceSubType = exports.resourceMimeType = exports.recordStatusList = exports.questionTypeList = exports.qrPrefix = exports.programType = exports.programIconColor = exports.programIcon = exports.newRoleList = exports.loopType = exports.loopEmailSupportedPlaceholders = exports.ianaTimeZone = exports.hikeEmailSupportedPlaceholders = exports.extraSkillCompassOverallDefault = exports.defaultReport = exports.default720Report = exports.RuleSet = exports.HikeCategory = exports.ActivationCodeType = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.array.sort");
var _lhJsEmailContentLib = require("@lifehikes/lh-js-email-content-lib");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var userStatusList = exports.userStatusList = ['CONFIRMED', 'UNCONFIRMED', 'DISABLED', 'DELETED'];
var recordStatusList = exports.recordStatusList = ['draft', 'preview', 'published', 'archived'];
var questionTypeList = exports.questionTypeList = ['baseline', 'practice'];
var webContentTypeList = exports.webContentTypeList = ['b2b-learning-program'];
var roleList = exports.roleList = ['participant', 'otr-digital-producer', 'root', 'client-team-manager', 'hr-admin', 'otr-coach', 'otr-rater', 'otr-product-admin', 'client-staff', 'anonymous', 'otr-delphi-rater', 'otr-delphi-admin', 'tas-qa'];
var newRoleList = exports.newRoleList = [{
  key: "participant",
  val: "Participant",
  description: "Normal App user",
  type: "LifeHikes"
}, {
  key: "otr-digital-producer",
  val: "CSM/Producer",
  description: "Show Coach Tools on iOS App, able to login to Admin Portal",
  type: "LifeHikes"
}, {
  key: "otr-coach",
  val: "Coach",
  description: "Show Coach Tools on iOS App",
  type: "LifeHikes"
}, {
  key: "otr-rater",
  val: "TAS",
  description: "Show Rater Tools on iOS App",
  type: "LifeHikes"
}, {
  key: "tas-qa",
  val: "TAS QA",
  description: "Have access to Hikes, Benchmark, Summit, Task Status Report, Manager Report",
  type: "LifeHikes"
}, {
  key: "tas-admin",
  val: "TAS Admin",
  description: "The Admin staff who manages TAS.",
  type: "LifeHikes"
}, {
  key: "otr-product-admin",
  val: "Product Team",
  description: "Same as Participant (Please DO NOT use it for now)",
  type: "LifeHikes"
}, {
  key: "client-staff",
  val: "Client Staff",
  description: "Client representative. e.g. Client POC who enrolls his/her team members into programs.",
  type: "LifeHikes"
}, {
  key: "otr-delphi-rater",
  val: "Delphi Rater",
  description: "For Delphi portal",
  type: "Delphi"
}, {
  key: "otr-delphi-admin",
  val: "Delphi Admin",
  description: "For Delphi portal",
  type: "Delphi"
}];

//export const scoringSkillSetList = ['communication', 'manager', 'sales', 'hard-skills', 'life-skills', 'demo', 'practice', 'no-rating']
var scoringSkillSetList = exports.scoringSkillSetList = [{
  key: "communication",
  val: "communication",
  description: "communication"
}, {
  key: "manager",
  val: "manager",
  description: "leadership"
}, {
  key: "sales",
  val: "sales",
  description: "sales"
}, {
  key: "hard-skills",
  val: "hard-skills",
  description: "hard-skills"
}, {
  key: "life-skills",
  val: "life-skills",
  description: "life-skills"
}, {
  key: "demo",
  val: "demo",
  description: "demo"
}, {
  key: "practice",
  val: "practice",
  description: "practice"
}, {
  key: "no-rating",
  val: "no-rating",
  description: "no-rating"
}];
var trainingStatus = exports.trainingStatus = ['draft', 'preview', 'published', 'archived'];
var resourceType = exports.resourceType = ['video', 'document', 'text'];
var resourceSubType = exports.resourceSubType = ['file', 'youtube', 'ted'];
var resourceMimeType = exports.resourceMimeType = ['image/jpeg', 'image/png', 'video/mp4', 'application/pdf', 'text/html', 'text/plain'];
var validImageExtension = exports.validImageExtension = '.jpg, .tiff, gif, .bmp, .png';
var qrPrefix = exports.qrPrefix = 'https://lifehikes.com/enrol/';
var stageType = exports.stageType = ['benchmark', 'summit', 'stage'];
var stageActionSetType = exports.stageActionSetType = ['benchmark', 'summit', 'bunny', 'diamond', 'info', 'moment', 'benchmarkreport', 'summitreport'];
// ['benchmark', 'summit', 'bunny', 'diamond', 'info', 'moment', 'resource', 'benchmarkreport', 'summitreport']
var stageActionType = exports.stageActionType = ['video', 'info', 'benchmarkreport', 'summitreport']; // ['video', 'info', 'resource', 'benchmarkreport', 'summitreport']
var userEvent = exports.userEvent = {
  Auth: {
    login: 'Login',
    logout: 'Logout',
    forgetPassword: 'Forget Password',
    changePassword: 'Change Password'
  },
  user: {
    edit: 'Edit User Profile',
    viewUserReport: 'View User Report',
    unenroll: 'Un-Enrollment',
    unenrollDeleteData: 'Un-Enrollment & delete user data',
    resumeUnenroll: 'Resume Un-Enrollment',
    disableUser: 'Disable User',
    enableUser: 'Enable User',
    editCoach: 'Edit Coach Profile',
    uploadCoachPhoto: 'Upload Coach Photo',
    editMyProfile: 'Edit My Profile',
    changePassword: 'Change Password',
    changePhoto: 'Change Photo'
  },
  training: {
    edit: 'Edit Training',
    create: 'Create Training',
    generateCode: 'Generate Training QR Code',
    updateCode: 'Update Training QR Code',
    uploadParticipant: 'Enroll Participant by upload',
    editReportEmailList: 'Edit Final Report Recipient Email List',
    sendReport: 'Send Final Manager Report',
    generateFeedback: 'Generate Feedback Report',
    delete: 'Delete Training'
  },
  hikeAdvancedReport: {
    edit: 'Edit Hike Advanced Report',
    create: 'Create Hike Advanced Report',
    delete: 'Delete Hike Advanced Report'
  },
  hike: {
    edit: 'Edit Hike',
    create: 'Create Hike',
    generateCode: 'Generate Hike QR Code',
    updateCode: 'Update QR Code',
    uploadParticipant: 'Enroll Participant by upload',
    editReportEmailList: 'Edit Final Report Recipient Email List',
    sendReport: 'Send Final Manager Report',
    generateFeedback: 'Generate Feedback Report',
    delete: 'Delete Hike'
  },
  activity: {
    edit: 'Edit Activity',
    create: 'Create Activity',
    export: 'Export Activity',
    delete: 'Delete Activity'
  },
  assessment: {
    edit: 'Edit Assessment',
    create: 'Create Assessment',
    delete: 'Delete Assessment'
  },
  resource: {
    edit: 'Edit Resource',
    create: 'Create Resource',
    delete: 'Delete Resource'
  },
  badge: {
    edit: 'Edit Badge',
    create: 'Create Badge',
    delete: 'Delete Badge'
  },
  publicFile: {
    upload: 'Upload Public File'
  },
  smartTip: {
    edit: 'Edit Smart Tip',
    create: 'Create Smart Tip',
    delete: 'Delete Smart Tip'
  },
  webContent: {
    edit: 'Edit Web Content',
    create: 'Create Web Content',
    delete: 'Delete Web Content'
  },
  raterAssignment: {
    release: 'Release Taks',
    addIndividualRule: 'Add Individual Rule',
    updateIndividualRule: 'Update Individual Rule',
    updateDefaultRule: 'Update Default Rule'
  },
  loop: {
    edit: 'Edit Loop',
    create: 'Create Loop',
    generateCode: 'Generate Loop QR Code',
    updateCode: 'Update Loop QR Code',
    delete: 'Delete Hike',
    uploadParticipant: 'Enroll Participant by upload'
  },
  survey: {
    edit: 'Edit Survey',
    create: 'Create Survey',
    delete: 'Delete Survey'
  }
};
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// export const ianaTimeZone = [
//   { label: '(UTCâˆ’11) Pacific/Niue', offset: 'Pacific/Niue' },
//   { label: '(UTCâˆ’11) Pacific/Pago_Pago', offset: 'Pacific/Pago_Pago' },
//   { label: '(UTCâˆ’10) America/Adak', offset: 'America/Adak' },
//   { label: '(UTCâˆ’10) Pacific/Honolulu', offset: 'Pacific/Honolulu' },
//   { label: '(UTCâˆ’10) Pacific/Rarotonga', offset: 'Pacific/Rarotonga' },
//   { label: '(UTCâˆ’10) Pacific/Tahiti', offset: 'Pacific/Tahiti' },
//   { label: '(UTCâˆ’09) Pacific/Marquesas', offset: 'Pacific/Marquesas' },
//   { label: '(UTCâˆ’09) America/Anchorage', offset: 'America/Anchorage' },
//   { label: '(UTCâˆ’09) America/Juneau', offset: 'America/Juneau' },
//   { label: '(UTCâˆ’09) America/Metlakatla', offset: 'America/Metlakatla' },
//   { label: '(UTCâˆ’09) America/Nome', offset: 'America/Nome' },
//   { label: '(UTCâˆ’09) America/Sitka', offset: 'America/Sitka' },
//   { label: '(UTCâˆ’09) America/Yakutat', offset: 'America/Yakutat' },
//   { label: '(UTCâˆ’09) Pacific/Gambier', offset: 'Pacific/Gambier' },
//   { label: '(UTCâˆ’08) America/Dawson', offset: 'America/Dawson' },
//   { label: '(UTCâˆ’08) America/Los_Angeles', offset: 'America/Los_Angeles' },
//   { label: '(UTCâˆ’08) America/Tijuana', offset: 'America/Tijuana' },
//   { label: '(UTCâˆ’08) America/Vancouver', offset: 'America/Vancouver' },
//   { label: '(UTCâˆ’08) America/Whitehorse', offset: 'America/Whitehorse' },
//   { label: '(UTCâˆ’08) Pacific/Pitcairn', offset: 'Pacific/Pitcairn' },
//   { label: '(UTCâˆ’07) America/Boise', offset: 'America/Boise' },
//   { label: '(UTCâˆ’07) America/Cambridge_Bay', offset: 'America/Cambridge_Bay' },
//   { label: '(UTCâˆ’07) America/Chihuahua', offset: 'America/Chihuahua' },
//   { label: '(UTCâˆ’07) America/Creston', offset: 'America/Creston' },
//   { label: '(UTCâˆ’07) America/Dawson_Creek', offset: 'America/Dawson_Creek' },
//   { label: '(UTCâˆ’07) America/Denver', offset: 'America/Denver' },
//   { label: '(UTCâˆ’07) America/Edmonton', offset: 'America/Edmonton' },
//   { label: '(UTCâˆ’07) America/Fort_Nelson', offset: 'America/Fort_Nelson' },
//   { label: '(UTCâˆ’07) America/Hermosillo', offset: 'America/Hermosillo' },
//   { label: '(UTCâˆ’07) America/Inuvik', offset: 'America/Inuvik' },
//   { label: '(UTCâˆ’07) America/Mazatlan', offset: 'America/Mazatlan' },
//   { label: '(UTCâˆ’07) America/Ojinaga', offset: 'America/Ojinaga' },
//   { label: '(UTCâˆ’07) America/Phoenix', offset: 'America/Phoenix' },
//   { label: '(UTCâˆ’07) America/Yellowknife', offset: 'America/Yellowknife' },
//   { label: '(UTCâˆ’06) America/Bahia_Banderas', offset: 'America/Bahia_Banderas' },
//   { label: '(UTCâˆ’06) America/Belize', offset: 'America/Belize' },
//   { label: '(UTCâˆ’06) America/Chicago', offset: 'America/Chicago' },
//   { label: '(UTCâˆ’06) America/Costa_Rica', offset: 'America/Costa_Rica' },
//   { label: '(UTCâˆ’06) America/El_Salvador', offset: 'America/El_Salvador' },
//   { label: '(UTCâˆ’06) America/Guatemala', offset: 'America/Guatemala' },
//   { label: '(UTCâˆ’06) America/Indiana/Knox', offset: 'America/Indiana/Knox' },
//   { label: '(UTCâˆ’06) America/Indiana/Tell_City', offset: 'America/Indiana/Tell_City' },
//   { label: '(UTCâˆ’06) America/Managua', offset: 'America/Managua' },
//   { label: '(UTCâˆ’06) America/Matamoros', offset: 'America/Matamoros' },
//   { label: '(UTCâˆ’06) America/Menominee', offset: 'America/Menominee' },
//   { label: '(UTCâˆ’06) America/Merida', offset: 'America/Merida' },
//   { label: '(UTCâˆ’06) America/Mexico_City', offset: 'America/Mexico_City' },
//   { label: '(UTCâˆ’06) America/Monterrey', offset: 'America/Monterrey' },
//   { label: '(UTCâˆ’06) America/North_Dakota/Beulah', offset: 'America/North_Dakota/Beulah' },
//   { label: '(UTCâˆ’06) America/North_Dakota/Center', offset: 'America/North_Dakota/Center' },
//   { label: '(UTCâˆ’06) America/North_Dakota/New_Salem', offset: 'America/North_Dakota/New_Salem' },
//   { label: '(UTCâˆ’06) America/Rainy_River', offset: 'America/Rainy_River' },
//   { label: '(UTCâˆ’06) America/Rankin_Inlet', offset: 'America/Rankin_Inlet' },
//   { label: '(UTCâˆ’06) America/Regina', offset: 'America/Regina' },
//   { label: '(UTCâˆ’06) America/Resolute', offset: 'America/Resolute' },
//   { label: '(UTCâˆ’06) America/Swift_Current', offset: 'America/Swift_Current' },
//   { label: '(UTCâˆ’06) America/Tegucigalpa', offset: 'America/Tegucigalpa' },
//   { label: '(UTCâˆ’06) America/Winnipeg', offset: 'America/Winnipeg' },
//   { label: '(UTCâˆ’06) Pacific/Easter', offset: 'Pacific/Easter' },
//   { label: '(UTCâˆ’06) Pacific/Galapagos', offset: 'Pacific/Galapagos' },
//   { label: '(UTCâˆ’05) America/Atikokan', offset: 'America/Atikokan' },
//   { label: '(UTCâˆ’05) America/Bogota', offset: 'America/Bogota' },
//   { label: '(UTCâˆ’05) America/Cancun', offset: 'America/Cancun' },
//   { label: '(UTCâˆ’05) America/Detroit', offset: 'America/Detroit' },
//   { label: '(UTCâˆ’05) America/Eirunepe', offset: 'America/Eirunepe' },
//   { label: '(UTCâˆ’05) America/Grand_Turk', offset: 'America/Grand_Turk' },
//   { label: '(UTCâˆ’05) America/Guayaquil', offset: 'America/Guayaquil' },
//   { label: '(UTCâˆ’05) America/Havana', offset: 'America/Havana' },
//   { label: '(UTCâˆ’05) America/Indiana/Indianapolis', offset: 'America/Indiana/Indianapolis' },
//   { label: '(UTCâˆ’05) America/Indiana/Marengo', offset: 'America/Indiana/Marengo' },
//   { label: '(UTCâˆ’05) America/Indiana/Petersburg', offset: 'America/Indiana/Petersburg' },
//   { label: '(UTCâˆ’05) America/Indiana/Vevay', offset: 'America/Indiana/Vevay' },
//   { label: '(UTCâˆ’05) America/Indiana/Vincennes', offset: 'America/Indiana/Vincennes' },
//   { label: '(UTCâˆ’05) America/Indiana/Winamac', offset: 'America/Indiana/Winamac' },
//   { label: '(UTCâˆ’05) America/Iqaluit', offset: 'America/Iqaluit' },
//   { label: '(UTCâˆ’05) America/Jamaica', offset: 'America/Jamaica' },
//   { label: '(UTCâˆ’05) America/Kentucky/Louisville', offset: 'America/Kentucky/Louisville' },
//   { label: '(UTCâˆ’05) America/Kentucky/Monticello', offset: 'America/Kentucky/Monticello' },
//   { label: '(UTCâˆ’05) America/Lima', offset: 'America/Lima' },
//   { label: '(UTCâˆ’05) America/Nassau', offset: 'America/Nassau' },
//   { label: '(UTCâˆ’05) America/New_York', offset: 'America/New_York' },
//   { label: '(UTCâˆ’05) America/Nipigon', offset: 'America/Nipigon' },
//   { label: '(UTCâˆ’05) America/Panama', offset: 'America/Panama' },
//   { label: '(UTCâˆ’05) America/Pangnirtung', offset: 'America/Pangnirtung' },
//   { label: '(UTCâˆ’05) America/Port-au-Prince', offset: 'America/Port-au-Prince' },
//   { label: '(UTCâˆ’05) America/Rio_Branco', offset: 'America/Rio_Branco' },
//   { label: '(UTCâˆ’05) America/Thunder_Bay', offset: 'America/Thunder_Bay' },
//   { label: '(UTCâˆ’05) America/Toronto', offset: 'America/Toronto' },
//   { label: '(UTCâˆ’04) America/Asuncion', offset: 'America/Asuncion' },
//   { label: '(UTCâˆ’04) America/Barbados', offset: 'America/Barbados' },
//   { label: '(UTCâˆ’04) America/Blanc-Sablon', offset: 'America/Blanc-Sablon' },
//   { label: '(UTCâˆ’04) America/Boa_Vista', offset: 'America/Boa_Vista' },
//   { label: '(UTCâˆ’04) America/Campo_Grande', offset: 'America/Campo_Grande' },
//   { label: '(UTCâˆ’04) America/Caracas', offset: 'America/Caracas' },
//   { label: '(UTCâˆ’04) America/Cuiaba', offset: 'America/Cuiaba' },
//   { label: '(UTCâˆ’04) America/Curacao', offset: 'America/Curacao' },
//   { label: '(UTCâˆ’04) America/Glace_Bay', offset: 'America/Glace_Bay' },
//   { label: '(UTCâˆ’04) America/Goose_Bay', offset: 'America/Goose_Bay' },
//   { label: '(UTCâˆ’04) America/Guyana', offset: 'America/Guyana' },
//   { label: '(UTCâˆ’04) America/Halifax', offset: 'America/Halifax' },
//   { label: '(UTCâˆ’04) America/La_Paz', offset: 'America/La_Paz' },
//   { label: '(UTCâˆ’04) America/Manaus', offset: 'America/Manaus' },
//   { label: '(UTCâˆ’04) America/Martinique', offset: 'America/Martinique' },
//   { label: '(UTCâˆ’04) America/Moncton', offset: 'America/Moncton' },
//   { label: '(UTCâˆ’04) America/Port_of_Spain', offset: 'America/Port_of_Spain' },
//   { label: '(UTCâˆ’04) America/Porto_Velho', offset: 'America/Porto_Velho' },
//   { label: '(UTCâˆ’04) America/Puerto_Rico', offset: 'America/Puerto_Rico' },
//   { label: '(UTCâˆ’04) America/Santiago', offset: 'America/Santiago' },
//   { label: '(UTCâˆ’04) America/Santo_Domingo', offset: 'America/Santo_Domingo' },
//   { label: '(UTCâˆ’04) America/Thule', offset: 'America/Thule' },
//   { label: '(UTCâˆ’04) Atlantic/Bermuda', offset: 'Atlantic/Bermuda' },
//   { label: '(UTCâˆ’03) America/St_Johns', offset: 'America/St_Johns' },
//   { label: '(UTCâˆ’03) America/Araguaina', offset: 'America/Araguaina' },
//   { label: '(UTCâˆ’03) America/Argentina/Buenos_Aires', offset: 'America/Argentina/Buenos_Aires' },
//   { label: '(UTCâˆ’03) America/Argentina/Catamarca', offset: 'America/Argentina/Catamarca' },
//   { label: '(UTCâˆ’03) America/Argentina/Cordoba', offset: 'America/Argentina/Cordoba' },
//   { label: '(UTCâˆ’03) America/Argentina/Jujuy', offset: 'America/Argentina/Jujuy' },
//   { label: '(UTCâˆ’03) America/Argentina/La_Rioja', offset: 'America/Argentina/La_Rioja' },
//   { label: '(UTCâˆ’03) America/Argentina/Mendoza', offset: 'America/Argentina/Mendoza' },
//   { label: '(UTCâˆ’03) America/Argentina/Rio_Gallegos', offset: 'America/Argentina/Rio_Gallegos' },
//   { label: '(UTCâˆ’03) America/Argentina/Salta', offset: 'America/Argentina/Salta' },
//   { label: '(UTCâˆ’03) America/Argentina/San_Juan', offset: 'America/Argentina/San_Juan' },
//   { label: '(UTCâˆ’03) America/Argentina/San_Luis', offset: 'America/Argentina/San_Luis' },
//   { label: '(UTCâˆ’03) America/Argentina/Tucuman', offset: 'America/Argentina/Tucuman' },
//   { label: '(UTCâˆ’03) America/Argentina/Ushuaia', offset: 'America/Argentina/Ushuaia' },
//   { label: '(UTCâˆ’03) America/Bahia', offset: 'America/Bahia' },
//   { label: '(UTCâˆ’03) America/Belem', offset: 'America/Belem' },
//   { label: '(UTCâˆ’03) America/Cayenne', offset: 'America/Cayenne' },
//   { label: '(UTCâˆ’03) America/Fortaleza', offset: 'America/Fortaleza' },
//   { label: '(UTCâˆ’03) America/Godthab', offset: 'America/Godthab' },
//   { label: '(UTCâˆ’03) America/Maceio', offset: 'America/Maceio' },
//   { label: '(UTCâˆ’03) America/Miquelon', offset: 'America/Miquelon' },
//   { label: '(UTCâˆ’03) America/Montevideo', offset: 'America/Montevideo' },
//   { label: '(UTCâˆ’03) America/Paramaribo', offset: 'America/Paramaribo' },
//   { label: '(UTCâˆ’03) America/Punta_Arenas', offset: 'America/Punta_Arenas' },
//   { label: '(UTCâˆ’03) America/Recife', offset: 'America/Recife' },
//   { label: '(UTCâˆ’03) America/Santarem', offset: 'America/Santarem' },
//   { label: '(UTCâˆ’03) America/Sao_Paulo', offset: 'America/Sao_Paulo' },
//   { label: '(UTCâˆ’03) Antarctica/Palmer', offset: 'Antarctica/Palmer' },
//   { label: '(UTCâˆ’03) Antarctica/Rothera', offset: 'Antarctica/Rothera' },
//   { label: '(UTCâˆ’03) Atlantic/Stanley', offset: 'Atlantic/Stanley' },
//   { label: '(UTCâˆ’02) America/Noronha', offset: 'America/Noronha' },
//   { label: '(UTCâˆ’02) Atlantic/South_Georgia', offset: 'Atlantic/South_Georgia' },
//   { label: '(UTCâˆ’01) America/Scoresbysund', offset: 'America/Scoresbysund' },
//   { label: '(UTCâˆ’01) Atlantic/Azores', offset: 'Atlantic/Azores' },
//   { label: '(UTCâˆ’01) Atlantic/Cape_Verde', offset: 'Atlantic/Cape_Verde' },
//   { label: '(UTC+00) Africa/Abidjan', offset: 'Africa/Abidjan' },
//   { label: '(UTC+00) Africa/Accra', offset: 'Africa/Accra' },
//   { label: '(UTC+00) Africa/Bissau', offset: 'Africa/Bissau' },
//   { label: '(UTC+00) Africa/El_Aaiun', offset: 'Africa/El_Aaiun' },
//   { label: '(UTC+00) Africa/Monrovia', offset: 'Africa/Monrovia' },
//   { label: '(UTC+00) America/Danmarkshavn', offset: 'America/Danmarkshavn' },
//   { label: '(UTC+00) Antarctica/Troll', offset: 'Antarctica/Troll' },
//   { label: '(UTC+00) Atlantic/Canary', offset: 'Atlantic/Canary' },
//   { label: '(UTC+00) Atlantic/Faroe', offset: 'Atlantic/Faroe' },
//   { label: '(UTC+00) Atlantic/Madeira', offset: 'Atlantic/Madeira' },
//   { label: '(UTC+00) Atlantic/Reykjavik', offset: 'Atlantic/Reykjavik' },
//   { label: '(UTC+00) Europe/Dublin', offset: 'Europe/Dublin' },
//   { label: '(UTC+00) Europe/Lisbon', offset: 'Europe/Lisbon' },
//   { label: '(UTC+00) Europe/London', offset: 'Europe/London' },
//   { label: '(UTC+01) Africa/Algiers', offset: 'Africa/Algiers' },
//   { label: '(UTC+01) Africa/Casablanca', offset: 'Africa/Casablanca' },
//   { label: '(UTC+01) Africa/Ceuta', offset: 'Africa/Ceuta' },
//   { label: '(UTC+01) Africa/Lagos', offset: 'Africa/Lagos' },
//   { label: '(UTC+01) Africa/Ndjamena', offset: 'Africa/Ndjamena' },
//   { label: '(UTC+01) Africa/Tunis', offset: 'Africa/Tunis' },
//   { label: '(UTC+01) Europe/Amsterdam', offset: 'Europe/Amsterdam' },
//   { label: '(UTC+01) Europe/Andorra', offset: 'Europe/Andorra' },
//   { label: '(UTC+01) Europe/Belgrade', offset: 'Europe/Belgrade' },
//   { label: '(UTC+01) Europe/Berlin', offset: 'Europe/Berlin' },
//   { label: '(UTC+01) Europe/Brussels', offset: 'Europe/Brussels' },
//   { label: '(UTC+01) Europe/Budapest', offset: 'Europe/Budapest' },
//   { label: '(UTC+01) Europe/Copenhagen', offset: 'Europe/Copenhagen' },
//   { label: '(UTC+01) Europe/Gibraltar', offset: 'Europe/Gibraltar' },
//   { label: '(UTC+01) Europe/Luxembourg', offset: 'Europe/Luxembourg' },
//   { label: '(UTC+01) Europe/Madrid', offset: 'Europe/Madrid' },
//   { label: '(UTC+01) Europe/Malta', offset: 'Europe/Malta' },
//   { label: '(UTC+01) Europe/Monaco', offset: 'Europe/Monaco' },
//   { label: '(UTC+01) Europe/Oslo', offset: 'Europe/Oslo' },
//   { label: '(UTC+01) Europe/Paris', offset: 'Europe/Paris' },
//   { label: '(UTC+01) Europe/Prague', offset: 'Europe/Prague' },
//   { label: '(UTC+01) Europe/Rome', offset: 'Europe/Rome' },
//   { label: '(UTC+01) Europe/Stockholm', offset: 'Europe/Stockholm' },
//   { label: '(UTC+01) Europe/Tirane', offset: 'Europe/Tirane' },
//   { label: '(UTC+01) Europe/Vienna', offset: 'Europe/Vienna' },
//   { label: '(UTC+01) Europe/Warsaw', offset: 'Europe/Warsaw' },
//   { label: '(UTC+01) Europe/Zurich', offset: 'Europe/Zurich' },
//   { label: '(UTC+02) Africa/Cairo', offset: 'Africa/Cairo' },
//   { label: '(UTC+02) Africa/Johannesburg', offset: 'Africa/Johannesburg' },
//   { label: '(UTC+02) Africa/Khartoum', offset: 'Africa/Khartoum' },
//   { label: '(UTC+02) Africa/Maputo', offset: 'Africa/Maputo' },
//   { label: '(UTC+02) Africa/Tripoli', offset: 'Africa/Tripoli' },
//   { label: '(UTC+02) Africa/Windhoek', offset: 'Africa/Windhoek' },
//   { label: '(UTC+02) Asia/Amman', offset: 'Asia/Amman' },
//   { label: '(UTC+02) Asia/Beirut', offset: 'Asia/Beirut' },
//   { label: '(UTC+02) Asia/Damascus', offset: 'Asia/Damascus' },
//   { label: '(UTC+02) Asia/Famagusta', offset: 'Asia/Famagusta' },
//   { label: '(UTC+02) Asia/Gaza', offset: 'Asia/Gaza' },
//   { label: '(UTC+02) Asia/Hebron', offset: 'Asia/Hebron' },
//   { label: '(UTC+02) Asia/Jerusalem', offset: 'Asia/Jerusalem' },
//   { label: '(UTC+02) Europe/Athens', offset: 'Europe/Athens' },
//   { label: '(UTC+02) Europe/Bucharest', offset: 'Europe/Bucharest' },
//   { label: '(UTC+02) Europe/Chisinau', offset: 'Europe/Chisinau' },
//   { label: '(UTC+02) Europe/Helsinki', offset: 'Europe/Helsinki' },
//   { label: '(UTC+02) Europe/Kaliningrad', offset: 'Europe/Kaliningrad' },
//   { label: '(UTC+02) Europe/Kiev', offset: 'Europe/Kiev' },
//   { label: '(UTC+02) Europe/Riga', offset: 'Europe/Riga' },
//   { label: '(UTC+02) Europe/Sofia', offset: 'Europe/Sofia' },
//   { label: '(UTC+02) Europe/Tallinn', offset: 'Europe/Tallinn' },
//   { label: '(UTC+02) Europe/Uzhgorod', offset: 'Europe/Uzhgorod' },
//   { label: '(UTC+02) Europe/Vilnius', offset: 'Europe/Vilnius' },
//   { label: '(UTC+02) Europe/Zaporozhye', offset: 'Europe/Zaporozhye' },
//   { label: '(UTC+03) Asia/Tehran', offset: 'Asia/Tehran' },
//   { label: '(UTC+03) Africa/Juba', offset: 'Africa/Juba' },
//   { label: '(UTC+03) Africa/Nairobi', offset: 'Africa/Nairobi' },
//   { label: '(UTC+03) Antarctica/Syowa', offset: 'Antarctica/Syowa' },
//   { label: '(UTC+03) Asia/Baghdad', offset: 'Asia/Baghdad' },
//   { label: '(UTC+03) Asia/Qatar', offset: 'Asia/Qatar' },
//   { label: '(UTC+03) Asia/Riyadh', offset: 'Asia/Riyadh' },
//   { label: '(UTC+03) Europe/Istanbul', offset: 'Europe/Istanbul' },
//   { label: '(UTC+03) Europe/Kirov', offset: 'Europe/Kirov' },
//   { label: '(UTC+03) Europe/Minsk', offset: 'Europe/Minsk' },
//   { label: '(UTC+03) Europe/Moscow', offset: 'Europe/Moscow' },
//   { label: '(UTC+03) Europe/Simferopol', offset: 'Europe/Simferopol' },
//   { label: '(UTC+04) Asia/Kabul', offset: 'Asia/Kabul' },
//   { label: '(UTC+04) Asia/Baku', offset: 'Asia/Baku' },
//   { label: '(UTC+04) Asia/Dubai', offset: 'Asia/Dubai' },
//   { label: '(UTC+04) Asia/Tbilisi', offset: 'Asia/Tbilisi' },
//   { label: '(UTC+04) Asia/Yerevan', offset: 'Asia/Yerevan' },
//   { label: '(UTC+04) Europe/Astrakhan', offset: 'Europe/Astrakhan' },
//   { label: '(UTC+04) Europe/Samara', offset: 'Europe/Samara' },
//   { label: '(UTC+04) Europe/Saratov', offset: 'Europe/Saratov' },
//   { label: '(UTC+04) Europe/Ulyanovsk', offset: 'Europe/Ulyanovsk' },
//   { label: '(UTC+04) Europe/Volgograd', offset: 'Europe/Volgograd' },
//   { label: '(UTC+04) Indian/Mahe', offset: 'Indian/Mahe' },
//   { label: '(UTC+04) Indian/Mauritius', offset: 'Indian/Mauritius' },
//   { label: '(UTC+04) Indian/Reunion', offset: 'Indian/Reunion' },
//   { label: '(UTC+05) Asia/Kathmandu', offset: 'Asia/Kathmandu' },
//   { label: '(UTC+05) Asia/Colombo', offset: 'Asia/Colombo' },
//   { label: '(UTC+05) Asia/Kolkata', offset: 'Asia/Kolkata' },
//   { label: '(UTC+05) Antarctica/Mawson', offset: 'Antarctica/Mawson' },
//   { label: '(UTC+05) Asia/Aqtau', offset: 'Asia/Aqtau' },
//   { label: '(UTC+05) Asia/Aqtobe', offset: 'Asia/Aqtobe' },
//   { label: '(UTC+05) Asia/Ashgabat', offset: 'Asia/Ashgabat' },
//   { label: '(UTC+05) Asia/Atyrau', offset: 'Asia/Atyrau' },
//   { label: '(UTC+05) Asia/Dushanbe', offset: 'Asia/Dushanbe' },
//   { label: '(UTC+05) Asia/Karachi', offset: 'Asia/Karachi' },
//   { label: '(UTC+05) Asia/Oral', offset: 'Asia/Oral' },
//   { label: '(UTC+05) Asia/Qyzylorda', offset: 'Asia/Qyzylorda' },
//   { label: '(UTC+05) Asia/Samarkand', offset: 'Asia/Samarkand' },
//   { label: '(UTC+05) Asia/Tashkent', offset: 'Asia/Tashkent' },
//   { label: '(UTC+05) Asia/Yekaterinburg', offset: 'Asia/Yekaterinburg' },
//   { label: '(UTC+05) Indian/Kerguelen', offset: 'Indian/Kerguelen' },
//   { label: '(UTC+05) Indian/Maldives', offset: 'Indian/Maldives' },
//   { label: '(UTC+06) Asia/Yangon', offset: 'Asia/Yangon' },
//   { label: '(UTC+06) Indian/Cocos', offset: 'Indian/Cocos' },
//   { label: '(UTC+06) Antarctica/Vostok', offset: 'Antarctica/Vostok' },
//   { label: '(UTC+06) Asia/Almaty', offset: 'Asia/Almaty' },
//   { label: '(UTC+06) Asia/Bishkek', offset: 'Asia/Bishkek' },
//   { label: '(UTC+06) Asia/Dhaka', offset: 'Asia/Dhaka' },
//   { label: '(UTC+06) Asia/Omsk', offset: 'Asia/Omsk' },
//   { label: '(UTC+06) Asia/Thimphu', offset: 'Asia/Thimphu' },
//   { label: '(UTC+06) Asia/Urumqi', offset: 'Asia/Urumqi' },
//   { label: '(UTC+06) Indian/Chagos', offset: 'Indian/Chagos' },
//   { label: '(UTC+07) Antarctica/Davis', offset: 'Antarctica/Davis' },
//   { label: '(UTC+07) Asia/Bangkok', offset: 'Asia/Bangkok' },
//   { label: '(UTC+07) Asia/Barnaul', offset: 'Asia/Barnaul' },
//   { label: '(UTC+07) Asia/Ho_Chi_Minh', offset: 'Asia/Ho_Chi_Minh' },
//   { label: '(UTC+07) Asia/Hovd', offset: 'Asia/Hovd' },
//   { label: '(UTC+07) Asia/Jakarta', offset: 'Asia/Jakarta' },
//   { label: '(UTC+07) Asia/Krasnoyarsk', offset: 'Asia/Krasnoyarsk' },
//   { label: '(UTC+07) Asia/Novokuznetsk', offset: 'Asia/Novokuznetsk' },
//   { label: '(UTC+07) Asia/Novosibirsk', offset: 'Asia/Novosibirsk' },
//   { label: '(UTC+07) Asia/Pontianak', offset: 'Asia/Pontianak' },
//   { label: '(UTC+07) Asia/Tomsk', offset: 'Asia/Tomsk' },
//   { label: '(UTC+07) Indian/Christmas', offset: 'Indian/Christmas' },
//   { label: '(UTC+08) Australia/Eucla', offset: 'Australia/Eucla' },
//   { label: '(UTC+08) Asia/Brunei', offset: 'Asia/Brunei' },
//   { label: '(UTC+08) Asia/Choibalsan', offset: 'Asia/Choibalsan' },
//   { label: '(UTC+08) Asia/Hong_Kong', offset: 'Asia/Hong_Kong' },
//   { label: '(UTC+08) Asia/Irkutsk', offset: 'Asia/Irkutsk' },
//   { label: '(UTC+08) Asia/Kuala_Lumpur', offset: 'Asia/Kuala_Lumpur' },
//   { label: '(UTC+08) Asia/Kuching', offset: 'Asia/Kuching' },
//   { label: '(UTC+08) Asia/Macau', offset: 'Asia/Macau' },
//   { label: '(UTC+08) Asia/Makassar', offset: 'Asia/Makassar' },
//   { label: '(UTC+08) Asia/Manila', offset: 'Asia/Manila' },
//   { label: '(UTC+08) Asia/Shanghai', offset: 'Asia/Shanghai' },
//   { label: '(UTC+08) Asia/Singapore', offset: 'Asia/Singapore' },
//   { label: '(UTC+08) Asia/Taipei', offset: 'Asia/Taipei' },
//   { label: '(UTC+08) Asia/Ulaanbaatar', offset: 'Asia/Ulaanbaatar' },
//   { label: '(UTC+08) Australia/Perth', offset: 'Australia/Perth' },
//   { label: '(UTC+09) Australia/Adelaide', offset: 'Australia/Adelaide' },
//   { label: '(UTC+09) Australia/Broken_Hill', offset: 'Australia/Broken_Hill' },
//   { label: '(UTC+09) Australia/Darwin', offset: 'Australia/Darwin' },
//   { label: '(UTC+09) Asia/Chita', offset: 'Asia/Chita' },
//   { label: '(UTC+09) Asia/Dili', offset: 'Asia/Dili' },
//   { label: '(UTC+09) Asia/Jayapura', offset: 'Asia/Jayapura' },
//   { label: '(UTC+09) Asia/Khandyga', offset: 'Asia/Khandyga' },
//   { label: '(UTC+09) Asia/Pyongyang', offset: 'Asia/Pyongyang' },
//   { label: '(UTC+09) Asia/Seoul', offset: 'Asia/Seoul' },
//   { label: '(UTC+09) Asia/Tokyo', offset: 'Asia/Tokyo' },
//   { label: '(UTC+09) Asia/Yakutsk', offset: 'Asia/Yakutsk' },
//   { label: '(UTC+09) Pacific/Palau', offset: 'Pacific/Palau' },
//   { label: '(UTC+10) Australia/Lord_Howe', offset: 'Australia/Lord_Howe' },
//   { label: '(UTC+10) Antarctica/DumontDUrville', offset: 'Antarctica/DumontDUrville' },
//   { label: '(UTC+10) Asia/Ust-Nera', offset: 'Asia/Ust-Nera' },
//   { label: '(UTC+10) Asia/Vladivostok', offset: 'Asia/Vladivostok' },
//   { label: '(UTC+10) Australia/Brisbane', offset: 'Australia/Brisbane' },
//   { label: '(UTC+10) Australia/Currie', offset: 'Australia/Currie' },
//   { label: '(UTC+10) Australia/Hobart', offset: 'Australia/Hobart' },
//   { label: '(UTC+10) Australia/Lindeman', offset: 'Australia/Lindeman' },
//   { label: '(UTC+10) Australia/Melbourne', offset: 'Australia/Melbourne' },
//   { label: '(UTC+10) Australia/Sydney', offset: 'Australia/Sydney' },
//   { label: '(UTC+10) Pacific/Chuuk', offset: 'Pacific/Chuuk' },
//   { label: '(UTC+10) Pacific/Guam', offset: 'Pacific/Guam' },
//   { label: '(UTC+10) Pacific/Port_Moresby', offset: 'Pacific/Port_Moresby' },
//   { label: '(UTC+11) Antarctica/Casey', offset: 'Antarctica/Casey' },
//   { label: '(UTC+11) Antarctica/Macquarie', offset: 'Antarctica/Macquarie' },
//   { label: '(UTC+11) Asia/Magadan', offset: 'Asia/Magadan' },
//   { label: '(UTC+11) Asia/Sakhalin', offset: 'Asia/Sakhalin' },
//   { label: '(UTC+11) Asia/Srednekolymsk', offset: 'Asia/Srednekolymsk' },
//   { label: '(UTC+11) Pacific/Bougainville', offset: 'Pacific/Bougainville' },
//   { label: '(UTC+11) Pacific/Efate', offset: 'Pacific/Efate' },
//   { label: '(UTC+11) Pacific/Guadalcanal', offset: 'Pacific/Guadalcanal' },
//   { label: '(UTC+11) Pacific/Kosrae', offset: 'Pacific/Kosrae' },
//   { label: '(UTC+11) Pacific/Norfolk', offset: 'Pacific/Norfolk' },
//   { label: '(UTC+11) Pacific/Noumea', offset: 'Pacific/Noumea' },
//   { label: '(UTC+11) Pacific/Pohnpei', offset: 'Pacific/Pohnpei' },
//   { label: '(UTC+12) Asia/Anadyr', offset: 'Asia/Anadyr' },
//   { label: '(UTC+12) Asia/Kamchatka', offset: 'Asia/Kamchatka' },
//   { label: '(UTC+12) Pacific/Auckland', offset: 'Pacific/Auckland' },
//   { label: '(UTC+12) Pacific/Fiji', offset: 'Pacific/Fiji' },
//   { label: '(UTC+12) Pacific/Funafuti', offset: 'Pacific/Funafuti' },
//   { label: '(UTC+12) Pacific/Kwajalein', offset: 'Pacific/Kwajalein' },
//   { label: '(UTC+12) Pacific/Majuro', offset: 'Pacific/Majuro' },
//   { label: '(UTC+12) Pacific/Nauru', offset: 'Pacific/Nauru' },
//   { label: '(UTC+12) Pacific/Tarawa', offset: 'Pacific/Tarawa' },
//   { label: '(UTC+12) Pacific/Wake', offset: 'Pacific/Wake' }
// ]

var salesForceTimeZone = exports.salesForceTimeZone = [{
  label: '(GMT+14:00) Line Islands Time (Pacific/Kiritimati)',
  offset: 'Pacific/Kiritimati',
  gmt: '+14:00'
}, {
  label: '(GMT+13:00) Phoenix Islands Time (Pacific/Enderbury)',
  offset: 'Pacific/Enderbury',
  gmt: '+13:00'
}, {
  label: '(GMT+13:00) Tonga Standard Time (Pacific/Tongatapu)',
  offset: 'Pacific/Tongatapu',
  gmt: '+13:00'
}, {
  label: '(GMT+12:45) Chatham Standard Time (Pacific/Chatham)',
  offset: 'Pacific/Chatham',
  gmt: '+12:45'
}, {
  label: '(GMT+13:45) Chatham Daylight Time (Pacific/Chatham)',
  offset: 'Pacific/Chatham',
  gmt: '+13:45'
}, {
  label: '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time (Asia/Kamchatka)',
  offset: 'Asia/Kamchatka',
  gmt: '+12:00'
}, {
  label: '(GMT+12:00) New Zealand Standard Time (Pacific/Auckland)',
  offset: 'Pacific/Auckland',
  gmt: '+12:00'
}, {
  label: '(GMT+13:00) New Zealand Daylight Time (Pacific/Auckland)',
  offset: 'Pacific/Auckland',
  gmt: '+13:00'
}, {
  label: '(GMT+12:00) Fiji Standard Time (Pacific/Fiji)',
  offset: 'Pacific/Fiji',
  gmt: '+12:00'
}, {
  label: '(GMT+13:00) Fiji Summer Time (Pacific/Fiji)',
  offset: 'Pacific/Fiji',
  gmt: '+13:00'
}, {
  label: '(GMT+11:00) Solomon Islands Time (Pacific/Guadalcanal)',
  offset: 'Pacific/Guadalcanal',
  gmt: '+11:00'
}, {
  label: '(GMT+11:00) Norfolk Island Time (Pacific/Norfolk)',
  offset: 'Pacific/Norfolk',
  gmt: '+11:00'
}, {
  label: '(GMT+12:00) Norfolk Island Time (Pacific/Norfolk)',
  offset: 'Pacific/Norfolk',
  gmt: '+12:00'
}, {
  label: '(GMT+10:30) Lord Howe Standard Time (Australia/Lord_Howe)',
  offset: 'Australia/Lord_Howe',
  gmt: '+10:30'
}, {
  label: '(GMT+11:00) Lord Howe Daylight Time (Australia/Lord_Howe)',
  offset: 'Australia/Lord_Howe',
  gmt: '+11:00'
}, {
  label: '(GMT+10:00) Australian Eastern Standard Time (Australia/Brisbane)',
  offset: 'Australia/Brisbane',
  gmt: '+10:00'
}, {
  label: '(GMT+10:00) Australian Eastern Standard Time (Australia/Sydney)',
  offset: 'Australia/Sydney',
  gmt: '+10:00'
}, {
  label: '(GMT+11:00) Australian Eastern Daylight Time (Australia/Sydney)',
  offset: 'Australia/Sydney',
  gmt: '+11:00'
}, {
  label: '(GMT+09:30) Australian Central Standard Time (Australia/Adelaide)',
  offset: 'Australia/Adelaide',
  gmt: '+09:30'
}, {
  label: '(GMT+10:30) Australian Central Daylight Time (Australia/Adelaide)',
  offset: 'Australia/Adelaide',
  gmt: '+10:30'
}, {
  label: '(GMT+09:30) Australian Central Standard Time (Australia/Darwin)',
  offset: 'Australia/Darwin',
  gmt: '+09:30'
}, {
  label: '(GMT+09:00) Korean Standard Time (Asia/Seoul)',
  offset: 'Asia/Seoul',
  gmt: '+09:00'
}, {
  label: '(GMT+09:00) Japan Standard Time (Asia/Tokyo)',
  offset: 'Asia/Tokyo',
  gmt: '+09:00'
}, {
  label: '(GMT+08:00) Hong Kong Standard Time (Asia/Hong_Kong)',
  offset: 'Asia/Hong_Kong',
  gmt: '+08:00'
}, {
  label: '(GMT+08:00) Malaysia Time (Asia/Kuala_Lumpur)',
  offset: 'Asia/Kuala_Lumpur',
  gmt: '+08:00'
}, {
  label: '(GMT+08:00) Philippine Standard Time (Asia/Manila)',
  offset: 'Asia/Manila',
  gmt: '+08:00'
}, {
  label: '(GMT+08:00) China Standard Time (Asia/Shanghai)',
  offset: 'Asia/Shanghai',
  gmt: '+08:00'
}, {
  label: '(GMT+08:00) Singapore Standard Time (Asia/Singapore)',
  offset: 'Asia/Singapore',
  gmt: '+08:00'
}, {
  label: '(GMT+08:00) Taipei Standard Time (Asia/Taipei)',
  offset: 'Asia/Taipei',
  gmt: '+08:00'
}, {
  label: '(GMT+08:00) Australian Western Standard Time (Australia/Perth)',
  offset: 'Australia/Perth',
  gmt: '+08:00'
}, {
  label: '(GMT+07:00) Indochina Time (Asia/Bangkok)',
  offset: 'Asia/Bangkok',
  gmt: '+07:00'
}, {
  label: '(GMT+07:00) Indochina Time (Asia/Ho_Chi_Minh)',
  offset: 'Asia/Ho_Chi_Minh',
  gmt: '+07:00'
}, {
  label: '(GMT+07:00) Western Indonesia Time (Asia/Jakarta)',
  offset: 'Asia/Jakarta',
  gmt: '+07:00'
}, {
  label: '(GMT+06:30) Myanmar Time (Asia/Rangoon)',
  offset: 'Asia/Rangoon',
  gmt: '+06:30'
}, {
  label: '(GMT+06:00) Bangladesh Standard Time (Asia/Dhaka)',
  offset: 'Asia/Dhaka',
  gmt: '+06:00'
}, {
  label: '(GMT+05:45) Nepal Time (Asia/Kathmandu)',
  offset: 'Asia/Kathmandu',
  gmt: '+05:45'
}, {
  label: '(GMT+05:30) India Standard Time (Asia/Colombo)',
  offset: 'Asia/Colombo',
  gmt: '+05:30'
}, {
  label: '(GMT+05:30) India Standard Time (Asia/Kolkata)',
  offset: 'Asia/Kolkata',
  gmt: '+05:30'
}, {
  label: '(GMT+05:00) Pakistan Standard Time (Asia/Karachi)',
  offset: 'Asia/Karachi',
  gmt: '+05:00'
}, {
  label: '(GMT+05:00) Uzbekistan Standard Time (Asia/Tashkent)',
  offset: 'Asia/Tashkent',
  gmt: '+05:00'
}, {
  label: '(GMT+05:00) Yekaterinburg Standard Time (Asia/Yekaterinburg)',
  offset: 'Asia/Yekaterinburg',
  gmt: '+05:00'
}, {
  label: '(GMT+04:30) Afghanistan Time (Asia/Kabul)',
  offset: 'Asia/Kabul',
  gmt: '+04:30'
}, {
  label: '(GMT+04:00) Azerbaijan Standard Time (Asia/Baku)',
  offset: 'Asia/Baku',
  gmt: '+04:00'
}, {
  label: '(GMT+04:00) Gulf Standard Time (Asia/Dubai)',
  offset: 'Asia/Dubai',
  gmt: '+04:00'
}, {
  label: '(GMT+04:00) Georgia Standard Time (Asia/Tbilisi)',
  offset: 'Asia/Tbilisi',
  gmt: '+04:00'
}, {
  label: '(GMT+04:00) Armenia Standard Time (Asia/Yerevan)',
  offset: 'Asia/Yerevan',
  gmt: '+04:00'
}, {
  label: '(GMT+03:30) Iran Standard Time (Asia/Tehran)',
  offset: 'Asia/Tehran',
  gmt: '+03:30'
}, {
  label: '(GMT+04:30) Iran Daylight Time (Asia/Tehran)',
  offset: 'Asia/Tehran',
  gmt: '+04:30'
}, {
  label: '(GMT+03:00) East Africa Time (Africa/Nairobi)',
  offset: 'Africa/Nairobi',
  gmt: '+03:00'
}, {
  label: '(GMT+03:00) Arabian Standard Time (Asia/Baghdad)',
  offset: 'Asia/Baghdad',
  gmt: '+03:00'
}, {
  label: '(GMT+03:00) Arabian Standard Time (Asia/Kuwait)',
  offset: 'Asia/Kuwait',
  gmt: '+03:00'
}, {
  label: '(GMT+03:00) Arabian Standard Time (Asia/Riyadh)',
  offset: 'Asia/Riyadh',
  gmt: '+03:00'
}, {
  label: '(GMT+03:00) Eastern European Standard Time (Europe/Istanbul)',
  offset: 'Europe/Istanbul',
  gmt: '+03:00'
}, {
  label: '(GMT+03:00) Moscow Standard Time (Europe/Minsk)',
  offset: 'Europe/Minsk',
  gmt: '+03:00'
}, {
  label: '(GMT+03:00) Moscow Standard Time (Europe/Moscow)',
  offset: 'Europe/Moscow',
  gmt: '+03:00'
}, {
  label: '(GMT+02:00) Eastern European Standard Time (Africa/Cairo)',
  offset: 'Africa/Cairo',
  gmt: '+02:00'
}, {
  label: '(GMT+02:00) South Africa Standard Time (Africa/Johannesburg)',
  offset: 'Africa/Johannesburg',
  gmt: '+02:00'
}, {
  label: '(GMT+02:00) Eastern European Standard Time (Asia/Beirut)',
  offset: 'Asia/Beirut',
  gmt: '+02:00'
}, {
  label: '(GMT+03:00) Eastern European Summer Time (Asia/Beirut)',
  offset: 'Asia/Beirut',
  gmt: '+03:00'
}, {
  label: '(GMT+02:00) Israel Standard Time (Asia/Jerusalem)',
  offset: 'Asia/Jerusalem',
  gmt: '+02:00'
}, {
  label: '(GMT+03:00) Israel Daylight Time (Asia/Jerusalem)',
  offset: 'Asia/Jerusalem',
  gmt: '+03:00'
}, {
  label: '(GMT+02:00) Eastern European Standard Time (Europe/Athens)',
  offset: 'Europe/Athens',
  gmt: '+02:00'
}, {
  label: '(GMT+03:00) Eastern European Summer Time (Europe/Athens)',
  offset: 'Europe/Athens',
  gmt: '+03:00'
}, {
  label: '(GMT+02:00) Eastern European Standard Time (Europe/Bucharest)',
  offset: 'Europe/Bucharest',
  gmt: '+02:00'
}, {
  label: '(GMT+03:00) Eastern European Summer Time (Europe/Bucharest)',
  offset: 'Europe/Bucharest',
  gmt: '+03:00'
}, {
  label: '(GMT+02:00) Eastern European Standard Time (Europe/Helsinki)',
  offset: 'Europe/Helsinki',
  gmt: '+02:00'
}, {
  label: '(GMT+03:00) Eastern European Summer Time (Europe/Helsinki)',
  offset: 'Europe/Helsinki',
  gmt: '+03:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Africa/Algiers)',
  offset: 'Africa/Algiers',
  gmt: '+01:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Europe/Amsterdam)',
  offset: 'Europe/Amsterdam',
  gmt: '+01:00'
}, {
  label: '(GMT+02:00) Central European Summer Time (Europe/Amsterdam)',
  offset: 'Europe/Amsterdam',
  gmt: '+02:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Europe/Berlin)',
  offset: 'Europe/Berlin',
  gmt: '+01:00'
}, {
  label: '(GMT+02:00) Central European Summer Time (Europe/Berlin)',
  offset: 'Europe/Berlin',
  gmt: '+02:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Europe/Brussels)',
  offset: 'Europe/Brussels',
  gmt: '+01:00'
}, {
  label: '(GMT+02:00) Central European Summer Time (Europe/Brussels)',
  offset: 'Europe/Brussels',
  gmt: '+02:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Europe/Paris)',
  offset: 'Europe/Paris',
  gmt: '+01:00'
}, {
  label: '(GMT+02:00) Central European Summer Time (Europe/Paris)',
  offset: 'Europe/Paris',
  gmt: '+02:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Europe/Prague)',
  offset: 'Europe/Prague',
  gmt: '+01:00'
}, {
  label: '(GMT+02:00) Central European Summer Time (Europe/Prague)',
  offset: 'Europe/Prague',
  gmt: '+02:00'
}, {
  label: '(GMT+01:00) Central European Standard Time (Europe/Rome)',
  offset: 'Europe/Rome',
  gmt: '+01:00'
}, {
  label: '(GMT+02:00) Central European Summer Time (Europe/Rome)',
  offset: 'Europe/Rome',
  gmt: '+02:00'
}, {
  label: '(GMT+00:00) Western European Standard Time (Africa/Casablanca)',
  offset: 'Africa/Casablanca',
  gmt: '+00:00'
}, {
  label: '(GMT+01:00) Western European Summer Time (Africa/Casablanca)',
  offset: 'Africa/Casablanca',
  gmt: '+01:00'
}, {
  label: '(GMT+00:00) Greenwich Mean Time (Europe/Dublin)',
  offset: 'Europe/Dublin',
  gmt: '+00:00'
}, {
  label: '(GMT+01:00) Irish Standard Time (Europe/Dublin)',
  offset: 'Europe/Dublin',
  gmt: '+01:00'
}, {
  label: '(GMT+00:00) Western European Standard Time (Europe/Lisbon)',
  offset: 'Europe/Lisbon',
  gmt: '+00:00'
}, {
  label: '(GMT+01:00) Western European Summer Time (Europe/Lisbon)',
  offset: 'Europe/Lisbon',
  gmt: '+01:00'
}, {
  label: '(GMT+00:00) Greenwich Mean Time (Europe/London)',
  offset: 'Europe/London',
  gmt: '+00:00'
}, {
  label: '(GMT+01:00) British Summer Time (Europe/London)',
  offset: 'Europe/London',
  gmt: '+01:00'
}, {
  label: '(GMT+00:00) Greenwich Mean Time GMT)',
  offset: 'Greenwich Mean Time GMT',
  gmt: '+00:00'
}, {
  label: '(GMT-01:00) East Greenland Standard Time (America/Scoresbysund)',
  offset: 'America/Scoresbysund',
  gmt: '-01:00'
}, {
  label: '(GMT+00:00) East Greenland Summer Time (America/Scoresbysund)',
  offset: 'America/Scoresbysund',
  gmt: '+00:00'
}, {
  label: '(GMT-01:00) Azores Standard Time (Atlantic/Azores)',
  offset: 'Atlantic/Azores',
  gmt: '-01:00'
}, {
  label: '(GMT+00:00) Azores Summer Time (Atlantic/Azores)',
  offset: 'Atlantic/Azores',
  gmt: '+00:00'
}, {
  label: '(GMT-01:00) Cape Verde Standard Time (Atlantic/Cape_Verde)',
  offset: 'Atlantic/Cape_Verde',
  gmt: '-01:00'
}, {
  label: '(GMT-02:00) South Georgia Time (Atlantic/South_Georgia)',
  offset: 'Atlantic/South_Georgia',
  gmt: '-02:00'
}, {
  label: '(GMT-03:00) Argentina Standard Time (America/Argentina/Buenos_Aires)',
  offset: 'America/Argentina/Buenos_Aires',
  gmt: '-03:00'
}, {
  label: '(GMT-03:00) Brasilia Standard Time (America/Sao_Paulo)',
  offset: 'America/Sao_Paulo',
  gmt: '-03:00'
}, {
  label: '(GMT-03:30) Newfoundland Standard Time (America/St_Johns)',
  offset: 'America/St_Johns',
  gmt: '-03:30'
}, {
  label: '(GMT-02:30) Newfoundland Daylight Time (America/St_Johns)',
  offset: 'America/St_Johns',
  gmt: '-02:30'
}, {
  label: '(GMT-04:00) Venezuela Time (America/Caracas)',
  offset: 'America/Caracas',
  gmt: '-04:00'
}, {
  label: '(GMT-04:00) Atlantic Standard Time (America/Halifax)',
  offset: 'America/Halifax',
  gmt: '-04:00'
}, {
  label: '(GMT-03:00) Atlantic Daylight Time (America/Halifax)',
  offset: 'America/Halifax',
  gmt: '-03:00'
}, {
  label: '(GMT-04:00) Atlantic Standard Time (America/Puerto_Rico)',
  offset: 'America/Puerto_Rico',
  gmt: '-04:00'
}, {
  label: '(GMT-04:00) Chile Standard Time (America/Santiago)',
  offset: 'America/Santiago',
  gmt: '-04:00'
}, {
  label: '(GMT-03:00) Chile Summer Time (America/Santiago)',
  offset: 'America/Santiago',
  gmt: '-03:00'
}, {
  label: '(GMT-04:00) Atlantic Standard Time (Atlantic/Bermuda)',
  offset: 'Atlantic/Bermuda',
  gmt: '-04:00'
}, {
  label: '(GMT-03:00) Atlantic Daylight Time (Atlantic/Bermuda)',
  offset: 'Atlantic/Bermuda',
  gmt: '-03:00'
}, {
  label: '(GMT-05:00) Colombia Standard Time (America/Bogota)',
  offset: 'America/Bogota',
  gmt: '-05:00'
}, {
  label: '(GMT-05:00) Eastern Standard Time (America/Indiana/Indianapolis)',
  offset: 'America/Indiana/Indianapolis',
  gmt: '-05:00'
}, {
  label: '(GMT-04:00) Eastern Daylight Time (America/Indiana/Indianapolis)',
  offset: 'America/Indiana/Indianapolis',
  gmt: '-04:00'
}, {
  label: '(GMT-05:00) Peru Standard Time (America/Lima)',
  offset: 'America/Lima',
  gmt: '-05:00'
}, {
  label: '(GMT-05:00) Eastern Standard Time (America/New_York)',
  offset: 'America/New_York',
  gmt: '-05:00'
}, {
  label: '(GMT-04:00) Eastern Daylight Time (America/New_York)',
  offset: 'America/New_York',
  gmt: '-04:00'
}, {
  label: '(GMT-05:00) Eastern Standard Time (America/Panama)',
  offset: 'America/Panama',
  gmt: '-05:00'
}, {
  label: '(GMT-06:00) Central Standard Time (America/Chicago)',
  offset: 'America/Chicago',
  gmt: '-06:00'
}, {
  label: '(GMT-05:00) Central Daylight Time (America/Chicago)',
  offset: 'America/Chicago',
  gmt: '-05:00'
}, {
  label: '(GMT-06:00) Central Standard Time (America/El_Salvador)',
  offset: 'America/El_Salvador',
  gmt: '-06:00'
}, {
  label: '(GMT-06:00) Central Standard Time (America/Mexico_City)',
  offset: 'America/Mexico_City',
  gmt: '-06:00'
}, {
  label: '(GMT-05:00) Central Daylight Time (America/Mexico_City)',
  offset: 'America/Mexico_City',
  gmt: '-05:00'
}, {
  label: '(GMT-07:00) Mountain Standard Time (America/Denver)',
  offset: 'America/Denver',
  gmt: '-07:00'
}, {
  label: '(GMT-06:00) Mountain Daylight Time (America/Denver)',
  offset: 'America/Denver',
  gmt: '-06:00'
}, {
  label: '(GMT-07:00) Mexican Pacific Standard Time (America/Mazatlan)',
  offset: 'America/Mazatlan',
  gmt: '-07:00'
}, {
  label: '(GMT-06:00) Mexican Pacific Daylight Time (America/Mazatlan)',
  offset: 'America/Mazatlan',
  gmt: '-06:00'
}, {
  label: '(GMT-07:00) Mountain Standard Time (America/Phoenix)',
  offset: 'America/Phoenix',
  gmt: '-07:00'
}, {
  label: '(GMT-08:00) Pacific Standard Time (America/Los_Angeles)',
  offset: 'America/Los_Angeles',
  gmt: '-08:00'
}, {
  label: '(GMT-07:00) Pacific Daylight Time (America/Los_Angeles)',
  offset: 'America/Los_Angeles',
  gmt: '-07:00'
}, {
  label: '(GMT-08:00) Pacific Standard Time (America/Tijuana)',
  offset: 'America/Tijuana',
  gmt: '-08:00'
}, {
  label: '(GMT-07:00) Pacific Daylight Time (America/Tijuana)',
  offset: 'America/Tijuana',
  gmt: '-07:00'
}, {
  label: '(GMT-08:00) Pitcairn Time (Pacific/Pitcairn)',
  offset: 'Pacific/Pitcairn',
  gmt: '-08:00'
}, {
  label: '(GMT-09:00) Alaska Standard Time (America/Anchorage)',
  offset: 'America/Anchorage',
  gmt: '-09:00'
}, {
  label: '(GMT-08:00) Alaska Daylight Time (America/Anchorage)',
  offset: 'America/Anchorage',
  gmt: '-08:00'
}, {
  label: '(GMT-09:00) Gambier Time (Pacific/Gambier)',
  offset: 'Pacific/Gambier',
  gmt: '-09:00'
}, {
  label: '(GMT-09:30) Marquesas Time (Pacific/Marquesas)',
  offset: 'Pacific/Marquesas',
  gmt: '-09:30'
}, {
  label: '(GMT-10:00) Hawaii-Aleutian Standard Time (America/Adak)',
  offset: 'America/Adak',
  gmt: '-10:00'
}, {
  label: '(GMT-09:00) Hawaii-Aleutian Daylight Time (America/Adak)',
  offset: 'America/Adak',
  gmt: '-09:00'
}, {
  label: '(GMT-10:00) Hawaii-Aleutian Standard Time (Pacific/Honolulu)',
  offset: 'Pacific/Honolulu',
  gmt: '-10:00'
}, {
  label: '(GMT-11:00) Niue Time (Pacific/Niue)',
  offset: 'Pacific/Niue',
  gmt: '-11:00'
}, {
  label: '(GMT-11:00) Samoa Standard Time (Pacific/Pago_Pago)',
  offset: 'Pacific/Pago_Pago',
  gmt: '-11:00'
}];
var momentJSZoneObjects = _momentTimezone.default.tz.names().map(function (zone) {
  return {
    zone: zone,
    gmt: _momentTimezone.default.tz(zone).format('Z')
  };
});
var ianaTimeZone = exports.ianaTimeZone = salesForceTimeZone.filter(function (item) {
  return momentJSZoneObjects.find(function (element) {
    return element.gmt === item.gmt && element.zone === item.offset;
  });
}).sort(function (a, b) {
  if (a.gmt === b.gmt) {
    return a.offset.localeCompare(b.offset);
  }
  return parseFloat(b.gmt.replace(':', '.')) - parseFloat(a.gmt.replace(':', '.'));
});

// For table only.
var skillCompassGroupContentDefault = exports.skillCompassGroupContentDefault = [{
  'key': 'opening',
  'avgScore': 4.1
}, {
  'key': 'language_strength',
  'avgScore': 5.5
}, {
  'key': 'uniqueness',
  'avgScore': 4.7
}, {
  'key': 'interesting',
  'avgScore': 5.4,
  'weight': 6
}, {
  'key': 'clarity',
  'avgScore': 5.0
}, {
  'key': 'memorability',
  'avgScore': 4.8
}, {
  'key': 'stories',
  'avgScore': 4.1
}, {
  'key': 'data',
  'avgScore': 3.8
}, {
  'key': 'closing',
  'avgScore': 3.8
}];
var skillCompassGroupDeliveryDefault = exports.skillCompassGroupDeliveryDefault = [{
  'key': 'eye_contact',
  'avgScore': 5.2
}, {
  'key': 'authenticity',
  'avgScore': 5.6,
  'weight': 5
}, {
  'key': 'personalization',
  'avgScore': 3.9
}, {
  'key': 'audience_involvement',
  'avgScore': 2.8
}, {
  'key': 'responsiveness',
  'avgScore': 2.4
}];

// Start Skill Lists
var skillCompassVideoDefault = exports.skillCompassVideoDefault = [{
  'key': 'opening',
  'avgScore': 4.1
}, {
  'key': 'language_strength',
  'avgScore': 5.5
}, {
  'key': 'uniqueness',
  'avgScore': 4.7
}, {
  'key': 'clarity',
  'avgScore': 5.0
}, {
  'key': 'memorability',
  'avgScore': 4.8
}, {
  'key': 'stories',
  'avgScore': 4.1
}, {
  'key': 'data',
  'avgScore': 3.8
}, {
  'key': 'closing',
  'avgScore': 3.8
}, {
  'key': 'responsiveness',
  'avgScore': 2.4
}, {
  'key': 'audience_involvement',
  'avgScore': 2.8
}, {
  'key': 'eye_contact',
  'avgScore': 5.2
}, {
  'key': 'personalization',
  'avgScore': 3.9
}, {
  'key': 'voice_modulation',
  'avgScore': 4.9
}, {
  'key': 'body_language',
  'avgScore': 3.0
}, {
  'key': 'movement',
  'avgScore': 1.5
}, {
  'key': 'sensory_engagement',
  'avgScore': 3.4
}, {
  'key': 'interesting',
  'avgScore': 5.4
}];
var skillCompassOverallDefault = exports.skillCompassOverallDefault = [{
  'key': 'interesting',
  'avgScore': 5.4,
  'weight': 6
}, {
  'key': 'authenticity',
  'avgScore': 5.6,
  'weight': 5
}, {
  'key': 'didactic',
  'avgScore': 3.4,
  'weight': 3
}, {
  'key': 'variety',
  'avgScore': 3.6,
  'weight': 6
}];
var extraSkillCompassOverallDefault = exports.extraSkillCompassOverallDefault = [{
  'key': 'value_proposition',
  'avgScore': 4.7
}, {
  'key': 'discovery',
  'avgScore': 4.5
}, {
  'key': 'conversational_intelligence',
  'avgScore': 4.2
}, {
  'key': 'flip',
  'avgScore': 4.1
}, {
  'key': 'validate_pivot',
  'avgScore': 3.7
}];

// #LHVO-1745
var skillCompassSaleDefault = exports.skillCompassSaleDefault = [{
  'key': 'customizing_conversation',
  'avgScore': 4.0
}, {
  'key': 'consultative_speaking',
  'avgScore': 4.0
}, {
  'key': 'handling_objections',
  'avgScore': 4.0
}, {
  'key': 'asking',
  'avgScore': 4.0
}, {
  'key': 'closing_commitment',
  'avgScore': 4.0
}, {
  'key': 'purposeful_prospecting',
  'avgScore': 4.0
}, {
  'key': 'solid_systems',
  'avgScore': 4.0
}];
var skillCompassLeadershipDefault = exports.skillCompassLeadershipDefault = [{
  'key': 'role_clarity',
  'avgScore': 4.0,
  'newKey': 'role_alignment'
}, {
  'key': 'time_based',
  'avgScore': 4.0
}, {
  'key': 'commitment_results',
  'avgScore': 4.0,
  'newKey': 'plan_role_development'
}, {
  'key': 'self_knowledge',
  'avgScore': 4.0
}, {
  'key': 'clear_KPIs',
  'avgScore': 4.0
}, {
  'key': 'candor',
  'avgScore': 4.0
}, {
  'key': 'reading_responding',
  'avgScore': 4.0
}, {
  'key': 'delegation',
  'avgScore': 4.0
}];

//LHVO-1778 for eHealth
var skillCustomDefault = exports.skillCustomDefault = [{
  'key': 'script_accuracy',
  'avgScore': 4.0
}, {
  'key': 'empathy',
  'avgScore': 4.0
}];

//LHVO-1887 - Add 2 new skills for custom Hike (emergency case)
var skillCustom1887Default = exports.skillCustom1887Default = [{
  'key': 'authentic_questions',
  'avgScore': 3.8
}, {
  'key': 'features_to_benefits',
  'avgScore': 4.1
}];
// End Skill Lists

// Library should concat all the lists
var skillCompassLibrary = exports.skillCompassLibrary = skillCompassVideoDefault.concat(skillCompassOverallDefault).concat(extraSkillCompassOverallDefault).concat(skillCompassSaleDefault).concat(skillCompassLeadershipDefault).concat(skillCustomDefault).concat(skillCustom1887Default);
var defaultReport = exports.defaultReport = {
  'globalAverage': {
    'description': '',
    'tipsTitle': '',
    'tipsDescription': ''
  }
};
var default720Report = exports.default720Report = {
  'bossRate': 35,
  'directRate': 35,
  'peerRate': 10,
  'raterRate': 20
};
var loopType = exports.loopType = [{
  key: "full",
  val: "full",
  description: "Full Loop"
}, {
  key: "half",
  val: "half",
  description: "Half Loop"
}, {
  key: "alacarte",
  val: "alacarte",
  description: "A la carte Loop"
}];
var programType = exports.programType = [{
  key: "externalAction",
  val: "externalAction",
  description: "External URL (Open website)"
}, {
  key: "info",
  val: "info",
  description: "Info Pop-up (Text information)"
}, {
  key: "enrollment",
  val: "enrollment",
  description: "Enrollment"
}];
var programIcon = exports.programIcon = [{
  key: "none",
  val: "none",
  description: "No icon"
}, {
  key: "orientation",
  val: "orientation",
  description: "Session Zero"
}, {
  key: "major",
  val: "major",
  description: "Major program"
}, {
  key: "vilt",
  val: "vilt",
  description: "VILT"
}, {
  key: "ilt",
  val: "ilt",
  description: "ILT"
}, {
  key: "basecamp",
  val: "basecamp",
  description: "Basecamp"
}, {
  key: "campfire",
  val: "campfire",
  description: "Campfire"
}, {
  key: "1on1Coaching",
  val: "1on1Coaching",
  description: "1:1 Coaching"
}, {
  key: "hiker",
  val: "hiker",
  description: "Hiker"
}, {
  key: "steps",
  val: "steps",
  description: "Steps"
}, {
  key: "path",
  val: "path",
  description: "Path"
}];
var programIconColor = exports.programIconColor = [{
  key: "black",
  val: "black",
  description: "Black"
}, {
  key: "green",
  val: "green",
  description: "Green"
}, {
  key: "blue",
  val: "blue",
  description: "Blue"
}, {
  key: "red",
  val: "red",
  description: "Red"
}, {
  key: "orange",
  val: "orange",
  description: "Orange"
}, {
  key: "purple",
  val: "purple",
  description: "Purple"
}];
var showButton = exports.showButton = [{
  key: "Y",
  val: "Y",
  description: "Show"
}, {
  key: "N",
  val: "N",
  description: "Hide"
}];
var ActivationCodeType = exports.ActivationCodeType = ['hike', 'training', 'loop'];
var HikeCategory = exports.HikeCategory = [{
  key: "please-select",
  val: "",
  description: "Please select"
}, {
  key: "communication",
  val: "communication",
  description: "Communication Range"
}, {
  key: "manager",
  val: "manager",
  description: "Leadership Range"
}, {
  key: "sales",
  val: "sales",
  description: "Sales Range"
}, {
  key: "hard-skills",
  val: "hard-skills",
  description: "Hard Skills Range"
}, {
  key: "life-skills",
  val: "life-skills",
  description: "Life Skills Range"
}, {
  key: "demo",
  val: "demo",
  description: "Demo - (General)"
}, {
  key: "practice",
  val: "practice",
  description: "TAS Practice - (General)"
}];
var RuleSet = exports.RuleSet = [{
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_NO_EMAIL,
  description: 'No Email'
}, {
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_GENERAL_HIKE,
  description: 'General Hike'
}, {
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_MANAGER_GATEWAY_HIKE,
  description: 'Manager Gateway Hike'
}, {
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_ORIENTATION,
  description: 'Session Zero'
}, {
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_BASECAMP,
  description: 'Basecamp'
}, {
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_ONE_ON_ONE_COACHING,
  description: '1-1 Coaching'
}, {
  key: _lhJsEmailContentLib.ruleSetNames.HIKE_EMAIL_RULE_SET_CAMPFIRE,
  description: 'Campfire'
}];
var hikeEmailSupportedPlaceholders = exports.hikeEmailSupportedPlaceholders = [{
  tag: "[[recipientFirstName]]",
  description: "Recipient user's Given name.",
  example: "Tommy"
}, {
  tag: "[[recipientLastName]]",
  description: "Recipient user's Surname.",
  example: "Harris"
}, {
  tag: "[[hikeEventStartDate]]",
  description: 'The event start date of the Hike in "MM DD, YYYY" format.',
  example: "Sep 23, 2023"
}, {
  tag: "[[hikeEventStartTime]]",
  description: 'The event start time of the Hike in "HH: MM AM/ PM" format.',
  example: "11:30 AM ((GMT-04:00) Eastern Daylight Time)"
}, {
  tag: "[[hikeEventEndDate]]",
  description: 'The event end date of the Hike in "MM DD, YYYY" format.',
  example: "Sep 23, 2023"
}, {
  tag: "[[hikeEventEndTime]]",
  description: 'The event end time of the Hike in "HH:MM AM/PM" format.',
  example: "11:30 AM ((GMT-04:00) Eastern Daylight Time)"
}, {
  tag: "[[hikeGoogleCalendarUrl]]",
  description: 'The Google calendar link of the Hike program. It will use the Hike event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://calendar.google.com/calendar/u/0/r/eventedit?dates=20230418T200000Z/20230523T193000Z&details=Whether+you+want+to+strengthen+and+sustain+relationships+with+customers,+encourage+a+collaborative+team+environment,+increase+efficiency,+or+expand+your+influence,+clear,+confident,+and+concise+communication+is+a+critical+skill+that+has+the+power+to+transform+any+business+scenario.+Experience+Own+The+Room+Hike+5,+an+immersive+and+metrics-driven+learning+journey.+This+highly+impactful+program+equips+anyone,+from+entry-level+professionals+to+seasoned+CEOs,+with+the+skills+and+confidence+to+influence+and+motivate+in+any+situation.+You%E2%80%99ll+be+coached+live+via+Zoom+video+conferencing+software+by+an+Own+The+Room+Coach.+&location&text=GE+Aerospace:+April+Cohort+2'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[hikeYahooCalendarUrl]]",
  description: 'The Yahoo calendar link of the Hike program. It will use the Hike event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://calendar.yahoo.com/?desc=Whether%20you%20want%20to%20strengthen%20and%20sustain%20relationships%20with%20customers%2C%20encourage%20a%20collaborative%20team%20environment%2C%20increase%20efficiency%2C%20or%20expand%20your%20influence%2C%20clear%2C%20confident%2C%20and%20concise%20communication%20is%20a%20critical%20skill%20that%20has%20the%20power%20to%20transform%20any%20business%20scenario.%20Experience%20Own%20The%20Room%20Hike%205%2C%20an%20immersive%20and%20metrics-driven%20learning%20journey.%20This%20highly%20impactful%20program%20equips%20anyone%2C%20from%20entry-level%20professionals%20to%20seasoned%20CEOs%2C%20with%20the%20skills%20and%20confidence%20to%20influence%20and%20motivate%20in%20any%20situation.%20You%E2%80%99ll%20be%20coached%20live%20via%20Zoom%20video%20conferencing%20software%20by%20an%20Own%20The%20Room%20Coach.%20&et=20230523T193000Z&in_loc=&st=20230418T200000Z&title=GE%20Aerospace%3A%20April%20Cohort%202&v=60'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[hikeOutlookCalendarUrl]]",
  description: 'The Outlook calendar link of the Hike program. It will use the Hike event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://outlook.live.com/calendar/0/deeplink/compose?body=Whether%20you%20want%20to%20strengthen%20and%20sustain%20relationships%20with%20customers%2C%20encourage%20a%20collaborative%20team%20environment%2C%20increase%20efficiency%2C%20or%20expand%20your%20influence%2C%20clear%2C%20confident%2C%20and%20concise%20communication%20is%20a%20critical%20skill%20that%20has%20the%20power%20to%20transform%20any%20business%20scenario.%20Experience%20Own%20The%20Room%20Hike%205%2C%20an%20immersive%20and%20metrics-driven%20learning%20journey.%20This%20highly%20impactful%20program%20equips%20anyone%2C%20from%20entry-level%20professionals%20to%20seasoned%20CEOs%2C%20with%20the%20skills%20and%20confidence%20to%20influence%20and%20motivate%20in%20any%20situation.%20You%E2%80%99ll%20be%20coached%20live%20via%20Zoom%20video%20conferencing%20software%20by%20an%20Own%20The%20Room%20Coach.%20&enddt=2023-05-23T19%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-04-18T20%3A00%3A00%2B00%3A00&subject=GE%20Aerospace%3A%20April%20Cohort%202'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[hikeOffice365CalendarUrl]]",
  description: 'The Office365 calendar link of the Hike program. It will use the Hike event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://outlook.office.com/calendar/0/deeplink/compose?body=Whether%20you%20want%20to%20strengthen%20and%20sustain%20relationships%20with%20customers%2C%20encourage%20a%20collaborative%20team%20environment%2C%20increase%20efficiency%2C%20or%20expand%20your%20influence%2C%20clear%2C%20confident%2C%20and%20concise%20communication%20is%20a%20critical%20skill%20that%20has%20the%20power%20to%20transform%20any%20business%20scenario.%20Experience%20Own%20The%20Room%20Hike%205%2C%20an%20immersive%20and%20metrics-driven%20learning%20journey.%20This%20highly%20impactful%20program%20equips%20anyone%2C%20from%20entry-level%20professionals%20to%20seasoned%20CEOs%2C%20with%20the%20skills%20and%20confidence%20to%20influence%20and%20motivate%20in%20any%20situation.%20You%E2%80%99ll%20be%20coached%20live%20via%20Zoom%20video%20conferencing%20software%20by%20an%20Own%20The%20Room%20Coach.%20&enddt=2023-05-23T19%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-04-18T20%3A00%3A00%2B00%3A00&subject=GE%20Aerospace%3A%20April%20Cohort%202'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[orientationUrl]]",
  description: "An URL of the Session Zero webpage.",
  example: "https://lifehikes.com/xxxxx"
}, {
  tag: "[[hikeTitle]]",
  description: "The Hike title.",
  example: "GE Aerospace: April Cohort 2"
}, {
  tag: "[[zoomUrl]]",
  description: "The Zoom link URL.",
  example: "https://lifehikes.zoom.us/j/3315262859"
}, {
  tag: "[[trailGuideUrl]]",
  description: "The trail guide material URL.",
  example: "https://rs-portal-staging.lifehikes.com/#/page?key=test-page-1"
}, {
  tag: "[[loopScheduleViewUrl]]",
  description: "The URL of the Loop Schedule View webpage.",
  example: "https://lifehikes.com/xxxxx"
}];
var loopEmailSupportedPlaceholders = exports.loopEmailSupportedPlaceholders = [{
  tag: "[[recipientFirstName]]",
  description: "Recipient user's Given name.",
  example: "Tommy"
}, {
  tag: "[[recipientLastName]]",
  description: "Recipient user's Surname.",
  example: "Harris"
}, {
  tag: "[[loopEventStartDate]]",
  description: 'The event start date of the Loop in "MM DD, YYYY" format.',
  example: "Sep 23, 2023"
}, {
  tag: "[[loopEventStartTime]]",
  description: 'The event start time of the Loop in "HH: MM AM/ PM" format.',
  example: "11:30 AM ((GMT-04:00) Eastern Daylight Time)"
}, {
  tag: "[[loopEventEndDate]]",
  description: 'The event end date of the Loop in "MM DD, YYYY" format.',
  example: "Sep 23, 2023"
}, {
  tag: "[[loopEventEndTime]]",
  description: 'The event end time of the Loop in "HH:MM AM/PM" format.',
  example: "11:30 AM ((GMT-04:00) Eastern Daylight Time)"
}, {
  tag: "[[loopGoogleCalendarUrl]]",
  description: 'The Google calendar link of the Loop program. It will use the Loop event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://calendar.google.com/calendar/u/0/r/eventedit?dates=20230418T200000Z/20230523T193000Z&details=Whether+you+want+to+strengthen+and+sustain+relationships+with+customers,+encourage+a+collaborative+team+environment,+increase+efficiency,+or+expand+your+influence,+clear,+confident,+and+concise+communication+is+a+critical+skill+that+has+the+power+to+transform+any+business+scenario.+Experience+Own+The+Room+Hike+5,+an+immersive+and+metrics-driven+learning+journey.+This+highly+impactful+program+equips+anyone,+from+entry-level+professionals+to+seasoned+CEOs,+with+the+skills+and+confidence+to+influence+and+motivate+in+any+situation.+You%E2%80%99ll+be+coached+live+via+Zoom+video+conferencing+software+by+an+Own+The+Room+Coach.+&location&text=GE+Aerospace:+April+Cohort+2'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[loopYahooCalendarUrl]]",
  description: 'The Yahoo calendar link of the Loop program. It will use the Loop event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://calendar.yahoo.com/?desc=Whether%20you%20want%20to%20strengthen%20and%20sustain%20relationships%20with%20customers%2C%20encourage%20a%20collaborative%20team%20environment%2C%20increase%20efficiency%2C%20or%20expand%20your%20influence%2C%20clear%2C%20confident%2C%20and%20concise%20communication%20is%20a%20critical%20skill%20that%20has%20the%20power%20to%20transform%20any%20business%20scenario.%20Experience%20Own%20The%20Room%20Hike%205%2C%20an%20immersive%20and%20metrics-driven%20learning%20journey.%20This%20highly%20impactful%20program%20equips%20anyone%2C%20from%20entry-level%20professionals%20to%20seasoned%20CEOs%2C%20with%20the%20skills%20and%20confidence%20to%20influence%20and%20motivate%20in%20any%20situation.%20You%E2%80%99ll%20be%20coached%20live%20via%20Zoom%20video%20conferencing%20software%20by%20an%20Own%20The%20Room%20Coach.%20&et=20230523T193000Z&in_loc=&st=20230418T200000Z&title=GE%20Aerospace%3A%20April%20Cohort%202&v=60'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[loopOutlookCalendarUrl]]",
  description: 'The Outlook calendar link of the Loop program. It will use the Loop event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://outlook.live.com/calendar/0/deeplink/compose?body=Whether%20you%20want%20to%20strengthen%20and%20sustain%20relationships%20with%20customers%2C%20encourage%20a%20collaborative%20team%20environment%2C%20increase%20efficiency%2C%20or%20expand%20your%20influence%2C%20clear%2C%20confident%2C%20and%20concise%20communication%20is%20a%20critical%20skill%20that%20has%20the%20power%20to%20transform%20any%20business%20scenario.%20Experience%20Own%20The%20Room%20Hike%205%2C%20an%20immersive%20and%20metrics-driven%20learning%20journey.%20This%20highly%20impactful%20program%20equips%20anyone%2C%20from%20entry-level%20professionals%20to%20seasoned%20CEOs%2C%20with%20the%20skills%20and%20confidence%20to%20influence%20and%20motivate%20in%20any%20situation.%20You%E2%80%99ll%20be%20coached%20live%20via%20Zoom%20video%20conferencing%20software%20by%20an%20Own%20The%20Room%20Coach.%20&enddt=2023-05-23T19%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-04-18T20%3A00%3A00%2B00%3A00&subject=GE%20Aerospace%3A%20April%20Cohort%202'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[loopOffice365CalendarUrl]]",
  description: 'The Office365 calendar link of the Loop program. It will use the Loop event start and end time.',
  example: "<a target='_blank' class='el-link el-link--primary is-underline' href='https://outlook.office.com/calendar/0/deeplink/compose?body=Whether%20you%20want%20to%20strengthen%20and%20sustain%20relationships%20with%20customers%2C%20encourage%20a%20collaborative%20team%20environment%2C%20increase%20efficiency%2C%20or%20expand%20your%20influence%2C%20clear%2C%20confident%2C%20and%20concise%20communication%20is%20a%20critical%20skill%20that%20has%20the%20power%20to%20transform%20any%20business%20scenario.%20Experience%20Own%20The%20Room%20Hike%205%2C%20an%20immersive%20and%20metrics-driven%20learning%20journey.%20This%20highly%20impactful%20program%20equips%20anyone%2C%20from%20entry-level%20professionals%20to%20seasoned%20CEOs%2C%20with%20the%20skills%20and%20confidence%20to%20influence%20and%20motivate%20in%20any%20situation.%20You%E2%80%99ll%20be%20coached%20live%20via%20Zoom%20video%20conferencing%20software%20by%20an%20Own%20The%20Room%20Coach.%20&enddt=2023-05-23T19%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-04-18T20%3A00%3A00%2B00%3A00&subject=GE%20Aerospace%3A%20April%20Cohort%202'>Link</a>  (Please find the URL of the link)"
}, {
  tag: "[[loopTitle]]",
  description: "The Loop title.",
  example: "Autumn Loop 2023​"
}];