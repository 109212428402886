"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteUserAccount = deleteUserAccount;
exports.deleteUserProfile = deleteUserProfile;
exports.getBaselineStatus = getBaselineStatus;
exports.getInfo = getInfo;
exports.getNoOfUsers = getNoOfUsers;
exports.getUser = getUser;
exports.getUserList = getUserList;
exports.globalLogout = globalLogout;
exports.login = login;
exports.refreshCognitoToken = refreshCognitoToken;
exports.updateMyProfile = updateMyProfile;
exports.updateUser = updateUser;
exports.updateUserProfile = updateUserProfile;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(username, password) {
  var data = {
    username: username,
    password: password
  };
  return (0, _request.default)({
    url: '/auth',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    data: data
  });
}
function globalLogout() {
  return (0, _request.default)({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  });
}
function getInfo(token) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/users/me',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers: headers
  });
}
function getUserList(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/users',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers: headers,
    params: query
  });
}
function getUser(token, uuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/users/".concat(uuid),
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers: headers
  });
}
function updateUser(token, uuid, data) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/users/".concat(uuid),
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    headers: headers,
    data: data
  });
}
function updateMyProfile(token, userObj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/users/me',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    headers: headers,
    data: userObj
  });
}
function updateUserProfile(token, userObj) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/users/' + userObj.uuid,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    headers: headers,
    data: userObj
  });
}
function deleteUserProfile(token, uuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/users/".concat(uuid, "/delete-permanent"),
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    headers: headers
  });
}
function getNoOfUsers(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/users/no-of-users',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers: headers,
    params: query
  });
}
function refreshCognitoToken(token) {
  var data = {
    'refreshToken': token
  };
  return (0, _request.default)({
    url: '/auth',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    data: data
  });
}
function getBaselineStatus(token, list) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/users/baselineStatus",
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    headers: headers,
    data: list
  });
}
function deleteUserAccount(token, uuid) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/users/".concat(uuid, "/delete-user-permanent"),
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'delete',
    headers: headers
  });
}