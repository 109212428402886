"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBadgeList = getBadgeList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getBadgeList(token, setting) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/badges/?page=".concat(setting.page, "&limit=").concat(setting.limit, "&sort=").concat(setting.sorting),
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get',
    headers: headers
  });
}