"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _log = require("@/utils/log");
var _ga = require("@/utils/ga");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _SurveyImageInput = _interopRequireDefault(require("@/views/survey-builder/components/SurveyImageInput"));
var _index = require("./index");
var _cookie = require("@/utils/cookie");
var _survey = require("@/api/survey");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { SurveyDebug } from './index'
var _default = exports.default = {
  name: 'SurveyMain',
  components: {
    Tinymce: _Tinymce.default,
    SurveyPage: _index.SurveyPage,
    SurveyImageInput: _SurveyImageInput.default
    // SurveyDebug
  },

  props: {
    displayType: {
      type: String,
      default: 'create'
    }
  },
  data: function data() {
    var defaultSurveyForm = {
      id: '',
      type: '',
      category: '',
      subcategory: '',
      title: '',
      description: '',
      accessMethod: '',
      accessKey: '',
      iconImg: '',
      iconDisableImg: '',
      backgroundColor: '',
      timesTake: 0,
      pages: [],
      equations: [],
      belongToHike: null,
      mailList: [],
      needNotifyEmail: true,
      emailTemplate: {
        subject: '',
        mailBody: ''
      },
      publish: ''
      // createdAt
      // updatedAt
    };

    return {
      fullscreenloading: false,
      isInUse: 'N',
      defaultSurveyForm: defaultSurveyForm,
      surveyForm: this.displayType === 'create' ? JSON.parse(JSON.stringify(defaultSurveyForm)) : null,
      surveyFormRules: {
        type: [{
          required: true,
          message: 'Please select survey type',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: 'Please enter survey title',
          trigger: 'blur'
        }],
        accessMethod: [{
          required: true,
          message: 'Please select access method',
          trigger: 'blur'
        }]
      },
      typeOptions: [{
        value: 'learning-passport',
        label: 'learning-passport'
      }, {
        value: 'manager720',
        label: 'manager720'
        // }, {
        //   value: 'hike',
        //   label: 'hike'
        // }, {
        //   value: 'loop',
        //   label: 'loop'
        // }, {
        //   value: 'vilt',
        //   label: 'vilt'
        // }, {
        //   value: 'other',
        //   label: 'other'
      }, {
        value: 'hike-feedback',
        label: 'hike-feedback'
      }],
      accessMethodOptions: [{
        value: 'user',
        label: 'user'
      }, {
        value: 'key',
        label: 'key'
      }, {
        value: 'public',
        label: 'public'
      }]
    };
  },
  created: function created() {
    var _this = this;
    if (this.displayType === 'edit') {
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var id = this.$route.params.id;
      this.fullscreenloading = true;
      (0, _survey.getSurveyIsInUse)(token, id).then(function (response) {
        _this.fullscreenloading = false;
        _this.isInUse = response.data.isInUse;
        _this.getSurvey(id, false);
      }).catch(function (error) {
        (0, _log.showDebugLog)('survey-getSurveyIsInUse-error', error);
        _this.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    } else if (this.displayType === 'createfromcopy') {
      var fromId = typeof this.$route.query.fromId === 'undefined' ? '' : this.$route.query.fromId;
      this.getSurvey(fromId, true);
    }
  },
  methods: {
    getSurvey: function getSurvey(id, isCopy) {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.fullscreenloading = true;
      (0, _survey.getSurveyById)(token, id).then(function (response) {
        var d = response.data;
        // check error and return immediate
        if (typeof d.id === 'undefined') {
          // this.fullscreenloading = false
          var msg = 'Cannot get the survey, please contact to system administrator';
          (0, _log.displayPopupMessage)(msg, 'error');
          return;
        }
        (0, _log.showDebugLog)('Survey-getSurveyById-response', d);
        // this.surveyForm.id = isCopy ? 'copy' : d.id

        _this2.fullscreenloading = false;
        if (isCopy) {
          var surveyForm = JSON.parse(JSON.stringify(_this2.defaultSurveyForm));

          // type
          surveyForm.type = _this2.$route.query.type === 'true' ? d.type : '';
          // category
          surveyForm.category = _this2.$route.query.c === 'true' ? d.category : '';
          // title
          surveyForm.title = _this2.$route.query.t === 'true' ? d.title : '';
          // description
          surveyForm.description = _this2.$route.query.d === 'true' ? d.description : '';
          // accessMethod
          surveyForm.accessMethod = _this2.$route.query.am === 'true' ? d.accessMethod : '';
          // accessKey
          surveyForm.accessKey = _this2.$route.query.ak === 'true' ? d.accessKey : '';
          // iconImg
          surveyForm.iconImg = _this2.$route.query.ii === 'true' ? d.iconImg : '';
          // iconDisableImg
          surveyForm.iconDisableImg = _this2.$route.query.idi === 'true' ? d.iconDisableImg : '';
          // backgroundColor
          surveyForm.backgroundColor = _this2.$route.query.bc === 'true' ? d.backgroundColor : '';
          // timesTake
          surveyForm.timesTake = _this2.$route.query.tt === 'true' ? d.timesTake : '';
          // pages
          surveyForm.pages = _this2.$route.query.p === 'true' ? d.pages : '';
          _this2.surveyForm = surveyForm;
        } else {
          // If survey is in use, disable all delete buttons
          if (_this2.isInUse === 'Y') {
            response.data.pages.forEach(function (page) {
              page.isDeletable = false;
              page.questions.forEach(function (question) {
                question.isDeletable = false;
              });
            });
          }
          _this2.surveyForm = Object.assign({}, _this2.defaultSurveyForm, response.data);
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('survey-getSurveyById-error', error);
        _this2.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    isValid: function isValid() {
      if (this.surveyForm.type === '') {
        var msg = 'Type is missing';
        (0, _log.displayPopupMessage)(msg, 'error');
        return false;
      }
      if (this.surveyForm.title.trim() === '') {
        var _msg = 'Title is missing';
        (0, _log.displayPopupMessage)(_msg, 'error');
        return false;
      }
      if (this.surveyForm.accessMethod === '') {
        var _msg2 = 'Access Method is missing';
        (0, _log.displayPopupMessage)(_msg2, 'error');
        return false;
      }
      return true;
    },
    doCreate: function doCreate(publishStatus) {
      var _this3 = this;
      if (!this.isValid()) {
        return;
      }
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');
      var formData = JSON.parse(JSON.stringify(this.surveyForm));
      formData.publish = publishStatus;

      // Remove useless page properties
      formData.pages.forEach(function (page) {
        delete page.label;
        delete page.isDeletable;
        delete page.thumbnailFile;
        delete page.showThumbnailUploadErrMsg;

        // Remove useless question properties
        page.questions.forEach(function (question) {
          delete question.isDeletable;
        });
      });
      this.fullscreenloading = true;
      (0, _survey.createSurvey)(token, formData).then(function (response) {
        (0, _log.showDebugLog)('training-create-response', response);
        _this3.fullscreenloading = false;
        var msg = "".concat(response.data.title, " (").concat(response.data.id, ") is created.");
        (0, _log.displayPopupMessage)(msg, 'success');
        if (_this3.displayType === 'createfromcopy' || _this3.displayType === 'create') {
          _this3.$router.push("/survey/edit/".concat(response.data.id));
        }
        (0, _ga.writeGaEvent)(_this3, 'Survey', "".concat(_lifehikesInitData.userEvent.survey.create), oEmail, "Survey Id: ".concat(response.data.id));
      }).catch(function (error) {
        (0, _log.showDebugLog)('survey-create-error', error);
        _this3.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    checkAndSave: function checkAndSave(publishStatus) {
      var _this4 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var id = this.$route.params.id;
      this.fullscreenloading = true;
      (0, _survey.getSurveyIsInUse)(token, id).then(function (response) {
        _this4.fullscreenloading = false;
        if (_this4.isInUse === 'N' && response.data.isInUse === 'Y') {
          _this4.$confirm('This survey is in use. Please refresh the page to get latest status.', 'Confirm', {
            confirmButtonText: 'OK and refresh',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            location.reload();
          }).catch(function () {});
        } else {
          _this4.doSave(publishStatus);
        }
      }).catch(function (error) {
        (0, _log.showDebugLog)('survey-getSurveyIsInUse-error', error);
        _this4.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    doSave: function doSave(publishStatus) {
      var _this5 = this;
      if (!this.isValid()) {
        return;
      }
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var oEmail = (0, _cookie.getCookie)('EMAIL');
      var id = this.$route.params.id;
      var formData = JSON.parse(JSON.stringify(this.surveyForm));
      formData.publish = publishStatus;

      // Remove useless page properties
      formData.pages.forEach(function (page) {
        delete page.label;
        delete page.isDeletable;
        delete page.thumbnailFile;
        delete page.showThumbnailUploadErrMsg;

        // Remove useless question properties
        page.questions.forEach(function (question) {
          delete question.isDeletable;
        });
      });
      this.fullscreenloading = true;
      (0, _log.showDebugLog)('Save Form', formData);
      (0, _survey.updateSurveyById)(token, id, formData).then(function (response) {
        (0, _log.showDebugLog)('Survey-update-response', response);
        _this5.fullscreenloading = false;
        // this.surveyForm.id = response.data.id
        _this5.surveyForm.publish = response.data.publish;
        var msg = "".concat(response.data.title, " (").concat(response.data.id, ") is updated.");
        (0, _log.displayPopupMessage)(msg, 'success');
        (0, _ga.writeGaEvent)(_this5, 'Survey', "".concat(_lifehikesInitData.userEvent.survey.edit), oEmail, "Survey Id: ".concat(response.data.id));
      }).catch(function (error) {
        (0, _log.showDebugLog)('Survey-update-error', error);
        _this5.fullscreenloading = false;
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};