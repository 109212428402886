var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("i", { staticClass: "el-icon-date" }),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [_vm._v("Timezone")]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 5 } }, [
            _c("span", [_vm._v("Local Time")]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 5 } }, [
            _c("div", { staticStyle: { "pading-left": "10px" } }, [
              _vm._v("UTC Time"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("div", [
              _vm.isRequired
                ? _c("span", { staticClass: "otr-red" }, [_vm._v("*")])
                : _vm._e(),
              _vm._v(" Start"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", placeholder: "select" },
                  on: { change: _vm.setTimeZone },
                  model: {
                    value: _vm.startTimeZome,
                    callback: function ($$v) {
                      _vm.startTimeZome = $$v
                    },
                    expression: "startTimeZome",
                  },
                },
                _vm._l(_vm.timeZoneList, function (t) {
                  return _c("el-option", {
                    key: t.offset + t.gmt,
                    attrs: { label: t.label, value: t.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "Select date and time",
                  "default-time": "09:30:00",
                },
                on: {
                  change: function ($event) {
                    return _vm.setUtcTime($event, "start")
                  },
                },
                model: {
                  value: _vm.startTime.local,
                  callback: function ($$v) {
                    _vm.$set(_vm.startTime, "local", $$v)
                  },
                  expression: "startTime.local",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c("el-input", {
                attrs: {
                  readonly: true,
                  disabled: "",
                  placeholder: _vm.startPlaceholder,
                },
                model: {
                  value: _vm.startTime.utc,
                  callback: function ($$v) {
                    _vm.$set(_vm.startTime, "utc", $$v)
                  },
                  expression: "startTime.utc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("div", [
              _vm.isRequired
                ? _c("span", { staticClass: "otr-red" }, [_vm._v("*")])
                : _vm._e(),
              _vm._v(" End"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", placeholder: "select" },
                  on: { change: _vm.setTimeZone },
                  model: {
                    value: _vm.endTimeZome,
                    callback: function ($$v) {
                      _vm.endTimeZome = $$v
                    },
                    expression: "endTimeZome",
                  },
                },
                _vm._l(_vm.timeZoneList, function (t) {
                  return _c("el-option", {
                    key: t.offset + t.gmt,
                    attrs: { label: t.label, value: t.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "Select date and time",
                  "default-time": "11:00:00",
                },
                on: {
                  change: function ($event) {
                    return _vm.setUtcTime($event, "end")
                  },
                },
                model: {
                  value: _vm.endTime.local,
                  callback: function ($$v) {
                    _vm.$set(_vm.endTime, "local", $$v)
                  },
                  expression: "endTime.local",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c("el-input", {
                attrs: {
                  readonly: true,
                  disabled: "",
                  placeholder: _vm.endPlaceholder,
                },
                model: {
                  value: _vm.endTime.utc,
                  callback: function ($$v) {
                    _vm.$set(_vm.endTime, "utc", $$v)
                  },
                  expression: "endTime.utc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }