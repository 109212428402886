"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGameForHike = getGameForHike;
exports.getGames = getGames;
exports.updateGameById = updateGameById;
var _request = _interopRequireDefault(require("@/utils/request"));
// import { fileURLToPath } from 'url'

function getGames(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/resources',
    method: 'get',
    params: query,
    headers: headers
  });
}
function getGameForHike(token) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: '/games/hike',
    method: 'get',
    headers: headers
  });
}
function updateGameById(token, id, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: '/resources/' + id,
    method: 'patch',
    headers: headers,
    data: data
  });
}