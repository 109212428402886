"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['Industries', 'Technology', 'Forex', 'Gold', 'Forecasts']
        },
        series: [{
          name: 'WEEKLY WRITE ARTICLES',
          type: 'pie',
          roseType: 'radius',
          radius: [15, 95],
          center: ['50%', '38%'],
          data: [{
            value: 320,
            name: 'Industries'
          }, {
            value: 240,
            name: 'Technology'
          }, {
            value: 149,
            name: 'Forex'
          }, {
            value: 100,
            name: 'Gold'
          }, {
            value: 59,
            name: 'Forecasts'
          }],
          animationEasing: 'cubicInOut',
          animationDuration: 2600
        }]
      });
    }
  }
};