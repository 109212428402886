var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.tableLoading,
          expression: "tableLoading",
          modifiers: { body: true },
        },
      ],
      staticStyle: { width: "100%" },
      attrs: { data: _vm.myBadges, border: "" },
    },
    [
      _c("el-table-column", {
        attrs: { prop: "title", label: "Title", width: "180" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "icon", label: "Icon", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("img", {
                  attrs: { src: scope.row.thumbnailUrl, height: "50" },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "description", label: "Description", width: "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.description))])]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }