"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 权限判断指令
var _default2 = exports.default = {
  directives: {
    permission: _index.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          uuid: '',
          addrType: '',
          addr1: '',
          addr2: '',
          addr3: '',
          city: '',
          state: '',
          postalCode: '',
          countryCode: '',
          billingInfo: {
            prefix: '',
            givenName: '',
            middleName: '',
            lastName: '',
            addr1: '',
            addr2: '',
            addr3: '',
            city: '',
            state: '',
            postalCode: '',
            countryCode: ''
          }
        };
      }
    }
  },
  data: function data() {
    return {
      countryList: [],
      genderList: [],
      typeList: ['Home', 'Work', 'Other'],
      suffixList: ['', 'Sr.', 'Jr.', 'II', 'III'],
      prefixList: ['', 'Mr', 'Miss', 'Mrs', 'Ms', 'Dr', 'Prof', 'Sir', 'Lady'],
      me: {},
      isSame: false
    };
  },
  created: function created() {
    var _this = this;
    console.log('page-user-acount-user', this.user);
    this.me = {
      id: this.user.id,
      uuid: this.user.uuid,
      addrType: this.user.addrType,
      addr1: this.user.addr1,
      addr2: this.user.addr2,
      addr3: this.user.addr3,
      city: this.user.city,
      state: this.user.state,
      postalCode: this.user.postalCode,
      countryCode: this.user.countryCode,
      billingInfo: this.user.billingInfo
    };
    this.$store.dispatch('facets/getInitData', 'en').then(function () {
      _this.countryList = _this.$store.getters.countries;
      _this.genderList = _this.$store.getters.genders;
    });
  },
  methods: {
    checkPermission: _permission.default,
    copyAddress: function copyAddress() {
      if (this.isSame) {
        this.me.billingInfo.addr1 = this.me.addr1;
        this.me.billingInfo.addr2 = this.me.addr2;
        this.me.billingInfo.addr3 = this.me.addr3;
        this.me.billingInfo.city = this.me.city;
        this.me.billingInfo.state = this.me.state;
        this.me.billingInfo.postalCode = this.me.postalCode;
        this.me.billingInfo.countryCode = this.me.countryCode;
      } else {
        this.me.billingInfo.addr1 = '';
        this.me.billingInfo.addr2 = '';
        this.me.billingInfo.addr3 = '';
        this.me.billingInfo.city = '';
        this.me.billingInfo.state = '';
        this.me.billingInfo.postalCode = '';
        this.me.billingInfo.countryCode = '';
      }
    },
    save: function save() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');

      // console.log('token', token)
      (0, _user.updateMyProfile)(token, this.me).then(function (response) {
        var data = response.data;
        // console.log('page-user-save', response)
        _this2.me.addrType = data.addrType;
        _this2.me.addr1 = data.addr1;
        _this2.me.addr2 = data.addr2;
        _this2.me.addr3 = data.addr3;
        _this2.me.city = data.city;
        _this2.me.state = data.state;
        _this2.me.postalCode = data.postalCode;
        _this2.me.countryCode = data.countryCode;
        _this2.me.billingInfo = data.billingInfo;
        (0, _log.displayPopupMessage)('User\' address information has been updated successfully', 'success');
        (0, _ga.writeGaEvent)(_this2, 'User', 'Update User Profile', email, "User Profile: ".concat(data.uuid));
      }).catch(function (error) {
        (0, _log.showDebugLog)('Page-user-Save-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};