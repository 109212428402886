var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["root", "otr-digital-producer"],
              expression: "['root','otr-digital-producer']",
            },
          ],
          attrs: { label: "Address Type" },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.addrType,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "addrType", $$v)
                },
                expression: "selectedUser.addrType",
              },
            },
            _vm._l(_vm.typeList, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["root", "otr-digital-producer"],
              expression: "['root','otr-digital-producer']",
            },
          ],
          attrs: { label: "Address 1" },
        },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.addr1,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "addr1", $$v)
              },
              expression: "selectedUser.addr1",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["root", "otr-digital-producer"],
              expression: "['root','otr-digital-producer']",
            },
          ],
          attrs: { label: "Address 2" },
        },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.addr2,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "addr2", $$v)
              },
              expression: "selectedUser.addr2",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["root", "otr-digital-producer"],
              expression: "['root','otr-digital-producer']",
            },
          ],
          attrs: { label: "Address 3" },
        },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.addr3,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "addr3", $$v)
              },
              expression: "selectedUser.addr3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "City" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.city,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "city", $$v)
              },
              expression: "selectedUser.city",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "State" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.state,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "state", $$v)
              },
              expression: "selectedUser.state",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "postalCode" } },
        [
          _c("el-input", {
            model: {
              value: _vm.selectedUser.postalCode,
              callback: function ($$v) {
                _vm.$set(_vm.selectedUser, "postalCode", $$v)
              },
              expression: "selectedUser.postalCode",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Country" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.selectedUser.countryCode,
                callback: function ($$v) {
                  _vm.$set(_vm.selectedUser, "countryCode", $$v)
                },
                expression: "selectedUser.countryCode",
              },
            },
            _vm._l(_vm.countryList, function (item) {
              return _c("el-option", {
                key: item.countryCode,
                attrs: { label: item.country, value: item.countryCode },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["root", "otr-digital-producer"],
              expression: "['root','otr-digital-producer']",
            },
          ],
        },
        [
          _c("hr", { staticClass: "spliter" }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "custom-label", staticStyle: { width: "100%" } },
            [
              _vm._v("Billing Info "),
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.copyAddress },
                  model: {
                    value: _vm.isSame,
                    callback: function ($$v) {
                      _vm.isSame = $$v
                    },
                    expression: "isSame",
                  },
                },
                [_vm._v("(Same as above )")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Prefix" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.selectedUser.billingInfo.prefix,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectedUser.billingInfo, "prefix", $$v)
                    },
                    expression: "selectedUser.billingInfo.prefix",
                  },
                },
                _vm._l(_vm.prefixList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Given Name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.givenName,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "givenName", $$v)
                  },
                  expression: "selectedUser.billingInfo.givenName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Middle Name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.middleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "middleName", $$v)
                  },
                  expression: "selectedUser.billingInfo.middleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Surname" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "lastName", $$v)
                  },
                  expression: "selectedUser.billingInfo.lastName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Suffix" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.selectedUser.billingInfo.suffix,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectedUser.billingInfo, "suffix", $$v)
                    },
                    expression: "selectedUser.billingInfo.suffix",
                  },
                },
                _vm._l(_vm.suffixList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Address 1" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.addr1,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "addr1", $$v)
                  },
                  expression: "selectedUser.billingInfo.addr1",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Address 2" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.addr2,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "addr2", $$v)
                  },
                  expression: "selectedUser.billingInfo.addr2",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Address 3" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.addr3,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "addr3", $$v)
                  },
                  expression: "selectedUser.billingInfo.addr3",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "City" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "city", $$v)
                  },
                  expression: "selectedUser.billingInfo.city",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "State" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "state", $$v)
                  },
                  expression: "selectedUser.billingInfo.state",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "postalCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.selectedUser.billingInfo.postalCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedUser.billingInfo, "postalCode", $$v)
                  },
                  expression: "selectedUser.billingInfo.postalCode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Country" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.selectedUser.billingInfo.countryCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectedUser.billingInfo, "countryCode", $$v)
                    },
                    expression: "selectedUser.billingInfo.countryCode",
                  },
                },
                _vm._l(_vm.countryList, function (item) {
                  return _c("el-option", {
                    key: item.countryCode,
                    attrs: { label: item.country, value: item.countryCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _vm.checkPermission(["root", "otr-digital-producer"])
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("Update")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-link",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "info", href: "/#/users" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }