"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LoopMain = _interopRequireDefault(require("./components/LoopMain"));
//
//
//
//
var _default = exports.default = {
  name: 'LoopCreate',
  components: {
    LoopMain: _LoopMain.default
  }
};