var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _vm.user
        ? _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, xs: 24 } },
                    [
                      _vm.user.id
                        ? _c("user-card", { attrs: { user: _vm.user } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18, xs: 24 } },
                    [
                      _c(
                        "el-card",
                        [
                          _c(
                            "el-tabs",
                            {
                              model: {
                                value: _vm.activeTab,
                                callback: function ($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "General", name: "general" },
                                },
                                [
                                  _vm.user.id
                                    ? _c("GeneralInfo", {
                                        attrs: { user: _vm.user },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Address", name: "address" },
                                },
                                [
                                  _vm.user.id
                                    ? _c("Address", {
                                        attrs: { user: _vm.user },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Education",
                                    name: "education",
                                  },
                                },
                                [
                                  _vm.user.id
                                    ? _c("Education", {
                                        attrs: { user: _vm.user },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.checkPermission([
                                "root",
                                "otr-digital-producer",
                              ])
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "Skill Set",
                                        name: "skillset",
                                      },
                                    },
                                    [
                                      _vm.user.id
                                        ? _c("SkillSet", {
                                            attrs: {
                                              skillSet:
                                                _vm.user.scoringSkillSet,
                                              uuid: _vm.user.uuid,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkPermission([
                                "root",
                                "otr-digital-producer",
                              ])
                                ? _c(
                                    "el-tab-pane",
                                    { attrs: { label: "Coins", name: "coin" } },
                                    [
                                      _vm.coin.id
                                        ? _c("Coin", {
                                            attrs: { coin: _vm.coin },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkPermission([
                                "root",
                                "otr-digital-producer",
                              ])
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      attrs: { label: "Badges", name: "badge" },
                                    },
                                    [
                                      _vm.user.id
                                        ? _c("Badge", {
                                            attrs: { badges: _vm.user.badges },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }