"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SurveyMain = _interopRequireDefault(require("./components/SurveyMain"));
//
//
//
//
var _default = exports.default = {
  name: 'SurveyBuilderCreate',
  components: {
    SurveyMain: _SurveyMain.default
  }
};