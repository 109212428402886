"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCookie = getCookie;
exports.removeCookie = removeCookie;
exports.setCookie = setCookie;
require("core-js/modules/es6.array.find");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var CookieKeys = [{
  key: 'ACCESS_TOKEN',
  value: 'access-token'
}, {
  key: 'REFRESH_TOKEN',
  value: 'refresh-token'
}, {
  key: 'UUID',
  value: 'uuid'
}, {
  key: 'ROLES',
  value: 'roles'
}, {
  key: 'EMAIL',
  value: 'email'
}, {
  key: 'TRAINING_ID',
  value: 'training-id'
}];
function getCookie(cookieKey) {
  var cookie = CookieKeys.find(function (c) {
    return c.key === cookieKey;
  });
  return cookie ? _jsCookie.default.get(cookie.value) : '';
}
function setCookie(cookieKey, value) {
  var cookie = CookieKeys.find(function (c) {
    return c.key === cookieKey;
  });
  return cookie ? _jsCookie.default.set(cookie.value, value) : '';
}
function removeCookie(cookieKey) {
  var cookie = CookieKeys.find(function (c) {
    return c.key === cookieKey;
  });
  return cookie ? _jsCookie.default.remove(cookie.value) : '';
}