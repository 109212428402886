"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _user = require("@/api/user");
var _training = require("@/api/training");
var _cookie = require("@/utils/cookie");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      totalNoOfUser: -1,
      totalNoOfUnconfirmUser: -1,
      totalNoOfILT: -1
    };
  },
  created: function created() {
    this.getNoOfUsersFigure('CONFIRMED');
    this.getNoOfUsersFigure('UNCONFIRMED');
    this.getNoOfILTEventsFigure();
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    },
    getNoOfUsersFigure: function getNoOfUsersFigure(s) {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var query = {
        status: s
      };
      (0, _user.getNoOfUsers)(token, query).then(function (response) {
        if (s === 'CONFIRMED') {
          _this.totalNoOfUser = response.data.count ? response.data.count : 0;
        } else {
          _this.totalNoOfUnconfirmUser = response.data.count ? response.data.count : 0;
        }
      });
    },
    getNoOfILTEventsFigure: function getNoOfILTEventsFigure() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var query = {
        page: 1,
        limit: 1,
        sort: '-createdAt',
        publish: 'published'
      };
      (0, _training.getTrainingList)(token, query).then(function (response) {
        _this2.totalNoOfILT = response.data.count ? response.data.count : 0;
      });
    }
  }
};