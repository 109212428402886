var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "margin-bottom": "20px" }, attrs: { span: 24 } },
            [
              _c("span", { staticStyle: { "font-size": "1.5em" } }, [
                _vm._v("Rater Assignment"),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _vm.hasPermission(["root", "otr-digital-producer", "tas-admin"])
            ? _c(
                "el-col",
                { attrs: { span: 5, offset: 0 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "20px" } } },
                    [
                      _c(
                        "a",
                        { attrs: { href: "#/rater-assignment/index-rule" } },
                        [
                          _c("img", {
                            staticClass: "image",
                            attrs: {
                              src: require("@/assets/otr/images/web-settings.png"),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "14px",
                            "font-size": "3em",
                            "text-align": "center",
                          },
                        },
                        [_c("span", [_vm._v("Rule")])]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission([
            "root",
            "otr-digital-producer",
            "tas-qa",
            "tas-admin",
          ])
            ? _c(
                "el-col",
                { attrs: { span: 5, offset: 1 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "20px" } } },
                    [
                      _c(
                        "a",
                        { attrs: { href: "#/rater-assignment/index-report" } },
                        [
                          _c("img", {
                            staticClass: "image",
                            attrs: {
                              src: require("@/assets/otr/images/chart.png"),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "14px",
                            "font-size": "3em",
                            "text-align": "center",
                          },
                        },
                        [_c("span", [_vm._v("Report")])]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission(["root", "otr-digital-producer", "tas-admin"])
            ? _c(
                "el-col",
                { attrs: { span: 5, offset: 1 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "20px" } } },
                    [
                      _c(
                        "a",
                        { attrs: { href: "#/rater-assignment/index-job" } },
                        [
                          _c("img", {
                            staticClass: "image",
                            attrs: {
                              src: require("@/assets/otr/images/job.png"),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "14px",
                            "font-size": "3em",
                            "text-align": "center",
                          },
                        },
                        [_c("span", [_vm._v("Task Pool")])]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }