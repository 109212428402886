"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var utils = _interopRequireWildcard(require("@/utils/auth"));
var _log = require("@/utils/log");
var _user = require("@/api/user");
var _lodash = _interopRequireDefault(require("lodash"));
var _lifehikesInitData = require("@/lifehikes-init-data");
var _moment = _interopRequireDefault(require("moment"));
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { showDebugLog } from '@/utils/log'

var tempTeam = [];
var _default = exports.default = {
  name: 'TrainingBaseline',
  props: {
    parentTeams: {
      type: Array,
      default: tempTeam
    },
    internalTitle: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      myTrainingTeams: [],
      fullscreenloading: false,
      isShowResult: false,
      noOfSuccess: 0,
      noOfFail: 0,
      tableData: [],
      tableHeader: [],
      smallDrop: 'smallDrop'
    };
  },
  created: function created() {
    /* no code */
    this.getTeamBaselineStatus(this.parentTeams);
    //console.info("a:"+JSON.stringify(this.parentTeams));
  },

  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var rowIndex = _ref.rowIndex;
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }
      return "evenRow";
    },
    getTeamBaselineStatus: function getTeamBaselineStatus(userlist) {
      var _this = this;
      // console.log('ORI LIST', userlist)
      if (userlist.length > 0) {
        var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
        var userlist = this.getUUidArray(userlist[0].members);
        // console.log('LIST', userlist)
        this.fullscreenloading = true;
        (0, _user.getBaselineStatus)(token, {
          list: userlist
        }).then(function (response) {
          (0, _log.showDebugLog)('Get BaselineStatus', response.data.rows);
          _this.myTrainingTeams = response.data.rows;
          _this.fullscreenloading = false;
          //console.info("a:"+JSON.stringify(this.myTrainingTeams))
        }).catch(function (error) {
          (0, _log.showDebugLog)('GetBaseline-API-error', error);
        });
      }
    },
    getUUidArray: function getUUidArray(list) {
      var userlist = [];
      for (var i = 0; i < list.length; i++) {
        userlist.push(list[i].uuid);
      }
      return userlist;
    },
    sort_change: function sort_change(column) {
      var newItems = this.myTrainingTeams.sort(this.sortFun(column.prop));
      if (column.order === "descending") {
        newItems = newItems.reverse();
      }
      this.myTrainingTeams = newItems;
      //console.info("sort a:"+JSON.stringify(this.myTrainingTeams));
    },
    sortFun: function sortFun(attr) {
      return function (item1, item2) {
        item1 = item1[attr].toLowerCase();
        item2 = item2[attr].toLowerCase();
        if (item1 < item2) {
          return -1;
        } else if (item1 > item2) {
          return 1;
        } else {
          return 0;
        }
      };
    }
  }
};