"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _log = require("@/utils/log");
var _resource = require("@/api/resource");
var _hike = require("@/api/hike");
var _cookie = require("@/utils/cookie");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultValue = [];
var _default = exports.default = {
  name: 'HikeResource',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    parentResource: {
      type: Array,
      default: defaultValue
    },
    hikeId: {
      type: String,
      default: ''
    },
    fromId: {
      type: String,
      default: ''
    },
    internalTitle: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dragging: false,
      fullscreenloading: false,
      resourceLibrary: [],
      resources: [],
      resourcesWithTitle: [],
      total: 0,
      query: {
        page: 1,
        limit: 20,
        sort: '+title',
        q: undefined,
        category: 'event-resource'
      }
    };
  },
  computed: {
    filterResLibrary: function filterResLibrary() {
      var _this = this;
      return this.resourceLibrary.filter(function (v) {
        if (_this.isNotInTraining(v)) {
          return v;
        }
        return false;
      });
    }
  },
  watch: {
    resourcesWithTitle: {
      handler: function handler(newVal) {
        // showDebugLog('Child-module emit to parent new value', newVal)
        this.passBackToParent(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    this.getResourceLibrary();
    if (this.hikeId === 'copy') {
      if (this.$route.query.r === 'true') {
        this.getSelectedResource(this.fromId);
      }
    } else {
      this.getSelectedResource(this.hikeId);
    }
    this.resources = this.parentResource;
  },
  methods: {
    getResourceLibrary: function getResourceLibrary(newSearch) {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      this.query.q = this.query.q === '' ? undefined : this.query.q;
      if (newSearch) {
        this.query.page = 1;
      }
      (0, _resource.fetchList)(token, this.query).then(function (response) {
        (0, _log.showDebugLog)('hike-getResourceLibrary-API', response.data);
        _this2.resourceLibrary = response.data.rows;
        _this2.total = response.data.count;
      }).catch(function (error) {
        (0, _log.showDebugLog)('hike-getResourceLibrary-API-error', error.repsonse.data.message);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    getSelectedResource: function getSelectedResource(id) {
      var _this3 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      (0, _hike.getResourceByHikeId)(token, id).then(function (response) {
        (0, _log.showDebugLog)('hike-getResourceLibrary-API', response.data);
        _this3.resourcesWithTitle = response.data.rows;
      }).catch(function (error) {
        (0, _log.showDebugLog)('hike-getResourceLibrary-API-error', error.repsonse.data.message);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    },
    passBackToParent: function passBackToParent(res) {
      var resList = [];
      for (var i = 0; i < this.resourcesWithTitle.length; ++i) {
        resList.push(this.resourcesWithTitle[i].id);
      }
      this.$emit('updateParentResource', resList);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getResourceLibrary(false);
    },
    changeResourceSelection: function changeResourceSelection(value, id, type, title) {
      if (value) {
        this.resourcesWithTitle.push({
          id: id,
          type: type,
          title: title
        });
      } else {
        /*
        const removedItem = _.remove(this.resourcesWithTitle, (item) => {
          return item.id === id
        })
        */

        this.resourcesWithTitle = this.resourcesWithTitle.filter(function (item) {
          return item.id !== id;
        });
      }
    }
  }
};