"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAdvancedReport = createAdvancedReport;
exports.getAdvancedReportById = getAdvancedReportById;
exports.getAdvancedReportHikeParticipants = getAdvancedReportHikeParticipants;
exports.getAdvancedReports = getAdvancedReports;
exports.updateAdvancedReport = updateAdvancedReport;
var _request = _interopRequireDefault(require("@/utils/request"));
// import { fileURLToPath } from 'url'

function getAdvancedReports(token, query) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/hike-advanced-reports",
    method: 'get',
    headers: headers,
    params: query
  });
}
function getAdvancedReportById(token, reportId) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  return (0, _request.default)({
    url: "/hike-advanced-reports/".concat(reportId),
    method: 'get',
    headers: headers
  });
}
function getAdvancedReportHikeParticipants(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: "/hike-advanced-reports/hike-participants",
    method: 'post',
    headers: headers,
    data: data
  });
}
function createAdvancedReport(token, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: "/hike-advanced-reports",
    method: 'post',
    headers: headers,
    data: data
  });
}
function updateAdvancedReport(token, reportId, formData) {
  var headers = {
    'Authorization': 'Bearer ' + token
  };
  var data = formData;
  return (0, _request.default)({
    url: "/hike-advanced-reports/".concat(reportId),
    method: 'post',
    headers: headers,
    data: data
  });
}