"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.ends-with");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _lodash = _interopRequireDefault(require("lodash"));
var _cookie = require("@/utils/cookie");
// import { getToken } from '@/utils/auth'
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout 2 mins
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  // if (store.getters.token) {
  //  // let each request carry token
  //  // ['X-Token'] is a custom headers key
  //  // please modify it according to the actual situation
  //  config.headers['X-Token'] = getToken()
  // }
  // config.headers['Access-Control-Allow-Origin'] = '*'
  return config;
}, function (error) {
  // do something with request error
  // console.log('Error from request', error) // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var refreshToken = (0, _cookie.getCookie)('REFRESH_TOKEN');
  // console.log('URL', response.config.url)

  /* if (!(_.endsWith(response.config.url, '/users/auth') && (response.config.method === 'patch' || response.config.method === 'post'))
  && !(_.endsWith(response.config.url, '/users/auth/forgot')
  && !response.config.url.includes('/info/app-init?lang'))
  ) */
  if (!response.config.url.includes('/info/app-init?lang') && !(_lodash.default.endsWith(response.config.url, '/user/auth') && response.config.method === 'patch') &&
  // login
  !(_lodash.default.endsWith(response.config.url, '/user/auth') && response.config.method === 'post') &&
  // refresh token
  !(_lodash.default.endsWith(response.config.url, '/auth') && response.config.method === 'patch') &&
  // localhost login
  !(_lodash.default.endsWith(response.config.url, '/auth') && response.config.method === 'post') // localhost refresh token
  ) {
    // console.log('RUN REFRESH TOKEN')

    _store.default.dispatch('user/refreshToken', refreshToken).then(function () {}).catch(function (err) {
      console.log('refresh token after each api', err);
    });
  }

  // console.log('response-axios', response)

  return response;
}, function (error) {
  // console.log('response-axios-err', error)
  // console.log('response-axios-err', error.response)

  if (typeof error.response === 'undefined') return Promise.reject(error);
  if (typeof error.response.status === 'undefined') return Promise.reject(error);
  if (error.response.status === 401) {
    // if the api access denied or token timeout, if just aoi access denied , redirect to dashboard page.
    // otherwise force logout
    if (error.response.config.url.indexOf('users/me') >= 0) {
      // console.log('response-axios-err', error.response)
      _store.default.dispatch('user/logout').then(function () {
        // console.log('frontend logout', '')
        _elementUi.MessageBox.alert('The token has expired, Please login again', 'Error', {
          confirmButtonText: 'OK',
          callback: function callback() {
            location.reload(); /* 为了重新实例化vue-router对象 避免bug*/
          }
        });
      });
    } else {
      _store.default.dispatch('user/redirectLandingPage');
    }
  }
  return Promise.reject(error);
});
var _default = exports.default = service;