"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          uuid: '',
          givenName: '',
          familyName: '',
          phone: '',
          birthday: '',
          genderCode: '',
          city: '',
          state: '',
          countryCode: '',
          orgName: '',
          jobTitle: '',
          email: '',
          bio: ''
        };
      }
    }
  },
  data: function data() {
    return {
      countryList: [],
      genderList: [],
      me: {}
    };
  },
  created: function created() {
    var _this = this;
    // console.log('page-profile-acount-user', this.user)
    this.me = {
      id: this.user.id,
      uuid: this.user.uuid,
      givenName: this.user.givenName,
      familyName: this.user.familyName,
      phone: this.user.phone,
      birthday: this.user.birthday,
      genderCode: this.user.genderCode,
      city: this.user.city,
      state: this.user.state,
      countryCode: this.user.countryCode,
      orgName: this.user.orgName,
      jobTitle: this.user.jobTitle,
      bio: this.user.bio
    };
    this.$store.dispatch('facets/getInitData', 'en').then(function () {
      _this.countryList = _this.$store.getters.countries;
      _this.genderList = _this.$store.getters.genders;
    });
  },
  methods: {
    save: function save() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      if (this.me.givenName === "") {
        var msg = "Given name is missing";
        (0, _log.displayPopupMessage)(msg, "error");
        return;
      }
      if (this.me.familyName === "") {
        var _msg = "Surname is missing";
        (0, _log.displayPopupMessage)(_msg, "error");
        return;
      }
      // console.log('token', token)
      (0, _user.updateMyProfile)(token, this.me).then(function (response) {
        var data = response.data;
        // console.log('page-profile-save', response)
        _this2.me.givenName = data.givenName;
        _this2.me.familyName = data.familyName;
        _this2.me.phone = data.phone;
        _this2.me.birthday = data.birthday;
        _this2.me.genderCode = data.genderCode;
        _this2.me.city = data.city;
        _this2.me.state = data.state;
        _this2.me.countryCode = data.countryCode;
        _this2.me.orgName = data.orgName;
        _this2.me.jobTitle = data.jobTitle;
        _this2.me.bio = data.bio;
        (0, _log.displayPopupMessage)('User information has been updated successfully', 'success');
        (0, _ga.writeGaEvent)(_this2, 'User', 'Update My Profile', email, '');
      }).catch(function (error) {
        (0, _log.showDebugLog)('Page-Profile-Save-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};