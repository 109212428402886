"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTokenPopup = getTokenPopup;
exports.selectAccount = selectAccount;
exports.setMsalUsername = setMsalUsername;
exports.signIn = signIn;
exports.signOut = signOut;
var _authConfig = require("./authConfig");
var _msalBrowser = require("@azure/msal-browser");
var msalUsername = '';
function setMsalUsername(username) {
  msalUsername = username;
}
function selectAccount() {
  /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

  var currentAccounts = _authConfig.myMSALObj.getAllAccounts();
  if (currentAccounts.length === 0) {
    return;
  } else if (currentAccounts.length > 1) {
    // Add choose account code here
    console.warn('Multiple accounts detected.');
  } else if (currentAccounts.length === 1) {
    msalUsername = currentAccounts[0].username;
    console.log(msalUsername);
    return msalUsername;
  }
}
function signIn() {
  /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
  return _authConfig.myMSALObj.loginPopup(_authConfig.loginRequest);
}
function signOut() {
  /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

  var logoutRequest = {
    account: _authConfig.myMSALObj.getAccountByUsername(msalUsername),
    postLogoutRedirectUri: _authConfig.msalConfig.auth.redirectUri,
    mainWindowRedirectUri: _authConfig.msalConfig.auth.redirectUri
  };
  _authConfig.myMSALObj.logoutPopup(logoutRequest);
}
function getTokenPopup(request) {
  /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
  request.account = _authConfig.myMSALObj.getAccountByUsername(msalUsername);
  return _authConfig.myMSALObj.acquireTokenSilent(request).catch(function (error) {
    console.warn('silent token acquisition fails. acquiring token using popup');
    if (error instanceof _msalBrowser.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return _authConfig.myMSALObj.acquireTokenPopup(request).then(function (tokenResponse) {
        console.log(tokenResponse);
        return tokenResponse;
      }).catch(function (error) {
        console.error(error);
      });
    } else {
      console.warn(error);
    }
  });
}