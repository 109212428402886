"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ResourceDetail = _interopRequireDefault(require("./components/ResourceDetail"));
//
//
//
//
var _default = exports.default = {
  name: 'ResourceEdit',
  components: {
    ResourceDetail: _ResourceDetail.default
  }
};