"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _user = require("@/api/user");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
var _log = require("@/utils/log");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 权限判断指令
var _default = exports.default = {
  props: {
    uuid: {
      type: String,
      default: ''
    },
    skillSet: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      raterSkillSet: [],
      scoringSkillSetList: _lifehikesInitData.scoringSkillSetList,
      listLoading: false
    };
  },
  created: function created() {
    this.raterSkillSet = this.skillSet;
  },
  methods: {
    checkPermission: _permission.default,
    save: function save() {
      var _this = this;
      var token = (0, _cookie.getCookie)('ACCESS_TOKEN');
      var email = (0, _cookie.getCookie)('EMAIL');
      // console.log('token', token)
      var newRaterSkillSet = _lifehikesInitData.scoringSkillSetList.filter(function (key) {
        return _this.raterSkillSet.includes(key.val);
      }).map(function (p) {
        return p.val;
      });
      console.info('-------newRaterSkillSet----------');
      console.info(newRaterSkillSet);
      (0, _user.updateUserProfile)(token, {
        uuid: this.uuid,
        scoringSkillSet: newRaterSkillSet
      }).then(function (response) {
        var data = response.data;
        // console.log('page-user-save', response)
        _this.raterSkillSet = data.scoringSkillSet;
        (0, _log.displayPopupMessage)('User Scoring Skill Set has been updated successfully', 'success');
        (0, _ga.writeGaEvent)(_this, 'User', 'Update Scoring Skill Set', email, "User Profile: ".concat(data.uuid));
      }).catch(function (error) {
        (0, _log.showDebugLog)('Page-user-Save-Err', error);
        (0, _log.displayPopupMessage)(error, 'error');
      });
    }
  }
};