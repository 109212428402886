"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _moment = _interopRequireDefault(require("moment"));
var _loop = require("@/api/loop");
var _log = require("@/utils/log");
var _cookie = require("@/utils/cookie");
var _ga = require("@/utils/ga");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: "LoopParticipant",
  props: {
    parentId: {
      type: String,
      default: ""
    },
    internalTitle: {
      type: String,
      default: ""
    },
    parentStaff: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      participantList: [],
      unEnrollParticipantVisible1: false,
      unEnrollParticipantVisible2: false,
      unEnrollParticipantName: "",
      unEnrollParticipantUuid: "",
      staff: []
    };
  },
  created: function created() {
    var id = this.parentId;
    this.staff = this.parentStaff;
    if (id !== "copy") {
      this.getLoopParticipants();
    }
  },
  methods: {
    getLoopParticipants: function getLoopParticipants() {
      var _this = this;
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      var id = this.parentId;
      (0, _loop.getParticipant)(token, id).then(function (response) {
        _this.loading = false;
        var dataList = response.data.rows.map(function (p) {
          if (p.enrolledBy) {
            p.enrolledByVal = p.enrolledBy.uuid;
          } else {
            p.enrolledByVal = null;
          }
          return p;
        });
        console.info(dataList);
        _this.participantList = dataList;
      }).catch(function (error) {
        _this.loading = false;
        (0, _log.showDebugLog)("Hike-getParticipant-API-error", error);
        (0, _log.displayPopupMessage)(error, "error");
      });
    },
    unEnrollParticipant: function unEnrollParticipant(user) {
      this.unEnrollParticipantVisible2 = false;
      this.unEnrollParticipantVisible1 = true;
      this.unEnrollParticipantName = user.givenName + " " + user.familyName;
      this.unEnrollParticipantUuid = user.uuid;
    },
    unEnrollParticipantFirstConfirm: function unEnrollParticipantFirstConfirm() {
      var _this2 = this;
      this.unEnrollParticipantVisible1 = false;
      setTimeout(function () {
        return _this2.unEnrollParticipantVisible2 = true;
      }, 500);
    },
    doUnEnrollParticipant: function doUnEnrollParticipant() {
      var _this3 = this;
      // Reset
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      this.loading = true;
      (0, _loop.unenrollParticipant)(token, this.parentId, this.unEnrollParticipantUuid).then(function (response) {
        _this3.getLoopParticipants();
      }).catch(function (error) {
        _this3.loading = false;
        (0, _log.showDebugLog)("Loop-getParticipant-API-error", error);
        (0, _log.displayPopupMessage)(error, "error");
      });
      this.unEnrollParticipantVisible1 = false;
      this.unEnrollParticipantVisible2 = false;
      this.unEnrollParticipantName = "";
      this.unEnrollParticipantUuid = "";
    },
    sort_change: function sort_change(column) {
      var newItems = this.participantList.sort(this.sortFun(column.prop));
      if (column.order === "descending") {
        newItems = newItems.reverse();
      }
      this.participantList = newItems;
      //console.info("sort a:"+JSON.stringify(this.participantList));
    },
    sortFun: function sortFun(attr) {
      return function (item1, item2) {
        item1 = item1[attr].toLowerCase();
        item2 = item2[attr].toLowerCase();
        if (item1 < item2) {
          return -1;
        } else if (item1 > item2) {
          return 1;
        } else {
          return 0;
        }
      };
    },
    UpdateLoopParticipantEnrolledBy: function UpdateLoopParticipantEnrolledBy(user) {
      var _this4 = this;
      var data = {
        enrolledBy: user.enrolledByVal ? user.enrolledByVal : 'N/A'
      };
      var uuid = user.uuid;
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      this.loading = true;
      (0, _loop.updateLoopParticipantEnrolledBy)(token, uuid, this.parentId, data).then(function (response) {
        var msg = 'The participant’s enrolled by is updated successfully.';
        (0, _log.displayPopupMessage)(msg, "success");
        _this4.getLoopParticipants();
      }).catch(function (error) {
        _this4.loading = false;
        (0, _log.showDebugLog)("Loop-getParticipant-API-error", error);
        (0, _log.displayPopupMessage)(error, "error", null, false);
      });
    }
  }
};