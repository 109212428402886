var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("General")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [_vm._v("ID:")]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", [_c("b", [_vm._v(_vm._s(_vm.loop.id))])]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Type"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Type" },
                      model: {
                        value: _vm.loop.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.loop, "type", $$v)
                        },
                        expression: "loop.type",
                      },
                    },
                    _vm._l(_vm.loopType, function (t) {
                      return _c("el-option", {
                        key: t.key,
                        attrs: {
                          label: t.description,
                          value: t.val,
                          disabled: t.val === "hike",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Title"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Title" },
                    model: {
                      value: _vm.loop.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop, "title", $$v)
                      },
                      expression: "loop.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Internal Title:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Internal Title" },
                    model: {
                      value: _vm.loop.internal.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop.internal, "title", $$v)
                      },
                      expression: "loop.internal.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("PID:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "e.g. TOP-VILTYURH-JKMVB-BGHGI" },
                    model: {
                      value: _vm.loop.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop, "pid", $$v)
                      },
                      expression: "loop.pid",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Description:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("vue-editor", {
                    attrs: { editorToolbar: _vm.customToolbar },
                    model: {
                      value: _vm.loop.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop, "description", $$v)
                      },
                      expression: "loop.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Salesforce")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("SF Opportunity Number:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "SF Opportunity Number" },
                    model: {
                      value: _vm.loop.internal.opportunityNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop.internal, "opportunityNumber", $$v)
                      },
                      expression: "loop.internal.opportunityNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Client Name:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Client Name" },
                    model: {
                      value: _vm.loop.internal.clientName,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop.internal, "clientName", $$v)
                      },
                      expression: "loop.internal.clientName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Event Time")]),
          _vm._v(" "),
          _c("event-time", {
            attrs: {
              start: _vm.loop.start,
              end: _vm.loop.end,
              "is-required": true,
            },
            on: {
              "update:start": function ($event) {
                return _vm.$set(_vm.loop, "start", $event)
              },
              "update:end": function ($event) {
                return _vm.$set(_vm.loop, "end", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "otrfieldset" },
        [
          _c("legend", [_vm._v("Welcome Resource  Setting")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Welcome Video Resource:"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-input", {
                    staticStyle: { width: "230px" },
                    attrs: { placeholder: "Resource Id", readonly: "" },
                    model: {
                      value: _vm.loop.welcomeBox.resource,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop.welcomeBox, "resource", $$v)
                      },
                      expression: "loop.welcomeBox.resource",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "450px" },
                    attrs: { placeholder: "Title", readonly: "" },
                    model: {
                      value: _vm.loop.welcomeBox.resourceTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop.welcomeBox, "resourceTitle", $$v)
                      },
                      expression: "loop.welcomeBox.resourceTitle",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.openResourceDialog("video")
                        },
                      },
                    },
                    [_vm._v("Select")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.clearReplayResource()
                        },
                      },
                    },
                    [_vm._v("Clear")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "otr-form__label" }, [
                  _vm._v("Welcome Message"),
                  _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
                  _vm._v(":"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("vue-editor", {
                    attrs: { editorToolbar: _vm.customToolbar },
                    model: {
                      value: _vm.loop.welcomeBox.coachHtmlTips,
                      callback: function ($$v) {
                        _vm.$set(_vm.loop.welcomeBox, "coachHtmlTips", $$v)
                      },
                      expression: "loop.welcomeBox.coachHtmlTips",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "otr-form__label", attrs: { span: 3 } }, [
            _vm._v(_vm._s(_vm.$t("otrTraining.publishStatus")) + " :"),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 21 } }, [
            _vm._v(_vm._s(_vm.loop.publish)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loop.createdAt
        ? _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "otr-form__label", attrs: { span: 3 } },
                [_vm._v("Created At :")]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _vm._v(_vm._s(_vm.showUTCDate(_vm.loop.createdAt)) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loop.updatedAt
        ? _c(
            "el-row",
            { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "otr-form__label", attrs: { span: 3 } },
                [_vm._v("Updated At :")]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _vm._v(_vm._s(_vm.showUTCDate(_vm.loop.updatedAt))),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: { title: "Resource", visible: _vm.dialogResourceVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogResourceVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: { placeholder: "Search by keyword" },
                    model: {
                      value: _vm.resourceQuery.q,
                      callback: function ($$v) {
                        _vm.$set(_vm.resourceQuery, "q", $$v)
                      },
                      expression: "resourceQuery.q",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getResources()
                        },
                      },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.body",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                  modifiers: { body: true },
                },
              ],
              attrs: { data: _vm.resourceOptions },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Add", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addResourceInStage(
                                  scope.row.id,
                                  scope.row.title
                                )
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Category", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.category))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Title", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: { title: "Resource", visible: _vm.dialogContactVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogContactVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: { placeholder: "Search by keyword" },
                    model: {
                      value: _vm.contactQuery.q,
                      callback: function ($$v) {
                        _vm.$set(_vm.contactQuery, "q", $$v)
                      },
                      expression: "contactQuery.q",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getList()
                        },
                      },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.body",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                  modifiers: { body: true },
                },
              ],
              attrs: { data: _vm.userList },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Add", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addContactInStage(scope.row)
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "UUID", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.uuid))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "GIVEN NAME", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.givenName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Surname", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.familyName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "EMAIL", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.email))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }