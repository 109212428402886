"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/codebuild/output/src582379239/src/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _components = _interopRequireDefault(require("./modules/components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      icon: 'dashboard',
      affix: true,
      noCache: true
    }
  }]
},
/*
{
  path: '/documentation',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/documentation/index'),
      name: 'Documentation',
      meta: { title: 'Documentation', icon: 'documentation', affix: true }
    }
  ]
},
*/
{
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/profile/index'));
      });
    },
    name: 'Profile',
    meta: {
      title: 'Profile',
      icon: 'user',
      noCache: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/users',
  component: _layout.default,
  redirect: '/users/index',
  meta: {
    title: 'Users',
    icon: 'user',
    roles: ['root', 'otr-digital-producer']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/user/index'));
      });
    },
    name: 'Users',
    meta: {
      title: 'Users',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    }
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/user/edit'));
      });
    },
    name: 'UserEdit',
    meta: {
      title: 'User Edit',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'coin/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/user/coin'));
      });
    },
    name: 'UserCoinEdit',
    meta: {
      title: 'Coin Edit',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/training',
  component: _layout.default,
  redirect: '/training/index',
  meta: {
    title: 'Events',
    icon: 'education',
    roles: ['root', 'otr-digital-producer']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/training/index'));
      });
    },
    name: 'TrainingList',
    meta: {
      title: 'Events',
      roles: ['root', 'otr-digital-producer'],
      noCache: false
    }
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/training/edit'));
      });
    },
    name: 'TrainingEdit',
    meta: {
      title: 'Edit',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/training/create'));
      });
    },
    name: 'TrainingCreate',
    meta: {
      title: 'Create',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'createfromcopy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/training/createFromCopy'));
      });
    },
    name: 'TrainingFromCreate',
    meta: {
      title: 'Create',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'userprogress/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/training/userProgress'));
      });
    },
    name: 'TrainingUserProgress',
    meta: {
      title: 'User Progress',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/hike',
  component: _layout.default,
  redirect: '/hike/index',
  // alwaysShow: true, // will always show the root menu
  name: 'hike',
  meta: {
    title: 'Hike Index',
    icon: 'hiker',
    roles: ['root', 'otr-digital-producer', 'tas-qa'],
    // you can set roles in root nav
    noCache: true
  },
  hidden: false,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/index'));
      });
    },
    name: 'HikeIndex',
    meta: {
      title: 'Hikes',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: false
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/create'));
      });
    },
    name: 'HikeCreate',
    meta: {
      title: 'Hike Create',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/edit'));
      });
    },
    name: 'HikeEdit',
    meta: {
      title: 'Hike Edit',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'action-setting/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/action-setting'));
      });
    },
    name: 'HikeActionSetting',
    meta: {
      title: 'Hike Action Setting',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'createfromcopy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/createFromCopy'));
      });
    },
    name: 'HikeFromCreate',
    meta: {
      title: 'Hike Create',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'enroll/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/enroll'));
      });
    },
    name: 'HikeEnrollment',
    meta: {
      title: 'Hike Enrollment',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'award-steps/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/steps'));
      });
    },
    name: 'HikeAwardSteps',
    meta: {
      title: 'Hike Award Steps',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'checking/report/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/score-checking-report'));
      });
    },
    name: 'HikeScoreReport',
    meta: {
      title: 'Score Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'task-report/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/task-report'));
      });
    },
    name: 'HikeTaskReport',
    meta: {
      title: 'Task Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'attendant-report/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/attendant-report'));
      });
    },
    name: 'HikeAttendantReport',
    meta: {
      title: 'Attendance Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'activity-report/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/activity-report'));
      });
    },
    name: 'HikeActivityReport',
    meta: {
      title: 'Activity Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'send-survey-notification/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/send-survey-notification'));
      });
    },
    name: 'HikeSendSurveyNotification',
    meta: {
      title: 'Send Survey Notification',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'response-status-report/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/response-status-report'));
      });
    },
    name: 'ResponseStatusReport',
    meta: {
      title: 'Response Status Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'feedback-result/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike/feedback-result'));
      });
    },
    name: 'HikeFeedbackResult',
    meta: {
      title: 'In-app Feedback Results',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/loop',
  component: _layout.default,
  redirect: '/loop/index',
  // alwaysShow: true, // will always show the root menu
  name: 'loop',
  meta: {
    title: 'Loop Index',
    icon: 'loop',
    roles: ['root', 'otr-digital-producer', 'tas-qa'],
    // you can set roles in root nav
    noCache: true
  },
  hidden: false,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/loop/index'));
      });
    },
    name: 'LoopIndex',
    meta: {
      title: 'Loops',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: false
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/loop/create'));
      });
    },
    name: 'LoopCreate',
    meta: {
      title: 'Loop Create',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/loop/edit'));
      });
    },
    name: 'LoopEdit',
    meta: {
      title: 'Loop Edit',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'enroll/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/loop/enroll'));
      });
    },
    name: 'LoopEnrollment',
    meta: {
      title: 'Loop Enrollment',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'createfromcopy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/loop/createFromCopy'));
      });
    },
    name: 'LoopFromCreate',
    meta: {
      title: 'Loop Create',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/hike-advanced-report',
  component: _layout.default,
  redirect: '/hike-advanced-report/index',
  meta: {
    title: 'Hike Cohort Report Index',
    icon: 'excel',
    roles: ['root', 'otr-digital-producer', 'tas-qa']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike-advanced-report/index'));
      });
    },
    name: 'HikeAdvReport',
    meta: {
      title: 'Hike Cohort Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike-advanced-report/create'));
      });
    },
    name: 'HikeAdvReportCreate',
    meta: {
      title: 'Hike Cohort Report Create',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/hike-advanced-report/edit'));
      });
    },
    name: 'HikeAdvReportEdit',
    meta: {
      title: 'Hike Cohort Report Edit',
      roles: ['root', 'otr-digital-producer', 'tas-qa'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/resources',
  component: _layout.default,
  redirect: '/resources/index',
  // alwaysShow: true, // will always show the root menu
  name: 'Resources',
  meta: {
    title: 'Resources',
    icon: 'documentation',
    roles: ['root', 'otr-digital-producer'],
    // you can set roles in root nav
    noCache: true
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/resource/index'));
      });
    },
    name: 'ResourceIndex',
    meta: {
      title: 'Resources',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    }
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/resource/edit'));
      });
    },
    name: 'ResourceEdit',
    meta: {
      title: 'Edit',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/resource/create'));
      });
    },
    name: 'ResourceCreate',
    meta: {
      title: 'Create',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/report',
  component: _layout.default,
  redirect: '/report/ilt-attendance',
  // alwaysShow: true, // will always show the root menu
  name: 'Report',
  meta: {
    title: 'Report',
    icon: 'chart',
    roles: ['root', 'otr-digital-producer'],
    // you can set roles in root nav
    noCache: true
  },
  hidden: false,
  children: [{
    path: 'ilt-attendance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/report/ilt-attendance'));
      });
    },
    name: 'ReportAttendance',
    meta: {
      title: 'Event\'s Attendance',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    }
  }, {
    path: 'attendance/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/report/attendance-report'));
      });
    },
    name: 'ILTReport',
    meta: {
      title: 'Event\'s Attendance Report',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'progress/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/report/progress-report'));
      });
    },
    name: 'ProgressReport',
    meta: {
      title: 'Progress Report',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/rater-assignment',
  component: _layout.default,
  redirect: '/rater-assignment/index',
  // alwaysShow: true, // will always show the root menu
  name: 'rater-assignment',
  meta: {
    title: 'Rater Assignment',
    icon: 'rating2',
    roles: ['root', 'otr-digital-producer', 'tas-qa', 'tas-admin'],
    // you can set roles in root nav
    noCache: true
  },
  hidden: false,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/rater-assignment/index'));
      });
    },
    name: 'rater-assignment-index',
    meta: {
      title: 'Rater Assignment',
      roles: ['root', 'otr-digital-producer', 'tas-qa', 'tas-admin'],
      noCache: true
    }
  }, {
    path: 'index-rule',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/rater-assignment/index-rule'));
      });
    },
    name: 'rater-assignment-index-rule',
    meta: {
      title: 'Rule',
      roles: ['root', 'otr-digital-producer', 'tas-qa', 'tas-admin'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'index-rule-default',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/rater-assignment/index-rule-default'));
      });
    },
    name: 'rater-assignment-index-rule-default',
    meta: {
      title: 'Rule',
      roles: ['root', 'otr-digital-producer', 'tas-qa', 'tas-admin'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'index-report',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/rater-assignment/index-report'));
      });
    },
    name: 'rater-assignment-index-report',
    meta: {
      title: 'Report',
      roles: ['root', 'otr-digital-producer', 'tas-qa', 'tas-admin'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'index-job',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/lifehikes/rater-assignment/index-job'));
      });
    },
    name: 'rater-assignment-index-job',
    meta: {
      title: 'Task Pool',
      roles: ['root', 'otr-digital-producer', 'tas-qa', 'tas-admin'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/survey',
  component: _layout.default,
  redirect: '/survey/index',
  // alwaysShow: true, // will always show the root menu
  name: 'survey-builder',
  meta: {
    title: 'Survey Builer',
    icon: 'surveyor-white',
    roles: ['root', 'otr-digital-producer'],
    // you can set roles in root nav
    noCache: true
  },
  hidden: false,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/survey-builder/index'));
      });
    },
    name: 'SurveyBuilderIndex',
    meta: {
      title: 'Survey Builder',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/survey-builder/create'));
      });
    },
    name: 'SurveyBuilderCreate',
    meta: {
      title: 'Create',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'edit/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/survey-builder/edit'));
      });
    },
    name: 'SurveyBuilderEdit',
    meta: {
      title: 'Edit',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }, {
    path: 'createfromcopy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/survey-builder/createFromCopy'));
      });
    },
    name: 'SurveyBuilderFromCreate',
    meta: {
      title: 'Create',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/learning-passport',
  component: _layout.default,
  redirect: '/learning-passport/index',
  // alwaysShow: true, // will always show the root menu
  name: 'learning-passport',
  meta: {
    title: 'Learning Passport',
    icon: 'passport',
    roles: ['root', 'otr-digital-producer'],
    // you can set roles in root nav
    noCache: true
  },
  hidden: false,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/learning-passport/index'));
      });
    },
    name: 'learning-passport-uploader',
    meta: {
      title: 'Learning Passport Uploader',
      roles: ['root', 'otr-digital-producer'],
      noCache: true
    }
  }]
},
/* // Remove webcontent + questions for >1.2.9
{
  path: '/questions',
  component: Layout,
  redirect: '/questions/index',
  // alwaysShow: true, // will always show the root menu
  name: 'Questions',
  hidden: false,
  meta: {
    title: 'Questions',
    icon: 'list',
    roles: ['root', 'otr-digital-producer'], // you can set roles in root nav
    noCache: true
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/lifehikes/question/index'),
      name: 'QuestionIndex',
      meta: {
        title: 'Questions',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      }
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/lifehikes/question/edit'),
      name: 'QuestionEdit',
      meta: {
        title: 'Edit',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      },
      hidden: true
    },
    {
      path: 'create',
      component: () => import('@/views/lifehikes/question/create'),
      name: 'QuestionCreate',
      meta: {
        title: 'Create',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      },
      hidden: true
    }
  ]
},
{
  path: '/webcontent',
  component: Layout,
  redirect: '/webcontent/index',
  // alwaysShow: true, // will always show the root menu
  name: 'WebContent',
  hidden: false,
  meta: {
    title: 'Web Content',
    icon: 'international',
    roles: ['root', 'otr-digital-producer'], // you can set roles in root nav
    noCache: true
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/lifehikes/training-webcontent/index'),
      name: 'WebContentIndex',
      meta: {
        title: 'Web Content',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      }
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/lifehikes/training-webcontent/edit'),
      name: 'WebContentEdit',
      meta: {
        title: 'Edit',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      },
      hidden: true
    },
    {
      path: 'create',
      component: () => import('@/views/lifehikes/training-webcontent/create'),
      name: 'WebContentCreate',
      meta: {
        title: 'Create',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      },
      hidden: true
    }
  ]
},
*/
/*
{
  path: '/smart-tip',
  component: Layout,
  redirect: '/smart-tip/index',
  // alwaysShow: true, // will always show the root menu
  name: 'SmartTip',
  meta: {
    title: 'SmartTip',
    icon: 'star',
    roles: ['root', 'otr-digital-producer'], // you can set roles in root nav
    noCache: true
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/lifehikes/smarttip/index'),
      name: 'SmartTipIndex',
      meta: {
        title: 'Smart Tip',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      }
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/lifehikes/smarttip/edit'),
      name: 'SmartTipEdit',
      meta: {
        title: 'Edit',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      },
      hidden: true
    },
    {
      path: 'create',
      component: () => import('@/views/lifehikes/smarttip/create'),
      name: 'SmartTipCreate',
      meta: {
        title: 'Create',
        roles: ['root', 'otr-digital-producer'],
        noCache: true
      },
      hidden: true
    }
  ]
},
*/

/* default sample code
{
  path: '/icon',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/icons/index'),
      name: 'Icons',
      meta: { title: 'Icons', icon: 'icon', noCache: true }
    }
  ]
},
componentsRouter,
chartsRouter,
nestedRouter,
tableRouter,
{
  path: '/example',
  component: Layout,
  redirect: '/example/list',
  name: 'Example',
  meta: {
    title: 'Example',
    icon: 'example'
  },
  children: [
    {
      path: 'create',
      component: () => import('@/views/example/create'),
      name: 'CreateArticle',
      meta: { title: 'Create Article', icon: 'edit' }
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/example/edit'),
      name: 'EditArticle',
      meta: { title: 'Edit Article', noCache: true, activeMenu: '/example/list' },
      hidden: true
    },
    {
      path: 'list',
      component: () => import('@/views/example/list'),
      name: 'ArticleList',
      meta: { title: 'Article List', icon: 'list' }
    }
  ]
},

{
  path: '/tab',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/tab/index'),
      name: 'Tab',
      meta: { title: 'Tab', icon: 'tab' }
    }
  ]
},

{
  path: '/error',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'Error Pages',
    icon: '404'
  },
  children: [
    {
      path: '401',
      component: () => import('@/views/error-page/401'),
      name: 'Page401',
      meta: { title: '401', noCache: true }
    },
    {
      path: '404',
      component: () => import('@/views/error-page/404'),
      name: 'Page404',
      meta: { title: '404', noCache: true }
    }
  ]
},

{
  path: '/error-log',
  component: Layout,
  children: [
    {
      path: 'log',
      component: () => import('@/views/error-log/index'),
      name: 'ErrorLog',
      meta: { title: 'Error Log', icon: 'bug' }
    }
  ]
},

{
  path: '/excel',
  component: Layout,
  redirect: '/excel/export-excel',
  name: 'Excel',
  meta: {
    title: 'Excel',
    icon: 'excel'
  },
  children: [
    {
      path: 'export-excel',
      component: () => import('@/views/excel/export-excel'),
      name: 'ExportExcel',
      meta: { title: 'Export Excel' }
    },
    {
      path: 'export-selected-excel',
      component: () => import('@/views/excel/select-excel'),
      name: 'SelectExcel',
      meta: { title: 'Export Selected' }
    },
    {
      path: 'export-merge-header',
      component: () => import('@/views/excel/merge-header'),
      name: 'MergeHeader',
      meta: { title: 'Merge Header' }
    },
    {
      path: 'upload-excel',
      component: () => import('@/views/excel/upload-excel'),
      name: 'UploadExcel',
      meta: { title: 'Upload Excel' }
    }
  ]
},

{
  path: '/zip',
  component: Layout,
  redirect: '/zip/download',
  alwaysShow: true,
  name: 'Zip',
  meta: { title: 'Zip', icon: 'zip' },
  children: [
    {
      path: 'download',
      component: () => import('@/views/zip/index'),
      name: 'ExportZip',
      meta: { title: 'Export Zip' }
    }
  ]
},

{
  path: '/pdf',
  component: Layout,
  redirect: '/pdf/index',
  children: [
    {
      path: 'index',
      component: () => import('@/views/pdf/index'),
      name: 'PDF',
      meta: { title: 'PDF', icon: 'pdf' }
    }
  ]
},
{
  path: '/pdf/download',
  component: () => import('@/views/pdf/download'),
  hidden: true
},

{
  path: '/theme',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/theme/index'),
      name: 'Theme',
      meta: { title: 'Theme', icon: 'theme' }
    }
  ]
},

{
  path: '/clipboard',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/clipboard/index'),
      name: 'ClipboardDemo',
      meta: { title: 'Clipboard', icon: 'clipboard' }
    }
  ]
},

{
  path: 'external-link',
  component: Layout,
  children: [
    {
      path: 'https://github.com/PanJiaChen/vue-element-admin',
      meta: { title: 'External Link', icon: 'link' }
    }
  ]
},
*/
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// console.log('router', router)
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;