var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("otrTraining.id")) + ":")]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 21 } }, [
            _c("span", [_c("b", [_vm._v(_vm._s(_vm.genInfo.id))])]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v("Channel"),
              _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
              _vm._v(":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { placeholder: "Channel" },
                  model: {
                    value: _vm.genInfo.channel,
                    callback: function ($$v) {
                      _vm.$set(_vm.genInfo, "channel", $$v)
                    },
                    expression: "genInfo.channel",
                  },
                },
                [
                  _c("el-option", {
                    key: "ilt",
                    attrs: { label: "ILT", value: "ilt" },
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    key: "vilt",
                    attrs: { label: "VILT", value: "vilt" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.title"))),
              _c("span", { staticClass: "otr-red" }, [_vm._v("*")]),
              _vm._v(":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("otrTraining.title") },
                model: {
                  value: _vm.genInfo.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo, "title", $$v)
                  },
                  expression: "genInfo.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.internalTitle")) + ":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("otrTraining.internalTitle") },
                model: {
                  value: _vm.genInfo.internal.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo.internal, "title", $$v)
                  },
                  expression: "genInfo.internal.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.description")) + ":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("tinymce", {
                ref: "editorTInfo",
                attrs: { id: "tinymceTInfo", height: 300 },
                model: {
                  value: _vm.genInfo.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo, "description", $$v)
                  },
                  expression: "genInfo.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v("Estimated Participants:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("el-input-number", {
                attrs: { min: 0, max: 10000 },
                model: {
                  value: _vm.genInfo.estimatedParticipantCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo, "estimatedParticipantCount", $$v)
                  },
                  expression: "genInfo.estimatedParticipantCount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.sfOPNum")) + ":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("otrTraining.sfOPNum1") },
                model: {
                  value: _vm.genInfo.internal.opportunityNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo.internal, "opportunityNumber", $$v)
                  },
                  expression: "genInfo.internal.opportunityNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.clientName")) + ":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("otrTraining.clientName") },
                model: {
                  value: _vm.genInfo.internal.clientName,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo.internal, "clientName", $$v)
                  },
                  expression: "genInfo.internal.clientName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _c("span", { staticStyle: { color: "blue" } }, [_vm._v("Event Time")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { border: "0px solid #dcdfe6", padding: "10px" } },
        [
          _c("event-time", {
            attrs: {
              start: _vm.genInfo.start,
              end: _vm.genInfo.end,
              "is-required": false,
            },
            on: {
              "update:start": function ($event) {
                return _vm.$set(_vm.genInfo, "start", $event)
              },
              "update:end": function ($event) {
                return _vm.$set(_vm.genInfo, "end", $event)
              },
            },
          }),
          _vm._v(" "),
          _vm.sessions.length === 0 && _vm.genInfo.id
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "fr",
                          attrs: { type: "success" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.generateDefaultSession($event)
                            },
                          },
                        },
                        [_vm._v("Generate Default Session Events")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _c("span", { staticStyle: { color: "blue" } }, [
          _vm._v("Venue (Location)"),
        ]),
      ]),
      _vm._v(" "),
      _vm.genInfo.channel === "ilt"
        ? _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("otrTraining.address1")) + " :"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("otrTraining.address1") },
                        model: {
                          value: _vm.genInfo.address.address1,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo.address, "address1", $$v)
                          },
                          expression: "genInfo.address.address1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("otrTraining.address2")) + " :"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("otrTraining.address2") },
                        model: {
                          value: _vm.genInfo.address.address2,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo.address, "address2", $$v)
                          },
                          expression: "genInfo.address.address2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("otrTraining.city")) + " :"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("otrTraining.city") },
                        model: {
                          value: _vm.genInfo.address.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo.address, "city", $$v)
                          },
                          expression: "genInfo.address.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("otrTraining.region")) + " :"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("otrTraining.region") },
                        model: {
                          value: _vm.genInfo.address.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo.address, "region", $$v)
                          },
                          expression: "genInfo.address.region",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("otrTraining.country")) + " :"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("otrTraining.select"),
                          },
                          model: {
                            value: _vm.genInfo.address.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.genInfo.address, "country", $$v)
                            },
                            expression: "genInfo.address.country",
                          },
                        },
                        _vm._l(_vm.countryList, function (item) {
                          return _c("el-option", {
                            key: item.countryCode,
                            attrs: {
                              label: item.country,
                              value: item.countryCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v("Webinar Software:"),
                  ]),
                  _vm._v(" "),
                  false
                    ? _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "Zoom / WebEx / Hangout ...etc",
                            },
                            model: {
                              value: _vm.genInfo.webinarName,
                              callback: function ($$v) {
                                _vm.$set(_vm.genInfo, "webinarName", $$v)
                              },
                              expression: "genInfo.webinarName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "Zoom (Passcode:1234) / WebEx / Hangout ...etc",
                        },
                        model: {
                          value: _vm.genInfo.webinarName,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo, "webinarName", $$v)
                          },
                          expression: "genInfo.webinarName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [_vm._v("Webinar ID:")]),
                  _vm._v(" "),
                  false
                    ? _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "Webinar ID (e.g. 123 456 789)",
                            },
                            model: {
                              value: _vm.genInfo.webinarKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.genInfo, "webinarKey", $$v)
                              },
                              expression: "genInfo.webinarKey",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "Webinar ID (e.g.https://owntheroom.zoom.us/j/12345678)",
                        },
                        model: {
                          value: _vm.genInfo.webinarKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo, "webinarKey", $$v)
                          },
                          expression: "genInfo.webinarKey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v("Webinar Passcode:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Webinar Passcode" },
                        model: {
                          value: _vm.genInfo.webinarPasscode,
                          callback: function ($$v) {
                            _vm.$set(_vm.genInfo, "webinarPasscode", $$v)
                          },
                          expression: "genInfo.webinarPasscode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _c("span", { staticStyle: { color: "blue" } }, [_vm._v("Others")]),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v("Feedback form link:"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _c("el-input", {
                attrs: { placeholder: "Feedback form link" },
                model: {
                  value: _vm.genInfo.feedbackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.genInfo, "feedbackUrl", $$v)
                  },
                  expression: "genInfo.feedbackUrl",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__30", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c("span", { staticClass: "otr-form__label" }, [
              _vm._v(_vm._s(_vm.$t("otrTraining.tags")) + ":"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 21 } },
            [
              _vm._l(_vm.genInfo.tags, function (tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag,
                    staticStyle: { "margin-left": "5px" },
                    attrs: { "disable-transitions": false, closable: "" },
                    on: {
                      close: function ($event) {
                        return _vm.handleClose(tag)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(tag) + "\n      ")]
                )
              }),
              _vm._v(" "),
              _vm.tagInputVisible
                ? _c("el-input", {
                    ref: "saveTagInput",
                    staticClass: "input-new-tag",
                    staticStyle: { width: "200px" },
                    on: { blur: _vm.handleTagInputConfirm },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleTagInputConfirm($event)
                      },
                    },
                    model: {
                      value: _vm.tagInputValue,
                      callback: function ($$v) {
                        _vm.tagInputValue = $$v
                      },
                      expression: "tagInputValue",
                    },
                  })
                : _c(
                    "el-button",
                    {
                      staticClass: "button-new-tag",
                      on: { click: _vm.showTagInput },
                    },
                    [_vm._v("+ New Tag")]
                  ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "otr-form__label", attrs: { span: 3 } }, [
            _vm._v(_vm._s(_vm.$t("otrTraining.publishStatus")) + " :"),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 21 } }, [
            _vm._v(_vm._s(_vm.parentGenInfo.publish)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }