"use strict";

var _interopRequireDefault = require("/codebuild/output/src3067534842/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
var _lodash = _interopRequireDefault(require("lodash"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));
var _vue2Editor = require("vue2-editor");
var _lifehikesInitData = require("@/lifehikes-init-data");
var _log = require("@/utils/log");
var _cookie = require("@/utils/cookie");
var _resource = require("@/api/resource");
var _index2 = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
var _index3 = _interopRequireDefault(require("../../../../components/ScheduleEventTime/index.vue"));
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Tinymce from '@/components/Tinymce'
// import QrcodeVue from 'qrcode.vue'
var defaultValue = [];
var defEventStartTime = {
  utc: "",
  timezone: _momentTimezone.default.tz.guess(),
  localtime: ""
};
var defEventEndTime = {
  utc: "",
  timezone: _momentTimezone.default.tz.guess(),
  localtime: ""
};
var _default = exports.default = {
  name: "HikeStage",
  components: {
    VueEditor: _vue2Editor.VueEditor,
    UploadExcelComponent: _index.default,
    EventTime: _index2.default,
    ScheduleEventTime: _index3.default
  },
  props: {
    parentId: {
      type: String,
      default: ""
    },
    parentStages: {
      type: Array,
      default: defaultValue
    },
    internalTitle: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    parentEventStartTime: {
      type: Object,
      default: defEventStartTime
    },
    parentEventEndTime: {
      type: Object,
      default: defEventEndTime
    },
    parentWebinarName: {
      type: String,
      default: ""
    },
    parentWebinarKey: {
      type: String,
      default: ""
    },
    parentWebinarPasscode: {
      type: String,
      default: ""
    },
    parentFlagRemindSave: {
      type: Boolean,
      default: true
    },
    parentDisplayType: {
      type: String,
      default: "create"
    }
  },
  data: function data() {
    return {
      dialogActionSettingPageVisible: false,
      testTime: "",
      loading: false,
      selectloading: false,
      resourceTableLoading: false,
      hikeId: this.parentId,
      firstTab: "stage1",
      actionTab: "setting",
      activeName: "first",
      // default for tabs (action). this prevent to select all tabs at the same time
      activeCollapseNames: ["0"],
      stageType: _lifehikesInitData.stageType,
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      resourceQuery: {
        page: 1,
        limit: 999,
        q: undefined,
        sort: "title"
      },
      resourceOptions: [],
      stageIndex: 0,
      hikeStages: [],
      ratingDeadlineRemindSave: true,
      customToolbar: [["bold"], [{
        list: "bullet"
      }]],
      customToolbarWeb: [["bold", "italic", "underline"], [{
        list: "ordered"
      }, {
        list: "bullet"
      }], [{
        color: []
      }, {
        background: []
      }], ["clean"]],
      defaultSortBenchmark: 5,
      defaultSortSummit: 7,
      // Set repeated schedule
      recurrenceDialogVisible: false,
      recurrenceDialogForm: {
        start: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        end: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        recur: 'daily'
      }
    };
  },
  filters: {
    numberFormatter: function numberFormatter(value) {
      return value.toFixed(1);
    }
  },
  watch: {
    hikeStages: {
      handler: function handler(newVal) {
        // showDebugLog('Child-module emit to parent new value', newVal)
        this.passBackToParent(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    this.hikeStages = this.parentStages;
    // console.log('Create HikeStage', this.hikeStages)
    this.getResources();
    // await this.getResourceTitle()
    var id = this.parentId;
  },
  methods: {
    dateFormatter: function dateFormatter(dateStr) {
      var theDate = (0, _momentTimezone.default)(String(dateStr));
      if (theDate.isValid()) {
        return theDate.format("YYYY-MM-DD HH:mm:ss");
      }
      return "--";
    },
    getCurrentStage: function getCurrentStage() {
      var stageIdx = parseInt(this.firstTab.replace("stage", "")) - 1;
      if (stageIdx < this.hikeStages.length) {
        var stage = this.hikeStages[stageIdx];
        return stage;
      }
      return undefined;
    },
    calcVideoScore: function calcVideoScore(actionSet) {
      var total = 0;
      // console.log('showVideoScoreAlert', actionSet)
      if (typeof actionSet.actions !== "undefined") {
        for (var i = 0; i < actionSet.actions.length; i++) {
          // console.log(`scor3e-${i}`, actionScoreConfig[i].scoreConfig)
          if (typeof actionSet.actions[i].scoreConfig !== "undefined") {
            total += actionSet.actions[i].scoreConfig.rate;
          }
        }
      }
      return total;
    },
    calcVideoCount: function calcVideoCount(actionSet) {
      if (typeof actionSet.actions !== "undefined") {
        return actionSet.actions.length;
      }
      return 0;
    },
    calcVideoSkillWeight: function calcVideoSkillWeight(scoreConfig) {
      var total = 0;
      if (scoreConfig.scores) {
        var _iterator = _createForOfIteratorHelper(scoreConfig.scores),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var skill = _step.value;
            if (typeof skill.enable !== "undefined" && skill.enable) {
              total += skill["rate"];
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      return total;
    },
    goToActionSetPage: function goToActionSetPage(hikeId, index) {
      this.$router.push("/hike/action-setting/".concat(hikeId, "?s=stage").concat(index + 1));
    },
    copyEventTime: function copyEventTime(stageIdx) {
      var start = _lodash.default.cloneDeep(this.parentEventStartTime);
      var end = _lodash.default.cloneDeep(this.parentEventEndTime);
      this.hikeStages[stageIdx].start = start;
      this.hikeStages[stageIdx].end = end;
      this.setDefaultTaskSortDay(stageIdx);
    },
    copyEventTime1: function copyEventTime1(stageIdx) {
      if (this.hikeStages[stageIdx].start.utc !== "") {
        var start = _lodash.default.cloneDeep(this.hikeStages[stageIdx].start);
        var momentUTC = (0, _momentTimezone.default)(start.utc).add(-30, "m").tz(start.timezone);
        var momentLocal = (0, _momentTimezone.default)(start.local).add(-30, "m").tz(start.timezone);
        start.utc = momentUTC.utc().format();
        start.local = momentLocal.utc().format();
        this.hikeStages[stageIdx].enableStart = start;
      } else {
        var start = _lodash.default.cloneDeep(this.hikeStages[stageIdx].start);
        this.hikeStages[stageIdx].enableStart = start;
      }
      if (this.hikeStages[stageIdx].start.utc !== "") {
        var end = _lodash.default.cloneDeep(this.hikeStages[stageIdx].start);
        var momentUTC = (0, _momentTimezone.default)(end.utc).add(1, "w").add(30, "m").tz(end.timezone);
        var momentLocal = (0, _momentTimezone.default)(end.local).add(1, "w").add(30, "m").tz(end.timezone);
        end.utc = momentUTC.utc().format();
        end.local = momentLocal.utc().format();
        this.hikeStages[stageIdx].enableEnd = end;
      } else {
        var end = _lodash.default.cloneDeep(this.hikeStages[stageIdx].start);
        this.hikeStages[stageIdx].enableEnd = end;
      }
    },
    openRecurrenceDialog: function openRecurrenceDialog() {
      this.recurrenceDialogVisible = true;
    },
    setRecurrenceDialogTimeZone: function setRecurrenceDialogTimeZone(e) {
      (0, _log.showDebugLog)("select timezone value", e);
      this.recurrenceDialogForm.start.timezone = e;
      this.recurrenceDialogForm.end.timezone = e;
      this.setRecurrenceDialogUtcTime(this.recurrenceDialogForm.start.local, "start");
      this.setRecurrenceDialogUtcTime(this.recurrenceDialogForm.end.local, "end");
    },
    setRecurrenceDialogUtcTime: function setRecurrenceDialogUtcTime(e, dtype) {
      if (this.recurrenceDialogForm.start.timezone) {
        (0, _log.showDebugLog)("print timezone", this.recurrenceDialogForm.start.timezone);
        var tmp_datetime;

        // set the local time without timezone
        tmp_datetime = (0, _momentTimezone.default)(this.recurrenceDialogForm[dtype].local).format("YYYY-MM-DD HH:mm:ss").toString();
        // set the tmp local time with timezone
        if (_momentTimezone.default.tz(tmp_datetime, this.recurrenceDialogForm[dtype].timezone).isValid()) {
          tmp_datetime = _momentTimezone.default.tz(tmp_datetime, this.recurrenceDialogForm[dtype].timezone).format();
          (0, _log.showDebugLog)("TMP local new ".concat(dtype, " time"), tmp_datetime);
          this.recurrenceDialogForm[dtype].utc = _momentTimezone.default.parseZone(tmp_datetime).utc().format();
        } else {
          this.recurrenceDialogForm[dtype].utc = "";
          this.recurrenceDialogForm[dtype].local = "";
        }
      } else {
        this.recurrenceDialogForm[dtype].utc = "";
        this.recurrenceDialogForm[dtype].local = "";
      }
      this.$forceUpdate();
    },
    setRepeatSchedule: function setRepeatSchedule() {
      var _this = this;
      var add = 0;
      var unit = '';
      if (this.recurrenceDialogForm.recur === 'daily') {
        add = 1;
        unit = 'd';
      } else if (this.recurrenceDialogForm.recur === 'weekly') {
        add = 7;
        unit = 'd';
      } else if (this.recurrenceDialogForm.recur === 'bi-weekly') {
        add = 14;
        unit = 'd';
      } else if (this.recurrenceDialogForm.recur === 'monthly') {
        add = 1;
        unit = 'M';
      } else if (this.recurrenceDialogForm.recur === 'yearly') {
        add = 1;
        unit = 'y';
      }
      this.hikeStages.forEach(function (stage, index) {
        stage.start.local = (0, _momentTimezone.default)(_this.recurrenceDialogForm.start.local).add(add * index, unit).toDate();
        stage.end.local = (0, _momentTimezone.default)(_this.recurrenceDialogForm.end.local).add(add * index, unit).toDate();
        _this.setTimeZone(_this.recurrenceDialogForm.start.timezone, index);
      });
      this.recurrenceDialogVisible = false;
      (0, _log.displayPopupMessage)("Event times of stages(".concat(this.hikeStages.map(function (s, i) {
        return i + 1;
      }).join(', '), ") have been set"), 'success');
    },
    copyWebinar: function copyWebinar(stageIdx) {
      this.hikeStages[stageIdx].webinarName = this.parentWebinarName;
      this.hikeStages[stageIdx].webinarKey = this.parentWebinarKey;
      this.hikeStages[stageIdx].webinarPasscode = this.parentWebinarPasscode;
    },
    addStage: function addStage() {
      var s = {
        type: "benchmark",
        allowCheckin: true,
        checkinStepsValue: 0,
        actionSets: [],
        // resources: [],
        title: "",
        shortDescription: "",
        description: "",
        report: _lodash.default.cloneDeep(_lifehikesInitData.defaultReport),
        start: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        end: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        enableStart: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        enableEnd: {
          utc: "",
          timezone: _momentTimezone.default.tz.guess(),
          local: ""
        },
        webinarName: "",
        webinarKey: "",
        webinarPasscode: "",
        defaultCoin: 0,
        taskSortDay: 0,
        enable720Survey: false,
        config720Survey: _lodash.default.cloneDeep(_lifehikesInitData.default720Report)
      };
      this.hikeStages.push(s);
      this.firstTab = "stage".concat(this.hikeStages.length);
    },
    copyLastStage: function copyLastStage() {
      if (this.hikeStages.length > 0) {
        var maxIdx = this.hikeStages.length - 1;
        var targetIdx = -1;
        for (var _i = maxIdx; _i >= 0; _i--) {
          var type = this.hikeStages[_i].type;
          if (type === "stage") {
            targetIdx = _i;
            break;
          }
        }
        if (targetIdx === -1) {
          (0, _log.displayPopupMessage)('"Normal Stage" not found.', "info");
        } else {
          var newStage = _lodash.default.cloneDeep(this.hikeStages[targetIdx]);
          delete newStage.id;
          for (var i = 0; i < newStage.actionSets.length; i++) {
            delete newStage.actionSets[i].id;
            for (var j = 0; j < newStage.actionSets[i].actions.length; j++) {
              delete newStage.actionSets[i].actions[j].id;
            }
          }
          this.hikeStages.push(newStage);
          this.firstTab = "stage".concat(this.hikeStages.length);
        }
      } else {
        (0, _log.displayPopupMessage)("Nothing to copy", "info");
      }
    },
    removeStage: function removeStage(idx) {
      this.hikeStages.splice(idx, 1);
      this.firstTab = idx === 0 ? "stage1" : "stage".concat(idx);
    },
    moveNext: function moveNext(index) {
      if (index < this.hikeStages.length) {
        var element = _lodash.default.cloneDeep(this.hikeStages[index]);
        var element2 = _lodash.default.cloneDeep(this.hikeStages[index + 1]);
        this.$set(this.hikeStages, index, element2);
        this.$set(this.hikeStages, index + 1, element);
        this.firstTab = "stage".concat(index + 2);

        /*
        this.$nextTick(() =>
          window.tinymce.get(`stageDesc_${index}`).setContent(element2.description || ''))
        */
        // this.$refs.smartTip.setContent(this.selectedTip.contents[this.contentIdx])
        // console.log(this.hikeStages);

        this.$forceUpdate();
      }
    },
    movePrevious: function movePrevious(index) {
      if (index > 0) {
        var element = _lodash.default.cloneDeep(this.hikeStages[index]);
        var element2 = _lodash.default.cloneDeep(this.hikeStages[index - 1]);
        this.$set(this.hikeStages, index - 1, element);
        this.$set(this.hikeStages, index, element2);
        this.firstTab = "stage".concat(index);

        /*
        this.$nextTick(() =>
          window.tinymce.get(`stageDesc_${index}`).setContent(element2.description || ''))
        */
        this.$forceUpdate();
      }
    },
    getResources: function getResources() {
      var _this2 = this;
      var token = (0, _cookie.getCookie)("ACCESS_TOKEN");
      (0, _resource.fetchList)(token, this.resourceQuery).then(function (response) {
        var data = response.data;
        _this2.resourceOptions = data.rows;
        _this2.resourceTableLoading = false;
      }).catch(function (error) {
        (0, _log.showDebugLog)("Resource-API-response", error);
        _this2.resourceTableLoading = false;
        (0, _log.displayPopupMessage)(error, "error");
      });
    },
    calLocalTime: function calLocalTime(utcTime, zone) {
      var localtime = "";
      if (utcTime && zone) {
        localtime = (0, _momentTimezone.default)(utcTime).tz(zone).format("YYYY-MM-DD HH:mm:ss").toString();
      }
      return localtime;
    },
    setDefaultTaskSortDay: function setDefaultTaskSortDay(idx) {
      // set default tasksortday
      if (this.hikeStages[idx].type === "benchmark" && this.hikeStages[idx].start.local) {
        this.hikeStages[idx].taskSortDay = this.defaultSortBenchmark;
      }
      if (this.hikeStages[idx].type === "summit" && this.hikeStages[idx].start.local) {
        this.hikeStages[idx].taskSortDay = this.defaultSortSummit;
      }
    },
    setTimeZone: function setTimeZone(e, idx) {
      (0, _log.showDebugLog)("select timezone value", e);
      this.hikeStages[idx].start.timezone = e;
      this.hikeStages[idx].end.timezone = e;
      this.hikeStages[idx].enableStart.timezone = e;
      this.hikeStages[idx].enableEnd.timezone = e;

      // this.hike.start.local= moment(this.hike.start.local).tz(e)
      // this.hike.end.local = moment(this.hike.end.local).tz(e)

      this.setUtcTime(this.hikeStages[idx].start.local, idx, "start");
      this.setUtcTime(this.hikeStages[idx].end.local, idx, "end");
      this.setUtcTime(this.hikeStages[idx].enableStart.local, idx, "enablestart");
      this.setUtcTime(this.hikeStages[idx].enableEnd.local, idx, "enableend");
    },
    setUtcTime: function setUtcTime(e, idx, dtype) {
      if (this.hikeStages[idx].start.timezone) {
        (0, _log.showDebugLog)("print timezone", this.hikeStages[idx].start.timezone);
        var tmp_datetime;
        if (dtype === "start") {
          this.setDefaultTaskSortDay(idx);

          // set the local time without timezone
          tmp_datetime = (0, _momentTimezone.default)(this.hikeStages[idx].start.local).format("YYYY-MM-DD HH:mm:ss").toString();
          // set the tmp local time with timezone
          if (_momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].start.timezone).isValid()) {
            tmp_datetime = _momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].start.timezone).format();
            // this.hikeStages[idx].start.local = moment.tz(tmp_datetime, this.hikeStages[idx].start.timezone).format('YYYY-MM-DD HH:mm:ss').toString()
            (0, _log.showDebugLog)("TMP local new time", tmp_datetime);
            this.hikeStages[idx].start.utc = _momentTimezone.default.parseZone(tmp_datetime).utc().format();
          } else {
            this.hikeStages[idx].start.utc = "";
            this.hikeStages[idx].start.local = "";
            this.hikeStages[idx].taskSortDay = 0;
          }
        }
        if (dtype === "end") {
          // set the local time without timezone
          tmp_datetime = (0, _momentTimezone.default)(this.hikeStages[idx].end.local).format("YYYY-MM-DD HH:mm:ss").toString();
          // set the tmp local time with timezone
          if (_momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].end.timezone).isValid()) {
            tmp_datetime = _momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].end.timezone).format();
            // this.hikeStages[idx].end.local = moment.tz(tmp_datetime, this.hikeStages[idx].end.timezone).format('YYYY-MM-DD HH:mm:ss').toString()
            (0, _log.showDebugLog)("TMP local new End time", tmp_datetime);
            this.hikeStages[idx].end.utc = _momentTimezone.default.parseZone(tmp_datetime).utc().format();
            (0, _log.showDebugLog)("utc-end", this.hikeStages[idx].end.utc);
          } else {
            this.hikeStages[idx].end.utc = "";
            this.hikeStages[idx].end.local = "";
          }
        }
        if (dtype === "enablestart") {
          // set the local time without timezone
          console.log("local enable start", this.hikeStages[idx].enableStart.local);
          tmp_datetime = (0, _momentTimezone.default)(this.hikeStages[idx].enableStart.local).format("YYYY-MM-DD HH:mm:ss").toString();

          // set the tmp local time with timezone
          if (_momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].enableStart.timezone).isValid()) {
            tmp_datetime = _momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].enableStart.timezone).format();
            // this.hikeStages[idx].enableStart.local = moment.tz(tmp_datetime, this.hikeStages[idx].enableStart.timezone).format('YYYY-MM-DD HH:mm:ss').toString()
            (0, _log.showDebugLog)("TMP local new time", tmp_datetime);
            this.hikeStages[idx].enableStart.utc = _momentTimezone.default.parseZone(tmp_datetime).utc().format();
            (0, _log.showDebugLog)("utc-enableStart", this.hikeStages[idx].enableStart.utc);
          } else {
            this.hikeStages[idx].enableStart.utc = "";
            this.hikeStages[idx].enableStart.local = "";
          }
        }
        if (dtype === "enableend") {
          // set the local time without timezone
          tmp_datetime = (0, _momentTimezone.default)(this.hikeStages[idx].enableEnd.local).format("YYYY-MM-DD HH:mm:ss").toString();
          // set the tmp local time with timezone
          if (_momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].enableEnd.timezone).isValid()) {
            tmp_datetime = _momentTimezone.default.tz(tmp_datetime, this.hikeStages[idx].enableEnd.timezone).format();
            // this.hikeStages[idx].end.local = moment.tz(tmp_datetime, this.hikeStages[idx].end.timezone).format('YYYY-MM-DD HH:mm:ss').toString()
            (0, _log.showDebugLog)("TMP local new End time", tmp_datetime);
            this.hikeStages[idx].enableEnd.utc = _momentTimezone.default.parseZone(tmp_datetime).utc().format();
            (0, _log.showDebugLog)("utc-end", this.hikeStages[idx].enableEnd.utc);
          } else {
            this.hikeStages[idx].enableEnd.utc = "";
            this.hikeStages[idx].enableEnd.local = "";
          }
        }
        // showDebugLog('utc-start', this.hikeStages[idx].start.utc)
      } else {
        this.hikeStages[idx].start.utc = "";
        this.hikeStages[idx].start.local = "";
        this.hikeStages[idx].end.utc = "";
        this.hikeStages[idx].end.local = "";
        this.hikeStages[idx].enableStart.utc = "";
        this.hikeStages[idx].enableStart.local = "";
        this.hikeStages[idx].enableEnd.utc = "";
        this.hikeStages[idx].enableEnd.local = "";
        this.hikeStages[idx].taskSortDay = 0;
      }
      this.$forceUpdate();
    },
    passBackToParent: function passBackToParent(res) {
      this.$emit("updateParentStage", res);
    },
    checkScoreExist: function checkScoreExist(action, actionSet) {
      var ret = false;
      if ((actionSet.type === "benchmark" || actionSet.type === "summit") && action.type === "video") {
        ret = true;
        if (action.scoreConfig === undefined) {
          ret = false;
        }
      } else {
        ret = false;
      }
      return ret;
    },
    handleActionSettingClose: function handleActionSettingClose(done) {
      this.dialogActionSettingPageVisible = false;
    },
    getDeadlineDate: function getDeadlineDate(type, startUtcTime, zone, days, stageType, idx) {
      var dl = "-";
      var retmsg = "";
      var nextStageStart = null;
      var warningMsg = "";
      var dayDiff;
      if (days) {
        var deadlineUtc = (0, _momentTimezone.default)(startUtcTime).utc().add(days, "days");
        if (idx < this.hikeStages.length - 1) {
          nextStageStart = (0, _momentTimezone.default)(this.hikeStages[idx + 1].start.utc).utc();
          dayDiff = parseInt((nextStageStart - deadlineUtc) / (24 * 3600 * 1000));
        }
        dl = type === "utc" ? deadlineUtc.format("YYYY-MM-DD HH:mm:ss").toString() : (0, _momentTimezone.default)(startUtcTime).tz(zone).add(days, "days").format("YYYY-MM-DD HH:mm:ss").toString();
        if (isNaN(Math.abs(dayDiff))) {
          if (nextStageStart && nextStageStart.isValid()) {
            warningMsg = "";
          } else {
            warningMsg = this.hikeStages.length > 1 ? ", <span class='report-open'>Starting Date in Stage 2 does not set</span>" : "*";
          }
        } else {
          if (nextStageStart > deadlineUtc) {
            warningMsg = ", <span class='report-complete'>".concat(Math.abs(dayDiff), "</span> day(s) <span class='report-complete'>BEFORE</span> next stage start");
          } else {
            warningMsg = ", <span class='report-open'>".concat(Math.abs(dayDiff), "</span> day(s) <span class=\"report-open\">AFTER</span> next stage start");
          }
        }
      }
      retmsg = stageType === "benchmark" ? "<u>".concat(dl, "</u> ").concat(warningMsg, " ") : "<u>".concat(dl, "</u>");
      return retmsg;
    },
    calDeadlineDay: function calDeadlineDay(type, idx) {
      var days;
      if (type === "summit") {
        if (this.hikeStages[idx].start.utc) {
          this.hikeStages[idx].taskSortDay = 7;
          console.log("sort day", this.hikeStages[idx].taskSortDay);
        } else {
          (0, _log.displayPopupMessage)("Please enter event start time", "warning");
        }
      }
      if (type === "benchmark") {
        if (this.hikeStages[idx].start.utc && this.hikeStages[idx + 1].start.utc) {
          var stage2StartBefore = (0, _momentTimezone.default)(this.hikeStages[idx + 1].start.utc).utc().add(-2, "days");
          var stage2Start = (0, _momentTimezone.default)(this.hikeStages[idx + 1].start.utc).utc();
          var stage1Start = (0, _momentTimezone.default)(this.hikeStages[idx].start.utc).utc();
          var stage1End = (0, _momentTimezone.default)(this.hikeStages[idx].end.utc).utc();
          if (stage2StartBefore < stage1End) {
            var newStage2Start = (0, _momentTimezone.default)(stage2Start).utc().add(1, "days");
            var dayDiff = parseInt((newStage2Start - stage1Start) / (24 * 3600 * 1000));
            this.hikeStages[idx].taskSortDay = dayDiff;
          } else {
            var dayDiff = parseInt((stage2StartBefore - stage1Start) / (24 * 3600 * 1000));
            this.hikeStages[idx].taskSortDay = dayDiff;
          }
          /*
          console.log('sort day- stage2StartBefore',  stage2StartBefore)
          console.log('sort day-start1Start',  stage1Start)
          console.log('sort day-benchmark',  this.hikeStages[idx].taskSortDay)
          console.log('sort day-diff',  dayDiff)
          */
        } else {
          (0, _log.displayPopupMessage)("Make sure stage 1 & 2 has start/end time", "warning");
        }
      }
    }
  }
};