"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.typed.uint8-array");
var _xlsx = _interopRequireDefault(require("xlsx"));
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//

var cptable = require("xlsx/dist/cpexcel.js");
var _default = exports.default = {
  props: {
    beforeUpload: Function,
    // eslint-disable-line
    onSuccess: Function // eslint-disable-line
  },
  data: function data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      }
    };
  },
  methods: {
    generateData: function generateData(_ref) {
      var header = _ref.header,
        results = _ref.results;
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop: function handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      var files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error('Only support uploading one file!');
        return;
      }
      var rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error('Only supports upload .csv suffix files');
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover: function handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload: function handleUpload() {
      this.$refs['excel-upload-input'].click();
    },
    handleClick: function handleClick(e) {
      var files = e.target.files;
      var rawFile = files[0]; // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$message.error('Only supports upload .csv suffix files');
        return false;
      }
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload: function upload(rawFile) {
      this.$refs['excel-upload-input'].value = null; // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      var before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData: function readerData(rawFile) {
      var _this = this;
      this.loading = true;
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          var workbook = null;
          //Check whether the file type is csv
          if (rawFile.type === "text/csv") {
            // Check whether the file type is utf-8 encoding. 
            var buf = new Uint8Array(data);
            var isUtf8File = (0, _validate.isUTF8)(buf);
            workbook = _xlsx.default.read(isUtf8File ? data : cptable.utils.decode(936, buf), {
              type: isUtf8File ? "array" : 'string'
            });
          } else {
            workbook = _xlsx.default.read(data, {
              type: 'array'
            });
          }
          var firstSheetName = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[firstSheetName];
          var header = _this.getHeaderRow(worksheet);
          var results = _xlsx.default.utils.sheet_to_json(worksheet);
          _this.generateData({
            header: header,
            results: results
          });
          _this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow: function getHeaderRow(sheet) {
      var headers = [];
      if (sheet['!ref'] === undefined) {
        return headers;
      }
      var range = _xlsx.default.utils.decode_range(sheet['!ref']);
      var C;
      var R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        var cell = sheet[_xlsx.default.utils.encode_cell({
          c: C,
          r: R
        })];
        /* find the cell in the first row */
        var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = _xlsx.default.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel: function isExcel(file) {
      return /\.(csv)$/.test(file.name);
    }
  }
};