var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "otr-line-spacing__30",
          attrs: { gutter: 20, width: "100%" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("search-box", {
                attrs: {
                  placeholder:
                    "Keyword Search: ID / Title / Internal Title / Description / SF Num",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search(1)
                  },
                },
                model: {
                  value: _vm.query.q,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "q", $$v)
                  },
                  expression: "query.q",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  ref: "status",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Status", clearable: "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("status")
                    },
                  },
                  model: {
                    value: _vm.query.publish,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "publish", $$v)
                    },
                    expression: "query.publish",
                  },
                },
                _vm._l(_vm.trainingStatus, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-select",
                {
                  ref: "producedBy",
                  staticClass: "filter-item",
                  attrs: { placeholder: "Produced By", clearable: "" },
                  on: { change: _vm.setMyTraining },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search(1)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.disableVisble("producedBy")
                    },
                  },
                  model: {
                    value: _vm.query.staffUuid,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "staffUuid", $$v)
                    },
                    expression: "query.staffUuid",
                  },
                },
                _vm._l(_vm.staffList, function (s) {
                  return _c("el-option", {
                    key: s.user,
                    attrs: { label: s.name, value: s.user },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search(1)
                    },
                  },
                },
                [_vm._v("Search")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/hike/create" } } },
                [
                  _c("el-button", { attrs: { type: "success" } }, [
                    _vm._v("Create"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.hikeList,
                    fit: "",
                    "highlight-current-row": "",
                    height: _vm.height,
                  },
                  on: { "sort-change": _vm.sort },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", fixed: "", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isExipred(scope.row.end.utc)
                              ? _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "mini" } },
                                  [_vm._v("Expired")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(scope.row.id))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Channel", fixed: "", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.channel))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Title",
                      fixed: "",
                      width: "250",
                      sortable: "custom",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              { staticClass: "otr-word-break" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: { to: "/hike/edit/" + scope.row.id },
                                  },
                                  [_c("u", [_vm._v(_vm._s(scope.row.title))])]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Internal Title",
                      width: "250",
                      sortable: "custom",
                      prop: "internal.title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getInternalTitle")(scope.row.internal)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        width: "100",
                        sortable: "custom",
                        prop: "internal.opportunityNumber",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "otr-word-break" }, [
                                _vm._v(
                                  _vm._s(
                                    typeof scope.row.internal === "undefined"
                                      ? ""
                                      : scope.row.internal.opportunityNumber
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v("\n            Salesforce"),
                        _c("br"),
                        _vm._v("\n            Opportunity"),
                        _c("br"),
                        _vm._v("\n            Number\n          "),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        width: "150",
                        sortable: "custom",
                        prop: "start",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.start.utc,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.start.local,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [_vm._v("UTC Start Time /")]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { color: "blue" } }, [
                          _vm._v("Local Start Time"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "End Time",
                        width: "150",
                        sortable: "custom",
                        prop: "end",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.end.utc,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scope.row.end.local,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [_vm._v("UTC End Time /")]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { color: "blue" } }, [
                          _vm._v("Local End Time"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.producer"),
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "otr-word-break" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getProducerStaff")(
                                    scope.row.staff,
                                    _vm.staffList
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Status",
                      width: "100",
                      sortable: "custom",
                      prop: "publish",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.publish))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Updated At",
                      width: "120",
                      sortable: "custom",
                      prop: "updatedAt",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.updatedAt,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Actions", width: "500" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              { attrs: { to: "/hike/edit/" + scope.row.id } },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Edit",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "edit" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/hike/action-setting/" + scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Activity Settings",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-setting",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Copy Hike",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "10px 10px" },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDrawer(
                                          scope.row.id,
                                          scope.row.title
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-document-copy",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              { attrs: { to: "/hike/enroll/" + scope.row.id } },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Enrollment List",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "hiker" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/hike/award-steps/" + scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Award Steps",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "steps" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/hike/checking/report/" +
                                    scope.row.id +
                                    "?type=benchmark",
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Benchmark Scoring",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [_vm._v("B")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/hike/checking/report/" +
                                    scope.row.id +
                                    "?type=summit",
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Summit Scoring",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [_vm._v("S")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.ratingMethod === "by_assignment"
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/hike/task-report/" + scope.row.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Task Status Report",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              padding: "10px 10px",
                                            },
                                            attrs: { type: "primary" },
                                          },
                                          [_vm._v("T")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/hike/attendant-report/" +
                                    scope.row.id +
                                    "?title=" +
                                    scope.row.title,
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Attendance Report",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "documentation",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/hike/activity-report/" +
                                    scope.row.id +
                                    "?title=" +
                                    scope.row.title,
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Activity Report",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "skill" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "TAS Rating",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "10px 10px" },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadTASRating(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("R")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.category === "manager"
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/hike/send-survey-notification/" +
                                        scope.row.id +
                                        "?title=" +
                                        scope.row.title,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Send Survey Notification",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              padding: "10px 10px",
                                            },
                                            attrs: { type: "primary" },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-message",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/hike/feedback-result/" + scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "In-app Feedback Results",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "primary" },
                                      },
                                      [_vm._v("F")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Delete Hike",
                                  placement: "top",
                                },
                              },
                              [
                                _vm.checkPermission([
                                  "root",
                                  "otr-digital-producer",
                                ])
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "10px 10px" },
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteHike(
                                              scope.row.id,
                                              scope.row.title
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete-solid",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.query.limit,
                      "pager-count": 5,
                      total: _vm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.checked.drawTitle,
            visible: _vm.copyDrawer,
            direction: "ttb",
            "before-close": _vm.handleDrawClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.copyDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "20px" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "title", $$v)
                    },
                    expression: "checked.title",
                  },
                },
                [_vm._v("Hike's Title")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.internalTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "internalTitle", $$v)
                    },
                    expression: "checked.internalTitle",
                  },
                },
                [_vm._v("Hike's InternalTitle")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "description", $$v)
                    },
                    expression: "checked.description",
                  },
                },
                [_vm._v("Hike's Descriptions")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.eventDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "eventDate", $$v)
                    },
                    expression: "checked.eventDate",
                  },
                },
                [_vm._v("Hike's Event Time")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.clientName,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "clientName", $$v)
                    },
                    expression: "checked.clientName",
                  },
                },
                [_vm._v("Hike's ClientName")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.resources,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "resources", $$v)
                    },
                    expression: "checked.resources",
                  },
                },
                [_vm._v("Hike's Resources")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.webinarInfo,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "webinarInfo", $$v)
                    },
                    expression: "checked.webinarInfo",
                  },
                },
                [_vm._v("Hike's Webinar Info")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.isFeedbackEnabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "isFeedbackEnabled", $$v)
                    },
                    expression: "checked.isFeedbackEnabled",
                  },
                },
                [_vm._v("Hike's In-app feedback")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.stages,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "stages", $$v)
                    },
                    expression: "checked.stages",
                  },
                },
                [_vm._v("Stages")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.report,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "report", $$v)
                    },
                    expression: "checked.report",
                  },
                },
                [_vm._v("Report")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.checked.emailSettings,
                    callback: function ($$v) {
                      _vm.$set(_vm.checked, "emailSettings", $$v)
                    },
                    expression: "checked.emailSettings",
                  },
                },
                [_vm._v("Email Settings")]
              ),
              _vm._v(" "),
              false
                ? _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked.stagesDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.checked, "stagesDate", $$v)
                        },
                        expression: "checked.stagesDate",
                      },
                    },
                    [_vm._v("Stage Date")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "40px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "10px 10px" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.copy },
                    },
                    [_vm._v("Copy")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }