"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lifehikesInitData = require("@/lifehikes-init-data");
var _vue2Editor = require("vue2-editor");
var _elementUi = require("element-ui");
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getCookie } from '@/utils/cookie'

var tmpHikeGeninfo = {};
// const defaultValue = []
var _default = exports.default = {
  name: 'HikeGeninfo',
  components: {
    VueEditor: _vue2Editor.VueEditor,
    EventTime: _index.default
  },
  props: {
    parentHike: {
      type: Object,
      default: tmpHikeGeninfo
    }
  },
  data: function data() {
    return {
      customToolbar: [['bold'], [{
        list: 'bullet'
      }]],
      loading: false,
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      countryList: [],
      tagInputVisible: false,
      /* use for Tag component */
      tagInputValue: '',
      /* use for tag component */
      hike: {},
      HikeCategory: _lifehikesInitData.HikeCategory
    };
  },
  watch: {
    hike: {
      handler: function handler(newVal) {
        this.passBackGenInfo(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    this.hike = this.parentHike;
    this.$store.dispatch('facets/getInitData', 'en').then(function () {
      _this.countryList = _this.$store.getters.countries;
    });
  },
  methods: {
    copyPostProgramFeedbackSurveyLandingPageLink: function copyPostProgramFeedbackSurveyLandingPageLink() {
      navigator.clipboard.writeText(this.hike.postProgramFeedbackSurveyLandingPageUrl);
      _elementUi.Message.info('Copied to Clipboard');
    },
    openPostProgramFeedbackSurveyLandingPage: function openPostProgramFeedbackSurveyLandingPage() {
      window.open(this.hike.postProgramFeedbackSurveyLandingPageUrl, '_blank');
    },
    prefillFeedbackLink: function prefillFeedbackLink() {
      this.hike.feedbackUrl = "https://otr.link/".concat(this.hike.internal.opportunityNumber);
    },
    passBackGenInfo: function passBackGenInfo(myHike) {
      this.$emit('updateParentGenInfo', myHike);
    },
    handleTagInputConfirm: function handleTagInputConfirm() {
      var inputValue = this.tagInputValue;
      if (inputValue && this.hike.tags.indexOf(inputValue) < 0) {
        this.hike.tags.push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    },
    showTagInput: function showTagInput() {
      var _this2 = this;
      this.tagInputVisible = true;
      this.$nextTick(function (_) {
        _this2.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleClose: function handleClose(tag) {
      this.hike.tags.splice(this.hike.tags.indexOf(tag), 1);
    },
    showUTCDate: function showUTCDate(utcDate) {
      //'YYYY-MM-DD HH:mm:ss [GMT]Z[ (]z[)]'
      //'YYYY-MM-DD HH:mm:ss (zZ)'
      return (0, _momentTimezone.default)(utcDate).utc().format('YYYY-MM-DD HH:mm:ss (z+0)');
    }
  }
};