var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "Gender" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.me.genderCode,
                callback: function ($$v) {
                  _vm.$set(_vm.me, "genderCode", $$v)
                },
                expression: "me.genderCode",
              },
            },
            _vm._l(_vm.genderList, function (item) {
              return _c("el-option", {
                key: item.genderCode,
                attrs: { label: item.gender, value: item.genderCode },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "*Given Name" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.givenName,
              callback: function ($$v) {
                _vm.$set(_vm.me, "givenName", $$v)
              },
              expression: "me.givenName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "*Surname" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.familyName,
              callback: function ($$v) {
                _vm.$set(_vm.me, "familyName", $$v)
              },
              expression: "me.familyName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Phone" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.phone,
              callback: function ($$v) {
                _vm.$set(_vm.me, "phone", $$v)
              },
              expression: "me.phone",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Birthday" } },
        [
          _c("el-date-picker", {
            staticStyle: { width: "100%" },
            attrs: {
              type: "date",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              placeholder: "Select date",
            },
            model: {
              value: _vm.me.birthday,
              callback: function ($$v) {
                _vm.$set(_vm.me, "birthday", $$v)
              },
              expression: "me.birthday",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "City" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.city,
              callback: function ($$v) {
                _vm.$set(_vm.me, "city", $$v)
              },
              expression: "me.city",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "State" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.state,
              callback: function ($$v) {
                _vm.$set(_vm.me, "state", $$v)
              },
              expression: "me.state",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Country" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "Select" },
              model: {
                value: _vm.me.countryCode,
                callback: function ($$v) {
                  _vm.$set(_vm.me, "countryCode", $$v)
                },
                expression: "me.countryCode",
              },
            },
            _vm._l(_vm.countryList, function (item) {
              return _c("el-option", {
                key: item.countryCode,
                attrs: { label: item.country, value: item.countryCode },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Company" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.orgName,
              callback: function ($$v) {
                _vm.$set(_vm.me, "orgName", $$v)
              },
              expression: "me.orgName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Title" } },
        [
          _c("el-input", {
            model: {
              value: _vm.me.jobTitle,
              callback: function ($$v) {
                _vm.$set(_vm.me, "jobTitle", $$v)
              },
              expression: "me.jobTitle",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "Bio" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 5, placeholder: "Please input" },
            model: {
              value: _vm.me.bio,
              callback: function ($$v) {
                _vm.$set(_vm.me, "bio", $$v)
              },
              expression: "me.bio",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Update")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }