"use strict";

var _interopRequireDefault = require("/codebuild/output/src365382019/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/codebuild/output/src365382019/src/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("codemirror/lib/codemirror.css");
require("tui-editor/dist/tui-editor.css");
require("tui-editor/dist/tui-editor-contents.css");
var _tuiEditor2 = _interopRequireDefault(require("tui-editor"));
var _defaultOptions = _interopRequireDefault(require("./default-options"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
// deps for editor
// codemirror
// editor ui
// editor content
var _default2 = exports.default = {
  name: 'MarkdownEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: function _default() {
        return 'markdown-editor-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return _defaultOptions.default;
      }
    },
    mode: {
      type: String,
      default: 'markdown'
    },
    height: {
      type: String,
      required: false,
      default: '300px'
    },
    language: {
      type: String,
      required: false,
      default: 'en_US' // https://github.com/nhnent/tui.editor/tree/master/src/js/langs
    }
  },
  data: function data() {
    return {
      editor: null
    };
  },
  computed: {
    editorOptions: function editorOptions() {
      var options = Object.assign({}, _defaultOptions.default, this.options);
      options.initialEditType = this.mode;
      options.height = this.height;
      options.language = this.language;
      return options;
    }
  },
  watch: {
    value: function value(newValue, preValue) {
      if (newValue !== preValue && newValue !== this.editor.getValue()) {
        this.editor.setValue(newValue);
      }
    },
    language: function language(val) {
      this.destroyEditor();
      this.initEditor();
    },
    height: function height(newValue) {
      this.editor.height(newValue);
    },
    mode: function mode(newValue) {
      this.editor.changeMode(newValue);
    }
  },
  mounted: function mounted() {
    this.initEditor();
  },
  destroyed: function destroyed() {
    this.destroyEditor();
  },
  methods: {
    initEditor: function initEditor() {
      var _this = this;
      this.editor = new _tuiEditor2.default(_objectSpread({
        el: document.getElementById(this.id)
      }, this.editorOptions));
      if (this.value) {
        this.editor.setValue(this.value);
      }
      this.editor.on('change', function () {
        _this.$emit('input', _this.editor.getValue());
      });
    },
    destroyEditor: function destroyEditor() {
      if (!this.editor) return;
      this.editor.off('change');
      this.editor.remove();
    },
    setValue: function setValue(value) {
      this.editor.setValue(value);
    },
    getValue: function getValue() {
      return this.editor.getValue();
    },
    setHtml: function setHtml(value) {
      this.editor.setHtml(value);
    },
    getHtml: function getHtml() {
      return this.editor.getHtml();
    }
  }
};