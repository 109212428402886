var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v("\n     " + _vm._s(_vm.internalTitle) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "heading" },
        [
          _vm.form.loop
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.openDialogBox },
                },
                [_vm._v("Create Activation Code")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        _vm._l(_vm.codeList, function (c, index) {
          return _c(
            "div",
            { key: index, class: { codeCard: true, used: true } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("p", [
                      _c("b", [_vm._v("Status")]),
                      _vm._v(": " + _vm._s(c.status)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "displayCode",
                        refInFor: true,
                        staticStyle: {
                          width: "90%",
                          padding: "10px",
                          height: "320px",
                          "text-align": "center",
                          overflow: "auto",
                          "background-color": "#ffffff",
                        },
                      },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/otr/images/lifehike_icon_high.png"),
                              height: "40",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("Scan this activation code using the"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _vm._v(
                              "\n              LifeHikes App to enroll in your program\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("qrcode-vue", {
                          attrs: {
                            value: c.activationUrl,
                            size: "150",
                            level: "H",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.printCode(index)
                              },
                            },
                          },
                          [_vm._v("Display Enrollment Code")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "p",
                        [
                          _c("b", [_vm._v("reuse Time")]),
                          _vm._v(":\n              "),
                          _c("el-input-number", {
                            attrs: {
                              min: _vm.originalCodeList[index].userLimit,
                              max: 999,
                              size: "mini",
                            },
                            model: {
                              value: c.userLimit,
                              callback: function ($$v) {
                                _vm.$set(c, "userLimit", $$v)
                              },
                              expression: "c.userLimit",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                plain: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateLimit(c, index)
                                },
                              },
                            },
                            [_vm._v("Update")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "(Remain Quota: " +
                            _vm._s(c.userLimit - c.activatedUsers.length) +
                            ")"
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("p", [_c("b", [_vm._v("Users")])]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "320px",
                          padding: "10px",
                          overflow: "auto",
                          "background-color": "#ffffff",
                        },
                      },
                      _vm._l(c.activatedUsers, function (u, i) {
                        return _c("div", { key: i }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(u.user === null ? "" : u.user.email) +
                              "\n            "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "word-wrap": "break-word",
                    "font-size": "11px",
                  },
                },
                [
                  _c("b", [_vm._v(_vm._s(_vm.$t("otrTraining.code")))]),
                  _vm._v(": " + _vm._s(c.code) + "\n      "),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Generate Code",
            visible: _vm.dialogVisible,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          false
            ? _c(
                "el-row",
                { staticClass: "otr-line-spacing__10", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(_vm._s(_vm.$t("otrTraining.noOfCode"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 999 },
                        model: {
                          value: _vm.form.numberOfCodes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "numberOfCodes", $$v)
                          },
                          expression: "form.numberOfCodes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__10", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _vm._v(_vm._s(_vm.$t("otrTraining.reuseTimes"))),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 999 },
                    model: {
                      value: _vm.form.userLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userLimit", $$v)
                      },
                      expression: "form.userLimit",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "otr-line-spacing__10", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _vm.errorMsg
                  ? _c("span", { style: _vm.errorStyle }, [
                      _vm._v(_vm._s(_vm.errorMsg)),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.disableGenButton },
                  on: { click: _vm.genCode },
                },
                [_vm._v("Generate")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Enrollment Code",
            visible: _vm.codeDialogVisible,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeDialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              margin: "0 auto",
              "max-width": "400px",
              border: "1px solid #000",
            },
            attrs: { src: _vm.codeImg },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "a",
                {
                  staticClass: "link-button",
                  attrs: {
                    download: "QR-" + _vm.form.loop + ".png",
                    href: _vm.codeImg,
                  },
                },
                [_vm._v("Download")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.codeDialogVisible = false
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }