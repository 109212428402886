"use strict";

var _interopRequireDefault = require("/codebuild/output/src582379239/src/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _log = require("@/utils/log");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _lifehikesInitData = require("@/lifehikes-init-data");
var _index = _interopRequireDefault(require("../../../../components/EventTime/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { fetchList, getResourceByTraining } from '@/api/resource'
// import _ from 'lodash'
// import { getCookie } from '@/utils/cookie'
// import { Datetime } from 'vue-datetime'
var defaultValue = [];
var defEventStart = {
  utc: '',
  timezone: _momentTimezone.default.tz.guess(),
  local: ''
};
var defEventEnd = {
  utc: '',
  timezone: _momentTimezone.default.tz.guess(),
  local: ''
};
var _default = exports.default = {
  name: 'TrainingSession',
  components: {
    Tinymce: _Tinymce.default,
    QrcodeVue: _qrcode.default,
    EventTime: _index.default
  },
  props: {
    parentSession: {
      type: Array,
      default: defaultValue
    },
    trainingId: {
      type: String,
      default: ''
    },
    fromId: {
      type: String,
      default: ''
    },
    internalTitle: {
      type: String,
      default: ''
    },
    channel: {
      type: String,
      default: 'ilt'
    },
    eventStart: {
      type: Object,
      default: defEventStart
    },
    eventEnd: {
      type: Object,
      default: defEventEnd
    },
    webinarName: {
      type: String,
      default: ''
    },
    webinarKey: {
      type: String,
      default: ''
    },
    webinarPasscode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      firstTab: 'session1',
      loading: false,
      sessions: [],
      testval: '',
      timeZoneList: _lifehikesInitData.ianaTimeZone,
      sessionTypeList: [{
        label: 'module',
        value: 'moment'
      }, {
        label: 'summit',
        value: 'summit'
      }, {
        label: 'info',
        value: 'info'
      }]
    };
  },
  watch: {
    sessions: {
      handler: function handler(newVal) {
        // showDebugLog('Child-module emit to parent new value', newVal)
        this.passBackToParent(newVal);
      },
      deep: true
    }
  },
  created: function created() {
    this.sessions = this.parentSession;
    console.log('session', this.sessions);
    /*
    for (var idx = 0; idx < this.sessions.length; idx++) {
      if (this.sessions[idx].id) {
        this.sessions.splice(idx, 1, Object.assign({}, this.sessions[idx], { '_id': this.sessions[idx].id }))
        // this.sessions[idx]._id = this.sessions[idx].id
      }
       if (typeof this.sessions[idx].start === 'undefined') {
        this.sessions[idx].start = { utc: '', timezone: moment.tz.guess(), local: '' }
      } else {
        var start = Object.assign({}, this.sessions[idx].start, { localtime: this.calLocalTime(this.sessions[idx].start.utc, this.sessions[idx].start.timezone) })
        this.sessions.splice(idx, 1, Object.assign({}, this.sessions[idx],
          { 'start': start }))
      }
       if (typeof this.sessions[idx].end === 'undefined') {
        this.sessions[idx].end = { utc: '', timezone: moment.tz.guess(), localtime: '' }
      } else {
        var end = Object.assign({}, this.sessions[idx].end, { localtime: this.calLocalTime(this.sessions[idx].end.utc, this.sessions[idx].end.timezone) })
        this.sessions.splice(idx, 1, Object.assign({}, this.sessions[idx],
          { 'end': end }))
      }
       if (typeof this.sessions[idx].recordingStart === 'undefined') {
        this.sessions[idx].recordingStart = { utc: '', timezone: moment.tz.guess(), localtime: '' }
      } else {
        var recordingStart = Object.assign({}, this.sessions[idx].recordingStart, { localtime: this.calLocalTime(this.sessions[idx].recordingStart.utc, this.sessions[idx].recordingStart.timezone) })
        this.sessions.splice(idx, 1, Object.assign({}, this.sessions[idx],
          { 'recordingStart': recordingStart }))
      }
       if (typeof this.sessions[idx].recordingEnd === 'undefined') {
        this.sessions[idx].recordingEnd = { utc: '', timezone: moment.tz.guess(), localtime: '' }
      } else {
        var recordingEnd = Object.assign({}, this.sessions[idx].recordingEnd, { localtime: this.calLocalTime(this.sessions[idx].recordingEnd.utc, this.sessions[idx].recordingEnd.timezone) })
        this.sessions.splice(idx, 1, Object.assign({}, this.sessions[idx],
          { 'recordingEnd': recordingEnd }))
      }
    }
    */
    (0, _log.showDebugLog)('new session', this.sessions);
  },
  methods: {
    setRecordingTime: function setRecordingTime(idx) {
      this.sessions[idx].recordingStart = _.cloneDeep(this.sessions[idx].start);
      // this.sessions[idx].recordingStart.utc = this.sessions[idx].start.utc
      // this.sessions[idx].recordingStart.timezone = this.sessions[idx].start.timezone

      this.sessions[idx].recordingEnd = _.cloneDeep(this.sessions[idx].end);
      // this.sessions[idx].recordingEnd.utc = this.sessions[idx].end.utc
      // this.sessions[idx].recordingEnd.timezone = this.sessions[idx].end.timezone
    },
    addSession: function addSession() {
      var coins = this.channel === 'ilt' ? 100 : 20;
      var s = {
        type: 'moment',
        title: '',
        shortDescription: '',
        description: '',
        allowCheckin: true,
        start: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        end: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        recordingStart: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        recordingEnd: {
          utc: '',
          timezone: _momentTimezone.default.tz.guess(),
          local: ''
        },
        webinarName: '',
        webinarKey: '',
        webinarPasscode: '',
        defaultCoin: coins
      };
      this.sessions.push(s);
      this.firstTab = "session".concat(this.sessions.length);
    },
    removeSession: function removeSession(idx) {
      this.sessions.splice(idx, 1);
    },
    copyLastSession: function copyLastSession() {
      // this.addSession()
      var lastIdx = this.sessions.length - 1;
      // const oriIdx = this.sessions.length - 2

      var start = {
        utc: this.sessions[lastIdx].start.utc,
        timezone: this.sessions[lastIdx].start.timezone,
        local: this.sessions[lastIdx].start.local
      };
      var end = {
        utc: this.sessions[lastIdx].end.utc,
        timezone: this.sessions[lastIdx].end.timezone,
        local: this.sessions[lastIdx].end.local
      };
      var rstart = {
        utc: this.sessions[lastIdx].recordingStart.utc,
        timezone: this.sessions[lastIdx].recordingStart.timezone,
        local: this.sessions[lastIdx].recordingStart.local
      };
      var rend = {
        utc: this.sessions[lastIdx].recordingEnd.utc,
        timezone: this.sessions[lastIdx].recordingEnd.timezone,
        local: this.sessions[lastIdx].recordingEnd.local
      };
      var s = {
        type: this.sessions[lastIdx].type,
        title: this.sessions[lastIdx].title,
        shortDescription: this.sessions[lastIdx].shortDescription,
        description: this.sessions[lastIdx].description,
        allowCheckin: this.sessions[lastIdx].allowCheckin,
        start: start,
        end: end,
        recordingStart: rstart,
        recordingEnd: rend,
        webinarName: this.sessions[lastIdx].webinarName,
        webinarKey: this.sessions[lastIdx].webinarKey,
        webinarPasscode: this.sessions[lastIdx].webinarPasscode,
        defaultCoin: this.sessions[lastIdx].defaultCoin
      };
      this.sessions.push(s);
      this.firstTab = "session".concat(this.sessions.length);
    },
    copyTimeFromEvent: function copyTimeFromEvent(idx) {
      this.sessions[idx].start = _.cloneDeep(this.eventStart);
      this.sessions[idx].end = _.cloneDeep(this.eventEnd);
    },
    copyWebinarFromEvent: function copyWebinarFromEvent(idx) {
      this.sessions[idx].webinarName = _.cloneDeep(this.webinarName);
      this.sessions[idx].webinarKey = _.cloneDeep(this.webinarKey);
      this.sessions[idx].webinarPasscode = _.cloneDeep(this.webinarPasscode);
    },
    passBackToParent: function passBackToParent(res) {
      this.$emit('updateParentSession', res);
    }
  }
};