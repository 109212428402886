var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullscreenloading,
          expression: "fullscreenloading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "background-color": "#eee",
            padding: "4px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.internalTitle))]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "otr-line-spacing__20" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.myTrainingTeams,
                    fit: "",
                    "highlight-current-row": "",
                    "row-class-name": _vm.tableRowClassName,
                  },
                  on: { "sort-change": _vm.sort_change },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "", type: "index" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Participant ID",
                      prop: "uuid",
                      sortable: "custom",
                      "sort-orders": ["ascending", "descending"],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.uuid))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.name"),
                      sortable: "custom",
                      prop: "givenName",
                      "sort-orders": ["ascending", "descending"],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.givenName) +
                                  "\n              " +
                                  _vm._s(scope.row.familyName)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("otrTraining.orgName"),
                      sortable: "custom",
                      prop: "orgName",
                      "sort-orders": ["ascending", "descending"],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.orgName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Is Done",
                      sortable: "custom",
                      prop: "baselineId",
                      "sort-orders": ["ascending", "descending"],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.baselineId === "" ? "NO" : "YES"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }