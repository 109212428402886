var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "otr-line-spacing__30",
          attrs: { gutter: 20, width: "100%" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 9 } },
            [
              _vm._v("Range (Hike Start Date) :\n      "),
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "Pick a day" },
                model: {
                  value: _vm.start,
                  callback: function ($$v) {
                    _vm.start = $$v
                  },
                  expression: "start",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _vm._v("To :\n      "),
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "Pick a day" },
                model: {
                  value: _vm.end,
                  callback: function ($$v) {
                    _vm.end = $$v
                  },
                  expression: "end",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search(1)
                    },
                  },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.loading,
                      expression: "loading",
                      modifiers: { body: true },
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.jobList,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "40" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Hike", width: "230" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.hikeTitle) + " "),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.hikeCategory)),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(scope.row.hike))]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(scope.row.userUuid))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Type", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.stageType))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Have User Done Benchmark/Summit",
                      width: "200",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              scope.row.userUploadAllVideo
                                ? _c("i", {
                                    staticClass: "el-icon-check",
                                    staticStyle: { color: "green" },
                                  })
                                : _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: { color: "red" },
                                  }),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "No. Of Rater",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.noOfRater))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Pickup by", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.assignTo,
                            function (item, index) {
                              return _c("div", { key: index }, [
                                _vm._v(_vm._s(_vm.getUserName(item))),
                              ])
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Task Assigned",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              scope.row.assignTo.length >= scope.row.noOfRater
                                ? _c("i", {
                                    staticClass: "el-icon-check",
                                    staticStyle: { color: "green" },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.query.limit,
                      "pager-count": 5,
                      total: _vm.jobs.length,
                      background: "",
                      layout: "total, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }