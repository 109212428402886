"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayPopupMessage = displayPopupMessage;
exports.getErrorMessage = getErrorMessage;
exports.showDebugLog = showDebugLog;
require("core-js/modules/es6.regexp.to-string");
var _elementUi = require("element-ui");
function showDebugLog(caption, content) {
  if (process.env.VUE_APP_ENV === 'development') {
    console.log(caption, content);
  }
}
function getErrorMessage(errorObj) {
  var msg = typeof errorObj.response === 'undefined' ? errorObj.toString() : errorObj.response.data.message;
  return msg;
}
function displayPopupMessage(errorObj, type, duration) {
  var showStack = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  // console.log('error type', typeof errorObj)
  // console.log('error ', errorObj)
  // console.log('duration', duration);
  if (duration === null || duration === undefined) {
    duration = 5 * 1000;
  }
  var msg = '';
  if (typeof errorObj.response === 'undefined') {
    msg = errorObj.toString();
  } else if (typeof errorObj.response.data.stack !== 'undefined' && showStack === true) {
    msg = errorObj.response.data.stack;
  } else if (typeof errorObj.response.data.message !== 'undefined') {
    msg = errorObj.response.data.message;
  } else {
    msg = 'Internal Error';
  }
  (0, _elementUi.Message)({
    message: msg,
    type: type,
    duration: duration,
    showClose: true
  });
}